import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import QTextFieldWithController from 'components/UI/TextFields/QTextFieldWithController';
import { useTimer } from 'hooks/useTimer';
import { LoginFormFields } from 'modules/auth/forms/LoginForm';
import { useLoginFormPersistance } from 'screens/auth/hooks/useLoginFormPersistance';
import { useCommonStyles } from 'styles/commonStyles';

interface IMFAStepProps {
  onChooseMFAMethodClick: () => void;
  onGetNewCodeClick: () => void;
  isLoading: boolean;
  deadline: number;
}

const MFAStep: FC<IMFAStepProps> = observer(({ onChooseMFAMethodClick, onGetNewCodeClick, isLoading, deadline }) => {
  const {
    authStore,
    languageToggleStore: { _lk, _stringFormat, translations },
  } = useRootStore();
  const { formState, getValues, setError, clearErrors, control } = useFormContext();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { minutes, seconds, timeIsUp } = useTimer(deadline);

  const isMultipleMFAMethods = useMemo(
    () => !!(authStore.mfaTypes && authStore.mfaTypes.length > 1),
    [authStore.mfaTypes]
  );
  const phone = authStore.mfaData?.phone || '';
  const messengerName =
    authStore.mfaData?.messengersTypes?.find(type => {
      return type.messengertypeid === getValues()[LoginFormFields.messengertypeid];
    })?.label || '';
  const currentCode = getValues()[LoginFormFields.mfacode];

  const disabled = !formState.isValid || !formState.isDirty;

  // Effects
  useLoginFormPersistance();

  useEffect(() => {
    if (authStore.mfa?.status === -10) {
      setError(LoginFormFields.mfacode, { message: _lk('Неверный код') }, { shouldFocus: true });
    }

    if (currentCode?.length < 6 && currentCode?.length > 0) {
      authStore.setMfa(null);
      clearErrors(LoginFormFields.mfacode);
    }
  }, [authStore.mfa, currentCode, seconds]);

  // Renders
  return (
    <>
      <Typography variant="body1" textAlign="center" className={classes.subtitle}>
        {_stringFormat(
          /* prettier-ignore */
          _lk('Мы отправили код в {messengerName} на номер {phone}, введите его или выберите другой способ подтверждения'),
          {
            messengerName,
            phone,
          }
        )}
      </Typography>
      <QTextFieldWithController
        name={LoginFormFields.mfacode}
        hookFormProps={{ control }}
        containerProps={{ my: 8 }}
        InputProps={{ sx: { maxWidth: 96, mx: 'auto' } }}
        FormHelperTextProps={{ sx: { textAlign: 'center' } }}
        inputProps={{ style: { textAlign: 'center' } }}
        errors={formState.errors[LoginFormFields.mfacode]?.message}
      />
      {!timeIsUp && (
        <Typography component="p" variant="caption" textAlign="center">
          {_stringFormat(_lk('Получить новый код можно через {minutes}:{seconds}'), { minutes, seconds })}
        </Typography>
      )}
      <Box mt={2} className={commonClasses.loginButtons}>
        <Button variant="contained" type="submit" disabled={disabled}>
          {isLoading ? <Loader color="#fff" size={24} /> : translations.send}
        </Button>
        {timeIsUp && (
          <Button variant="contained-contrast" onClick={onGetNewCodeClick}>
            {_lk('Получить новый код')}
          </Button>
        )}
        {isMultipleMFAMethods && (
          <Button variant="contained-contrast" onClick={onChooseMFAMethodClick}>
            {translations.mfaChangeMethod}
          </Button>
        )}
      </Box>
    </>
  );
});

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: theme.palette.customColors.surfaceTertiary,
  },
}));

export default MFAStep;
