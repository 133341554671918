import { Box, Button } from '@mui/material';
import { FC, useEffect } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import QSelectItem from 'components/UI/Selects/QSelectItem';
import QSelectWithController from 'components/UI/Selects/QSelectWithController';
import { LoginFormFields } from 'modules/auth/forms/LoginForm';
import { ClientForAuth } from 'modules/clients/models/ClientForAuth';
import { useLoginFormPersistance } from 'screens/auth/hooks/useLoginFormPersistance';
import { useCommonStyles } from 'styles/commonStyles';

interface IClientChoiceStepProps {
  isLoading: boolean;
  clients?: ClientForAuth[] | null;
}

const ClientChoiceStep: FC<IClientChoiceStepProps> = ({ clients, isLoading }) => {
  const commonClasses = useCommonStyles();
  const { control, watch } = useFormContext();
  const {
    authStore,
    languageToggleStore: { translations },
  } = useRootStore();

  // Effects
  useLoginFormPersistance();

  useEffect(() => {
    const subscr = watch((values: FieldValues, { name }) => {
      if (name !== LoginFormFields.clientid) {
        return;
      }

      const client = clients?.find(client => client.clientid === values[name]);

      if (!client) {
        return;
      }

      authStore.setAuthClient(client);
    });

    return () => subscr.unsubscribe();
  }, []);

  // Renders
  return (
    <>
      <Box mt={10} mb={10}>
        <QSelectWithController hookFormProps={{ control }} name={LoginFormFields.clientid}>
          {clients?.map(({ clientid, clientname }) => (
            <QSelectItem key={clientid} value={clientid}>
              {clientname}
            </QSelectItem>
          ))}
        </QSelectWithController>
      </Box>
      <Box className={commonClasses.loginButtons}>
        <Button variant="contained" fullWidth type="submit">
          {isLoading ? <Loader color="#fff" size={24} /> : translations.enter}
        </Button>
      </Box>
    </>
  );
};

export default ClientChoiceStep;
