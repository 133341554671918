import { jsonProperty, Serializable } from 'ts-serializable';

export class DataCenterModel extends Serializable {
  @jsonProperty(Number, null) dcid: number | null = null;
  @jsonProperty(Number, null) regid: number | null = null;
  @jsonProperty(String, null) regname: string | null = null;
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(String, null) urlping: string | null = null;
  @jsonProperty(String, null) urltrace: string | null = null;
  @jsonProperty(String, null) dns1: string | null = null;
  @jsonProperty(String, null) dns2: string | null = null;
  @jsonProperty(String, null) dns3: string | null = null;
  @jsonProperty(String, null) dns4: string | null = null;
  @jsonProperty(String, null) dns5: string | null = null;
}
