import ThemeToggleLocalRepository from './ThemeToggleLocalRepository';
import { ThemeTypes } from './types/ThemeToggleTypes';

export default class ThemeToggleService {
  themeLocal: ThemeToggleLocalRepository;

  constructor() {
    this.themeLocal = new ThemeToggleLocalRepository();
  }

  saveTheme = (theme: string) => {
    this.themeLocal.set(theme);
  };

  getTheme = () => {
    const theme = this.themeLocal.get() as ThemeTypes | null;
    return theme ? theme : ThemeTypes.SYSTEM;
  };
}
