import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { FileValidationRules } from 'base/modules/file-validation/models/FileValidationRules';
import { FilesHelper, IFileDownloadData } from 'helpers/FilesHelper';

import DocumentsService from './DocumentsService';
import { DocumentForm, DocumentFormFields } from './forms/DocumentForm';
import { DocumentsTableForm } from './forms/DocumentsTableForm';
import { DocumentsHelper } from './helpers/DocumentsHelper';
import { IDocumentsListQueryParams } from './interfaces/DocumentsInterfaces';
import { Document } from './models/Document';
import { DocumentToShow } from './models/DocumentToShow';
import { DocumentFile } from './models/file/DocumentFile';

export class DocumentsStore {
  documentsListLoading: boolean = false;
  documentIsLoading: boolean = false;
  documentsDownloading: boolean = false;
  documentFilesDeleting: boolean = false;
  documentsUploading: boolean = false;
  validationRulesLoading: boolean = false;
  someDocumentsDownloading: Map<number, boolean> = new Map();
  filesForRequisitesLoadings: Map<number, boolean> = new Map();

  approvalDocuments: Document[] | null = null;
  currentDocument: Document | null = null;
  readyToShowDocument: IFileDownloadData | null = null;

  availableDocuments: Document[] | null = null;

  documentsTableForm: DocumentsTableForm = DocumentsTableForm.createObservable();
  documentForm: DocumentForm = DocumentForm.createObservable();
  documentToShow: DocumentToShow | null = null;
  filesForReqiusites: Map<number, DocumentToShow> = new Map();

  fileValidationRules: FileValidationRules | null = null;

  private documentsService: DocumentsService;

  constructor() {
    makeAutoObservable(this);
    this.documentsService = new DocumentsService();

    reaction(() => this.uploadedDocuments, this.onUploadedDocumentsChange);
  }

  // computed
  get uploadedDocuments() {
    return this.approvalDocuments?.filter(({ csdid }) => csdid !== null);
  }

  get notUploadedDocuments() {
    return this.approvalDocuments?.filter(({ csdid }) => csdid === null);
  }

  get showLoadMoreDocuments() {
    return this.notUploadedDocuments && this.notUploadedDocuments.length > 0;
  }

  // Actions
  getDocumentsList = (query?: IDocumentsListQueryParams) => {
    if (this.documentsListLoading) {
      return;
    }

    this.setDocumentsListLoading(true);

    this.documentsService
      .getDocumentsList(query)
      .then(this.setApprovalDocuments)
      .catch(() => {})
      .finally(() => {
        this.setDocumentsListLoading(false);
      });
  };

  getDocumentValidationRules = () => {
    if (this.validationRulesLoading) {
      return;
    }

    this.setValidationRulesLoading(true);

    this.documentsService
      .getDocumentValidationRules()
      .then(this.setFileValidationRules)
      .catch(() => {})
      .finally(() => {
        this.setValidationRulesLoading(false);
      });
  };

  uploadDocument = (data: DocumentForm, callback?: () => void) => {
    if (this.documentsUploading) {
      return;
    }

    this.setDocumentsUploading(true);

    this.documentsService
      .uploadDocument(data)
      .then(() => {
        this.getDocumentsList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setDocumentsUploading(false);
      });
  };

  getDocumentToShow = (csdtid?: number) => {
    if (this.documentIsLoading) {
      return;
    }

    this.setDocumentIsLoading(true);

    this.documentsService
      .getDocumentToShow({ csdtid })
      .then(this.setDocumentToShow)
      .catch(() => {})
      .finally(() => {
        this.setDocumentIsLoading(false);
      });
  };

  getDocumentFilesForRequisites = (csdtid: number) => {
    if (this.filesForRequisitesLoadings.get(csdtid)) {
      return;
    }

    this.setFilesForRequisitesLoadings(csdtid, true);

    this.documentsService
      .getDocumentToShow({ csdtid })
      .then(doc => {
        runInAction(() => {
          this.filesForReqiusites.set(csdtid, doc);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setFilesForRequisitesLoadings(csdtid, false);
      });
  };

  deleteDocumentFromRequisite = (csdtid: number, csdid: number | null) => {
    if (this.filesForRequisitesLoadings.get(csdtid)) {
      return;
    }

    this.setFilesForRequisitesLoadings(csdtid, true);

    this.documentsService
      .deleteDocument({ csdid })
      .catch(() => {})
      .finally(() => {
        this.setFilesForRequisitesLoadings(csdtid, false);
        this.getDocumentFilesForRequisites(csdtid);
      });
  };

  deleteAllDocuments = (csdtid?: number, callback?: () => void) => {
    if (this.documentFilesDeleting) {
      return;
    }

    this.setDocumentFilesDeleting(true);

    this.documentsService
      .deleteAllDocuments({ csdtid })
      .then(() => {
        this.getDocumentsList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setDocumentFilesDeleting(false);
      });
  };

  downloadAllDocuments = (csdtid?: number) => {
    if (this.documentsDownloading) {
      return;
    }

    this.setDocumentsDownloading(true);

    this.documentsService
      .downloadAllDocuments({ csdtid })
      .then(({ blob, fileName }) => {
        FilesHelper.downloadFile(blob, fileName);
      })
      .catch(() => {})
      .finally(() => {
        this.setDocumentsDownloading(false);
      });
  };

  downloadDocument = (file: DocumentFile, callback?: () => void) => {
    if (this.someDocumentsDownloading.get(file.csdid ?? 0)) {
      return;
    }

    const ext = `.${file.ext}`;
    const fileName = file.filename ?? '';

    this.setSomeDocumentDownloading(file.csdid, true);

    this.documentsService
      .downloadDocument({ csdid: file.csdid })
      .then(blob => {
        if (ext && DocumentsHelper.isAvailableToShowImage(ext)) {
          this.setReadyToShowDocument(FilesHelper.getFileURLWithFileName(blob, fileName));
          callback?.();
          return;
        }

        FilesHelper.downloadFile(blob, fileName);
      })
      .catch(() => {})
      .finally(() => {
        this.setSomeDocumentDownloading(file.csdid, false);
      });
  };

  // Loadings
  setDocumentsListLoading = (state: boolean) => {
    this.documentsListLoading = state;
  };

  setDocumentIsLoading = (state: boolean) => {
    this.documentIsLoading = state;
  };

  setDocumentFilesDeleting = (state: boolean) => {
    this.documentFilesDeleting = state;
  };

  setDocumentsUploading = (state: boolean) => {
    this.documentsUploading = state;
  };

  setDocumentsDownloading = (state: boolean) => {
    this.documentsDownloading = state;
  };

  setValidationRulesLoading = (state: boolean) => {
    this.validationRulesLoading = state;
  };

  setSomeDocumentDownloading = (csdid: number | null, state: boolean) => {
    this.someDocumentsDownloading.set(csdid ?? 0, state);
  };

  // Setters
  setApprovalDocuments = (docs: Document[] | null) => {
    this.approvalDocuments = docs;
  };

  setCurrentDocument = (doc: Document | null) => {
    this.currentDocument = doc;
  };

  setReadyToShowDocument = (doc: IFileDownloadData | null) => {
    this.readyToShowDocument = doc;
  };

  setDocumentToShow = (doc: DocumentToShow | null) => {
    this.documentToShow = doc;
  };

  setFileValidationRules = (rules: FileValidationRules | null) => {
    this.fileValidationRules = rules;
  };

  setFilesForRequisitesLoadings = (csdtid: number, isLoading: boolean) => {
    this.filesForRequisitesLoadings.set(csdtid, isLoading);
  };

  // Reactions
  onUploadedDocumentsChange = (docs?: Document[]) => {
    if (!docs) {
      return;
    }

    docs.forEach(({ csdtid }) => {
      this.filesForRequisitesLoadings.set(csdtid, false);
    });
  };

  // Others
  setDocumentToForm = (document?: Document) => {
    this.documentForm.setValue(DocumentFormFields.csdtid, document ? document.csdtid : this.documentToShow?.csdtid);
    this.documentForm.setValue(
      DocumentFormFields.dtvalidity,
      document
        ? DocumentsHelper.getDocumentDtvalidityAsString(document.dtvalidity)
        : DocumentsHelper.getDocumentDtvalidityAsString(this.documentToShow?.dtvalidity)
    );
  };
}
