import { IAggregatedFieldValues } from '../interfaces/FieldsInterfaces';
import { Field } from '../models/Field';

export class FieldsHelper {
  static aggregateFieldValues(fields?: Field[] | null) {
    if (!fields) {
      return {};
    }

    return fields.reduce((prev, curr) => ({ ...prev, [curr.name]: curr.value }), {}) as IAggregatedFieldValues;
  }
}
