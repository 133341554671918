import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';
import { Nullable } from 'base/types/BaseTypes';

export enum MFAFormFields {
  messengertypeid = 'messengertypeid',
  code = 'code',
  isTunned = 'isTunned',
}

export class MFAForm extends BaseForm {
  messengertypeid: Nullable<number> = null;
  code: Nullable<number> = null;
  isTunned: Nullable<boolean> = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(MFAFormFields),
    });
  };
}
