import { ModelFactory } from 'base/ModelFactory';

import { Bill } from './models/Bill';
import { Subbill } from './models/Subbill';

export default class BillsFactory extends ModelFactory {
  createBillsList = (data: any[]) => {
    if (!data?.length) {
      return [];
    }

    return data.map(this.createBill);
  };

  createBill = (data: any) => {
    const model = this.create<Bill>(Bill, data);

    if (data?.subbill?.length) {
      model.subbills = data?.subbill?.map(this.createSubbill);
    }

    return model;
  };

  createSubbill = (data: any) => {
    return this.create<Subbill>(Subbill, data);
  };
}
