import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';

import RouteHelper from 'base/routes/helpers/RouteHelper';

import NotificationsButton from './Notifications/NotificationsButton';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import DesktopSearch from './Search/DesktopSearch';
import SupportMenu from './SupportMenu/SupportMenu';

interface IDesktopHeaderProps {}

const DesktopHeader: FC<IDesktopHeaderProps> = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const title = useMemo(() => {
    const routeTitle = RouteHelper.getCurrentRouteTitle(routes, pathname);
    return routeTitle;
  }, [pathname]);

  // Renders
  return (
    <Box component="header" className={classes.header}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box className={classes.headerActionsBlock}>
        <DesktopSearch />
        <NotificationsButton />
        <SupportMenu />
        <ProfileMenu />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4),
    minHeight: theme.spacing(16),
    maxHeight: theme.spacing(16),
    borderBottom: `1px solid ${theme.palette.customColors.border}`,
  },
  headerLogoBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    '& > a': {
      height: theme.spacing(5.5),
    },
  },
  headerActionsBlock: {
    display: 'flex',
    gap: theme.spacing(2),
    maxHeight: theme.spacing(10),
  },
}));

export default DesktopHeader;
