import { Box } from '@mui/material';

interface IQHelperTextProps {
  errors?: string | string[];
}

const QHelperText: React.FC<IQHelperTextProps> = ({ errors }) => {
  if (!errors) {
    return <></>;
  }

  if (Array.isArray(errors)) {
    return (
      <>
        {errors.map((item, index) => (
          <Box key={index} mb={0.5} component="span" display="block">
            {item}
          </Box>
        ))}
      </>
    );
  }

  return <>{errors}</>;
};

export default QHelperText;
