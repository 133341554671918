import Dto from 'base/Dto';
import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';
import { FilesHelper } from 'helpers/FilesHelper';

import UnitApiRepository from './UnitApiRepository';
import { UnitFactory } from './UnitFactory';
import { DeleteIpDto } from './dto/DeleteIpDto';
import { DeleteIpmiAccessDto } from './dto/DeleteIpmiAccessDto';
import { DeleteIpmiIpDto } from './dto/DeleteIpmiIpDto';
import { IpDto } from './dto/IpDto';
import { UpdateUnitDto } from './dto/UpdateUnitDto';
import { IpmiAccessForm } from './forms/IpmiAccessForm';
import { IpmiIpForm } from './forms/IpmiIpForm';
import { PowerHistoryTableForm } from './forms/PowerHistoryTableForm';
import { UnitTrafficTableForm } from './forms/UnitTrafficTableForm';
import { DataCenterModel } from './models/DataCenterModel';
import { IPModel } from './models/IPModel';
import { PowerHistoryModel } from './models/PowerHistoryModel';
import { UnitConnectionModel } from './models/UnitConnectionModel';
import { UnitFileModel } from './models/UnitFileModel';
import { UnitIpmiInfoModel } from './models/UnitIpmiInfoModel';
import { UnitModel } from './models/UnitModel';
import { UnitSaveIpModel } from './models/UnitSaveIpModel';

export default class UnitService {
  unitApi: UnitApiRepository;
  unitFactory: UnitFactory;
  paginationService: PaginationService;

  constructor() {
    this.unitApi = new UnitApiRepository();
    this.unitFactory = new UnitFactory();
    this.paginationService = new PaginationService();
  }

  getUnitInfo = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.getUnitInfo(query);

    return this.unitFactory.create<UnitModel>(UnitModel, data.data);
  };

  updateCurrentUnitCodecliName = async (unitInfo: UnitModel, codecli: string) => {
    const dto = Dto.populate(UpdateUnitDto, {
      unitid: unitInfo.unitid,
      codecli,
      unit_lastupdnumber: (unitInfo.unit_lastupdnumber ?? 0) + 1,
    });
    await this.unitApi.updateCurrentUnitCodecliName(dto);

    return this.unitFactory.create<UnitModel>(UnitModel, {
      ...unitInfo,
      codecli,
      unit_lastupdnumber: (unitInfo.unit_lastupdnumber ?? 0) + 1,
    });
  };

  // ipmi tab
  getUnitIpmiInfo = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.getUnitIpmiInfo(query);

    return this.unitFactory.create<UnitIpmiInfoModel>(UnitIpmiInfoModel, data.data);
  };

  getUnitIpmiCommands = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.getUnitIpmiCommands(query);

    return this.unitFactory.createUnitIpmiModelList(data.data);
  };

  getUnitConnections = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.getUnitConnections(query);

    return this.unitFactory.createList<UnitConnectionModel>(UnitConnectionModel, data.data);
  };

  getUnitSaveIps = async () => {
    const { data } = await this.unitApi.getUnitSaveIps();

    return this.unitFactory.createList<UnitSaveIpModel>(UnitSaveIpModel, data.data);
  };

  addIpmiIp = async (form: IpmiIpForm) => {
    const dto = Dto.populate(IpDto, form);

    const { data } = await this.unitApi.addIpmiIp(dto);

    return this.unitFactory.create<ResponseSuccess>(ResponseSuccess, data);
  };

  updateIpmiIp = async (form: IpmiIpForm, ip: UnitSaveIpModel) => {
    const dto = Dto.populate(IpDto, form);

    const { data } = await this.unitApi.updateIpmiIp(dto);

    return this.unitFactory.create<ResponseSuccess>(ResponseSuccess, data);
  };

  deleteIpmiIp = async (aiid: number) => {
    const dto = Dto.populate(DeleteIpmiIpDto, { aiids: [aiid] });

    const { data } = await this.unitApi.deleteIpmiIp(dto);

    return this.unitFactory.create<ResponseSuccess>(ResponseSuccess, data);
  };

  addIpmiAccess = async (form: IpmiAccessForm) => {
    const { ips, ...rest } = form;
    const formWithIps = { ...rest, 'ips[]': form.ips.map(ip => ip.ip) };

    const query = ApiHelper.getQueryString(formWithIps);

    const { data } = await this.unitApi.addIpmiAccess(query);

    return this.unitFactory.create<ResponseSuccess>(ResponseSuccess, data);
  };

  deleteIpmiAccess = async (icid: number) => {
    const dto = Dto.populate(DeleteIpmiAccessDto, { icid: [icid] });

    const { data } = this.unitApi.deleteIpmiAccess(dto);

    return this.unitFactory.create<ResponseSuccess>(ResponseSuccess, data);
  };

  // Traffic tab
  getUnitTraffic = async (unitTrafficTableForm: UnitTrafficTableForm) => {
    const query = ApiHelper.getQueryString({
      objectid: unitTrafficTableForm.objectid,
      connid: unitTrafficTableForm.connid,
    });
    const { data } = await this.unitApi.getUnitTraffic(query);
  };

  // ip tab
  getDataCenterInfo = async (dcid: number) => {
    const query = ApiHelper.getQueryString({ dcid });
    const { data } = await this.unitApi.getDataCenterInfo(query);

    return this.unitFactory.create<DataCenterModel>(DataCenterModel, data.data);
  };

  getIpList = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.getIpList(query);

    return this.unitFactory.createList<IPModel>(IPModel, data.data);
  };

  addIpRequest = async (unitid: number) => {
    const query = ApiHelper.getQueryString({ unitid });

    return this.unitApi.addIpRequest(query);
  };

  deleteIp = async (ip: string) => {
    const dto = Dto.populate(DeleteIpDto, { ipList: [{ ip }] });

    return this.unitApi.deleteIp(dto);
  };

  // files tab
  getDocumentsList = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.getDocumentsList(query);

    return this.unitFactory.createList<UnitFileModel>(UnitFileModel, data.data);
  };

  downloadFile = async (file: UnitFileModel) => {
    const query = ApiHelper.getQueryString({ docid: file.docid });
    const { data } = await this.unitApi.downloadFile(query);

    FilesHelper.downloadFile(data, file.filename ?? '');
  };

  // power tab
  getPowerHistory = async ({ page, limit, sortValue, objectid }: PowerHistoryTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);
    const query = ApiHelper.getQueryString({
      objectid,
      page,
      limit,
      ...sort,
      conntype: ['P'],
      status: [3, 4, 5, 9, 10],
    });
    const { data } = await this.unitApi.getPowerHistory(query);

    const items = this.unitFactory.createList<PowerHistoryModel>(PowerHistoryModel, data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };

  getPowerCurrentTasks = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid, limit: 25, page: 1, conntype: ['P'], status: [1, 2, 6] });
    const { data } = await this.unitApi.getPowerHistory(query);

    return this.unitFactory.createList<PowerHistoryModel>(PowerHistoryModel, data.data.items);
  };

  powerOn = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.powerOn(query);

    return data.data?.untid;
  };

  powerOff = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.powerOff(query);

    return data.data?.untid;
  };

  powerReboot = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.powerReboot(query);

    return data.data?.untid;
  };

  ipmiColdReset = async (objectid: number) => {
    const query = ApiHelper.getQueryString({ objectid });
    const { data } = await this.unitApi.ipmiColdReset(query);

    return data.data?.untid;
  };
}
