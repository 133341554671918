import { Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { useMenuStateAndAnchor } from 'hooks/useMenuStateAndAnchor';
import { useCommonStyles } from 'styles/commonStyles';

interface IProfileMenuProps {
  isMobile?: boolean;
  closeSidebarMenu?: () => void;
}

const ProfileMenu: FC<IProfileMenuProps> = ({ isMobile, closeSidebarMenu }) => {
  const {
    authStore,
    languageToggleStore: { _lk },
  } = useRootStore();

  const { open, anchor, toggleMenu } = useMenuStateAndAnchor();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const size = useMemo(() => {
    return isMobile ? 32 : 40;
  }, [isMobile]);

  // Handlers

  const handleLogoutClick = () => {
    toggleMenu();
    closeSidebarMenu?.();
    authStore.logOut();
    authStore.setLastCalledPathname(routes.MainScreen.path);
  };

  const handleProfileClick = () => {
    toggleMenu();
    closeSidebarMenu?.();
  };

  // Renders
  return (
    <>
      <IconButton
        onClick={toggleMenu}
        ref={anchor}
        size={isMobile ? 'medium' : 'small'}
        className={clsx({ [classes.profileMenuButton]: !isMobile })}
      >
        <Avatar className={commonClasses.avatar} sx={{ width: size, height: size }} />
        {isMobile && (
          <Typography variant="subtitle2" className={clsx(classes.title, { [classes.margin]: isMobile })}>
            {_lk('Профиль')}
          </Typography>
        )}
      </IconButton>
      <Menu keepMounted open={open} anchorEl={anchor.current} onClose={toggleMenu} className={classes.profileMenu}>
        <MenuItem>
          <Link
            onClick={handleProfileClick}
            to={routes.ProfileScreen.path}
            className={clsx(commonClasses.routerLink, commonClasses.routerLinkFullWidth)}
          >
            <Typography variant="body1">{routes.ProfileScreen.title}</Typography>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <Typography variant="body1">{_lk('Выход')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.text.primary,
  },
  margin: {
    marginLeft: theme.spacing(2),
  },
  profileMenu: {
    '& .MuiPaper-root': {
      width: theme.spacing(65),
    },
    '& .MuiList-root': {
      padding: 0,
    },
  },
  profileMenuButton: {
    padding: 0,
  },
}));

export default ProfileMenu;
