import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';

import PassesApiRepository from './PassesApiRepository';
import PassesFactory from './PassesFactory';
import { PassSaveDto } from './dto/PassSaveDto';
import { PassStatuses } from './enums/PassesEnums';
import { CarForm } from './forms/CarForm';
import { PassForm } from './forms/PassForm';
import { PassesTableForm } from './forms/PassesTableForm';
import { VisitorForm } from './forms/VisitorForm';
import { PassesHelper } from './helpers/PassesHelper';
import { AddCarSuccess } from './models/AddCarSuccess';
import { AddVisitorSuccess } from './models/AddVisitorSuccess';
import { Car } from './models/Car';
import { DataCenter } from './models/DataCenter';
import { Pass } from './models/Pass';
import { PassSaveSuccess } from './models/PassSaveSuccess';
import { Visitor } from './models/Visitor';

export default class PassesService {
  static readonly LIMIT = 5;

  private passesApi: PassesApiRepository;
  private paginationService: PaginationService;
  private passesFactory: PassesFactory;

  constructor() {
    this.passesApi = new PassesApiRepository();
    this.passesFactory = new PassesFactory();
    this.paginationService = new PaginationService();
  }

  getPassesList = async ({ page, limit, sortValue }: PassesTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);
    const query = ApiHelper.getQueryString({ page, limit, ...sort });

    const { data } = await this.passesApi.getPassesList(query);

    const items = this.passesFactory.createPassesList(data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };

  savePass = async (pass: PassForm) => {
    const dto = this.passesFactory.preparePassSaveDtoFromForm(pass);

    const { data } = await this.passesApi.savePass(dto);

    return this.passesFactory.create<PassSaveSuccess>(PassSaveSuccess, data.data);
  };

  cancelPass = async (pass: Pass) => {
    const passToCancel = this.passesFactory.createPass({ ...pass, status: PassStatuses.CANCELED });
    const dto = this.passesFactory.preparePassSaveDtoFromPass(passToCancel);

    const { data } = await this.passesApi.savePass(dto);

    return this.passesFactory.create<PassSaveSuccess>(PassSaveSuccess, data.data);
  };

  orderPass = async (pass: PassForm | Pass) => {
    let dto: PassSaveDto;

    if (PassesHelper.isPass(pass)) {
      const passToOrder = this.passesFactory.createPass({ ...pass, status: PassStatuses.NEW });
      dto = this.passesFactory.preparePassSaveDtoFromPass(passToOrder);
      await this.passesApi.savePass(dto);

      return passToOrder;
    }
    const changedPass = this.passesFactory.createPassFromForm(pass);
    const passToOrder = this.passesFactory.createPass({ ...changedPass, status: PassStatuses.NEW });
    dto = this.passesFactory.preparePassSaveDtoFromPass(passToOrder);
    await this.passesApi.savePass(dto);

    return passToOrder;
  };

  deletePass = async (pass: Pass) => {
    const dto = this.passesFactory.preparePassDeleteDto(pass);

    const { data } = await this.passesApi.deletePass(dto);

    return this.passesFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  getDataCenterList = async () => {
    const { data } = await this.passesApi.getDataCenterList();

    return this.passesFactory.createList<DataCenter>(DataCenter, data.data);
  };

  getVisitorsList = async () => {
    const { data } = await this.passesApi.getVisitorsList();

    return this.passesFactory.createList<Visitor>(Visitor, data.data);
  };

  getCarsList = async () => {
    const { data } = await this.passesApi.getCarsList();

    return this.passesFactory.createList<Car>(Car, data.data);
  };

  addCar = async (car: CarForm) => {
    const dto = this.passesFactory.prepareAddCarDto(car);
    const { data } = await this.passesApi.addCar(dto);

    return this.passesFactory.create<AddCarSuccess>(AddCarSuccess, data.data);
  };

  addVisitor = async (visitor: VisitorForm) => {
    const dto = this.passesFactory.prepareAddVisitorDto(visitor);
    const { data } = await this.passesApi.addVisitor(dto);

    return this.passesFactory.create<AddVisitorSuccess>(AddVisitorSuccess, data.data);
  };
}
