export enum UnitTabs {
  info = 'info',
  IPMI = 'IPMI',
  traffic = 'traffic',
  ip = 'ip',
  files = 'files',
  bill = 'bill',
  power = 'power',
  CPanel = 'CPanel',
}

export enum IPMIAccessTypes {
  U = 'U',
  Y = 'Y',
  N = 'N',
}

export enum PowerHistoryStatuses {
  DONE = 10,
  MANUALLY_DONE = 9,
  ERROR = 5,
  MANUALLY_ERROR = 4,
  WAITING = 6,
}

export enum PowerAction {
  ON = 'ON',
  OFF = 'OFF',
  REBOOT = 'REBOOT',
  IPMI_RESET = 'IPMI_RESET',
}
