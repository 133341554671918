import SortService from 'base/modules/sort/SortService';
import SortValue from 'base/modules/sort/models/SortValue';
import { SortOrders, SortOrdersEnum } from 'base/modules/sort/types/SortTypes';
import TablesService from 'modules/tables/TablesService';

export class TableFormService {
  static DEFAULT_LIMIT = 5;
  static DEFAULT_PAGE = 1;

  tablesService: TablesService;
  sortService: SortService;

  constructor() {
    this.tablesService = new TablesService();
    this.sortService = new SortService();
  }

  getSortValue = (currentSort: string, sortValue: SortValue): SortValue => {
    return this.sortService.getSortValue(currentSort, sortValue);
  };

  getCurrentSortValue = (currentSort: string, sortDirection: SortOrders): SortValue => {
    return this.tablesService.getCurrentSortValue(currentSort, sortDirection);
  };

  getInitialSortValue = () => {
    return this.sortService.sortFactory.create<SortValue>(SortValue, { orderBy: null, order: SortOrdersEnum.asc });
  };
}
