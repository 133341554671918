import { jsonProperty, Serializable } from 'ts-serializable';

import { TunedMFA } from './TunedMFA';

export class Phone extends Serializable {
  @jsonProperty(String) phone: string = '';
  @jsonProperty(Boolean) main: boolean = false;
  @jsonProperty(Boolean) needVerify: boolean = false;
  @jsonProperty(Boolean, null) verifyed: boolean | null = false;
  @jsonProperty(String) field: string = '';
  @jsonProperty(Array, null) tunnedFor: TunedMFA[] | null = null;
}
