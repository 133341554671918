import { makeAutoObservable } from 'mobx';

import { Language } from 'modules/language-toggle/models/Language';

import ProfileService from './ProfileService';
import { IUpdateUserLanguage } from './interfaces/UsersInterfaces';
import { CurrentUser } from './models/CurrentUser';

export class ProfileStore {
  isLoadingUserInfo: boolean = false;
  user: CurrentUser | null = null;

  private profileService: ProfileService;

  constructor() {
    makeAutoObservable(this);
    this.profileService = new ProfileService();
  }

  getUserInfo = async (
    language: Language | null,
    setLanguage: (language: Language | null) => void,
    languageChangedBeforeAuth: boolean
  ) => {
    if (this.isLoadingUserInfo) {
      return;
    }

    this.setIsLoadingUserInfo(true);

    try {
      const user = await this.profileService.getUserInfo();
      this.setUser(user);

      const userLanguageIsEqualToChosenLanguage = user.language?.langid === language?.langid;

      if (!languageChangedBeforeAuth) {
        setLanguage(user.language);
        return;
      }

      if (!userLanguageIsEqualToChosenLanguage) {
        this.updateUserLanguage({ id: user.userid, langid: language?.langid });
      }
    } catch (error) {
    } finally {
      this.setIsLoadingUserInfo(false);
    }
  };

  updateUserLanguage = (data: IUpdateUserLanguage) => {
    this.profileService.updateUserLanguage(data);
  };

  // Loadings
  setIsLoadingUserInfo = (state: boolean) => {
    this.isLoadingUserInfo = state;
  };

  // Setters
  setUser = (user: CurrentUser | null) => {
    this.user = user;
  };
}
