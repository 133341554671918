import { action, makeObservable } from 'mobx';

import { TableForm } from 'modules/tables/forms/TableForm/TableForm';

export enum BillsTableFormFields {}

export class BillsTableForm extends TableForm {
  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  resetForm = () => {};

  setAction<T>(key: keyof T, action: () => any): void {
    if (typeof action === 'function') {
      (this as any)[key] = action;
    }
  }

  static createObservable = () => {
    const context = new this();
    context.createTableFormObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(BillsTableFormFields),
      resetForm: action,
    });
  };
}
