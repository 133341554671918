import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { authRoutes, routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { DateHelper } from 'helpers/DateHelper';
import { useTimer } from 'hooks/useTimer';

interface IForbiddenProps {}

const Forbidden: FC<IForbiddenProps> = observer(() => {
  const {
    authStore,
    languageToggleStore: { _lk, _stringFormat },
  } = useRootStore();
  const classes = useStyles();
  const location = useLocation();

  const isAuthPath = location.pathname.includes(authRoutes.LoginScreen.path);

  const delay = Number(authStore.forbiddenError?.delay).valueOf() * 1000;

  const deadline = useMemo(
    () => Number(DateHelper.getDayJsObjectFromTimestamp(Date.now() + delay).unix()).valueOf() * 1000,
    [delay]
  );

  const onServerStatusOK = () => {
    if (isAuthPath) {
      window.location.href = authStore.lastCalledAuthPathname || authRoutes.LoginScreen.path;
      return;
    }

    if (authStore.lastCalledPathname) {
      window.location.href = authStore.lastCalledPathname;
      return;
    }

    window.location.href = routes.MainScreen.path;
  };

  const { minutes, seconds, timeIsUp } = useTimer(deadline);

  useEffect(() => {
    if (authStore.isCheckingServerStatus) {
      return;
    }

    const interval = setInterval(() => {
      authStore.getServerStatus({
        loader: false,
        callback: () => {
          clearInterval(interval);
          onServerStatusOK();
        },
      });
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [authStore.isCheckingServerStatus]);

  // Effects
  useEffect(() => {
    if (!timeIsUp) {
      return;
    }
    onServerStatusOK();
  }, [timeIsUp]);

  // Renders
  return (
    <Box className={clsx(classes.forbidden)}>
      <Typography variant="caption" component="p" textAlign="center">
        {authStore.forbiddenError?.banMessage.split('\n').map((str, i) => (
          <Fragment key={i}>
            {str}
            <br />
          </Fragment>
        ))}
      </Typography>
      {!timeIsUp && (
        <Typography component="p" variant="caption" textAlign="center">
          {_stringFormat(_lk('Сервис будет доступен через {minutes}:{seconds}'), { minutes, seconds })}
        </Typography>
      )}
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  forbidden: {},
}));

export default Forbidden;
