import { FieldValues } from 'react-hook-form';

import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';
import { FieldsHelper } from 'base/modules/fields/helpers/FieldsHelper';
import { IAggregatedFieldValues } from 'base/modules/fields/interfaces/FieldsInterfaces';
import { Field } from 'base/modules/fields/models/Field';
import { IAddressManualFields } from 'modules/addresses/interfaces/AddressesInterfaces';

import { ClientsApiRepository } from './ClientsApiRepository';
import ClientsFactory from './ClientsFactory';
import { IClientDocumentQueryParams } from './interfaces/ClientsInterfaces';
import { ClientDocument } from './models/ClientDocument';
import { ClientForAuth } from './models/ClientForAuth';
import { ClientRequisites } from './models/requisites/ClientRequisites';

export class ClientsService {
  private clientsApi: ClientsApiRepository;
  private clientsFactory: ClientsFactory;

  constructor() {
    this.clientsApi = new ClientsApiRepository();
    this.clientsFactory = new ClientsFactory();
  }

  getClientsList = async () => {
    const { data } = await this.clientsApi.getClientsList();

    return this.clientsFactory.createList<ClientForAuth>(ClientForAuth, data.data);
  };

  getClientSummary = async () => {
    const { data } = await this.clientsApi.getClientSummary();

    return this.clientsFactory.createClientSummary(data.data);
  };

  getClientDocuments = async () => {
    const { data } = await this.clientsApi.getClientDocuments();

    return this.clientsFactory.createList<ClientDocument>(ClientDocument, data.data);
  };

  getClientDocument = async (queryParams: IClientDocumentQueryParams) => {
    const queryString = ApiHelper.getQueryString(queryParams);

    const { data } = await this.clientsApi.getClientDocument(queryString);

    return data as Blob;
  };

  getClientRequisitesList = async () => {
    const { data } = await this.clientsApi.getClientRequisitesList();

    return this.clientsFactory.createRequisites(data.data);
  };

  updateClientRequisites = async (form: FieldValues) => {
    const { data } = this.clientsFactory.createUpdateRequisitesDto(form);

    const response = await this.clientsApi.updateClientRequisites(data);

    return this.clientsFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  validateClientRequisites = async (form: FieldValues) => {
    const { data } = this.clientsFactory.createValidateRequisitesDto(form);
    const response = await this.clientsApi.validateClientRequisites(data);

    return this.clientsFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  getAggregatedRequisitesValues = (
    requisites: ClientRequisites | null,
    addressesManualFields: IAddressManualFields | null
  ) => {
    const allFields = requisites
      ? (Object.values(requisites)
          .map(type => (type ? FieldsHelper.aggregateFieldValues(type.fields) : null))
          .reduce((prev, curr) => ({ ...prev, ...curr }), {}) as IAggregatedFieldValues)
      : {};

    if (addressesManualFields) {
      const addressesFields = Object.values(addressesManualFields).flat();

      return {
        ...allFields,
        ...FieldsHelper.aggregateFieldValues(addressesFields as Field[]),
      };
    }

    return { ...allFields };
  };
}
