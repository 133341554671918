import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { AddressesDto } from './dto/AddressesDto';

export class AddressesApiRepository extends AbstractApiRepository {
  getAddresses = (data: AddressesDto) => {
    return this.apiClient.post({ url: '/addresses/index', data });
  };

  getAddressReferences = (queryParams: string) => {
    return this.apiClient.get({ url: `/addresses/references${queryParams}` });
  };
}
