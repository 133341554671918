import { ModelFactory } from 'base/ModelFactory';

import { FileSize } from './models/FileSize';
import { FileValidationRules } from './models/FileValidationRules';

export default class FileValidationFactory extends ModelFactory {
  createFileValidationRules = (data: any) => {
    const fileValidationRules = this.create<FileValidationRules>(FileValidationRules, data);

    if (data.maxFileSize) {
      fileValidationRules.maxFileSize = this.create<FileSize>(FileSize, data.maxFileSize);
    }

    return fileValidationRules;
  };
}
