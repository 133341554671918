export enum VisitorTypes {
  visitor = 'visitor',
  user = 'user',
}

export enum PassStatuses {
  DRAFT = 1,
  NEW = 2,
  IN_PROCESSING = 3,
  BOOKED = 4,
  REJECTED = 5,
  CANCELED = 6,
}
