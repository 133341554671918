import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { useMenuStateAndAnchor } from 'hooks/useMenuStateAndAnchor';
import { ChangeClientFormFields } from 'modules/auth/forms/ChangeClientForm';
import { ChangeClientModalTypes } from 'modules/modal/types/ChangeClientTypes';

import ChangeClientModals from './modals/ChangeClientModals';

interface IChangeClientProps {}

const ChangeClient: FC<IChangeClientProps> = observer(() => {
  const {
    clientsStore,
    authStore,
    modalsStore,
    languageToggleStore: { _lk },
  } = useRootStore();
  const classes = useStyles();

  const clients = useMemo(() => {
    return clientsStore.clients?.filter(client => client.clientid !== authStore.authInfo?.clientid);
  }, [authStore.authInfo, clientsStore.clients]);

  const { open, anchor, toggleMenu } = useMenuStateAndAnchor();

  // Handlers
  const openCodeConfirmationModal = () => {
    modalsStore.setChangeClientModalType(ChangeClientModalTypes.CODE_CONFIRMATION);
  };

  const showChangeClientSuccessModal = () => {
    modalsStore.setChangeClientModalType(ChangeClientModalTypes.CLIENT_CHANGE_SUCCESS);
  };

  const handleChooseClient = (clientid: number) => () => {
    const client = clients?.find(client => client.clientid === clientid);

    if (!client) {
      return;
    }

    authStore.changeClientForm.setValue(ChangeClientFormFields.clientid, clientid);
    authStore.changeClient(authStore.changeClientForm, client, openCodeConfirmationModal, showChangeClientSuccessModal);
    toggleMenu();
  };

  // Renders
  const renderClientChanger = () =>
    clients && clients.length ? (
      <>
        <Button
          variant="text"
          className={classes.choose}
          onClick={toggleMenu}
          ref={anchor}
          disabled={clientsStore.isLoadingClients}
        >
          {_lk('Выбрать')}
        </Button>
        <Menu keepMounted open={open} onClose={toggleMenu} anchorEl={anchor.current}>
          {clients?.map(client => (
            <MenuItem key={client.clientid} onClick={handleChooseClient(client.clientid)}>
              <Typography variant="body1">{client.name || client.clientname}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <ChangeClientModals />
      </>
    ) : null;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
      <Box>
        <Typography variant="body2" className={classes.client}>
          {_lk('Клиент')}
        </Typography>
        <Typography variant="h6">{clientsStore.currentClientSummary?.name}</Typography>
      </Box>

      {renderClientChanger()}
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  client: {
    color: theme.palette.customColors.surfaceTertiary,
    marginBottom: theme.spacing(1),
  },
  choose: {
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
  },
}));

export default ChangeClient;
