import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';
import { DateHelper } from 'helpers/DateHelper';

import { AddCarDto } from './dto/AddCarDto';
import { AddVisitorDto } from './dto/AddVisitorDto';
import { PassDeleteDto } from './dto/PassDeleteDto';
import { PassSaveDto } from './dto/PassSaveDto';
import { CarForm } from './forms/CarForm';
import { PassForm } from './forms/PassForm';
import { VisitorForm } from './forms/VisitorForm';
import { Pass } from './models/Pass';
import { Visitor } from './models/Visitor';

export default class PassesFactory extends ModelFactory {
  // models
  createPass = (data: any) => {
    const successLoginAuthModel = this.create<Pass>(Pass, data);

    if (data?.visitors) {
      successLoginAuthModel.visitors = this.createList<Visitor>(Visitor, data.visitors);
    }

    return successLoginAuthModel;
  };

  createPassesList = (list: any[]) => {
    return list.map(this.createPass);
  };

  createPassFromForm = (form: PassForm) => {
    const prePass = this.getPrePass(form);

    return this.createPass(prePass);
  };

  // dtos
  preparePassSaveDtoFromForm = (form: PassForm) => {
    const preDto = this.getPrePass(form);

    return Dto.populate<PassSaveDto>(PassSaveDto, preDto);
  };

  preparePassDeleteDto = (pass: Pass) => {
    return Dto.populate<PassDeleteDto>(PassDeleteDto, pass);
  };

  preparePassSaveDtoFromPass = (pass: Pass) => {
    const preDto = { ...pass, visitor: pass.visitors };

    return Dto.populate<PassSaveDto>(PassSaveDto, preDto);
  };

  prepareAddVisitorDto = (visitor: VisitorForm) => {
    return Dto.populate<AddVisitorDto>(AddVisitorDto, visitor);
  };

  prepareAddCarDto = (car: CarForm) => {
    return Dto.populate<AddCarDto>(AddCarDto, car);
  };

  // privates
  private getPrePass = (form: PassForm) => ({
    ...form,
    dcid: form.dcid ? +form.dcid : form.dcid,
    haveautomobile: form.car ? 1 : 0,
    carnumber: form.car?.carnumber || null,
    carmodel: form.car?.carmodel || null,
    dcvcid: form.car?.dcvcid || null,
    dtvisitdate: DateHelper.getFormattedDate(form.dtvisitdate),
    dtvisittime: DateHelper.getFormattedTime(form.dtvisittime),
    visitors: form.visitor,
  });
}
