import { makeAutoObservable } from 'mobx';
import { FieldValues } from 'react-hook-form';

import { ResponseSuccess } from 'base/models/CommonModels';
import ErrorsService from 'base/modules/errors/ErrorsService';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { FieldTypes } from 'base/modules/fields/enums/FieldsEnums';
import { IAggregatedFieldValues } from 'base/modules/fields/interfaces/FieldsInterfaces';
import { Field } from 'base/modules/fields/models/Field';
import { FilesHelper, IFileDownloadData } from 'helpers/FilesHelper';
import { IAddressManualFields } from 'modules/addresses/interfaces/AddressesInterfaces';

import { ClientsService } from './ClientsService';
import { ClientDocument } from './models/ClientDocument';
import { ClientForAuth } from './models/ClientForAuth';
import { ClientSummary } from './models/ClientSummary';
import { ClientRequisites } from './models/requisites/ClientRequisites';
import { ClientRequisiteType } from './models/requisites/ClientRequisitesType';

export class ClientsStore {
  isLoadingClients: boolean = false;
  isLoadingClientDocuments: boolean = false;
  isLoadingClientDocument: boolean = false;
  isLoadingClientSummary: boolean = false;
  isLoadingClientRequisites: boolean = false;
  isUpdatingClientRequisites: boolean = false;

  clients: ClientForAuth[] | null = null;

  currentClientSummary: ClientSummary | null = null;
  clientDocuments: ClientDocument[] | null = null;

  clientContacts: ClientRequisiteType | null = null;
  contactToChange: Field | null = null;

  clientRequisites: ClientRequisites | null = null;

  addressesManualFields: IAddressManualFields | null = null;

  private clientsService: ClientsService;
  private errorsService: ErrorsService;

  constructor() {
    makeAutoObservable(this);
    this.clientsService = new ClientsService();
    this.errorsService = new ErrorsService();
  }

  // computed
  get clientIsResident() {
    return this.currentClientSummary?.isResident;
  }

  get clientPhones() {
    return this.clientContacts?.fields?.filter(({ type }) => type === FieldTypes.phone);
  }

  get clientEmails() {
    return this.clientContacts?.fields?.filter(({ type }) => type === FieldTypes.email);
  }

  get requisitesNotRequested() {
    return this.clientContacts === null && this.clientRequisites === null;
  }

  get mappedClientRequisites() {
    return this.clientRequisites ? Object.values(this.clientRequisites) : [];
  }

  get aggregatedRequisitesValues(): IAggregatedFieldValues {
    return this.clientsService.getAggregatedRequisitesValues(this.clientRequisites, this.addressesManualFields);
  }

  // actions
  getClientsList = async () => {
    if (this.isLoadingClients) {
      return;
    }

    this.setIsLoadingClients(true);

    try {
      const clients = await this.clientsService.getClientsList();
      this.setClients(clients);
    } catch (error) {
    } finally {
      this.setIsLoadingClients(false);
    }
  };

  getClientSummary = async () => {
    if (this.isLoadingClientSummary) {
      return;
    }

    this.setIsLoadingClientSummary(true);

    try {
      const clientSummary = await this.clientsService.getClientSummary();
      this.setCurrentClientSummary(clientSummary);
    } catch (error) {
    } finally {
      this.setIsLoadingClientSummary(false);
    }
  };

  getClientDocuments = () => {
    if (this.isLoadingClientDocuments) {
      return;
    }

    this.setIsLoadingClientDocuments(true);

    this.clientsService
      .getClientDocuments()
      .then(this.setClientDocuments)
      .catch(() => {})
      .finally(() => {
        this.setIsLoadingClientDocuments(false);
      });
  };

  getClientDocument = (
    docid: number,
    fileName: string | null,
    callback: (readyToShowDocument: IFileDownloadData) => void
  ) => {
    if (this.isLoadingClientDocuments) {
      return;
    }

    this.setIsLoadingClientDocuments(true);

    this.clientsService
      .getClientDocument({ docid })
      .then(file => {
        const ext = `.${fileName?.split('.').slice(-1)[0]}`;
        const readyToShowDocument = FilesHelper.getFileURL(file, ext);

        callback(readyToShowDocument);
      })
      .catch(() => {})
      .finally(() => {
        this.setIsLoadingClientDocuments(false);
      });
  };

  getClientRequisitesList = () => {
    if (this.isLoadingClientRequisites) {
      return;
    }

    this.setIsLoadingClientRequisites(true);

    this.clientsService
      .getClientRequisitesList()
      .then(data => {
        this.setRequisitesData(data);
      })
      .catch(() => {})
      .finally(() => {
        this.setIsLoadingClientRequisites(false);
      });
  };

  updateClientRequisites = (form: FieldValues, callback?: () => void) => {
    if (this.isUpdatingClientRequisites) {
      return;
    }

    this.setIsUpdatingClientRequisites(true);

    this.clientsService
      .updateClientRequisites(form)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsUpdatingClientRequisites(false);
      });
  };

  validateClientRequisites = (form: FieldValues, callback: (res: ResponseSuccess | (IErrorData | null)) => void) => {
    this.clientsService
      .validateClientRequisites(form)
      .then(res => {
        callback(res);
      })
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        callback(errors);
      })
      .finally(() => {});
  };

  // Loadings
  setIsLoadingClients = (state: boolean) => {
    this.isLoadingClients = state;
  };

  setIsLoadingClientSummary = (state: boolean) => {
    this.isLoadingClientSummary = state;
  };

  setIsLoadingClientDocuments = (state: boolean) => {
    this.isLoadingClientDocuments = state;
  };

  setIsLoadingClientDocument = (state: boolean) => {
    this.isLoadingClientDocument = state;
  };

  setIsLoadingClientRequisites = (state: boolean) => {
    this.isLoadingClientRequisites = state;
  };

  setIsUpdatingClientRequisites = (state: boolean) => {
    this.isUpdatingClientRequisites = state;
  };

  // Setters
  setClients = (clients: ClientForAuth[] | null) => {
    this.clients = clients;
  };

  setCurrentClientSummary = (client: ClientSummary | null) => {
    this.currentClientSummary = client;
  };

  setClientDocuments = (documents: ClientDocument[] | null) => {
    this.clientDocuments = documents;
  };

  setClientContacts = (data: ClientRequisiteType | null) => {
    this.clientContacts = data;
  };

  setClientRequisites = (data: ClientRequisites | null) => {
    this.clientRequisites = data;
  };

  setContactToChange = (field: Field | null) => {
    this.contactToChange = field;
  };

  setAddressesManualFields = (data: IAddressManualFields) => {
    this.addressesManualFields = data;
  };

  setRequisitesData = ({ contacts, ...rest }: ClientRequisites) => {
    this.setClientContacts(contacts);
    this.setClientRequisites(rest);
  };
}
