import { modelFactory } from 'base/ModelFactory';
import ApiHelper from 'base/helpers/ApiHelper';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';

import HardwareOrderApiRepository from './HardwareOrderApiRepository';
import { HardwaresTableForm } from './forms/HardwaresTableForm';
import { HardwareOrder } from './models/HardwareOrder';

export default class HardwareOrderService {
  static readonly LIMIT = 5;

  private hardwareOrderApi: HardwareOrderApiRepository;
  private paginationService: PaginationService;

  constructor() {
    this.paginationService = new PaginationService();
    this.hardwareOrderApi = new HardwareOrderApiRepository();
  }

  getHardwareOrderList = async ({ page, limit, sortValue }: HardwaresTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);
    const query = ApiHelper.getQueryString({ page, limit, ...sort });

    const { data } = await this.hardwareOrderApi.getHardwareOrderList(query);

    const items = modelFactory.createList<HardwareOrder>(HardwareOrder, data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };
}
