import { jsonProperty, Serializable } from 'ts-serializable';

import { Language } from 'modules/language-toggle/models/Language';

export class CurrentUser extends Serializable {
  @jsonProperty(Number) userid: number = 0;
  @jsonProperty(String, null) fio: string | null = null;
  @jsonProperty(String, null) dtpasschanged: string | null = null;
  @jsonProperty(String, null) avatar: string | null = null;
  @jsonProperty(Array, null) phones: string[] | null = null;
  @jsonProperty(Array, null) emails: string[] | null = null;
  @jsonProperty(Object, null) language: Language | null = null;
}
