import { IconButton, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { QIcon } from 'components/UI/QIcon/QIcon';
import { QIconIds } from 'components/UI/QIcon/QIconIds';
import { RegExpHelper } from 'helpers/RegExpHelper';
import { useMenuStateAndAnchor } from 'hooks/useMenuStateAndAnchor';
import { useCommonStyles } from 'styles/commonStyles';

interface ISupportMenuProps {}

const SupportMenu: FC<ISupportMenuProps> = observer(() => {
  const {
    clientsStore,
    languageToggleStore: { _lk },
  } = useRootStore();

  const phone = RegExpHelper.getDigitString(clientsStore.currentClientSummary?.phoneSupport);

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { open, anchor, toggleMenu } = useMenuStateAndAnchor();

  // Handlers
  const handleFAQClick = () => {};

  const handleSupportClick = () => {};

  // Renders
  return (
    <>
      <Box className={commonClasses.headerActionsWrapper} display="flex" alignItems="center">
        <IconButton onClick={toggleMenu} ref={anchor} size="small">
          <QIcon id={QIconIds.questionCircle} className={commonClasses.greenIcon} />
        </IconButton>
      </Box>
      <Menu keepMounted open={open} anchorEl={anchor.current} onClose={toggleMenu} className={classes.supportMenu}>
        <MenuItem onClick={handleFAQClick} className={commonClasses.menuItemsWithIcons}>
          <QIcon id={QIconIds.outlinedQuestionCircle} className={commonClasses.grayIcon} />
          <Typography variant="body1">{_lk('Часто задаваемые вопросы')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleSupportClick} className={commonClasses.menuItemsWithIcons}>
          <QIcon id={QIconIds.sms} className={commonClasses.grayIcon} width={20} height={20} />
          <Typography variant="body1">{_lk('Техподдержка')}</Typography>
        </MenuItem>
        <MenuItem className={commonClasses.menuItemsWithIcons}>
          <QIcon id={QIconIds.phone} className={commonClasses.grayIcon} width={20} height={20} />
          <Typography component="a" href={`tel:+${phone}`} variant="body1" className={classes.call}>
            {clientsStore.currentClientSummary?.phoneSupport}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
});

const useStyles = makeStyles<Theme, ISupportMenuProps>(theme => ({
  supportMenu: {
    '& .MuiPaper-root': {
      width: theme.spacing(72),
    },
  },
  call: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

export default SupportMenu;
