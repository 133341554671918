import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum CarFormFields {
  carmodel = 'carmodel',
  carnumber = 'carnumber',
}

export class CarForm extends BaseForm {
  carmodel: string | null = null;
  carnumber: string | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(CarFormFields),
    });
  };
}
