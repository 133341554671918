import { BaseForm } from 'base/BaseForm';
import { Nullable } from 'base/types/BaseTypes';

import { TypeContact } from '../../auth/types/AuthTypes';

export enum ResetPasswordFormFields {
  contact = 'contact',
  typeContact = 'typeContact',
  code = 'code',
  password = 'password',
  confirm = 'confirm',
}

export class ResetPasswordForm extends BaseForm {
  contact: string = '';
  typeContact: TypeContact = TypeContact.phone;
  code: Nullable<string> = null;
  password: string = '';
  confirm: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }
}
