import { jsonProperty, Serializable } from 'ts-serializable';

export class UnitModel extends Serializable {
  @jsonProperty(Number, null) unitid: number | null = null;
  @jsonProperty(String, null) codeour: string | null = null;
  @jsonProperty(String, null) codecli: string | null = null;
  @jsonProperty(Boolean, null) powerManagementAvailable: boolean | null = null;
  @jsonProperty(String, null) powerManagementDesc: string | null = null;
  @jsonProperty(String, null) powerManagementDescFull: string | null = null;
  @jsonProperty(Number, null) ipmiManagementAvailable: number | null = null;
  @jsonProperty(String, null) ipmiManagementDesc: string | null = null;
  @jsonProperty(String, null) ipmiManagementDescFull: string | null = null;
  @jsonProperty(Number, null) dcid: number | null = null;
  @jsonProperty(Boolean, null) isReady: boolean | null = null;
  @jsonProperty(String, null) isReadyDesc: string | null = null;
  @jsonProperty(Number, null) unit_lastupdnumber: number | null = null;
  @jsonProperty(String, null) cfg: string | null = null;
  @jsonProperty(String, null) dcposition: string | null = null;
  @jsonProperty(String, null) connections: string | null = null;
}
