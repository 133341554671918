import { useRef, useState } from 'react';

export const useMenuStateAndAnchor = () => {
  const [open, setOpen] = useState(false);
  const anchor = useRef<HTMLButtonElement | null>(null);

  const toggleMenu = () => {
    setOpen(o => !o);
  };

  return {
    open,
    anchor,
    toggleMenu,
  };
};
