export class RegExpHelper {
  static emailMaskPlaceholder = '___@__.__';

  static getMaskRegExp(str: string) {
    if (str === '-') {
      return str;
    }

    return new RegExp(str);
  }

  static getDigitString(str?: string) {
    return str?.match(/\d/g)?.join('');
  }

  static getStringFromBrackets(str?: string) {
    return str
      ?.match(/\[(\\.|[^"\\])*\]/g)
      ?.join('')
      .replace(/\[|\]/g, '') as string;
  }
}
