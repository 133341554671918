import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const subtitle = (theme: Theme) => ({
  marginTop: theme.spacing(4),
  maxWidth: theme.spacing(100),
  alignSelf: 'center',
});

export const useSuccessModalStyles = makeStyles(theme => ({
  modal: {
    minHeight: theme.spacing(90),
  },
  title: {
    marginTop: theme.spacing(8),
  },
  subtitleGray: {
    ...subtitle(theme),
    color: theme.palette.customColors.surfaceTertiary,
  },
  subtitlePrimary: {
    ...subtitle(theme),
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(8),
    maxWidth: theme.spacing(38),
    alignSelf: 'center',
  },
}));

export const useInfoModalStyles = makeStyles(theme => ({
  modal: {
    maxWidth: theme.spacing(100),
    minHeight: theme.spacing(62),
  },
}));
