import { ModelFactory } from 'base/ModelFactory';

import { Field } from './models/Field';
import { PossibleValue } from './models/PossibbleValue';

export default class FieldsFactory extends ModelFactory {
  createField = (data: any) => {
    const field = this.create<Field>(Field, data);

    if (data.possibleValues) {
      const possibleValuesArray = Array.isArray(data.possibleValues)
        ? data.possibleValues
        : Object.entries(data.possibleValues).map(([key, value]) => ({ key, value }));

      if (possibleValuesArray.every((v: any) => typeof v !== 'string')) {
        field.possibleValues = this.createPossibleValuesList(possibleValuesArray);
      }
    }

    return field;
  };

  createPossibleValuesList = (data: any[]) => {
    return data.map(this.createPossibleValue);
  };

  createPossibleValue = (data: any) => {
    return this.create<PossibleValue>(PossibleValue, data);
  };

  createFieldsList = (data: any[]) => {
    return data.map(this.createField);
  };
}
