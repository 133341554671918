import { SelectChangeEvent } from '@mui/material';
import { IControllerProps } from 'interfaces/HookFormInterfaces';
import { IQSelectProps } from 'interfaces/UIComponentsInterfaces';
import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import QSelect from './QSelect';

interface IQSelectWithController extends Omit<IQSelectProps, 'name'>, IControllerProps {
  singleDefaultSelectedValue?: string | null | undefined;
  validate?: (name: string, value: string) => void;
}

const QSelectWithController: React.FC<IQSelectWithController> = props => {
  const { errors, name, hookFormProps, singleDefaultSelectedValue, onChange, onBlur, validate, ...rest } = props;
  const { field, formState } = useController({ name, ...hookFormProps });

  // Effects
  useEffect(() => {
    if (singleDefaultSelectedValue) {
      field.onChange(singleDefaultSelectedValue);
    }
  }, [singleDefaultSelectedValue]);

  // Handlers
  const handleChange = (e: SelectChangeEvent<any>) => {
    field.onChange(e.target.value);

    validate?.(name, e.target.value);
  };

  // Renders
  return (
    <QSelect
      {...rest}
      errors={errors?.length ? errors : formState.errors[name]?.message}
      {...field}
      onChange={handleChange}
    />
  );
};

export default QSelectWithController;
