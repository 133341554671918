import { action, makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';
import SortValue from 'base/modules/sort/models/SortValue';
import { SortOrders } from 'base/modules/sort/types/SortTypes';

import { TableFormService } from './TableFormService';

export enum TableFormFields {
  query = 'query',
  totalItems = 'totalItems',
  totalPages = 'totalPages',
  currentItems = 'currentItems',
  limit = 'limit',
  page = 'page',
  sortValue = 'sortValue',
  expandedSet = 'expandedSet',
  checkedSet = 'checkedSet',
  filters = 'filters',
}

export enum TableFormDynamicActions {
  getList = 'getList',
}

export class TableForm extends BaseForm {
  query: string = '';
  totalItems: number = 0;
  totalPages: number = 0;
  currentItems: number = 0;
  limit: number = TableFormService.DEFAULT_LIMIT;
  page: number = TableFormService.DEFAULT_PAGE;
  sortValue: SortValue;
  expandedSet: Set<number> = new Set();
  checkedSet: Set<number> = new Set();
  filters: { [k: string]: number[] } = {};

  private tableFormService: TableFormService;

  constructor(props?: any) {
    super(props);
    this.load(props);
    this.tableFormService = new TableFormService();
    this.sortValue = this.tableFormService.getInitialSortValue();
  }

  get checked() {
    return [...this.checkedSet];
  }

  get expanded() {
    return [...this.expandedSet];
  }

  setExpanded = (id: number | number[] | null) => {
    if (id === null) {
      this.expandedSet.clear();
      return;
    }

    if (Array.isArray(id)) {
      id.forEach(id => {
        if (this.expandedSet.has(id)) {
          this.expandedSet.delete(id);
          return;
        }
        this.expandedSet.add(id);
      });
      return;
    }

    if (this.expandedSet.has(id)) {
      this.expandedSet.delete(id);
      return;
    }

    this.expandedSet.add(id);
  };

  setChecked = (id: number | number[] | null) => {
    if (id === null) {
      this.checkedSet.clear();
      return;
    }

    if (Array.isArray(id)) {
      id.forEach(id => {
        if (this.checkedSet.has(id)) {
          this.checkedSet.delete(id);
          return;
        }
        this.checkedSet.add(id);
      });
      return;
    }

    if (this.checkedSet.has(id)) {
      this.checkedSet.delete(id);
      return;
    }

    this.checkedSet.add(id);
  };

  setFilters = (name: string, id: number | number[] | null) => {
    if (id === null) {
      this.filters = { ...this.filters, [name]: [] };
      return;
    }

    if (Array.isArray(id)) {
      this.filters = { ...this.filters, [name]: id };
      return;
    }

    if (this.filters[name].includes(id)) {
      this.filters = { ...this.filters, [name]: this.filters[name].filter(existed => existed !== id) };
      return;
    }

    this.filters = { ...this.filters, [name]: [...this.filters[name], id] };
  };

  setValueAndGetList<T>(key: keyof T, value: any) {
    this.setValue(key, value);

    if (this.getList) {
      this.getList();
    } else {
      this.logActionError();
    }
  }

  setValuesAndGetList(props: any) {
    this.setValues(props);

    if (this.getList) {
      this.getList();
    } else {
      this.logActionError();
    }
  }

  setCurrentSortValue(currentSort: string, sortDirection: SortOrders) {
    const sortValue = this.tableFormService.getCurrentSortValue(currentSort, sortDirection);
    this.setValue(TableFormFields.sortValue, sortValue);
  }

  setSortValue(currentSort: string) {
    const sortValue = this.tableFormService.getSortValue(currentSort, this.sortValue);
    this.setValue(TableFormFields.sortValue, sortValue);
  }

  setSortValueAndGetList(currentSort: string) {
    const sortValue = this.tableFormService.getSortValue(currentSort, this.sortValue);
    this.setValueAndGetList(TableFormFields.sortValue, sortValue);
    return sortValue;
  }

  createTableFormObservable() {
    this.createObservable();
    return makeObservable(this, {
      ...this.makeObservableValues(TableFormFields),
      setValueAndGetList: action,
      setValuesAndGetList: action,
      setSortValueAndGetList: action,
      setExpanded: action,
      setChecked: action,
      setFilters: action,
    });
  }

  private getList: (() => void) | null = null;

  private logActionError = () => {
    if (typeof this.getList !== 'function') {
      // eslint-disable-next-line no-console
      console.error(`
        Add to the store form action!
        For example: this.yourObservableForm.setAction(TableFormDynamicActions.getList, yourGetTableListRequest);
      `);
    }
  };

  resetForm = () => {
    this.page = TableFormService.DEFAULT_PAGE;
    this.limit = TableFormService.DEFAULT_LIMIT;
    this.sortValue = this.tableFormService.getInitialSortValue();
    this.query = '';
  };
}
