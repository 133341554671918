import React from 'react';

import { AddressesStore } from 'modules/addresses/AddressesStore';
import { AuthStore } from 'modules/auth/AuthStore';
import { OAuth2Store } from 'modules/auth/modules/oauth2/OAuth2Store';
import { BillsStore } from 'modules/bills/BillsStore';
import { ClientsStore } from 'modules/clients/ClientsStore';
import { DocumentsStore } from 'modules/documents/DocumentsStore';
import { HardwareOrderStore } from 'modules/hardware-order/HardwareOrderStore';
import { HardwareStockStore } from 'modules/hardware-stock/HardwareStockStore';
import { LanguageToggleStore } from 'modules/language-toggle/LanguageToggleStore';
import { MFAStore } from 'modules/mfa/MFAStore';
import { ModalsStore } from 'modules/modal/ModalsStore';
import { PassesStore } from 'modules/passes/PassesStore';
import { ProfileStore } from 'modules/profile/ProfileStore';
import { RoutesStore } from 'modules/routes/RoutesStore';
import { ServicePaymentStore } from 'modules/service-payment/ServicePaymentStore';
import { ServiceStore } from 'modules/service/ServiceStore';
import { ServicesStore } from 'modules/services/ServicesStore';
import { ThemeToggleStore } from 'modules/theme-toggle/ThemeToggleStore';
import { ToolsStore } from 'modules/tools/ToolsStore';
import { UnitStore } from 'modules/unit/UnitStore';
import { UsersStore } from 'modules/users/UsersStore';

import { RegistrantsStore } from './../modules/registrants/RegistrantsStore';

class RootStore {
  modalsStore: ModalsStore;

  themeToggleStore: ThemeToggleStore;
  languageToggleStore: LanguageToggleStore;
  routesStore: RoutesStore;

  authStore: AuthStore;
  oAuth2Store: OAuth2Store;
  mfaStore: MFAStore;

  profileStore: ProfileStore;
  clientsStore: ClientsStore;
  addressesStore: AddressesStore;
  billsStore: BillsStore;
  usersStore: UsersStore;
  toolsStore: ToolsStore;
  passesStore: PassesStore;
  documentsStore: DocumentsStore;
  hardwareOrderStore: HardwareOrderStore;
  hardwareStockStore: HardwareStockStore;
  registrantsStore: RegistrantsStore;
  servicesStore: ServicesStore;
  serviceStore: ServiceStore;
  servicePaymentStore: ServicePaymentStore;
  unitStore: UnitStore;

  constructor() {
    this.modalsStore = new ModalsStore();

    this.themeToggleStore = new ThemeToggleStore();
    this.languageToggleStore = new LanguageToggleStore();
    this.routesStore = new RoutesStore();

    this.authStore = new AuthStore();
    this.oAuth2Store = new OAuth2Store();
    this.mfaStore = new MFAStore();

    this.clientsStore = new ClientsStore();
    this.addressesStore = new AddressesStore();
    this.profileStore = new ProfileStore();
    this.billsStore = new BillsStore();
    this.usersStore = new UsersStore();
    this.toolsStore = new ToolsStore();
    this.passesStore = new PassesStore();
    this.documentsStore = new DocumentsStore();
    this.hardwareOrderStore = new HardwareOrderStore();
    this.hardwareStockStore = new HardwareStockStore();
    this.registrantsStore = new RegistrantsStore();
    this.servicesStore = new ServicesStore();
    this.serviceStore = new ServiceStore();
    this.servicePaymentStore = new ServicePaymentStore();
    this.unitStore = new UnitStore();
  }
}

export const rootStore = new RootStore();

export const storesContext = React.createContext(rootStore);
