import { alpha } from '@mui/material';

import { CommonColors } from 'styles/colors/common';
import { DarkThemeColors } from 'styles/colors/dark';
import { LightThemeColors } from 'styles/colors/light';
import { theme } from 'styles/muiTheme';

export const cssBaselineOverrides = {
  MuiCssBaseline: {
    styleOverrides: {
      main: {
        flex: '1 0 auto',
      },
      '#root': {
        position: 'relative',
        minHeight: '100dvh',
        overflow: 'hidden',
      },
      '.router-link': {
        textDecoration: 'none',
      },
      '.rnc__base': {
        position: 'static',
      },
    },
  },
};

export const inputCommonOverrides = {
  root: {
    borderRadius: 8,
  },
  notchedOutline: {
    borderColor: 'transparent',
  },
  input: {
    padding: '8px 12px',
    ...theme.typography.body1,
  },
};

export const formLabelOverrides = {
  MuiFormLabel: {
    styleOverrides: {
      root: {
        display: 'block',
        marginBottom: 8,
      },
    },
  },
};

export const buttonOverrides = {
  root: {
    minWidth: 48,
    borderRadius: 8,
    padding: '8px 16px',
    color: CommonColors.white,
  },
  text: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textPrimary: {
    color: CommonColors.white,
    '&$disabled': {
      color: CommonColors.white,
      opacity: 0.33,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  textSecondary: {
    color: CommonColors.black,
    '&$disabled': {
      color: alpha(CommonColors.black, 0.33),
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  contained: {
    boxShadow: 'none',
    padding: '8px 16px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    '&$disabled': {
      color: CommonColors.white,
      opacity: 0.33,
    },
    '&:hover': {},
  },
  outlined: {
    boxShadow: 'none',
    background: CommonColors.white,
    '& $label': {
      '& i': {
        fontSize: 18,
      },
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  startIcon: {
    marginRight: 10,
    marginLeft: 0,
  },
};

export const tableOverrides = (color: LightThemeColors | DarkThemeColors) => ({
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 'none',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderCollapse: 'collapse',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${color}`,
      },
    },
  },
});

export const tabsOverrides = (colorAccent: LightThemeColors | DarkThemeColors) => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 'auto',
        marginBottom: -1,
      },
      indicator: {
        height: 2,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      textColorPrimary: {
        color: colorAccent,
        '&:hover:not(&$selected)': {
          color: colorAccent,
          '&:after': {
            opacity: 1,
          },
        },
      },
      root: {
        color: 'red',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        minWidth: 'auto !important',
        minHeight: 'auto',
        padding: '20px',
        zIndex: 1,
        '&:not(:last-child)': {
          marginInlineEnd: 0,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: 3,
          width: '100%',
          backgroundColor: colorAccent,
          opacity: 0,
          zIndex: -1,
        },
      },
    },
  },
});

export const buttonGroupOverrides = {
  root: {
    backgroundColor: LightThemeColors.overlay,
    padding: '4px',
    borderRadius: '8px',
  },
  grouped: {
    backgroundColor: 'transparent',
    color: LightThemeColors.active,
    fontSize: '14px',
    lineHeight: '20px',
    '&:not(:first-of-type)': {
      borderRadius: '8px',
    },
    '&:not(:last-of-type)': {
      borderRadius: '8px',
    },
    '&:hover': {
      color: CommonColors.white,
    },
  },
};
