import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class HardwareStockApiRepository extends AbstractApiRepository {
  getHardwareList = (query: string) => {
    return this.apiClient.get({ url: `/hardware/index${query}` });
  };

  getCategories = () => {
    return this.apiClient.get({ url: '/hardware/device-categories' });
  };

  getVendors = () => {
    return this.apiClient.get({ url: '/hardware/device-vendors' });
  };

  getLocations = () => {
    return this.apiClient.get({ url: '/hardware/locations' });
  };
}
