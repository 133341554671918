import { appConfig } from 'appConfig';

import AbstractLocalRepositoryWithId from 'base/AbstractLocalRepository';

export default class MFAMessengerTypesLocalRepository extends AbstractLocalRepositoryWithId {
  id: any;
  constructor(id: any) {
    super();
    this.id = id;
  }

  tableName(): string {
    return `${this.id}_${appConfig.mfaTypes}`;
  }
}
