import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { Loader } from 'components/UI/Loader';
import { QIcon } from 'components/UI/QIcon/QIcon';
import { EmailMaskInput } from 'components/UI/TextFields/QMaskedInput';
import QPhoneInputWithController from 'components/UI/TextFields/QPhoneInputWithController';
import QTextFieldWithController from 'components/UI/TextFields/QTextFieldWithController';
import { RegExpHelper } from 'helpers/RegExpHelper';
import { TypeContact } from 'modules/auth/types/AuthTypes';
import { ResetPasswordFormFields } from 'modules/users/forms/ResetPasswordForm';
import { useCommonStyles } from 'styles/commonStyles';

interface IContactStepProps {
  isLoading: boolean;
  errors: IErrorData | null;
}

export const ContactStep: React.FC<IContactStepProps> = ({ isLoading, errors }) => {
  const {
    languageToggleStore: { _lk, translations },
  } = useRootStore();
  const commonClasses = useCommonStyles();

  const { control, formState, setError, clearErrors } = useFormContext();

  const { field: typeContact } = useController({ name: ResetPasswordFormFields.typeContact, control });
  const { field: contact } = useController({ name: ResetPasswordFormFields.contact, control });

  const contactErrorMessage = formState.errors[ResetPasswordFormFields.contact]?.message;

  const defaultTextFieldProps = {
    name: ResetPasswordFormFields.contact,
    hookFormProps: { control },
    errors: contactErrorMessage,
  };

  // Effects
  useEffect(() => {
    if (errors) {
      setError(ResetPasswordFormFields.contact, { message: errors[ResetPasswordFormFields.contact] });
    }
  }, [errors]);

  // Handlers
  const handleChangeTypeContact = () => {
    contact.onChange('');
    clearErrors(ResetPasswordFormFields.contact);

    const newType = typeContact.value === TypeContact.phone ? TypeContact.email : TypeContact.phone;

    typeContact.onChange(newType);
  };

  // Renders
  return (
    <>
      <Box mb={8}>
        <Typography textAlign="center" className={commonClasses.onSurfaceTertiary} variant="body1">
          {typeContact.value === TypeContact.phone
            ? _lk('Укажите номер вашего телефона')
            : _lk('Введите адрес электронной почты')}
        </Typography>
      </Box>
      <Box mb={6}>
        {typeContact.value === TypeContact.phone ? (
          <QPhoneInputWithController disableCountrySelect required maxLength={16} {...defaultTextFieldProps} />
        ) : (
          <QTextFieldWithController
            required
            placeholder={RegExpHelper.emailMaskPlaceholder}
            InputProps={{ inputComponent: EmailMaskInput }}
            {...defaultTextFieldProps}
          />
        )}
      </Box>
      <Box mb={8} display="flex" alignItems="center" justifyContent="center">
        <Button
          variant="text"
          color="primary"
          startIcon={
            typeContact.value === TypeContact.phone ? '@' : <QIcon id="phone-alt" className={commonClasses.greenIcon} />
          }
          onClick={handleChangeTypeContact}
        >
          {typeContact.value === TypeContact.phone
            ? _lk('Использовать электронную почту')
            : _lk('Использовать номер телефона')}
        </Button>
      </Box>
      <Box mb={4}>
        <Button disabled={!contact.value || isLoading} type="submit" fullWidth variant="contained">
          {isLoading ? <Loader color="#fff" size={24} /> : translations.proceed}
        </Button>
      </Box>
    </>
  );
};
