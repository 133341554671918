import { jsonProperty, Serializable } from 'ts-serializable';

export class Totals extends Serializable {
  @jsonProperty(String) amblvlnotax: string = '';
  @jsonProperty(String) amblvlnotaxround: string = '';
  @jsonProperty(String) amblvltax: string = '';
  @jsonProperty(String) amblvltotal: string = '';
  @jsonProperty(String) amblvltotalround: string = '';
  @jsonProperty(Number) amountdisc: number = 0;
}
