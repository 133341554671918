import { jsonProperty, Serializable } from 'ts-serializable';

export class ObjectModel extends Serializable {
  @jsonProperty(Number, null) aclassid: number | null = null;
  @jsonProperty(Number, null) objectid: number | null = null;
  @jsonProperty(String, null) linkname: string | null = null;
  @jsonProperty(String, null) codecli: string | null = null;
  @jsonProperty(String, null) servertype: string | null = null;
  @jsonProperty(String, null) codeour: string | null = null;
}
