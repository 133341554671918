import { jsonProperty, Serializable } from 'ts-serializable';

import { ClientForAuth } from 'modules/clients/models/ClientForAuth';
import { MFA } from 'modules/mfa/models/MFA';

export class SuccessAuth extends Serializable {
  @jsonProperty(String, null) accessToken: string | null = null;
  @jsonProperty(Number, null) accessExpired: number | null = null;
  @jsonProperty(String, null) refreshToken: string | null = null;
  @jsonProperty(Number, null) refreshExpired: number | null = null;
  @jsonProperty(Number, null) timestamp: number | null = null;
  @jsonProperty(Number, null) status: number | null = null;
  @jsonProperty(Number, null) authStatus: number | null = null;
  @jsonProperty(MFA, null) mfa: MFA | null = null;
  @jsonProperty(Array, null) clients: ClientForAuth[] | null = null;
}
