import { configure } from 'mobx';
import { createRoot } from 'react-dom/client';
import 'reflect-metadata';

import { App } from './App';
import reportWebVitals from './reportWebVitals';

configure({ enforceActions: 'observed' });

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// Помогает при верстке, после можно отключить, так как при интеграции с апи может помешать
// Warning при разработке появляется из-за этого
if (module.hot) {
  module.hot.accept();
}

reportWebVitals();
