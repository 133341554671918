import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import ScreenLayout from 'components/Layouts/ScreenLayout';

import { routes } from '../../routes/routes';

const NotFoundScreen: React.FC = () => {
  const {
    languageToggleStore: { _lk },
  } = useRootStore();

  const classes = useStyles();

  // Renders
  return (
    <ScreenLayout splashScreen className={classes.splashScreen}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Box mb={2}>
          <h1 className={classes.error404}>404</h1>
        </Box>
        <Box display="flex" flexDirection="column" gap={6} maxWidth={isMobile ? 300 : 520}>
          <Typography variant={isMobile ? 'h5' : 'h3'} textAlign="center">
            {_lk('Страница не найдена')}
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {_lk('Возможно, она была перемещена или удалена. Вы можете перейти на')}{' '}
            <Link to={routes.MainScreen.path} className={classes.link}>
              {_lk('главную страницу')}
            </Link>
          </Typography>
        </Box>
      </Box>
    </ScreenLayout>
  );
};

const useStyles = makeStyles(theme => ({
  splashScreen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error404: {
    margin: 0,
    color: theme.palette.common.white,
    textShadow: `0px 4px 40px ${theme.palette.action.selected}`,
    fontSize: 258,
    lineHeight: 1,
    fontWeight: 800,
    [theme.breakpoints.down('sm')]: {
      fontSize: 150,
    },
  },
  text: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '28px',
    letterSpacing: '0.7px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export default NotFoundScreen;
