import { AxiosRequestConfig } from 'axios';

import { modelFactory } from 'base/ModelFactory';
import { AuthTokenData } from 'modules/auth/models/AuthTokenData';

import TokenLocalService from '../token/TokenLocalService';
import { OAuth2Service } from './OAuth2Service';

export class OAuth2Store {
  oAuth2Service: OAuth2Service;
  oAuth2TokenLocal: TokenLocalService;

  constructor() {
    this.oAuth2Service = new OAuth2Service();
    this.oAuth2TokenLocal = new TokenLocalService();
  }

  getNewAccessToken = async (data: { refreshToken: string; accessToken: string }) => {
    const newTokens = await this.oAuth2Service.getNewAccessToken(data);
    const oldTokens = this.oAuth2TokenLocal.getToken();

    if (oldTokens) {
      this.oAuth2TokenLocal.saveToken({
        ...oldTokens,
        ...newTokens,
      });
    }

    return newTokens;
  };

  logOut = async (config: AxiosRequestConfig) => {
    const loggedOut = await this.oAuth2Service.logOut(config);
    this.oAuth2TokenLocal.deleteToken();
    return loggedOut;
  };

  getToken = () => {
    const localToken = this.oAuth2TokenLocal.getToken();

    if (localToken) {
      return modelFactory.create<AuthTokenData>(AuthTokenData, localToken);
    }

    return null;
  };

  saveToken = (token: AuthTokenData) => {
    return this.oAuth2TokenLocal.saveToken(token);
  };

  deleteToken = () => {
    return this.oAuth2TokenLocal.deleteToken();
  };
}
