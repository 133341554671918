import { CSSProperties } from '@mui/styles';

import sprite from 'assets/images/vector/sprite.svg';

interface IQIconProps {
  id: string;
  width?: number;
  height?: number;
  className?: string;
  style?: CSSProperties;
}

export const QIcon: React.FC<IQIconProps> = ({ id, width = 24, height = 24, ...rest }) => {
  return (
    <svg {...rest} width={width} height={height}>
      <use href={`${sprite}#${id}`} />
    </svg>
  );
};
