import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum TracerouteFormFields {
  dcid = 'dcid',
  urlForTraceroute = 'urlForTraceroute',
  ipv6 = 'ipv6',
  udp = 'udp',
  firstTtl = 'firstTtl',
  maxTtl = 'maxTtl',
}

export class TracerouteForm extends BaseForm {
  dcid: number = 0;
  urlForTraceroute: string = '';
  ipv6: number = 0;
  udp: number = 0;
  firstTtl: number = 1;
  maxTtl: number = 1;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.dcid = 0;
    this.urlForTraceroute = '';
    this.ipv6 = 0;
    this.udp = 0;
    this.firstTtl = 1;
    this.maxTtl = 1;
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(TracerouteFormFields),
    });
  };
}
