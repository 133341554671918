import { Box } from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { BoxProps } from '@mui/system';
import { FC } from 'react';

import { useRipple } from 'hooks/useRippleRef';

interface IRippleBoxProps extends BoxProps {}

const RippleBox: FC<IRippleBoxProps> = ({ children, ...rest }) => {
  const { ref, onRippleStart, onRippleStop } = useRipple();

  // Renders
  return (
    <Box
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      {...rest}
      sx={{ overflow: 'hidden', position: 'relative', cursor: 'pointer' }}
    >
      {children}
      <TouchRipple ref={ref} center={false} />
    </Box>
  );
};

export default RippleBox;
