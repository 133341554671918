import { ModelFactory } from 'base/ModelFactory';

import { ObjectModel } from './models/ObjectModel';
import { ServiceModel } from './models/ServiceModel';

export class ServiceFactory extends ModelFactory {
  createService = (data: any) => {
    const service = this.create<ServiceModel>(ServiceModel, data);

    if (data.objects) {
      service.objects = this.createList<ObjectModel>(ObjectModel, data.objects);
    }

    if (data.children) {
      service.children = data.children.map(this.createService);
    }

    return service;
  };
}
