import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ICommonLayoutProps } from 'interfaces/UIComponentsInterfaces';
import { PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes/routes';

import RouteHelper from 'base/routes/helpers/RouteHelper';
import MobileHeader from 'components/Header/MobileHeader';
import { Loader } from 'components/UI/Loader';
import { useCommonStyles } from 'styles/commonStyles';

export const MobileBaseLayout: React.FC<PropsWithChildren<ICommonLayoutProps>> = ({
  isLoadingRequiredData,
  children,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { pathname } = useLocation();
  const title = useMemo(() => {
    return RouteHelper.getCurrentRouteTitle(routes, pathname);
  }, [pathname]);

  return (
    <Box minWidth="100%" minHeight="100%">
      <MobileHeader />
      <Box className={clsx(commonClasses.screenMainWrap, classes.mobileWrap)}>
        <Typography variant="subtitle1" className={clsx(classes.title, commonClasses.ellipsis)}>
          {title}
        </Typography>
        {isLoadingRequiredData ? <Loader minHeight="calc(100vh - 96px)" size={64} /> : children}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  mobileWrap: {
    marginTop: theme.spacing(16),
  },
  title: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));
