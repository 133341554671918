import { jsonProperty, Serializable } from 'ts-serializable';

import { Subbill } from './Subbill';

export class Bill extends Serializable {
  @jsonProperty(Number, null) billid: number = 0;
  @jsonProperty(String, null) billn: string | null = null;
  @jsonProperty(String, null) dtfrom: string | null = null;
  @jsonProperty(String, null) dtto: string | null = null;
  @jsonProperty(Number, null) payStatus: number | null = null;
  @jsonProperty(String, null) statusKey: string | null = null;
  @jsonProperty(Number, null) amounttotal: number | null = null;
  @jsonProperty(String, null) valuteid: string | null = null;
  @jsonProperty(String, null) amountRaw: string | null = null;
  @jsonProperty(String, null) amountParentRaw: string | null = null;
  @jsonProperty(String, null) amountChildRaw: string | null = null;
  @jsonProperty(String, null) dtbill: string | null = null;
  @jsonProperty([Subbill]) subbills: Subbill[] = [];
  @jsonProperty(Boolean, null) hasPdfForm: boolean | null = null;
  @jsonProperty(Boolean, null) availablepromisedpayment: boolean | null = null;
}
