import { ModelFactory } from 'base/ModelFactory';
import { ClientForAuth } from 'modules/clients/models/ClientForAuth';
import { MFA } from 'modules/mfa/models/MFA';

import { MFAMessengerType } from '../mfa/models/MFAMessengerType';
import { SuccessAuth } from './models/SuccessAuth';

export default class AuthFactory extends ModelFactory {
  createSuccessLoginAuth = (data: any) => {
    const successLoginAuthModel = this.create<SuccessAuth>(SuccessAuth, data);

    if (data?.clients && data?.clients.length) {
      successLoginAuthModel.clients = this.createList<ClientForAuth>(ClientForAuth, data.clients);
    }

    if (data?.mfa) {
      successLoginAuthModel.mfa = this.createMFA(data.mfa);
    }

    return successLoginAuthModel;
  };

  createMFA = (data: any) => {
    const mfa = this.create<MFA>(MFA, data);

    if (data?.messengersTypes && data?.messengersTypes.length) {
      mfa.messengersTypes = this.createList<MFAMessengerType>(MFAMessengerType, data.messengersTypes);
    }

    return mfa;
  };
}
