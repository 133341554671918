export enum AddressEnterMethod {
  SEARCH = 'SEARCH',
  MANUAL = 'MANUAL',
}

export enum AddressTypes {
  uaddress = 'uaddress',
  paddress = 'paddress',
  faddress = 'faddress',
}

export enum AddressAddInfoTypes {
  uaddinfo = 'uaddinfo',
  paddinfo = 'paddinfo',
  faddinfo = 'faddinfo',
}
