import { makeAutoObservable } from 'mobx';

import { rootStore } from 'base/RootStore';

import ThemeToggleService from './ThemeToggleService';
import { ThemeTypes } from './types/ThemeToggleTypes';

interface ThemeOptions {
  capitalize: boolean;
}

export class ThemeToggleStore {
  themeType: ThemeTypes = ThemeTypes.SYSTEM;

  private themeService: ThemeToggleService;

  constructor() {
    makeAutoObservable(this);
    this.themeService = new ThemeToggleService();
  }

  get theme() {
    if (this.themeType === ThemeTypes.SYSTEM) {
      const isSystemDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const systemTheme = isSystemDarkTheme ? ThemeTypes.DARK : ThemeTypes.LIGHT;
      return systemTheme;
    }

    return this.themeType;
  }

  setThemeType = (themeType: ThemeTypes) => {
    this.themeType = themeType;
    this.themeService.saveTheme(themeType);
  };

  getSavedTheme = () => {
    const savedTheme = this.themeService.getTheme();
    this.setThemeType(savedTheme);
  };

  getThemeName(options: ThemeOptions, themeType: ThemeTypes) {
    const { _lk } = rootStore.languageToggleStore;

    switch (themeType) {
      case ThemeTypes.LIGHT:
        return options?.capitalize ? _lk('Светлая тема') : _lk('светлая тема');

      case ThemeTypes.DARK:
        return options?.capitalize ? _lk('Темная тема') : _lk('темная тема');

      default:
        return options?.capitalize ? _lk('Как в системе') : _lk('как в системе');
    }
  }
}
