import { IQTableFilter } from 'interfaces/TableInterfaces';
import { makeAutoObservable, runInAction } from 'mobx';

import { ServiceStatuses } from 'modules/service/enums/ServiceStatuses';
import { ServiceModel } from 'modules/service/models/ServiceModel';
import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';

import ServicesService from './ServicesService';
import { ServicesFilters } from './enums/ServicesFilters';
import { ServicesTableForm } from './forms/ServicesTableForm';
import { IServiceTotalInfo, IServiceChildrenInfo } from './interfaces/ServicesInterfaces';
import { AgreementClass } from './models/AgreementClass';

export class ServicesStore {
  servicesLoading: boolean = false;
  childrenLoading: { [k: number]: boolean } = {};
  agreementClassesLoading: boolean = false;

  servicesTableForm = ServicesTableForm.createObservable();

  services: ServiceModel[] | null = null;
  servicesInfo: IServiceTotalInfo | null = null;
  servicesChildrenInfo: IServiceChildrenInfo = {};

  agreementClasses: AgreementClass[] | null = null;

  private servicesService: ServicesService;

  constructor() {
    makeAutoObservable(this);
    this.servicesService = new ServicesService();

    this.servicesTableForm.setAction(TableFormDynamicActions.getList, this.getServices);
  }

  get servicesFilterOptions(): { [k: string]: IQTableFilter[] | undefined } {
    return {
      [ServicesFilters.AGREEMENT_CLASS]: this.agreementClasses?.map(({ aclassid, name }) => ({
        id: aclassid,
        title: name,
      })),
    };
  }

  get servicesIds() {
    if (!this.services) {
      return [];
    }

    const allServices = this.services
      .filter(({ status }) => status !== ServiceStatuses.C)
      .map(function mapChildren(service: ServiceModel): ServiceModel | ServiceModel[] {
        if (service.children.length) {
          return [service, ...(service.children.map(mapChildren) as ServiceModel[])];
        }

        return service;
      })
      .flat(3);

    return allServices.map(({ subagrid }) => subagrid);
  }

  get allCurrentServicesClosed() {
    return this.services?.every(({ status }) => status === ServiceStatuses.C);
  }

  getServices = () => {
    if (this.servicesLoading) {
      return;
    }

    this.setServicesLoading(true);

    this.servicesService
      .getRootServices(this.servicesTableForm)
      .then(({ items, paginator, totals }) => {
        this.setServices(items);
        this.setServicesInfo({ count: 0, totals });
        runInAction(() => {
          this.servicesTableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setServicesLoading(false);
      });
  };

  getChildrenServices = (service: ServiceModel, callback: () => void) => {
    if (this.childrenLoading[service.subagrid]) {
      return;
    }

    if (service.children.length) {
      callback();
      return;
    }

    this.setChildrenLoading({
      [service.subagrid]: true,
    });

    this.servicesService
      .getChildrenServices(service.subagrid)
      .then(({ items, totals }) => {
        service.children = items;
        this.setServicesChildrenInfo({ [service.subagrid]: { count: items.length, totals } });
        callback();
      })
      .catch(() => {})
      .finally(() => {
        this.setChildrenLoading({ [service.subagrid]: false });
      });
  };

  getAgreementClasses = () => {
    if (this.agreementClassesLoading || this.agreementClasses) {
      return;
    }
    this.setAgreementClassesLoading(true);

    this.servicesService
      .getAgreementClasses()
      .then(this.setAgreementClasses)
      .catch(() => {})
      .finally(() => {
        this.setAgreementClassesLoading(false);
      });
  };

  // Setters
  setServices = (services: ServiceModel[]) => {
    this.services = services;
  };

  setAgreementClasses = (agreementClasses: AgreementClass[]) => {
    this.agreementClasses = agreementClasses;
  };

  // Loaders setters
  setServicesLoading = (loading: boolean) => {
    this.servicesLoading = loading;
  };

  setAgreementClassesLoading = (loading: boolean) => {
    this.agreementClassesLoading = loading;
  };

  setChildrenLoading = (loading: { [k: number]: boolean }) => {
    this.childrenLoading = { ...this.childrenLoading, ...loading };
  };

  setServicesChildrenInfo = (childrenInfo: IServiceChildrenInfo) => {
    this.servicesChildrenInfo = { ...this.servicesChildrenInfo, ...childrenInfo };
  };

  setServicesInfo = (info: IServiceTotalInfo) => {
    this.servicesInfo = info;
  };
}
