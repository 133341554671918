import { makeAutoObservable } from 'mobx';

import RoutesService from './RoutesService';

export class RoutesStore {
  private routesService: RoutesService;

  constructor() {
    makeAutoObservable(this);
    this.routesService = new RoutesService();
  }
}
