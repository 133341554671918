import { ITabItem } from 'interfaces/UIComponentsInterfaces';
import { makeAutoObservable, runInAction } from 'mobx';

import { rootStore } from 'base/RootStore';
import ErrorsService from 'base/modules/errors/ErrorsService';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';

import UnitService from './UnitService';
import { UnitTabs } from './enums/UnitEmuns';
import { IpmiAccessForm } from './forms/IpmiAccessForm';
import { IpmiIpForm } from './forms/IpmiIpForm';
import { PowerHistoryTableForm } from './forms/PowerHistoryTableForm';
import { UnitTrafficTableForm, UnitTrafficTableFormFields } from './forms/UnitTrafficTableForm';
import { DataCenterModel } from './models/DataCenterModel';
import { IPModel } from './models/IPModel';
import { PowerHistoryModel } from './models/PowerHistoryModel';
import { UnitConnectionModel } from './models/UnitConnectionModel';
import { UnitFileModel } from './models/UnitFileModel';
import { UnitIpmiInfoModel } from './models/UnitIpmiInfoModel';
import { UnitIpmiModel } from './models/UnitIpmiModel';
import { UnitModel } from './models/UnitModel';
import { UnitSaveIpModel } from './models/UnitSaveIpModel';

export class UnitStore {
  unitLoading: boolean = false;
  updateUnitLoading: boolean = false;
  unitIpmiInfoLoading: boolean = false;
  unitIpmiCommandsLoading: boolean = false;
  unitIpmiAccessLoading: boolean = false;
  unitIpmiIpsLoading: boolean = false;
  unitTrafficLoading: boolean = false;
  dataCenterLoading: boolean = false;
  ipListLoading: boolean = false;
  filesLoading: boolean = false;
  fileDownloadLoading: boolean = false;
  powerHistoryLoading: boolean = false;
  powerCurrentTasksLoading: boolean = false;
  powerTaskLoading: boolean = false;

  currentObjectId: number | null = null;
  unitInfo: UnitModel | null = null;

  unitIpmiInfo: UnitIpmiInfoModel | null = null;
  unitIpmiAccesses: UnitIpmiModel[] | null = null;
  unitIpmiIps: UnitSaveIpModel[] | null = null;

  unitIpmiToEdit: UnitSaveIpModel | null = null;
  unitIpmiToDelete: UnitSaveIpModel | null = null;
  unitIpmiAccessToEdit: UnitIpmiModel | null = null;
  unitIpmiAccessToDelete: UnitIpmiModel | null = null;

  unitConnection: UnitConnectionModel[] | null = null;

  initTrafficError: IErrorData | null = null;
  unitTrafficTableForm: UnitTrafficTableForm = UnitTrafficTableForm.createObservable();

  unitIpmiAccessForm: IpmiAccessForm = IpmiAccessForm.createObservable();

  dataCenter: DataCenterModel | null = null;
  ipList: IPModel[] | null = null;

  fileList: UnitFileModel[] | null = null;

  powerHistoryTableForm: PowerHistoryTableForm = PowerHistoryTableForm.createObservable();
  powerHistory: PowerHistoryModel[] | null = null;
  powerCurrentTasks: PowerHistoryModel[] | null = null;
  currentTaskId: number | null = null;

  private unitService: UnitService;
  private errorsService: ErrorsService;

  constructor() {
    this.unitService = new UnitService();
    this.errorsService = new ErrorsService();

    makeAutoObservable(this);
    this.unitTrafficTableForm.setAction(TableFormDynamicActions.getList, this.getUnitTraffic);
    this.powerHistoryTableForm.setAction(TableFormDynamicActions.getList, this.getPowerHistory);
  }

  getUnitInfo = (objectid: number) => {
    if (this.unitLoading) {
      return;
    }

    this.setUnitLoading(true);

    this.unitService
      .getUnitInfo(objectid)
      .then(this.setUnitInfo)
      .catch(() => {})
      .finally(() => {
        this.setUnitLoading(false);
      });
  };

  updateCurrentUnitCodecliName = (name: string, callback?: () => void) => {
    if (this.unitLoading || this.updateUnitLoading || !this.unitInfo) {
      return;
    }

    this.setUpdateUnitLoading(true);

    this.unitService
      .updateCurrentUnitCodecliName(this.unitInfo, name)
      .then(unitInfo => {
        this.setUnitInfo(unitInfo);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUpdateUnitLoading(false);
      });
  };

  // ipmi tab
  getUnitIpmiInfo = (objectid: number) => {
    this.setUnitIpmiInfoLoading(true);

    this.unitService
      .getUnitIpmiInfo(objectid)
      .then(data => {
        this.setUnitIpmiInfo(data);
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiInfoLoading(false);
      });
  };

  getUnitIpmiCommands = (objectid: number) => {
    this.setUnitIpmiCommandsLoading(true);
    this.setCurrentObjectId(objectid);

    this.unitService
      .getUnitIpmiCommands(objectid)
      .then(data => {
        this.setUnitIpmiAccesses(data);
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiCommandsLoading(false);
      });
  };

  getUnitConnections = (objectid: number) => {
    this.setUnitTrafficLoading(true);

    this.unitService
      .getUnitConnections(objectid)
      .then(data => {
        this.setUnitConnections(data);
        this.unitTrafficTableForm.setValuesAndGetList({
          [UnitTrafficTableFormFields.connid]: data[0].connid,
          [UnitTrafficTableFormFields.objectid]: objectid,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitTrafficLoading(false);
      });
  };

  getUnitSaveIps = () => {
    this.setUnitIpmiIpsLoading(true);

    this.unitService
      .getUnitSaveIps()
      .then(data => {
        this.setUnitIpmiIps(data);
      })
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        this.setInitTrafficError(errors);
      })
      .finally(() => {
        this.setUnitIpmiIpsLoading(false);
      });
  };

  addIpmiIp = (form: IpmiIpForm, callback?: () => void) => {
    this.setUnitIpmiIpsLoading(true);

    this.unitService
      .addIpmiIp(form)
      .then(() => {
        this.getUnitSaveIps();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiIpsLoading(false);
      });
  };

  updateIpmiIp = (form: IpmiIpForm, ip: UnitSaveIpModel, callback?: () => void) => {
    this.setUnitIpmiIpsLoading(true);

    this.unitService
      .updateIpmiIp(form, ip)
      .then(() => {
        this.getUnitSaveIps();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiIpsLoading(false);
      });
  };

  deleteIpmiIp = (aiid: number, callback?: () => void) => {
    this.setUnitIpmiIpsLoading(true);

    this.unitService
      .deleteIpmiIp(aiid)
      .then(() => {
        this.getUnitSaveIps();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiIpsLoading(false);
      });
  };

  addIpmiAccess = (form: IpmiAccessForm, callback?: () => void) => {
    this.setUnitIpmiAccessLoading(true);

    this.unitService
      .addIpmiAccess(form)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiAccessLoading(false);
      });
  };

  deleteIpmiAccess = (icid: number, callback?: () => void) => {
    this.setUnitIpmiAccessLoading(true);

    this.unitService
      .deleteIpmiAccess(icid)
      .then(() => {
        if (this.currentObjectId) {
          this.getUnitIpmiCommands(this.currentObjectId);
        }
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUnitIpmiAccessLoading(false);
      });
  };

  // traffic tab
  getUnitTraffic = () => {
    if (!this.unitTrafficTableForm.connid || !this.unitTrafficTableForm.objectid) {
      return;
    }

    this.setUnitTrafficLoading(true);

    this.unitService
      .getUnitTraffic(this.unitTrafficTableForm)
      .then(data => {})
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        this.setInitTrafficError(errors);
      })
      .finally(() => {
        this.setUnitTrafficLoading(false);
      });
  };

  // ip tab
  getDataCenterInfo = (dcid: number) => {
    this.setDataCenterLoading(true);

    this.unitService
      .getDataCenterInfo(dcid)
      .then(data => {
        this.setDataCenter(data);
      })
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        this.setInitTrafficError(errors);
      })
      .finally(() => {
        this.setDataCenterLoading(false);
      });
  };

  getIpList = (objectid: number) => {
    this.setIpListLoading(true);

    this.unitService
      .getIpList(objectid)
      .then(data => {
        this.setIpList(data);
      })
      .catch(() => {})
      .finally(() => {
        this.setIpListLoading(false);
      });
  };

  deleteIp = (ip: string, objectid: number, callback?: () => void) => {
    this.setIpListLoading(true);

    this.unitService
      .deleteIp(ip)
      .then(() => {
        this.getIpList(objectid);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setIpListLoading(false);
      });
  };

  // files tab
  getDocumentsList = (objectid: number) => {
    this.setFilesLoading(true);

    this.unitService
      .getDocumentsList(objectid)
      .then(data => {
        this.setFileList(data);
      })
      .catch(() => {})
      .finally(() => {
        this.setFilesLoading(false);
      });
  };

  downloadFile = (file: UnitFileModel) => {
    this.setFileDownloadLoading(true);

    this.unitService
      .downloadFile(file)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.setFileDownloadLoading(false);
      });
  };

  // powerTab
  getPowerHistory = () => {
    this.setPowerHistoryLoading(true);

    this.unitService
      .getPowerHistory(this.powerHistoryTableForm)
      .then(({ items, paginator }) => {
        this.setPowerHistory(items);
        runInAction(() => {
          this.powerHistoryTableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setPowerHistoryLoading(false);
      });
  };

  getPowerCurrentTasks = (objectid: number) => {
    this.setPowerCurrentTasksLoading(true);

    this.unitService
      .getPowerCurrentTasks(objectid)
      .then(data => {
        this.setPowerCurrentTasks(data);
      })
      .catch(() => {})
      .finally(() => {
        this.setPowerCurrentTasksLoading(false);
      });
  };

  powerOn = (objectid: number, callback?: () => void) => {
    this.setPowerTaskLoading(true);

    this.unitService
      .powerOn(objectid)
      .then(data => {
        this.setCurrentTaskId(data);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPowerTaskLoading(false);
      });
  };

  powerOff = (objectid: number, callback?: () => void) => {
    this.setPowerTaskLoading(true);

    this.unitService
      .powerOff(objectid)
      .then(data => {
        this.setCurrentTaskId(data);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPowerTaskLoading(false);
      });
  };

  powerReboot = (objectid: number, callback?: () => void) => {
    this.setPowerTaskLoading(true);

    this.unitService
      .powerReboot(objectid)
      .then(data => {
        this.setCurrentTaskId(data);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPowerTaskLoading(false);
      });
  };

  ipmiColdReset = (objectid: number, callback?: () => void) => {
    this.setPowerTaskLoading(true);

    this.unitService
      .ipmiColdReset(objectid)
      .then(data => {
        this.setCurrentTaskId(data);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPowerTaskLoading(false);
      });
  };

  getUnitTabs = (aclassid: number): ITabItem[] => {
    const { _lk } = rootStore.languageToggleStore;

    const infoTab = {
      id: UnitTabs.info,
      title: _lk('Инфо'),
    };

    const IPMITab = {
      id: UnitTabs.IPMI,
      title: _lk('IPMI'),
    };

    const trafficTab = {
      id: UnitTabs.traffic,
      title: _lk('Трафик'),
    };

    const ipTab = {
      id: UnitTabs.ip,
      title: _lk('IP Адреса'),
    };

    const filesTab = {
      id: UnitTabs.files,
      title: _lk('Файлы'),
    };

    const billTab = {
      id: UnitTabs.bill,
      title: _lk('Счета'),
    };

    const powerTab = {
      id: UnitTabs.power,
      title: _lk('Питание'),
    };

    const CPanelTab = {
      id: UnitTabs.CPanel,
      title: _lk('CPanel'),
    };

    switch (aclassid) {
      case 1:
      case 7:
      case 12:
        return [infoTab, IPMITab, trafficTab, ipTab, filesTab, billTab, powerTab, CPanelTab];
      default:
        return [];
    }
  };

  // loaders
  setUnitLoading = (loading: boolean) => {
    this.unitLoading = loading;
  };

  setUpdateUnitLoading = (loading: boolean) => {
    this.updateUnitLoading = loading;
  };

  setUnitIpmiCommandsLoading = (loading: boolean) => {
    this.unitIpmiCommandsLoading = loading;
  };

  setUnitIpmiAccessLoading = (loading: boolean) => {
    this.unitIpmiAccessLoading = loading;
  };

  setUnitTrafficLoading = (loading: boolean) => {
    this.unitTrafficLoading = loading;
  };

  setUnitIpmiInfoLoading = (loading: boolean) => {
    this.unitIpmiInfoLoading = loading;
  };

  setUnitIpmiIpsLoading = (loading: boolean) => {
    this.unitIpmiIpsLoading = loading;
  };

  setDataCenterLoading = (loading: boolean) => {
    this.dataCenterLoading = loading;
  };

  setIpListLoading = (loading: boolean) => {
    this.ipListLoading = loading;
  };

  setFilesLoading = (loading: boolean) => {
    this.filesLoading = loading;
  };

  setFileDownloadLoading = (loading: boolean) => {
    this.fileDownloadLoading = loading;
  };

  setPowerHistoryLoading = (loading: boolean) => {
    this.powerHistoryLoading = loading;
  };

  setPowerCurrentTasksLoading = (loading: boolean) => {
    this.powerCurrentTasksLoading = loading;
  };

  setPowerTaskLoading = (loading: boolean) => {
    this.powerTaskLoading = loading;
  };

  // setters
  setUnitInfo = (data: UnitModel | null) => {
    this.unitInfo = data;
  };

  setCurrentObjectId = (id: number | null) => {
    this.currentObjectId = id;
  };

  setUnitIpmiAccesses = (data: UnitIpmiModel[] | null) => {
    this.unitIpmiAccesses = data;
  };

  setUnitIpmiAccessToEdit = (data: UnitIpmiModel | null) => {
    this.unitIpmiAccessToEdit = data;
  };

  setUnitIpmiAccessToDelete = (data: UnitIpmiModel | null) => {
    this.unitIpmiAccessToDelete = data;
  };

  setUnitIpmiToEdit = (data: UnitSaveIpModel | null) => {
    this.unitIpmiToEdit = data;
  };

  setUnitIpmiToDelete = (data: UnitSaveIpModel | null) => {
    this.unitIpmiToDelete = data;
  };

  setUnitConnections = (data: UnitConnectionModel[] | null) => {
    this.unitConnection = data;
  };

  setUnitIpmiInfo = (data: UnitIpmiInfoModel | null) => {
    this.unitIpmiInfo = data;
  };

  setInitTrafficError = (error: IErrorData | null) => {
    this.initTrafficError = error;
  };

  setUnitIpmiIps = (data: UnitSaveIpModel[] | null) => {
    this.unitIpmiIps = data;
  };

  setDataCenter = (data: DataCenterModel | null) => {
    this.dataCenter = data;
  };

  setIpList = (data: IPModel[] | null) => {
    this.ipList = data;
  };

  setFileList = (data: UnitFileModel[] | null) => {
    this.fileList = data;
  };

  setPowerHistory = (data: PowerHistoryModel[] | null) => {
    this.powerHistory = data;
  };

  setPowerCurrentTasks = (data: PowerHistoryModel[] | null) => {
    this.powerCurrentTasks = data;
  };

  setCurrentTaskId = (id: number | null) => {
    this.currentTaskId = id;
  };
}
