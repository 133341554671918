import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';
import { Nullable } from 'base/types/BaseTypes';

export enum LoginFormFields {
  login = 'login',
  pass = 'pass',
  clientid = 'clientid',
  mfacode = 'mfacode',
  messengertypeid = 'messengertypeid',
}

export class LoginForm extends BaseForm {
  login: string = '';
  pass: string = '';
  osVersion: string = '';
  deviceModel: string = '';
  udid: string = '';
  mfacode: Nullable<string> = null;
  messengertypeid: Nullable<number> = null;
  clientid: Nullable<number> = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(LoginFormFields),
    });
  };
}
