import { jsonProperty, Serializable } from 'ts-serializable';

import { Visitor } from '../models/Visitor';

export class PassSaveDto extends Serializable {
  @jsonProperty(Number, null) dcpid: number | null = null;
  @jsonProperty(Number, null) dcid: number | null = null;
  @jsonProperty(Number, null) haveautomobile: number | null = null;
  @jsonProperty(Number, null) dcvcid: number | null = null;
  @jsonProperty(Number, null) status: number | null = null;
  @jsonProperty(String, null) dtvisitdate: string | null = null;
  @jsonProperty(String, null) dtvisittime: string | null = null;
  @jsonProperty(String, null) descr: string | null = null;
  @jsonProperty(String, null) carnumber: string | null = null;
  @jsonProperty(String, null) carmodel: string | null = null;
  @jsonProperty(Array, null) visitor: Visitor[] | null = null;
}
