import { Routes, Route, Navigate, NavigateProps } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';

import RouteHelper from '../helpers/RouteHelper';
import { IRoutes, IRoute } from '../interfaces/RouteInterfaces';
import { RoleKeys } from '../types/RouteTypes';
import { PageTitle } from './PageTitle';

interface IRoutesProps {
  routes: IRoutes;
  role?: RoleKeys;
  disableRedirect?: boolean;
  redirectProps?: { to: NavigateProps['to'] };
}

export const AppRoutes: React.FC<IRoutesProps> = ({ routes, role, disableRedirect, redirectProps }) => {
  const { languageToggleStore } = useRootStore();
  const routesList = RouteHelper.getRoutesWithCheckedRoles(routes, role);

  const renderRouteChildren = (route: IRoute) => {
    const { title, element } = route;

    if (element) {
      return (
        <>
          <PageTitle pageTitle={title} siteName={languageToggleStore.translations.siteName} />
          {element}
        </>
      );
    }

    return <></>;
  };

  return (
    <main>
      <Routes>
        {routesList.map(route => {
          const { element, path, ...rest } = route;
          return <Route key={path} path={path} element={renderRouteChildren(route)} {...rest} />;
        })}

        {!disableRedirect && (
          <Route path="*" element={<Navigate to={redirectProps ? redirectProps.to : routes.NotFoundScreen.path} />} />
        )}
      </Routes>
    </main>
  );
};
