import ProfileApiRepository from './ProfileApiRepository';
import ProfileFactory from './ProfileFactory';
import { IUpdateUserLanguage } from './interfaces/UsersInterfaces';

export default class ProfileService {
  private profileApi: ProfileApiRepository;
  private profileFactory: ProfileFactory;

  constructor() {
    this.profileApi = new ProfileApiRepository();
    this.profileFactory = new ProfileFactory();
  }

  getUserInfo = async () => {
    const { data } = await this.profileApi.getUserInfo();

    return this.profileFactory.createUser(data.data);
  };

  updateUserLanguage = async (update: IUpdateUserLanguage) => {
    const dto = this.profileFactory.prepareUpdateUserLangugeDto(update);

    const { data } = await this.profileApi.updateUserLanguage(dto);

    return data.data;
  };
}
