import { jsonProperty, Serializable } from 'ts-serializable';

import { IPMIAccessTypes } from '../enums/UnitEmuns';
import { AccessIp } from './AccessIp';
import { UnitIpmiTimerModel } from './UnitIpmiTimerModel';

export class UnitIpmiModel extends Serializable {
  @jsonProperty(String, null) ipmiIp: string | null = null;
  @jsonProperty(String, null) accessType: IPMIAccessTypes | null = null;
  @jsonProperty(String, null) accessTypeId: string | null = null;
  @jsonProperty(Number, null) icid: number | null = null;
  @jsonProperty(Array, null) accessIps: AccessIp[] | null = null;
  @jsonProperty(UnitIpmiTimerModel, null) timeLeft: UnitIpmiTimerModel | null = null;
}
