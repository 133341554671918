import { jsonProperty, Serializable } from 'ts-serializable';

import { DaysLeftTypes } from '../enums/DaysLeftTypes';
import { ServiceStatuses } from '../enums/ServiceStatuses';
import { ObjectModel } from './ObjectModel';

export class ServiceModel extends Serializable {
  @jsonProperty(Number) subagrid: number = 0;
  @jsonProperty(String, null) fullname: string | null = null;
  @jsonProperty(String, null) fullname2: string | null = null;
  @jsonProperty(Number, null) regid: number | null = null;
  @jsonProperty(Number, null) amounttotal: number | null = null;
  @jsonProperty(Number, null) amount2total: number | null = null;
  @jsonProperty(String, null) valuteid: string | null = null;
  @jsonProperty(Number, null) period: number | null = null;
  @jsonProperty(Number, null) updateid: number | null = null;
  @jsonProperty(String, null) aggregatedpayedfrom: string | null = null;
  @jsonProperty(String, null) aggregatedpayedto: string | null = null;
  @jsonProperty(String, null) status: ServiceStatuses | null = null;
  @jsonProperty(Number, null) reasonattentionid: number | null = null;
  @jsonProperty(String, null) clicomment: string | null = null;
  @jsonProperty(Number, null) aclassid: number | null = null;
  @jsonProperty(Number, null) testmode: number | null = null;
  @jsonProperty(Number, null) numitems: number | null = null;
  @jsonProperty(Number, null) parentsaid: number | null = null;
  @jsonProperty(Number, null) freeperiodusedxtimes: number | null = null;
  @jsonProperty(Number, null) sabrid: number | null = null;
  @jsonProperty(String, null) classobjectname: string | null = null;
  @jsonProperty(Array) objects: ObjectModel[] = [];
  @jsonProperty(String, null) amounttotalRaw: string | null = null;
  @jsonProperty(String, null) amount2totalRaw: string | null = null;
  @jsonProperty(String, null) daysLeftType: DaysLeftTypes | null = null;
  @jsonProperty(String, null) daysLeft: string | null = null;
  @jsonProperty(String, null) amblvltotal: string | null = null;
  @jsonProperty(String, null) amblvltotalRaw: string | null = null;
  @jsonProperty(Number, null) billid: number | null = null;
  @jsonProperty(Number, null) subbillid: number | null = null;
  @jsonProperty(String, null) statusTitle: string | null = null;
  @jsonProperty(String, null) blockingReason: string | null = null;
  @jsonProperty(String, null) testmodeTitle: string | null = null;
  @jsonProperty(String, null) freeperiodTitle: string | null = null;
  @jsonProperty(Object, null) amblvltotalsForChild: Record<string, string> | null = null;
  @jsonProperty(Number) countOfChild: number = 0;
  @jsonProperty(Array) children: ServiceModel[] = [];

  get allIds(): number[] {
    if (this.parentsaid) {
      return [this.parentsaid, this.subagrid, ...this.children.map(getIds).flat(2)];
    }

    return [this.subagrid, ...this.children.map(getIds).flat(2)];
  }
}

const getIds = ({ subagrid, children }: ServiceModel) => [subagrid, ...children.map(({ subagrid }) => subagrid)];
