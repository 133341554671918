import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICommonModalProps } from 'interfaces/UIComponentsInterfaces';
import { FC, useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import QModal from 'components/UI/Modal/QModal';
import { useSuccessModalStyles } from 'styles/modules/useModalStyles';

interface IChangeClientSuccessModalProps extends ICommonModalProps {}

const ChangeClientSuccessModal: FC<IChangeClientSuccessModalProps> = ({ close }) => {
  const successModalClasses = useSuccessModalStyles();
  const classes = useStyles();
  const {
    modalsStore,
    languageToggleStore: { _lk, translations },
  } = useRootStore();

  // Effects
  useEffect(() => {
    const timer = setTimeout(handleClose, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // Handlers
  const handleClose = () => {
    modalsStore.setChangeClientModalType(null);
    window.location.href = '/';
  };

  // Renders
  return (
    <QModal close={close} centerContent PaperProps={{ className: classes.modal }}>
      <Typography variant="h5" textAlign="center" className={successModalClasses.title}>
        {_lk('Выбран новый клиент!')}
      </Typography>
      <Button variant="contained" onClick={handleClose} className={successModalClasses.button}>
        {translations.ok}
      </Button>
    </QModal>
  );
};

const useStyles = makeStyles(theme => ({
  modal: {
    minHeight: theme.spacing(71),
    width: theme.spacing(100),
  },
}));

export default ChangeClientSuccessModal;
