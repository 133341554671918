import { useEffect, useRef, useState } from 'react';

import OthersHelper from 'helpers/OthersHelper';

export const useTimer = (deadline: number) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timeIsUp, setTimeIsUp] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const getTime = () => {
    if (deadline) {
      const time = deadline - Date.now();

      if (time <= 0 && timer.current) {
        clearInterval(timer.current);
        timer.current = null;
        setTimeIsUp(true);
        return;
      }

      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
      setTimeIsUp(false);
    } else {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
        setTimeIsUp(true);
        return;
      }
    }
  };

  useEffect(() => {
    if (deadline) {
      timer.current = setInterval(getTime, 1000);
    } else {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
        timer.current = null;
      }
    };
  }, [deadline]);

  return {
    days: `${days}`,
    hours: OthersHelper.getDigitWithZeroPrefix(hours),
    minutes: OthersHelper.getDigitWithZeroPrefix(minutes),
    seconds: OthersHelper.getDigitWithZeroPrefix(seconds),
    timeIsUp,
  };
};
