import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { DeleteIpDto } from './dto/DeleteIpDto';
import { DeleteIpmiAccessDto } from './dto/DeleteIpmiAccessDto';
import { DeleteIpmiIpDto } from './dto/DeleteIpmiIpDto';
import { IpDto } from './dto/IpDto';
import { UpdateUnitDto } from './dto/UpdateUnitDto';

export default class UnitApiRepository extends AbstractApiRepository {
  getUnitInfo = (query: string) => {
    return this.apiClient.get({ url: `/unit/info${query}` });
  };

  updateCurrentUnitCodecliName = (data: UpdateUnitDto) => {
    return this.apiClient.post({ url: `/unit/update`, data });
  };

  getUnitIpmiInfo = (query: string) => {
    return this.apiClient.get({ url: `/unit/ipmi-info${query}` });
  };

  // ipmi tab
  getUnitIpmiCommands = (query: string) => {
    return this.apiClient.get({ url: `/unit/ipmi-commands${query}` });
  };

  addIpmiAccess = (query: string) => {
    return this.apiClient.get({ url: `/unit/issue-ipmi-access${query}` });
  };

  editIpmiAccess = (query: string) => {
    return this.apiClient.get({ url: `/unit/edit-ipmi-access${query}` });
  };

  deleteIpmiAccess = (data: DeleteIpmiAccessDto) => {
    return this.apiClient.post({ url: `/unit/ipmi-commands-close`, data });
  };

  getUnitConnections = (query: string) => {
    return this.apiClient.get({ url: `/unit/connections${query}` });
  };

  getUnitSaveIps = () => {
    return this.apiClient.get({ url: `/ipmi-access-ips/index` });
  };

  addIpmiIp = (data: IpDto) => {
    return this.apiClient.post({ url: `/ipmi-access-ips/create`, data });
  };

  updateIpmiIp = (data: IpDto) => {
    return this.apiClient.post({ url: `/ipmi-access-ips/update`, data });
  };

  deleteIpmiIp = (data: DeleteIpmiIpDto) => {
    return this.apiClient.post({ url: `/ipmi-access-ips/delete`, data });
  };

  // Traffic tab
  getUnitTraffic = (query: string) => {
    return this.apiClient.get({ url: `/unit/traffic${query}` });
  };

  // ip tab
  getDataCenterInfo = (query: string) => {
    return this.apiClient.get({ url: `/dc/show${query}` });
  };

  getIpList = (query: string) => {
    return this.apiClient.get({ url: `/unit/ip-list${query}` });
  };

  addIpRequest = (query: string) => {
    return this.apiClient.get({ url: `/unit/ip-request-add${query}` });
  };

  deleteIp = (data: DeleteIpDto) => {
    return this.apiClient.post({ url: `/unit/ip-request-del`, data });
  };

  // files tab
  getDocumentsList = (query: string) => {
    return this.apiClient.get({ url: `/unit/documents${query}` });
  };

  downloadFile = (query: string) => {
    return this.apiClient.get({ url: `/unit/document-download${query}` });
  };

  // power tab
  getPowerHistory = (query: string) => {
    return this.apiClient.get({ url: `/units-net-task/index${query}` });
  };

  powerOff = (query: string) => {
    return this.apiClient.get({ url: `/unit/power-off${query}` });
  };

  powerOn = (query: string) => {
    return this.apiClient.get({ url: `/unit/power-on${query}` });
  };

  powerReboot = (query: string) => {
    return this.apiClient.get({ url: `/unit/power-reboot${query}` });
  };

  ipmiColdReset = (query: string) => {
    return this.apiClient.get({ url: `/unit/ipmi-cold-reset${query}` });
  };
}
