import { makeAutoObservable, runInAction } from 'mobx';

import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';

import BillsService from './BillsService';
import { BillsTableForm } from './forms/BillsTableForm';
import { Bill } from './models/Bill';

export class BillsStore {
  billsListLoading = false;
  billsList: Bill[] | null = null;

  billsTableForm: BillsTableForm = BillsTableForm.createObservable();

  private billsService: BillsService;

  constructor() {
    makeAutoObservable(this);
    this.billsService = new BillsService();

    this.billsTableForm.setAction(TableFormDynamicActions.getList, this.getBillsList);
  }

  get billsIds(): number[] {
    return this.billsList?.map(bill => bill.billid ?? 0) ?? [];
  }

  getBillsList = () => {
    if (this.billsListLoading) {
      return;
    }

    this.setBillsListLoading(true);

    this.billsService
      .getBillsList(this.billsTableForm)
      .then(({ items, paginator }) => {
        this.setBillsList(items);
        runInAction(() => {
          this.billsTableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setBillsListLoading(false);
      });
  };

  setBillsList = (items: Bill[] | null) => {
    this.billsList = items;
  };

  // Loaders
  setBillsListLoading = (loading: boolean) => {
    this.billsListLoading = loading;
  };
}
