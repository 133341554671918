export const appConfig = {
  apiUrl: {
    dev: process.env.REACT_APP_DEV_API_URL,
    prod: process.env.REACT_APP_PROD_API_URL,
  },

  orderServiceLink: 'https://atlex.ru/',

  // storage keys
  tokenStorageKey: '_atlexPrivateOfficeAccessToken',
  themeStorageKey: '_atlexThemeType',
  languageStorageKey: '_atlexLanguageType',
  mfaTypes: '_atlexMFATypes',
  authClient: '_atlexAuthClient',
  lexemes: '_atlexLexemes',
  forbidden: '_atlexForbidden',
  tables: '_atlexTables',
  pathname: '_atlexLatestPathname',
  authPathname: '_atlexLatestAuthPathname',
};
