import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { formatPhoneNumber } from 'react-phone-number-input';

import { useRootStore } from 'base/hooks/useRootStore';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { Loader } from 'components/UI/Loader';
import QTextFieldWithController from 'components/UI/TextFields/QTextFieldWithController';
import { useTimer } from 'hooks/useTimer';
import { TypeContact } from 'modules/auth/types/AuthTypes';
import { ResetPasswordFormFields } from 'modules/users/forms/ResetPasswordForm';
import { useCommonStyles } from 'styles/commonStyles';

interface ICodeStepProps {
  isLoading: boolean;
  errors: IErrorData | null;
  deadline: number;
  onGetNewCodeClick: () => void;
}

export const CodeStep: React.FC<ICodeStepProps> = observer(({ isLoading, errors, deadline, onGetNewCodeClick }) => {
  const {
    languageToggleStore: { _lk, _stringFormat },
  } = useRootStore();
  const commonClasses = useCommonStyles();

  const { minutes, seconds, timeIsUp } = useTimer(deadline);

  const { control, formState, setError, clearErrors } = useFormContext();

  const { field: typeContact } = useController({ name: ResetPasswordFormFields.typeContact, control });
  const { field: contact } = useController({ name: ResetPasswordFormFields.contact, control });

  const codeErrorMessage = formState.errors[ResetPasswordFormFields.code]?.message;

  // Effects
  useEffect(() => {
    if (errors) {
      setError(ResetPasswordFormFields.code, { message: errors.error });
    } else {
      clearErrors(ResetPasswordFormFields.code);
    }
  }, [errors]);

  // Renders
  return (
    <>
      <Box mb={12}>
        <Typography textAlign="center" className={commonClasses.onSurfaceTertiary} variant="body1">
          {typeContact.value === TypeContact.phone
            ? _lk('Мы отправили код на номер')
            : _lk('Мы отправили код на почту')}
          <br />
          {typeContact.value === TypeContact.phone ? formatPhoneNumber(contact.value) : contact.value}
        </Typography>
      </Box>
      <Box mb={12}>
        <QTextFieldWithController
          name={ResetPasswordFormFields.code}
          hookFormProps={{ control }}
          InputProps={{ sx: { maxWidth: 96, mx: 'auto' } }}
          FormHelperTextProps={{ sx: { textAlign: 'center' } }}
          inputProps={{ style: { textAlign: 'center' }, maxLength: 6 }}
          error={!!codeErrorMessage || !!errors}
          errors={codeErrorMessage}
          disabled={isLoading}
        />
      </Box>
      {!timeIsUp && (
        <Box mb={2}>
          <Typography variant="caption" component="p" textAlign="center">
            {_stringFormat(_lk('Получить новый код можно через {minutes}:{seconds}'), {
              minutes,
              seconds,
            })}
          </Typography>
        </Box>
      )}
      <Button
        disabled={!timeIsUp || isLoading}
        variant="contained-contrast"
        color="primary"
        fullWidth
        onClick={onGetNewCodeClick}
      >
        {isLoading ? <Loader color="#fff" size={24} /> : _lk('Получить новый код')}
      </Button>
    </>
  );
});
