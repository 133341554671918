import { makeAutoObservable, runInAction } from 'mobx';
import { FieldValues } from 'react-hook-form';

import { ResponseSuccess } from 'base/models/CommonModels';
import ErrorsService from 'base/modules/errors/ErrorsService';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { IAggregatedFieldValues } from 'base/modules/fields/interfaces/FieldsInterfaces';
import { IAddressManualFields } from 'modules/addresses/interfaces/AddressesInterfaces';
import { ClientsService } from 'modules/clients/ClientsService';
import { ClientRequisites } from 'modules/clients/models/requisites/ClientRequisites';
import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';

import RegistrantsService from './RegistrantsService';
import { RegistrantsTableForm } from './forms/RegistrantsTableForm';
import { Registrant } from './models/Registrant';

export class RegistrantsStore {
  registrantsLoading = false;
  registrantLoading = false;
  registrantCardLoading = false;
  isUpdatingRegistrant = false;
  isDeletingRegistrant = false;

  deletingRegistrant: Registrant | null = null;

  private registrantsService: RegistrantsService;
  private clientsService: ClientsService;
  private errorsService: ErrorsService;

  registrants: Registrant[] = [];
  registrantsTableForm: RegistrantsTableForm = RegistrantsTableForm.createObservable();
  currentRegistrant: Registrant | null = null;
  registrantCard: ClientRequisites | null = null;
  addressesManualFields: IAddressManualFields | null = null;
  requiredValidateFields: Record<string, boolean> | null = null;

  constructor() {
    makeAutoObservable(this);

    this.registrantsService = new RegistrantsService();
    this.clientsService = new ClientsService();
    this.errorsService = new ErrorsService();

    this.registrantsTableForm.setAction(TableFormDynamicActions.getList, this.getRegistrants);
  }

  // Getters
  get aggregatedRequisitesValues(): IAggregatedFieldValues {
    return this.clientsService.getAggregatedRequisitesValues(this.registrantCard, this.addressesManualFields);
  }

  get mappedRegistrantRequisites() {
    return this.registrantCard ? Object.values(this.registrantCard) : [];
  }

  getRegistrants = () => {
    if (this.registrantsLoading) {
      return;
    }

    this.setRegistrantsLoading(true);

    this.registrantsService
      .getRegistrants()
      .then(data => {
        this.setRegistrants(data);
      })
      .catch(error => {})
      .finally(() => {
        this.setRegistrantsLoading(false);
      });
  };

  getRegistrant = (registrantid: number) => {
    if (this.registrantLoading) {
      return;
    }

    this.setRegistrantLoading(true);

    this.registrantsService
      .getRegistrant(registrantid)
      .then(data => {
        runInAction(() => {
          this.currentRegistrant = data;
        });
      })
      .catch(error => {})
      .finally(() => {
        this.setRegistrantLoading(false);
      });
  };

  getRegistrantCard = (params: { [k: string]: string | number }, cb?: () => void) => {
    if (this.registrantCardLoading) {
      return;
    }

    this.setRegistrantCardLoading(true);

    this.registrantsService
      .getRegistrantCard(params)
      .then(({ card, requiredData }) => {
        this.setRequiredValidateFields(requiredData);
        runInAction(() => {
          this.registrantCard = card;
        });

        cb?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setRegistrantCardLoading(false);
      });
  };

  validateRegistrant = (form: FieldValues, callback: (res: ResponseSuccess | (IErrorData | null)) => void) => {
    this.registrantsService
      .validateRegistrant(form)
      .then(res => {
        callback(res);

        this.registrantsService.setRequiredValidateField(form, this.requiredValidateFields, true);
      })
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        callback(errors);

        this.registrantsService.setRequiredValidateField(form, this.requiredValidateFields, false);
      })
      .finally(() => {});
  };

  updateRegistrant = (form: FieldValues, callback?: () => void) => {
    if (this.isUpdatingRegistrant) {
      return;
    }

    this.setIsUpdatingRegistrant(true);

    this.registrantsService
      .updateRegistrant(form)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsUpdatingRegistrant(false);
      });
  };

  createRegistrant = (form: FieldValues, callback?: () => void) => {
    if (this.isUpdatingRegistrant) {
      return;
    }

    this.setIsUpdatingRegistrant(true);

    this.registrantsService
      .createRegistrant(form)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsUpdatingRegistrant(false);
      });
  };

  deleteRegistrant = (callback?: () => void) => {
    if (this.isUpdatingRegistrant || !this.deletingRegistrant?.registrantid) {
      return;
    }

    this.setIsDeletingRegistrant(true);

    this.registrantsService
      .deleteRegistrant(this.deletingRegistrant.registrantid)
      .then(() => {
        this.registrantsService.localDeleteRegistrant(
          this.deletingRegistrant?.registrantid ?? 0,
          this.registrants,
          this.setRegistrants
        );
      })
      .catch(() => {})
      .finally(() => {
        callback?.();

        this.setIsDeletingRegistrant(false);
      });
  };

  checkRequiredFields = () => {
    if (!this.requiredValidateFields) {
      return true;
    }

    return !Object.values(this.requiredValidateFields).includes(false);
  };

  // Setters
  setAddressesManualFields = (data: IAddressManualFields) => {
    this.addressesManualFields = data;
  };

  setRegistrants = (data: Registrant[]) => {
    this.registrants = data;
  };

  setRequiredValidateFields = (data: Record<string, boolean> | null) => {
    this.requiredValidateFields = data;
  };

  // Loaders setters
  setRegistrantsLoading = (value: boolean) => {
    this.registrantsLoading = value;
  };

  setRegistrantLoading = (value: boolean) => {
    this.registrantLoading = value;
  };

  setRegistrantCardLoading = (value: boolean) => {
    this.registrantCardLoading = value;
  };

  setIsUpdatingRegistrant = (state: boolean) => {
    this.isUpdatingRegistrant = state;
  };

  setDeletingRegistrant = (data: Registrant | null) => {
    this.deletingRegistrant = data;
  };

  setIsDeletingRegistrant = (state: boolean) => {
    this.isDeletingRegistrant = state;
  };
}
