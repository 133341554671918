import { ModelFactory } from 'base/ModelFactory';

import { Currency } from './models/payment/Currency';
import { PaymentMethod } from './models/payment/PaymentMethod';
import { PaymentMethods } from './models/payment/PaymentMethods';
import { AttentionText } from './models/precalculation/AttentionText';
import { InfoSubbill } from './models/precalculation/InfoSubbill';
import { Precalculation } from './models/precalculation/Precalculation';
import { PrecalculationInfo } from './models/precalculation/PrecalculationInfo';
import { PrecalculationParams } from './models/precalculation/PrecalculationParams';
import { PrecalculationPool } from './models/precalculation/PrecalculationPool';
import { Totals } from './models/precalculation/Totals';

export class ServicePaymentFactory extends ModelFactory {
  createPrecalculation = (data: any) => {
    const precalculation = this.create<Precalculation>(Precalculation, data);

    if (data.info) {
      precalculation.info = data.info.map(this.createPrecalculationInfo);
    }

    if (data.attentionText) {
      precalculation.attentionText = this.create<AttentionText>(AttentionText, data.attentionText);
    }

    if (data.params) {
      precalculation.params = this.create<PrecalculationParams>(PrecalculationParams, data.params);
    }

    return precalculation;
  };

  createPrecalculationInfo = (data: any) => {
    const precalculationInfo = this.create<PrecalculationInfo>(PrecalculationInfo, data);

    if (data.totals) {
      precalculationInfo.totals = this.create<Totals>(Totals, data.totals);
    }

    if (data.subbills) {
      precalculationInfo.subbills = data.subbills.map(this.createInfoSubbill);
    }

    return precalculationInfo;
  };

  createInfoSubbill = (data: any) => {
    return this.create<InfoSubbill>(InfoSubbill, data);
  };

  createPrecalculationPool = (data: any) => {
    const precalculationPool = this.create<PrecalculationPool>(PrecalculationPool, data);

    if (data.totals) {
      precalculationPool.totals = this.create<Totals>(Totals, data.totals);
    }

    return precalculationPool;
  };

  createPrecalculationPools = (data: any[]) => {
    return data.map(this.createPrecalculationPool);
  };

  createPaymentMethods = (data: any) => {
    const paymentMethods = this.create<PaymentMethods>(PaymentMethods, data);

    if (data.personalAccounts) {
      paymentMethods.personalAccounts = data.personalAccounts.map(this.createPaymentMethod);
    }

    if (data.paymentMethods) {
      paymentMethods.paymentMethods = data.paymentMethods.map(this.createPaymentMethod);
    }

    return paymentMethods;
  };

  createPaymentMethod = (data: any) => {
    const paymentMethod = this.create<PaymentMethod>(PaymentMethod, data);

    if (data.availableCurrencies) {
      paymentMethod.availableCurrencies = this.createList<Currency>(Currency, data.availableCurrencies);
    }

    return paymentMethod;
  };
}
