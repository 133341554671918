import { makeAutoObservable, reaction } from 'mobx';

import { rootStore } from 'base/RootStore';

import { AddressesService } from './AddressesService';
import { AddressTypes } from './enums/AddressesEnums';
import { IAddressManualFields, IAddressReferences, IAddressReferencesLoadings } from './interfaces/AddressesInterfaces';
import { Address } from './models/Address';
import { AddressRefences } from './models/AddressReferences';

export class AddressesStore {
  loadingAddresses: boolean = false;
  loadingAddressReferences: IAddressReferencesLoadings = {
    [AddressTypes.uaddress]: false,
    [AddressTypes.faddress]: false,
    [AddressTypes.paddress]: false,
  };

  addresses: Address[] | null = null;

  addressReferences: IAddressReferences = {
    [AddressTypes.uaddress]: null,
    [AddressTypes.faddress]: null,
    [AddressTypes.paddress]: null,
  };

  private addressesService: AddressesService;

  constructor() {
    makeAutoObservable(this);
    this.addressesService = new AddressesService();
    reaction(() => this.addressReferences, this.onAddressReferencesChange);
  }

  // actions
  getAddresses = (address: string, callback: () => void) => {
    if (this.loadingAddresses) {
      return;
    }

    this.setLoadingAddresses(true);

    this.addressesService
      .getAddresses({ address })
      .then(addresses => {
        this.setAddresses(addresses);
        callback();
      })
      .catch(() => {})
      .finally(() => {
        this.setLoadingAddresses(false);
      });
  };

  getAddressReferences = (addressType: AddressTypes, countryCode: number | null) => {
    if (this.loadingAddressReferences[addressType]) {
      return;
    }

    this.setLoadingAddressReferences({ ...this.loadingAddressReferences, [addressType]: true });

    this.addressesService
      .getAddressReferences({ addressType, countryCode, deviceType: 2 })
      .then(references => {
        this.setAddressReferences({ ...this.addressReferences, [addressType]: references });
      })
      .catch()
      .finally(() => {
        this.setLoadingAddressReferences({ ...this.loadingAddressReferences, [addressType]: false });
      });
  };

  // Loadings
  setLoadingAddresses = (state: boolean) => {
    this.loadingAddresses = state;
  };

  setLoadingAddressReferences = (state: IAddressReferencesLoadings) => {
    this.loadingAddressReferences = state;
  };

  // Setters
  setAddresses = (addresses: Address[] | null) => {
    this.addresses = addresses;
  };

  setAddressReferences = (references: IAddressReferences) => {
    this.addressReferences = references;
  };

  // reactions
  private onAddressReferencesChange = (references: IAddressReferences) => {
    const allReferences = Object.entries(references).filter(([_, reference]) => reference !== null) as [
      string,
      AddressRefences
    ][];

    const allFields = allReferences.map(([addressType, reference]) => [addressType, reference.fields.flat()]);
    const fields = Object.fromEntries(allFields) as IAddressManualFields;

    rootStore.clientsStore.setAddressesManualFields(fields);
  };
}
