import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';

import ServiceApiRepository from './ServiceApiRepository';
import { ServiceFactory } from './ServiceFactory';

export default class ServiceService {
  serviceApi: ServiceApiRepository;
  serviceFactory: ServiceFactory;

  constructor() {
    this.serviceApi = new ServiceApiRepository();
    this.serviceFactory = new ServiceFactory();
  }

  getService = async (subagrid: number) => {
    const query = ApiHelper.getQueryString({ subagrid });

    const { data } = await this.serviceApi.getService(query);

    return this.serviceFactory.createService(data.data);
  };

  closeService = async (subagrid: number) => {
    const queryString = ApiHelper.getQueryString({ subagrid });

    const { data } = await this.serviceApi.closeService(queryString);

    return this.serviceFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };
}
