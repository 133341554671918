import { MFAMessengerType } from 'modules/mfa/models/MFAMessengerType';

import MFAMessengerTypesLocalRepository from './MFAMessengerTypesLocalRepository';

export default class MFAMessengerTypesService {
  MFAMessengerTypesLocal: MFAMessengerTypesLocalRepository;

  constructor(clientid: number) {
    this.MFAMessengerTypesLocal = new MFAMessengerTypesLocalRepository(clientid);
  }

  saveTypes = (types: MFAMessengerType[]) => {
    this.MFAMessengerTypesLocal.set(types);
  };

  deleteTypes = () => {
    this.MFAMessengerTypesLocal.remove();
  };

  getTypes = () => {
    const types = this.MFAMessengerTypesLocal.get();

    return types ? (types as unknown as MFAMessengerType[]) : null;
  };
}
