import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';

export const QMaskedInput = (
  props: any,
  mask: MaskedInputProps['mask'],
  otherProps?: Omit<MaskedInputProps, 'mask'>
) => {
  const { inputRef, ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      ref={() => inputRef}
      mask={mask}
      placeholderChar={otherProps?.placeholderChar || '_'}
      {...otherProps}
    />
  );
};

export const EmailMaskInput = (props: any) => {
  return QMaskedInput(props, emailMask);
};
