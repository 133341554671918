import { makeAutoObservable } from 'mobx';

import { rootStore } from 'base/RootStore';
import { TableFormDynamicActions, TableFormFields } from 'modules/tables/forms/TableForm/TableForm';

import ToolsService from './ToolsService';
import { PingForm } from './forms/PingForm';
import { ToolsHistoryForm, ToolsHistoryFormFields } from './forms/ToolsHistoryForm';
import { ToolsTableForm } from './forms/ToolsTableForm';
import { TracerouteForm, TracerouteFormFields } from './forms/TracerouteForm';
import { IPingResult } from './interfaces/ToolsInterfaces';
import { CheckHistory } from './models/CheckHistory';
import { Ping } from './models/Ping/Ping';
import { PingEndpoint } from './models/PingEndpoints';
import { Traceroute } from './models/Traceroute/Traceroute';
import { TracerouteEndpoint } from './models/TracerouteEndpoint';

export class ToolsStore {
  loadingPingEndPoints: boolean = false;
  loadingTracerouteEndPoints: boolean = false;
  loadingHistory: boolean = false;
  loadingFavorites: boolean = false;
  creatingHistory: boolean = false;
  addingToFavorites: boolean = false;

  checkInProgress: boolean = false;
  pingCheckStarted: boolean = false;
  tracerouteCheckStarted: boolean = false;

  pingEndPoints: PingEndpoint[] | null = null;
  tracerouteEndPoints: TracerouteEndpoint[] | null = null;

  pings: Ping[] | null = null;
  traceroutes: Traceroute[] | null = null;

  history: CheckHistory[] | null = null;
  favorites: CheckHistory[] | null = null;

  pingForm: PingForm = PingForm.createObservable();
  tracerouteForm: TracerouteForm = TracerouteForm.createObservable();
  toolsHistoryForm: ToolsHistoryForm = ToolsHistoryForm.createObservable();
  bufferToolsHistoryForm: ToolsHistoryForm = ToolsHistoryForm.createObservable();

  toolsHistoryPaginantionForm: ToolsTableForm = ToolsTableForm.createObservable();
  toolsFavoritesPaginantionForm: ToolsTableForm = ToolsTableForm.createObservable();

  private toolsService: ToolsService;

  constructor() {
    makeAutoObservable(this);
    this.toolsService = new ToolsService();

    this.toolsHistoryPaginantionForm.setAction(TableFormDynamicActions.getList, () => {
      this.getChecksHistory(this.toolsHistoryForm, { loader: true });
    });
    this.toolsFavoritesPaginantionForm.setAction(TableFormDynamicActions.getList, () => {
      this.getFavorites(this.toolsHistoryForm, { loader: true });
    });
  }

  get checkHistoryIds() {
    return this.history?.map(({ id }) => id);
  }

  get pingCheckResult(): IPingResult {
    let pingResult: IPingResult = {
      packets: {
        transmitted: 0,
        received: 0,
        loss: 0,
        time: 0,
      },
      speed: {
        min: 0,
        max: 0,
        mdev: 0,
        avg: 0,
      },
    };

    this.pings?.forEach((ping, i, arr) => {
      pingResult.packets.transmitted += isNaN(Number(ping.packets?.transmitted).valueOf())
        ? 0
        : Number(ping.packets?.transmitted).valueOf();
      pingResult.packets.received += isNaN(Number(ping.packets?.received).valueOf())
        ? 0
        : Number(ping.packets?.received).valueOf();
      pingResult.packets.loss += isNaN(Number(ping.packets?.loss).valueOf()) ? 0 : Number(ping.packets?.loss).valueOf();
      pingResult.packets.time += isNaN(Number(ping.packets?.time).valueOf()) ? 0 : Number(ping.packets?.time).valueOf();

      pingResult.speed.min =
        pingResult.speed.min +
        (isNaN(Number(ping.speed?.min).valueOf()) ? 0 : Number(ping.speed?.min).valueOf()) / arr.length;
      pingResult.speed.max =
        pingResult.speed.max +
        (isNaN(Number(ping.speed?.max).valueOf()) ? 0 : Number(ping.speed?.max).valueOf()) / arr.length;
      pingResult.speed.mdev =
        pingResult.speed.mdev +
        (isNaN(Number(ping.speed?.mdev).valueOf()) ? 0 : Number(ping.speed?.mdev).valueOf()) / arr.length;
      pingResult.speed.avg =
        pingResult.speed.avg +
        (isNaN(Number(ping.speed?.avg).valueOf()) ? 0 : Number(ping.speed?.avg).valueOf()) / arr.length;
    });

    return pingResult;
  }

  get pingCheckResultText() {
    const {
      languageToggleStore: { translations },
    } = rootStore;

    return `${translations.packets}: ${translations.transmitted} ${this.pingCheckResult.packets.transmitted}, ${
      translations.received
    } ${this.pingCheckResult.packets.received}, ${translations.loss} ${this.pingCheckResult.packets.loss}\n
    ${translations.speed}: ${translations.min} ${this.pingCheckResult.speed.min.toFixed(2)} ${translations.ms}, ${
      translations.max
    } ${this.pingCheckResult.speed.max.toFixed(2)} ${translations.ms}, ${
      translations.avg
    } ${this.pingCheckResult.speed.avg.toFixed(2)} ${translations.ms}`;
  }

  get tracerouteCheckResultText() {
    const {
      languageToggleStore: { translations },
    } = rootStore;

    return this.traceroutes
      ?.map((traceroute, i) => {
        const hop = traceroute.hops[0];
        const packages = hop.packages;

        const packagesUrls = packages.filter(item => item.url);

        const url = packagesUrls[0]?.url || `${traceroute.url} (${traceroute.ip})`;

        return `${i + 1} ${packages.map(item => `${item.time} ${translations.ms}`).join(' ')} ${url}`;
      })
      .join('\n');
  }

  ping = (form: PingForm) => {
    if (!this.checkInProgress) {
      return;
    }

    this.toolsService
      .ping(form)
      .then(ping => {
        this.setPings(ping);
        this.ping(form);
      })
      .catch(() => {})
      .finally(() => {});
  };

  traceroute = (form: TracerouteForm) => {
    if (!this.checkInProgress) {
      return;
    }

    this.toolsService
      .traceroute(form)
      .then(traceroute => {
        this.setTraceroutes(traceroute);

        const maxTtl = this.tracerouteForm.maxTtl;
        const firstTtl = this.tracerouteForm.firstTtl;

        if (maxTtl === 64) {
          this.setCheckInProgress(false);
          this.toolsHistoryForm.setValue(ToolsHistoryFormFields.result, this.tracerouteCheckResultText);
          this.tracerouteForm.setValue(TracerouteFormFields.firstTtl, 1);
          this.tracerouteForm.setValue(TracerouteFormFields.maxTtl, 1);

          this.createCheckHistory(this.toolsHistoryForm);
          return;
        }

        this.tracerouteForm.setValue(TracerouteFormFields.maxTtl, maxTtl + 1);
        this.tracerouteForm.setValue(TracerouteFormFields.firstTtl, firstTtl + 1);
        this.tracerouteForm.setValue(TracerouteFormFields.urlForTraceroute, form.urlForTraceroute);

        this.traceroute(this.tracerouteForm);
      })
      .catch(() => {})
      .finally(() => {});
  };

  getPingEndPoints = () => {
    if (this.loadingPingEndPoints || this.pingEndPoints) {
      return;
    }
    this.setLoadingPingEndPoints(true);

    this.toolsService
      .getPingEndPoints()
      .then(this.setPingEndPoints)
      .catch(() => {})
      .finally(() => {
        this.setLoadingPingEndPoints(false);
      });
  };

  getTracerouteEndPoints = () => {
    if (this.loadingTracerouteEndPoints || this.tracerouteEndPoints) {
      return;
    }
    this.setLoadingTracerouteEndPoints(true);

    this.toolsService
      .getTracerouteEndPoints()
      .then(this.setTracerouteEndPoints)
      .catch(() => {})
      .finally(() => {
        this.setLoadingTracerouteEndPoints(false);
      });
  };

  getFavorites = (form: ToolsHistoryForm, options: { loader: boolean }, callback?: () => void) => {
    if (this.loadingFavorites) {
      return;
    }

    if (options?.loader) {
      this.setLoadingFavorites(true);
    }

    this.toolsService
      .getChecksHistory(this.toolsFavoritesPaginantionForm, { ...form, favorite: 1 } as ToolsHistoryForm)
      .then(({ items, paginator }) => {
        this.setFavorites(items);
        this.toolsFavoritesPaginantionForm.setValues(paginator);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setLoadingFavorites(false);
      });
  };

  addToFavorites = (id: number, form: ToolsHistoryForm) => {
    if (this.addingToFavorites) {
      return;
    }

    this.setAddingToFavorites(true);

    this.toolsService
      .updateCheckHistory(id, form)
      .then(item => {
        this.toolsHistoryPaginantionForm.setValue(
          TableFormFields.totalItems,
          this.toolsHistoryPaginantionForm.totalItems - 1
        );
        this.toolsFavoritesPaginantionForm.setValue(
          TableFormFields.totalItems,
          this.toolsFavoritesPaginantionForm.totalItems + 1
        );

        this.setFavorites(item);
        this.setHistory(this.history?.filter(item => item.id !== id) || [], { set: true });

        if (
          this.history &&
          this.history.length % this.toolsHistoryPaginantionForm.limit === 0 &&
          this.toolsHistoryPaginantionForm.page !== 1
        ) {
          this.toolsHistoryPaginantionForm.setValue(TableFormFields.page, this.toolsHistoryPaginantionForm.page - 1);
        }
        this.getChecksHistory(this.toolsHistoryForm, { loader: false });
      })
      .catch(() => {})
      .finally(() => {
        this.setAddingToFavorites(false);
      });
  };

  deleteFromFavorites = (id: number, form: ToolsHistoryForm) => {
    if (this.addingToFavorites) {
      return;
    }

    this.setAddingToFavorites(true);

    this.toolsService
      .updateCheckHistory(id, form)
      .then(check => {
        if (!this.favorites) {
          return;
        }

        this.toolsHistoryPaginantionForm.setValue(
          TableFormFields.totalItems,
          this.toolsHistoryPaginantionForm.totalItems + 1
        );
        this.toolsFavoritesPaginantionForm.setValue(
          TableFormFields.totalItems,
          this.toolsFavoritesPaginantionForm.totalItems - 1
        );

        this.setFavorites(
          this.favorites.filter(item => item.id !== check.id),
          { set: true }
        );
        this.setHistory(check);

        if (
          this.favorites &&
          this.favorites.length % this.toolsFavoritesPaginantionForm.limit === 0 &&
          this.toolsFavoritesPaginantionForm.page !== 1
        ) {
          this.toolsFavoritesPaginantionForm.setValue(
            TableFormFields.page,
            this.toolsFavoritesPaginantionForm.page - 1
          );
        }
        this.getFavorites(this.toolsHistoryForm, { loader: false });
      })
      .catch(() => {})
      .finally(() => {
        this.setAddingToFavorites(false);
      });
  };

  getChecksHistory = (form: ToolsHistoryForm, options: { loader: boolean }, callback?: () => void) => {
    if (this.loadingHistory) {
      return;
    }

    if (options?.loader) {
      this.setLoadingHistory(true);
    }

    this.toolsService
      .getChecksHistory(this.toolsHistoryPaginantionForm, { ...form, favorite: 0 } as ToolsHistoryForm)
      .then(({ items, paginator }) => {
        this.setHistory(items);
        this.toolsHistoryPaginantionForm.setValues(paginator);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setLoadingHistory(false);
      });
  };

  createCheckHistory = (form: ToolsHistoryForm, callback?: () => void) => {
    if (this.creatingHistory) {
      return;
    }

    this.setCreatingHistory(true);

    this.toolsService
      .createCheckHistory(form)
      .then(item => {
        this.setHistory(item);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setCreatingHistory(false);
      });
  };

  deleteCheckHistory = (ids?: number[]) => {
    if (!ids) {
      return;
    }

    this.toolsHistoryPaginantionForm.setValue(TableFormFields.page, 1);

    this.toolsService
      .deleteCheckHistory(ids)
      .then(() => {
        this.getChecksHistory(this.toolsHistoryForm, { loader: false });
      })
      .catch(() => {})
      .finally(() => {});
  };

  clearCheckHistory = () => {
    this.setHistory([]);

    this.toolsService
      .clearCheckHistory(0)
      .then(() => {})
      .catch(() => {})
      .finally(() => {});
  };

  // loadings
  setLoadingPingEndPoints = (loading: boolean) => {
    this.loadingPingEndPoints = loading;
  };

  setLoadingTracerouteEndPoints = (loading: boolean) => {
    this.loadingTracerouteEndPoints = loading;
  };

  setLoadingHistory = (loading: boolean) => {
    this.loadingHistory = loading;
  };

  setLoadingFavorites = (loading: boolean) => {
    this.loadingFavorites = loading;
  };

  setCreatingHistory = (creating: boolean) => {
    this.creatingHistory = creating;
  };

  setAddingToFavorites = (adding: boolean) => {
    this.addingToFavorites = adding;
  };

  // setters
  setCheckInProgress = (checkInProgress: boolean) => {
    this.checkInProgress = checkInProgress;
  };

  setPingCheckStarted = (pingCheckStarted: boolean) => {
    this.pingCheckStarted = pingCheckStarted;
  };

  setTracerouteCheckStarted = (tracerouteCheckStarted: boolean) => {
    this.tracerouteCheckStarted = tracerouteCheckStarted;
  };

  setPingEndPoints = (pingEndPoints: PingEndpoint[]) => {
    this.pingEndPoints = pingEndPoints;
  };

  setTracerouteEndPoints = (tracerouteEndPoints: TracerouteEndpoint[]) => {
    this.tracerouteEndPoints = tracerouteEndPoints;
  };

  setPings = (ping: Ping | null) => {
    if (!ping) {
      this.pings = null;
      return;
    }

    if (!this.pings) {
      this.pings = [ping];
      return;
    }

    this.pings = [...this.pings, ping];
  };

  setTraceroutes = (traceroute: Traceroute | null) => {
    if (!traceroute) {
      this.traceroutes = null;
      return;
    }

    if (!this.traceroutes) {
      this.traceroutes = [traceroute];
      return;
    }

    this.traceroutes = [...this.traceroutes, traceroute];
  };

  setHistory = (history: CheckHistory | CheckHistory[], options?: { set: boolean }) => {
    if (Array.isArray(history)) {
      if (!this.history || history.length === 0 || options?.set) {
        this.history = history;
        return;
      }

      const newHistory: CheckHistory[] = [];
      history.forEach(item => {
        if (!this.history?.some(historyItem => historyItem.id === item.id)) {
          newHistory.push(item);
        }
      });

      this.history = [...this.history, ...newHistory];
      return;
    }

    if (!this.history) {
      this.history = [history];
      return;
    }

    if (!this.history.find(item => item.id === history.id)) {
      this.history = [history, ...this.history];
      return;
    }
  };

  setFavorites = (favorites: CheckHistory | CheckHistory[], options?: { set: boolean }) => {
    if (Array.isArray(favorites)) {
      if (!this.favorites || options?.set) {
        this.favorites = favorites;
        return;
      }

      const newFavorites: CheckHistory[] = [];
      favorites.forEach(item => {
        if (!this.favorites?.some(favoritesItem => favoritesItem.id === item.id)) {
          newFavorites.push(item);
        }
      });

      this.favorites = [...this.favorites, ...newFavorites];
      return;
    }

    if (!this.favorites) {
      this.favorites = [favorites];
      return;
    }

    if (!this.favorites.find(item => item.id === favorites.id)) {
      this.favorites = [...this.favorites, favorites];
    }
  };

  // other
  cancelRequests = () => {
    this.toolsService.cancelRequests();
  };
}
