import { ChevronLeft } from '@mui/icons-material';
import { Box, Typography, IconButton, Divider, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { useAllMQ } from 'base/hooks/useAllMQ';
import { useRootStore } from 'base/hooks/useRootStore';
import RouteHelper from 'base/routes/helpers/RouteHelper';
import QSelect from 'components/UI/Selects/QSelect';
import QSelectItem from 'components/UI/Selects/QSelectItem';
import { ThemeTypes } from 'modules/theme-toggle/types/ThemeToggleTypes';
import { useCommonStyles } from 'styles/commonStyles';

import LogoWhite from 'assets/images/vector/logo-white.svg';
import Logo from 'assets/images/vector/logo.svg';

interface IAuthCardProps {
  title?: string;
  handleFirstStepBackButton?: () => void;
  hideSelectLanguage?: boolean;
}

export const AuthCard: React.FC<PropsWithChildren<IAuthCardProps>> = observer(
  ({ title, children, handleFirstStepBackButton, hideSelectLanguage = false }) => {
    const {
      authStore,
      languageToggleStore,
      themeToggleStore: { theme },
    } = useRootStore();
    const { _lk } = languageToggleStore;

    const classes = useStyles();
    const commonClasses = useCommonStyles();

    const params = useParams();
    const { isSM } = useAllMQ();

    // Handlers
    const handleChangeLanguage = (e: SelectChangeEvent<unknown>) => {
      const chosenLanguage = languageToggleStore.languages?.find(({ langid }) => langid === e.target.value);

      if (chosenLanguage) {
        languageToggleStore.setLanguageType(chosenLanguage);
        languageToggleStore.setLanguageTypeChangedBeforeAuth(true);
      }
    };

    // Renders
    const renderStepBackButton = () => {
      if (authStore.authStep === 1 && !handleFirstStepBackButton) {
        return null;
      }

      const handleStepBackClick = () => {
        if (handleFirstStepBackButton) {
          handleFirstStepBackButton();

          return;
        }

        authStore.setAuthStep(authStore.authStep - 1);
      };

      return (
        <Box className={commonClasses.stepBack}>
          <IconButton onClick={handleStepBackClick}>
            <ChevronLeft color="primary" />
          </IconButton>
        </Box>
      );
    };

    const renderSessionExpiredMessage = () => {
      const sessionExpired = params[RouteHelper.SESSION_PARAM] === RouteHelper.EXPIRED;

      if (!sessionExpired || authStore.authStep !== 1) {
        return null;
      }

      return (
        <Box className={commonClasses.expired}>
          <Typography variant="subtitle2" textAlign="center">
            {_lk('Время сессии истекло. Пожалуйста, авторизуйтесь заново')}
          </Typography>
        </Box>
      );
    };

    return (
      <>
        {renderSessionExpiredMessage()}
        {renderStepBackButton()}
        <Box mt={9} mb={4} textAlign="center">
          <img src={theme === ThemeTypes.DARK ? LogoWhite : Logo} alt="Atlex logo" />
          <Box mt={11}>
            <Typography variant="h5">{title}</Typography>
          </Box>
        </Box>
        {children}
        {!hideSelectLanguage && (
          <Box mt={4}>
            <Divider />
            <QSelect
              value={Number(languageToggleStore.languageType?.langid).valueOf() || ''}
              onChange={handleChangeLanguage}
              className={classes.select}
            >
              {languageToggleStore.languages?.map(lang => (
                <QSelectItem key={lang.langid} value={lang.langid}>
                  {lang.langname}
                </QSelectItem>
              ))}
            </QSelect>
          </Box>
        )}
      </>
    );
  }
);

const useStyles = makeStyles(theme => ({
  select: {
    backgroundColor: 'transparent',
  },
}));
