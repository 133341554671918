import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import clsx from 'clsx';
import { ISidebarProps } from 'interfaces/UIComponentsInterfaces';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useAllMQ } from 'base/hooks/useAllMQ';
import { useRootStore } from 'base/hooks/useRootStore';
import { QIcon } from 'components/UI/QIcon/QIcon';
import { QIconIds } from 'components/UI/QIcon/QIconIds';
import { ThemeTypes } from 'modules/theme-toggle/types/ThemeToggleTypes';
import { useCommonStyles } from 'styles/commonStyles';

import SidebarMenuList from './components/SidebarMenuList';

import LogoWhite from 'assets/images/vector/logo-white.svg';
import Logo from 'assets/images/vector/logo.svg';
import MobileLogoWhite from 'assets/images/vector/mobile-logo-white.svg';
import MobileLogo from 'assets/images/vector/mobile-logo.svg';

const DesktopSidebar: FC<ISidebarProps> = observer(({ isOpen, onToggle }) => {
  const {
    themeToggleStore: { theme },
  } = useRootStore();

  const { isXL } = useAllMQ();
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const logo = useMemo(() => {
    const mobileLogo = theme === ThemeTypes.DARK ? MobileLogoWhite : MobileLogo;
    const logo = theme === ThemeTypes.DARK ? LogoWhite : Logo;

    return isOpen ? logo : mobileLogo;
  }, [isOpen, theme]);

  // Renders
  return (
    <Box
      maxWidth={312}
      minWidth={312}
      minHeight="100vh"
      component="aside"
      className={clsx(commonClasses.sidebar, { [classes.collapsedMargin]: !isOpen })}
    >
      <Box className={clsx(commonClasses.sidebarLogoBlock, { [classes.collapsedJustify]: !isOpen })}>
        <Link to={routes.MainScreen.path}>
          <img src={logo} alt="mobile-logo" />
        </Link>
      </Box>
      <SidebarMenuList isOpen={isOpen} />
      {!isXL ? (
        <IconButton onClick={onToggle} className={clsx(classes.desktopToggle, { [classes.toggled]: !isOpen })}>
          <QIcon id={QIconIds.doubleChevron} className={commonClasses.greenIcon} />
        </IconButton>
      ) : null}
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  collapsedJustify: {
    justifyContent: 'flex-end',
  },
  collapsedMargin: {
    marginLeft: theme.spacing(-58),
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
  desktopToggle: {
    position: 'absolute',
    right: theme.spacing(5),
    bottom: theme.spacing(5),
    transition: 'all 0.3s ease',
    transform: 'rotate(0deg)',
  },
  toggled: {
    transform: 'rotate(-180deg)',
  },
}));

export default DesktopSidebar;
