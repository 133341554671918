import { makeAutoObservable, reaction } from 'mobx';

import { DateHelper } from 'helpers/DateHelper';
import { ServiceStatuses } from 'modules/service/enums/ServiceStatuses';
import { IPMIAccessTypes } from 'modules/unit/enums/UnitEmuns';

import LanguageToggleService from './LanguageToggleService';
import { Language } from './models/Language';
import { Localization } from './models/Localization';
import LexemesLocalService from './modules/lexemes/LexemesLocalService';

interface FileSizeOptions {
  bytes?: number | null;
  decimals?: number;
}

export class LanguageToggleStore {
  languagesLoading: boolean = false;
  lexemesLoading: boolean = false;

  languages: Language[] | null = null;

  localization: Localization | null = null;

  languageType: Language | null = null;
  languageTypeChangedBeforeAuth: boolean = false;

  private languageService: LanguageToggleService;
  private lexemesLocalService: LexemesLocalService;

  constructor() {
    makeAutoObservable(this);
    this.languageService = new LanguageToggleService();
    this.lexemesLocalService = new LexemesLocalService();

    this.setLocalization(this.lexemesLocalService.getLexemes());

    reaction(() => this.languages, this.onLanguagesLoad);
    reaction(() => this.languageType, this.onLanguageTypeChange);
  }

  // computed
  get locale() {
    return this.languageType?.langtag?.split('-')[0] || 'en';
  }

  get localesForPhoneInput() {
    return this.languages?.map(({ langtag }) => langtag?.split('-')[1]);
  }

  get translatedLabelsForPhoneInput() {
    const language = this.languageType?.langtag?.split('-')[0];

    if (language) {
      return this.languageService.countries[language];
    }

    return this.languageService.countries.en;
  }

  // translations
  get translations() {
    return {
      siteName: this._lk('Мой ATLEX.Ru'),

      // common
      close: this._lk('Закрыть'),
      cancel: this._lk('Отмена'),
      cancelVerb: this._lk('Отменить'),
      clear: this._lk('Очистить'),
      stop: this._lk('Стоп'),
      save: this._lk('Сохранить'),
      send: this._lk('Отправить'),
      edit: this._lk('Редактировать'),
      load: this._lk('Загрузить'),
      more: this._lk('Ещё'),
      detailed: this._lk('Подробнее'),
      add: this._lk('Добавить'),
      delete: this._lk('Удалить'),
      ok: this._lk('Хорошо'),
      choose: this._lk('Выбрать'),
      change: this._lk('Изменить'),
      search: this._lk('Поиск'),
      lastname: this._lk('Фамилия'),
      firstname: this._lk('Имя'),
      midname: this._lk('Отчество'),
      jobPosition: this._lk('Должность'),
      phone: this._lk('Телефон'),
      email: this._lk('Email'),
      proceed: this._lk('Далее'),

      filters: this._lk('Фильтры'),
      users: this._lk('Пользователи'),
      status: this._lk('Статус'),
      date: this._lk('Дата'),

      // measures
      bytes: this._lk('байт'),
      Kb: this._lk('Кб'),
      Mb: this._lk('Мб'),
      Gb: this._lk('Гб'),
      Tb: this._lk('Тб'),
      ms: this._lk('мс'),

      // tables
      naming: this._lk('Название'),

      // form fields validation error messages
      requiredField: this._lk('Обязательное поле'),
      requiredCodeLength: this._lk('Код должен состоять из 6 цифр'),

      // login & mfa
      enter: this._lk('Войти'),
      mfaTitle: this._lk('Двойная аутентификация'),
      mfaChangeMethod: this._lk('Выбрать другой способ'),
      regAction: this._lk('Зарегистрироваться'),
      forgotPassword: this._lk('Забыли пароль?'),
      password: this._lk('Пароль'),
      passwordsDoNotMatch: this._lk('Пароли не совпадают'),

      // documents
      documentApproved: this._lk('Утвержден'),
      documentInProgress: this._lk('На утверждении'),
      documentAskedToDelete: this._lk('Запрошено удаление'),
      documentOverdue: this._lk('Просрочено'),
      documentRejected: this._lk('Отказано'),

      // requisites
      requisites: this._lk('Реквизиты'),

      // registrants
      registrants: this._lk('Регистранты'),
      domenName: this._lk('Название домена'),
      regTerm: this._lk('Срок регистрации'),
      domens: this._lk('Домены'),

      // tools
      packets: this._lk('Пакеты'),
      speed: this._lk('Скорость'),
      transmitted: this._lk('Отправлено'),
      received: this._lk('Получено'),
      loss: this._lk('Потеряно'),
      min: this._lk('Мин.'),
      max: this._lk('Макс.'),
      avg: this._lk('Средн.'),

      access: this._lk('Доступ'),
    };
  }

  get filterTranslations(): { [k: string]: string } {
    return {
      'devcid[]': this._lk('Категория'),
      'hwvenid[]': this._lk('Производитель'),
      'storetype[]': this._lk('Местоположение'),
      'aclassid[]': this._lk('Все услуги'),
    };
  }

  get serviceStatusesTranslations(): { [k: string]: string } {
    return {
      [ServiceStatuses.N]: this._lk('Новая'),
      [ServiceStatuses.A]: this._lk('Рабочая'),
      [ServiceStatuses.b]: this._lk('Частично заблокирована'),
      [ServiceStatuses.B]: this._lk('Заблокирована'),
      [ServiceStatuses.C]: this._lk('Закрыта'),
    };
  }

  get unitIPMIAccessTypesTranslations(): { [k: string]: string } {
    return {
      [IPMIAccessTypes.U]: this._lk('Неограничений'),
      [IPMIAccessTypes.Y]: this._lk('Постоянный'),
      [IPMIAccessTypes.N]: this._lk('Временный'),
    };
  }

  get accessTypeTexts(): { [k: string]: string } {
    return {
      [IPMIAccessTypes.U]: this._lk('Доступ со всех IP и подсетей без ограничения по времени'),
      [IPMIAccessTypes.Y]: this._lk('Доступ с указанного IP или подсети без ограничения по времени'),
      [IPMIAccessTypes.N]: this._lk('Доступ с указанного IP или подсети до определённой даты и времени'),
    };
  }

  // local actions
  setLanguageType = (language: Language | null, callback?: () => void) => {
    this.languageType = language;

    if (language) {
      this.languageService.saveLanguage(language);
      callback?.();
    }
  };

  // actions
  getLanguagesList = async () => {
    if (this.languagesLoading) {
      return;
    }
    this.setLanguagesLoading(true);

    try {
      const languages = await this.languageService.getLanguageList();
      this.setLanguages(languages);
    } catch (error) {
    } finally {
      this.setLanguagesLoading(false);
    }
  };

  // loadings
  setLanguagesLoading = (state: boolean) => {
    this.languagesLoading = state;
  };

  setLexemesLoading = (state: boolean) => {
    this.lexemesLoading = state;
  };

  // setters
  setLanguages = (languages: Language[] | null) => {
    this.languages = languages;
  };

  setLanguageTypeChangedBeforeAuth = (state: boolean) => {
    this.languageTypeChangedBeforeAuth = state;
  };

  setLocalization = (localization: Localization | null) => {
    this.localization = localization;

    if (localization) {
      this.lexemesLocalService.saveLexemes(localization);
    }
  };

  // reactions
  private onLanguagesLoad = (languages: Language[] | null) => {
    if (!languages) {
      return;
    }

    const savedLanguage = this.languageService.getLanguage(languages);
    this.setLanguageType(savedLanguage);
  };

  private onLanguageTypeChange = (language: Language | null) => {
    if (!language || !language.langlocale) {
      return;
    }

    if (this.lexemesLoading) {
      return;
    }

    if (!this.localization) {
      this.primaryLocalizationLoad(language);
      return;
    }

    this.secondaryLocalizationLoad(language);
  };

  private primaryLocalizationLoad = (language: Language) => {
    if (!language.langlocale) {
      return;
    }

    this.setLexemesLoading(true);

    this.languageService
      .getLocalization(language.langlocale, DateHelper.date1991)
      .then(this.setLocalization)
      .catch(() => {})
      .finally(() => {
        this.setLexemesLoading(false);
      });
  };

  private secondaryLocalizationLoad = (language: Language) => {
    if (!language.langlocale || !this.localization) {
      return;
    }

    if (this.localization.langCode !== language.langlocale) {
      this.primaryLocalizationLoad(language);
      return;
    }

    const isOutdated = DateHelper.getPresentDateDiff(this.localization.writeTime) + DateHelper.hours24 <= 0;

    if (!isOutdated) {
      return;
    }

    this.setLexemesLoading(true);

    this.languageService
      .getLocalization(language.langlocale, this.localization.versionFrom)
      .then(this.setLocalization)
      .catch(() => {})
      .finally(() => {
        this.setLexemesLoading(false);
      });
  };

  // translations
  _lk = (str: string) => {
    if (!this.localization) {
      return str;
    }

    const translation = this.localization.lexemes[str];

    if (translation) {
      return translation;
    }

    return str;
  };

  _stringFormat = (str: string, values: Record<string, string | null | undefined>) => {
    let newString = str;
    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        newString = newString.replace(new RegExp('\\{' + key + '\\}', 'gm'), value);
      }
    });
    return newString;
  };

  getFormatBytes = ({ bytes, decimals = 2 }: FileSizeOptions) => {
    if (!bytes) {
      return '0';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      this.translations.bytes,
      this.translations.Kb,
      this.translations.Mb,
      this.translations.Gb,
      this.translations.Tb,
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  getMFAMessengerSubtitle = (options: { isTuned: boolean; isEnabled: boolean; isDefault: boolean }) => {
    if (options.isDefault) {
      return this._lk('Настроен и выбран как способ по умолчанию');
    }

    if (options.isTuned) {
      if (options.isEnabled) {
        return this._lk('Способ настроен и активен');
      }

      return this._lk('Способ настроен, но не активен');
    }

    return this._lk('Способ не настроен');
  };
}
