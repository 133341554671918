import { jsonProperty, Serializable } from 'ts-serializable';

export class UnitSaveIpModel extends Serializable {
  @jsonProperty(Number, null) aiid: number | null = null;
  @jsonProperty(Number, null) alid: number | null = null;
  @jsonProperty(String, null) ip: string | null = null;
  @jsonProperty(String, null) mask: string | null = null;
  @jsonProperty(String, null) fullip: string | null = null;
  @jsonProperty(String, null) descr: string | null = null;
}
