import PathnameLocalRepository from './PathnameLocalRepository';

export default class PathnameService {
  pathnameLocal: PathnameLocalRepository;

  constructor() {
    this.pathnameLocal = new PathnameLocalRepository();
  }

  savePathname = (error: string) => {
    this.pathnameLocal.set(error);
  };

  deletePathname = () => {
    this.pathnameLocal.remove();
  };

  getPathname = () => {
    const pathname = this.pathnameLocal.get();

    return pathname ? pathname : null;
  };
}
