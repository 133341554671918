import { makeStyles } from '@mui/styles';
import { FC } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import QTextField from 'components/UI/TextFields/QTextField';

interface IDesktopSearchProps {}

const DesktopSearch: FC<IDesktopSearchProps> = () => {
  const {
    languageToggleStore: { _lk },
  } = useRootStore();
  const classes = useStyles();

  // Renders
  return (
    <QTextField
      type="search"
      startIconId="search"
      placeholder={_lk('Поиск')}
      className={classes.searchField}
      startIconClassName={classes.searchIconClassName}
    />
  );
};

const useStyles = makeStyles(theme => ({
  searchField: {
    alignItems: 'center',
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  searchIconClassName: {
    fill: theme.palette.customColors.surfaceTertiary,
  },
}));

export default DesktopSearch;
