import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { sidebarLinksList } from 'lists/sidebarLinksList';
import { FC, MouseEvent, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ProfileMenu from 'components/Header/ProfileMenu/ProfileMenu';
import { QIcon } from 'components/UI/QIcon/QIcon';
import { useCommonStyles } from 'styles/commonStyles';

import ChangeClient from './ChangeClient';

interface ISidebarMenuListProps {
  isMobile?: boolean;
  isOpen?: boolean;
  closeSidebarMenu?: () => void;
}

const SidebarMenuList: FC<ISidebarMenuListProps> = ({ isMobile, isOpen, closeSidebarMenu }) => {
  const { pathname } = useLocation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const displayMenuItemName = useMemo(() => {
    if (isMobile) {
      return 'display';
    }

    return isOpen ? 'display' : 'none';
  }, [isOpen]);

  const handleLinkClick = (e: MouseEvent) => {
    closeSidebarMenu?.();
  };

  // Renders
  return (
    <Box className={commonClasses.sidebarMenu}>
      {isOpen && <ChangeClient />}
      {isMobile && <ProfileMenu isMobile closeSidebarMenu={closeSidebarMenu} />}
      {sidebarLinksList.map(item => (
        <Link key={item.id} to={item.link} className={commonClasses.routerLink} onClick={handleLinkClick}>
          <Box
            className={clsx(
              commonClasses.sidebarMenuItem,
              !isMobile && {
                [classes.selected]: pathname === item.link,
                [classes.collapsedJustify]: !isOpen,
              }
            )}
          >
            <QIcon id={item.icon} className={commonClasses.grayIcon} />
            <Typography variant="subtitle2" sx={{ display: displayMenuItemName }}>
              {item.title}
            </Typography>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  collapsedJustify: {
    justifyContent: 'flex-end',
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}));

export default SidebarMenuList;
