import { Localization } from 'modules/language-toggle/models/Localization';

import LexemesLocalRepository from './LexemesLocalRepository';

export default class LexemesLocalService {
  lexemesLocal: LexemesLocalRepository;

  constructor() {
    this.lexemesLocal = new LexemesLocalRepository();
  }

  saveLexemes = (lexemes: Localization | null) => {
    this.lexemesLocal.set(lexemes);
  };

  deleteLexemes = () => {
    this.lexemesLocal.remove();
  };

  getLexemes = () => {
    const lexemes = this.lexemesLocal.get();

    return lexemes ? (lexemes as unknown as Localization) : null;
  };
}
