import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { devRoutes, routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { BaseLayout } from 'components/Layouts/BaseLayout';
import { Loader } from 'components/UI/Loader';

import RouteHelper from '../helpers/RouteHelper';
import { AppRoutes } from './AppRoutes';

const isDev = process.env.NODE_ENV === 'development';
const routesToServe = isDev ? { ...devRoutes, ...routes } : routes;

interface IAuthRoutesProps {}

const AuthRoutes: FC<IAuthRoutesProps> = observer(() => {
  const { authStore, languageToggleStore, profileStore, clientsStore } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();

  const isForbidden = location.pathname.includes(RouteHelper.FORBIDDEN);

  const [isLoadingRequiredData, setIsLoadingRequiredData] = useState(true);

  // Effects
  useEffect(() => {
    if (isForbidden) {
      return;
    }
    const lastCalledPathname = `${location.pathname}${location.search}`;
    authStore.setLastCalledPathname(lastCalledPathname);
  }, [isForbidden, location.pathname, location.search]);

  useEffect(() => {
    authStore.getServerStatus({
      loader: true,
      callback: async () => {
        if (!authStore.forbiddenError) {
          await languageToggleStore.getLanguagesList();
          await authStore.getAuthInfo();
          await profileStore.getUserInfo(
            languageToggleStore.languageType,
            languageToggleStore.setLanguageType,
            languageToggleStore.languageTypeChangedBeforeAuth
          );
          await clientsStore.getClientsList();
          await clientsStore.getClientSummary();
        }
      },
    });

    if (authStore.forbiddenError) {
      setTimeout(() => {
        navigate(routes.ForbiddenScreen.path);
      }, 0);
    }
  }, [authStore.forbiddenError]);

  useEffect(() => {
    if (clientsStore.currentClientSummary) {
      setIsLoadingRequiredData(false);
    }
  }, [clientsStore.currentClientSummary]);

  useEffect(() => {
    if (!authStore.forbiddenError && isForbidden) {
      navigate(authStore.lastCalledPathname || routes.MainScreen.path);
    }
  }, [isForbidden, authStore.forbiddenError]);

  if (authStore.isCheckingServerStatus) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100dvh', width: '100%' }}>
        <Loader size={64} />
      </Box>
    );
  }

  // Renders
  if (authStore.forbiddenError || isForbidden) {
    return <AppRoutes routes={routesToServe} />;
  }

  return (
    <BaseLayout isLoadingRequiredData={isLoadingRequiredData}>
      <AppRoutes routes={routesToServe} />
    </BaseLayout>
  );
});

export default AuthRoutes;
