import { jsonProperty, Serializable } from 'ts-serializable';

export class MFAAccessibleMessenger extends Serializable {
  @jsonProperty(Number) messengertypeid: number = 0;
  @jsonProperty(Number) delay: number = 0;
  @jsonProperty(Number) cmcid: number = 0;
  @jsonProperty(String) label: string = '';
  @jsonProperty(String) associatedContact: string | string[] = '';
  @jsonProperty(String) settingsLink: string = '';
  @jsonProperty(Boolean) isDefault: boolean = true;
  @jsonProperty(Boolean) isTuned: boolean = true;
  @jsonProperty(Boolean) isEnabled: boolean = true;
  @jsonProperty(Boolean) allowedformfa: boolean = true;
}
