import { FieldValues } from 'react-hook-form/dist/types';

import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';
import { UpdateDataDto, ValidateDataDto } from 'base/dto/CommonDto';

import { CheckUserPhoneDto } from './dto/CheckUserPhoneDto';
import { UserDto } from './dto/UserDto';
import { Phone } from './models/Phone';
import { TunedMFA } from './models/TunedMFA';
import { User } from './models/User';

export default class UsersFactory extends ModelFactory {
  createUsersList = (data: any[]) => {
    return data.map(this.createUser);
  };

  createUser = (data: any) => {
    const user = this.create<User>(User, data);

    if (data.phones) {
      user.phones = this.createPhonesList(data.phones);
    }

    return user;
  };

  createPhonesList = (data: any[]) => {
    return data.map(this.createPhone);
  };

  createPhone = (data: any) => {
    const phone = this.create<Phone>(Phone, data);

    if (data.tunedFor) {
      phone.tunnedFor = this.createTunedMFAsList(data.tunedFor);
    }

    return phone;
  };

  createTunedMFAsList = (data: any[]) => {
    return data.map(this.createTunedMFA);
  };

  createTunedMFA = (data: any) => {
    return this.create<TunedMFA>(TunedMFA, data);
  };

  getUserDto = (data: any) => {
    return Dto.populate<UserDto>(UserDto, data);
  };

  getCheckUserPhoneDto = (data: any) => {
    return Dto.populate<CheckUserPhoneDto>(CheckUserPhoneDto, data);
  };

  getUserUpdateDto = (data: any) => {
    return Dto.populate<UpdateDataDto>(UpdateDataDto, { data });
  };

  createValidateRequisitesDto = (form: FieldValues) => {
    return Dto.populate<ValidateDataDto>(ValidateDataDto, {
      data: { ...form },
    });
  };
}
