import DocumentsService from '../DocumentsService';
import { DocumentFile } from '../models/file/DocumentFile';

export class DocumentsHelper {
  static isDocumentFile(object: unknown): object is DocumentFile {
    return typeof object === 'object' && object !== null && object.hasOwnProperty('csdid');
  }

  static isAvailableToShowImage(fileName?: string) {
    const ext = fileName?.split('.').slice(-1)[0].toLowerCase();
    return DocumentsService.AVAILABLE_IMAGES_EXTENSIONS.includes(`.${ext}`);
  }

  static getDocumentDtvalidityAsString(dtvalidity?: string | null) {
    return dtvalidity ? dtvalidity : '';
  }
}
