import FingerprintJS from '@fingerprintjs/fingerprintjs';
import UAParser from 'ua-parser-js';

import Dto from 'base/Dto';
import { ResponseSuccess } from 'base/models/CommonModels';

import AuthApiRepository from './AuthApiRepository';
import AuthFactory from './AuthFactory';
import { ChangeClientDto } from './dto/ChangeClientDto';
import LoginDto from './dto/LoginDto';
import { ChangeClientForm } from './forms/ChangeClientForm';
import { LoginForm } from './forms/LoginForm';
import { AuthInfo } from './models/AuthInfo';
import { PlatformData } from './models/PlatformData';
import { SuccessAuth } from './models/SuccessAuth';

export default class AuthService {
  authApi: AuthApiRepository;
  authFactory: AuthFactory;

  constructor() {
    this.authApi = new AuthApiRepository();
    this.authFactory = new AuthFactory();
  }

  login = async (form: LoginForm): Promise<SuccessAuth> => {
    const loginDto = Dto.populate(LoginDto, { ...form });
    const { data } = await this.authApi.login(loginDto);

    return this.authFactory.createSuccessLoginAuth(data.data);
  };

  changeClient = async (form: ChangeClientForm) => {
    const changeClientDto = Dto.populate(ChangeClientDto, { ...form });
    const { data } = await this.authApi.changeClient(changeClientDto);

    return this.authFactory.createSuccessLoginAuth(data.data);
  };

  getAuthInfo = async () => {
    const { data } = await this.authApi.getAuthInfo();

    return this.authFactory.create<AuthInfo>(AuthInfo, data.data);
  };

  logout = async () => {
    const res = await this.authApi.logout();

    return res;
  };

  getServerStatus = async () => {
    const { data } = await this.authApi.getServerStatus();

    return this.authFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  getOSDeviceFingerprint = async (): Promise<PlatformData> => {
    const UAParserInstance = new UAParser();
    const os = UAParserInstance.getOS();
    const device = UAParserInstance.getDevice();
    const cpu = UAParserInstance.getCPU();

    const fingerprint = await FingerprintJS.load();
    const { visitorId } = await fingerprint.get();

    return this.authFactory.create<PlatformData>(PlatformData, {
      osVersion: `${os.name} ${os.version}`,
      deviceModel: device.model || cpu.architecture || 'unknown',
      udid: visitorId,
    });
  };
}
