import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class ServicesApiRepository extends AbstractApiRepository {
  getRootServices = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/index${query}&parentsaid=0` });
  };

  getChildrenServices = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/index${query}` });
  };

  getAgreementClasses = () => {
    return this.apiClient.get({ url: '/agreement-classes/index?ordered=1' });
  };
}
