import { jsonProperty, Serializable } from 'ts-serializable';

import { CliTypes } from '../types/RegistrantsTypes';
import { DomainModel } from './DomainModel';

export class Registrant extends Serializable {
  @jsonProperty(Number, null) registrantid: number | null = null;
  @jsonProperty(String, null) clitype: CliTypes | null = null;
  @jsonProperty(String, null) respnsbluser: string | null = null;
  @jsonProperty(String, null) f01_namelast_eng: string | null = null;
  @jsonProperty(String, null) f02_namelast_rus: string | null = null;
  @jsonProperty(String, null) f03_namemid_eng: string | null = null;
  @jsonProperty(String, null) f04_namemid_rus: string | null = null;
  @jsonProperty(String, null) f05_namefirst_eng: string | null = null;
  @jsonProperty(String, null) f06_namefirst_rus: string | null = null;
  @jsonProperty(String, null) f07_pasp_data: string | null = null;
  @jsonProperty(String, null) f08_pasp_dt: string | null = null;
  @jsonProperty(String, null) f09_pasp_podr: string | null = null;
  @jsonProperty(String, null) f10_birth_dt: string | null = null;
  @jsonProperty(String, null) f11_orgname_eng: string | null = null;
  @jsonProperty(String, null) f12_orgname_rus: string | null = null;
  @jsonProperty(String, null) f13_inn: string | null = null;
  @jsonProperty(String, null) f14_addryur_rus: string | null = null;
  @jsonProperty(String, null) f15_phone_vo: string | null = null;
  @jsonProperty(String, null) f16_phone_fax: string | null = null;
  @jsonProperty(String, null) f17_email: string | null = null;
  @jsonProperty(String, null) f18_iso3166: string | null = null;
  @jsonProperty(String, null) f19_region_eng: string | null = null;
  @jsonProperty(String, null) f20_region_rus: string | null = null;
  @jsonProperty(String, null) f21_city_eng: string | null = null;
  @jsonProperty(String, null) f22_city_rus: string | null = null;
  @jsonProperty(String, null) f23_address_eng: string | null = null;
  @jsonProperty(String, null) f24_address_rus: string | null = null;
  @jsonProperty(String, null) f25_post_code: string | null = null;
  @jsonProperty(String, null) f27_usa_state: string | null = null;
  @jsonProperty(String, null) gpt_id: string | null = null;
  @jsonProperty(String, null) name: string | null = null;
  @jsonProperty(Array, null) domains: DomainModel[] | null = null;
}
