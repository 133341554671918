import { FC } from 'react';

import AuthScreen from 'screens/auth/components/AuthScreen';
import Forbidden from 'screens/auth/components/Forbidden';

interface IForbiddenScreenProps {}

const ForbiddenScreen: FC<IForbiddenScreenProps> = () => {
  // Effects

  // Handlers

  // Renders
  return (
    <AuthScreen>
      <Forbidden />
    </AuthScreen>
  );
};

export default ForbiddenScreen;
