import ApiHelper from 'base/helpers/ApiHelper';
import FileValidationFactory from 'base/modules/file-validation/FileValidationFactory';

import DocumentsApiRepository from './DocumentsApiRepository';
import DocumentsFactory from './DocumentsFactory';
import { DocumentForm } from './forms/DocumentForm';
import { IDocumentsListQueryParams, IDocumentActionQueryParams } from './interfaces/DocumentsInterfaces';
import { DeleteFileSuccess } from './models/DeleteFileSuccess';
import { Document } from './models/Document';
import { UploadDocumentSuccess } from './models/UploadDataSuccess';

export default class DocumentsService {
  static readonly ALL_DOCUMENTS_EXTENSION = '.zip';
  static readonly AVAILABLE_IMAGES_EXTENSIONS = ['.png', '.jpg', 'jpeg', '.svg', '.gif', '.bmp'];
  static readonly AVAILABLE_VIDEO_EXTENSIONS = [];

  private documentsApi: DocumentsApiRepository;
  private documentsFactory: DocumentsFactory;
  private fileValidationsFactory: FileValidationFactory;

  constructor() {
    this.documentsApi = new DocumentsApiRepository();
    this.documentsFactory = new DocumentsFactory();
    this.fileValidationsFactory = new FileValidationFactory();
  }

  getDocumentsList = async (params?: IDocumentsListQueryParams) => {
    const queryParams = ApiHelper.getQueryString(params);

    const { data } = await this.documentsApi.getDocumentsList(queryParams);

    return this.documentsFactory.createList<Document>(Document, data.data);
  };

  getDocumentValidationRules = async () => {
    const { data } = await this.documentsApi.getDocumentValidationRules();

    return this.fileValidationsFactory.createFileValidationRules(data.data);
  };

  getDocumentToShow = async (params: IDocumentActionQueryParams) => {
    const queryParams = ApiHelper.getQueryString(params);

    const { data } = await this.documentsApi.getDocumentToShow(queryParams);

    return this.documentsFactory.createDocumentToShow(data.data);
  };

  uploadDocument = async (form: DocumentForm) => {
    const dto = this.documentsFactory.prepareUploadDocumentFormData(form);

    const { data } = await this.documentsApi.uploadDocument(dto);

    return this.documentsFactory.create<UploadDocumentSuccess>(UploadDocumentSuccess, data.data);
  };

  downloadAllDocuments = async (params: IDocumentActionQueryParams) => {
    const queryParams = ApiHelper.getQueryString(params);

    const res = await this.documentsApi.downloadAllDocuments(queryParams);

    return this.documentsFactory.createDownloadableFile(res);
  };

  downloadDocument = async (params: IDocumentActionQueryParams) => {
    const queryParams = ApiHelper.getQueryString(params);

    const { data } = await this.documentsApi.downloadDocument(queryParams);

    return data;
  };

  deleteAllDocuments = async (params: IDocumentActionQueryParams) => {
    const queryParams = ApiHelper.getQueryString(params);

    const { data } = await this.documentsApi.deleteAllDocuments(queryParams);

    return data;
  };

  deleteDocument = async (params: IDocumentActionQueryParams) => {
    const queryParams = ApiHelper.getQueryString(params);

    const { data } = await this.documentsApi.deleteDocument(queryParams);

    return this.documentsFactory.create<DeleteFileSuccess>(DeleteFileSuccess, data.data);
  };
}
