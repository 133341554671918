import ApiHelper from 'base/helpers/ApiHelper';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';

import HardwareStockApiRepository from './HardwareStockApiRepository';
import { HardwareStockFactory } from './HardwareStockFactory';
import { HardwareStockTableForm } from './forms/HardwareStockTableForm';
import { DeviceCategory } from './models/DeviceCategory';
import { DeviceLocation } from './models/DeviceLocation';
import { DeviceVendor } from './models/DeviceVendor';

export default class HardwareStockService {
  static readonly LIMIT = 5;

  private hardwareStockApi: HardwareStockApiRepository;
  private paginationService: PaginationService;
  private hardwareStockFactory: HardwareStockFactory;

  constructor() {
    this.paginationService = new PaginationService();
    this.hardwareStockApi = new HardwareStockApiRepository();
    this.hardwareStockFactory = new HardwareStockFactory();
  }

  getHardwareList = async ({ page, limit, query, sortValue, filters }: HardwareStockTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);

    const queryString = ApiHelper.getQueryString({ page, limit, query, ...sort, ...filters });

    const { data } = await this.hardwareStockApi.getHardwareList(queryString);

    const items = this.hardwareStockFactory.createHardwareList(data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };

  getCategories = async () => {
    const { data } = await this.hardwareStockApi.getCategories();

    return this.hardwareStockFactory.createList<DeviceCategory>(DeviceCategory, data.data);
  };

  getVendors = async () => {
    const { data } = await this.hardwareStockApi.getVendors();

    return this.hardwareStockFactory.createList<DeviceVendor>(DeviceVendor, data.data);
  };

  getLocations = async () => {
    const { data } = await this.hardwareStockApi.getLocations();

    return this.hardwareStockFactory.createList<DeviceLocation>(DeviceLocation, data.data);
  };
}
