import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IQTextFieldWithControllerProps } from 'interfaces/UIComponentsInterfaces';
import React, { useState } from 'react';

import { AuthHelper } from 'modules/auth/helpers/AuthHepler';
import { useCommonStyles } from 'styles/commonStyles';

import { QIcon } from '../QIcon/QIcon';
import { QIconIds } from '../QIcon/QIconIds';
import QTextFieldWithController from './QTextFieldWithController';

interface IPasswordTextFieldProps extends IQTextFieldWithControllerProps {}

const PasswordTextField: React.FC<IPasswordTextFieldProps> = props => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  // Handlers
  const handleToggleShowPassword = () => {
    setPasswordVisibility(prevValues => !prevValues);
  };

  const handleClickRandom = () => {
    const randomValue = AuthHelper.getRandomPassword();

    props.hookFormProps.setValue?.(props.name, randomValue);
    props.hookFormProps.trigger?.(props.name);
  };

  // Renders
  const renderTextField = () => {
    return (
      <QTextFieldWithController
        {...props}
        fullWidth
        type={passwordVisibility ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <IconButton className={classes.iconBtn} onClick={handleToggleShowPassword}>
              {<QIcon id={passwordVisibility ? QIconIds.eyeSlash : QIconIds.eye} className={props.endIconClassName} />}
            </IconButton>
          ),
        }}
      />
    );
  };

  return (
    <>
      {props.hookFormProps.setValue ? (
        <Box display="flex" gap={2}>
          <Box width="100%">{renderTextField()}</Box>
          <div className={classes.randomBtnWrapper}>
            <IconButton onClick={handleClickRandom}>
              <QIcon id="dice" width={24} height={24} className={commonClasses.greenIcon} />
            </IconButton>
          </div>
        </Box>
      ) : (
        renderTextField()
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  iconBtn: {
    position: 'absolute',
    right: 0,
  },
  randomBtnWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.spacing(2),
    alignSelf: 'flex-start',
  },
}));

export default PasswordTextField;
