import { Dayjs } from 'dayjs';
import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

import { Car } from '../models/Car';
import { Visitor } from '../models/Visitor';

export enum PassFormFields {
  dcpid = 'dcpid',
  dcid = 'dcid',
  dtvisitdate = 'dtvisitdate',
  dtvisittime = 'dtvisittime',
  car = 'car',
  status = 'status',
  visitor = 'visitor',
}

export class PassForm extends BaseForm {
  dcid: number | null = null;
  dcpid: number | null = null;
  dtvisitdate: Dayjs | null = null;
  dtvisittime: Dayjs | null = null;
  status: number | null = null;
  car: Car | null = null;
  visitor: Visitor[] = [];

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset = () => {
    this.setValues({
      dcid: null,
      dcpid: null,
      dtvisitdate: null,
      dtvisittime: null,
      status: null,
      car: null,
      visitor: [],
    });
  };

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(PassFormFields),
    });
  };
}
