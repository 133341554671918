import { jsonProperty, Serializable } from 'ts-serializable';

import { Country } from './Country';
import { PayType } from './PayType';

export class ClientSummary extends Serializable {
  @jsonProperty(Number) id: number = 0;
  @jsonProperty(Number) lastOrderSoid: number = 0;
  @jsonProperty(String) name: string = '';
  @jsonProperty(String) verificationStatus: string = '';
  @jsonProperty(String) clitype: string = '';
  @jsonProperty(Object) country: Country | null = null;
  @jsonProperty(Object) defultPaytype: PayType | null = null;
  @jsonProperty(String) clitypeName: string = '';
  @jsonProperty(String) emailSupport: string = '';
  @jsonProperty(Array) emails: string[] = [];
  @jsonProperty(String) phoneSupport: string = '';
  @jsonProperty(Array) phones: string[] = [];
  @jsonProperty(String) whatsappSupport: string = '';
  @jsonProperty(String) viberSupport: string = '';
  @jsonProperty(String) telegramSupport: string = '';
  @jsonProperty(Boolean) needVerify: boolean = true;
  @jsonProperty(Boolean) isResident: boolean = true;
  @jsonProperty(Boolean) havepartnershipprogram: boolean = false;
}
