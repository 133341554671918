import { modelFactory } from 'base/ModelFactory';
import ApiHelper from 'base/helpers/ApiHelper';

import { MFAApiRepository } from './MFAApiRepository';
import { MFATurnOnDto } from './dto/MFATurnOnDto';
import { MFAAccessibleMessenger } from './models/MFAAccessibleMessenger';
import { MFACodeSendResponse } from './models/MFACodeSendResponse';

export class MFAService {
  private MFAApi: MFAApiRepository;

  constructor() {
    this.MFAApi = new MFAApiRepository();
  }

  getListOfAccessibleMessengers = async (): Promise<MFAAccessibleMessenger[]> => {
    const res = await this.MFAApi.getListOfAccessibleMessengers();
    const messengers = modelFactory.createList<MFAAccessibleMessenger>(MFAAccessibleMessenger, res.data.data);
    return messengers;
  };

  getMFAStatus = async (): Promise<boolean> => {
    const res = await this.MFAApi.getMFAStatus();
    return res.data.data.status;
  };

  turnMFAOn = async (data: any) => {
    const dto = modelFactory.create<MFATurnOnDto>(MFATurnOnDto, data);

    const res = await this.MFAApi.turnMFAOn(dto);

    return modelFactory.create<MFACodeSendResponse>(MFACodeSendResponse, res.data.data);
  };

  turnMFAOff = async (messengertypeid: number) => {
    const query = ApiHelper.getQueryString({ messengertypeid });
    const res = await this.MFAApi.turnMFAOff(query);

    return res.data.data;
  };

  turnLastMFAOff = async () => {
    const res = await this.MFAApi.turnLastMFAOff();

    return res.data.data;
  };

  setDefaultMessenger = async (messengertypeid: number) => {
    const query = ApiHelper.getQueryString({ messengertypeid });
    const res = await this.MFAApi.setDefaultMessenger(query);

    return res.data.data;
  };

  sendTestMessage = async (messengertypeid: number) => {
    const query = ApiHelper.getQueryString({ messengertypeid });
    const res = await this.MFAApi.sendTestMessage(query);

    return modelFactory.create<MFACodeSendResponse>(MFACodeSendResponse, res.data.data);
  };
}
