import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { modelFactory } from 'base/ModelFactory';
import { rootStore } from 'base/RootStore';
import ErrorsService from 'base/modules/errors/ErrorsService';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { ClientForAuth } from 'modules/clients/models/ClientForAuth';
import { MFA } from 'modules/mfa/models/MFA';
import UsersService from 'modules/users/UsersService';
import { ResetPasswordForm } from 'modules/users/forms/ResetPasswordForm';

import AuthService from './AuthService';
import { ChangeClientForm } from './forms/ChangeClientForm';
import { LoginForm } from './forms/LoginForm';
import { AuthInfo } from './models/AuthInfo';
import { AuthTokenData } from './models/AuthTokenData';
import { PlatformData } from './models/PlatformData';
import { SuccessAuth } from './models/SuccessAuth';
import AuthPathnameService from './modules/auth-pathname/AuthPathnameService';
import AuthClientService from './modules/client/AuthClientService';
import MFAMessengerTypesService from './modules/mfa/MFAMessengerTypesService';
import PathnameService from './modules/pathname/PathnameService';
import TokenService from './modules/token/TokenService';

export class AuthStore {
  isLoading = false;
  isCheckingServerStatus = false;
  isChangingClient = false;
  isLoadingAuthInfo = false;

  errorMessages: IErrorData | null = null;
  forbiddenError: IErrorData | null = null;

  platformData: PlatformData | null = null;
  date: number | null = null;
  mfa: MFA | null = null;
  mfaData: MFA | null = null;
  lastCalledPathname: string | null = null;
  lastCalledAuthPathname: string | null = null;

  loginForm: LoginForm = LoginForm.create();
  persistedLoginForm: LoginForm = LoginForm.create();
  authClients: ClientForAuth[] | null = null;
  bufferedAuthClient: ClientForAuth | null = null;

  resetPasswordForm: ResetPasswordForm = ResetPasswordForm.create();

  changeClientForm: ChangeClientForm = ChangeClientForm.create();

  auth: SuccessAuth | null = null;
  changeClientAuth: SuccessAuth | null = null;

  authInfo: AuthInfo | null = null;

  redirectToMain?: (() => void) | null = null;
  openCodeConfirmationModal?: (() => void) | null = null;
  showSuccessModal?: (() => void) | null = null;

  authStep: number = 1;
  registerStep: number = 1;
  forgotPasswordStep: number = 1;

  serverIsOk = false;
  isAuth = false;
  completeCheckAuth = false;
  isMountedBackground = false;

  private authService: AuthService;
  private userService: UsersService;
  private tokenService: TokenService;
  private errorsService: ErrorsService;

  private pathnameService: PathnameService;
  private authClientService: AuthClientService;
  private authPathnameService: AuthPathnameService;

  private mfaMessengerTypesService: MFAMessengerTypesService | undefined;

  constructor() {
    makeAutoObservable(this);
    this.authService = new AuthService();
    this.userService = new UsersService();
    this.tokenService = new TokenService();
    this.errorsService = new ErrorsService();

    this.pathnameService = new PathnameService();
    this.authClientService = new AuthClientService();
    this.authPathnameService = new AuthPathnameService();

    this.init();

    reaction(() => this.auth, this.onAuthChange);
    reaction(() => this.authInfo, this.onAuthInfoChange);
    reaction(() => this.changeClientAuth, this.onChangeClientAuthChange);
  }

  // computed
  get mfaTypes() {
    const persistedTypes = this.mfaMessengerTypesService?.getTypes();

    if (persistedTypes) {
      return persistedTypes;
    }

    return this.auth?.mfa?.messengersTypes || this.changeClientAuth?.mfa?.messengersTypes;
  }

  get deadline() {
    return Number(this.auth?.mfa?.delay).valueOf() * 1000 + Number(this.date).valueOf();
  }

  get changeClientDeadline() {
    return Number(this.changeClientAuth?.mfa?.delay).valueOf() * 1000 + Number(this.date).valueOf();
  }

  get resetPasswordDeadline() {
    return 62 * 1000 + Number(this.date).valueOf();
  }

  get authClient() {
    return this.authClientService.getClient();
  }

  // actions
  login = (form: LoginForm, callback?: () => void): void => {
    this.setRedirectToMain(callback);
    this.setIsLoading(true);

    if (this.authStep === 2 && form.clientid !== null) {
      this.mfaMessengerTypesService = new MFAMessengerTypesService(form.clientid);
    }

    this.authService
      .login(form)
      .then(this.setAuth)
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        this.setErrorMessages(errors);
      })
      .finally(() => this.setIsLoading(false));
  };

  changeClient = (
    form: ChangeClientForm,
    client: ClientForAuth,
    openCodeConfirmationModal?: () => void,
    showSuccessModal?: () => void
  ) => {
    if (this.isChangingClient) {
      return;
    }

    if (!form.clientid) {
      return;
    }

    this.mfaMessengerTypesService = new MFAMessengerTypesService(form.clientid);

    if (openCodeConfirmationModal) {
      this.setOpenCodeConfirmationModal(openCodeConfirmationModal);
    }

    if (showSuccessModal) {
      this.setShowSuccessModal(showSuccessModal);
    }

    this.setIsChangingClient(true);

    this.authService
      .changeClient(form)
      .then(auth => {
        runInAction(() => {
          this.bufferedAuthClient = client;
        });
        this.setChangeClientAuth(auth);
      })
      .catch(() => {})
      .finally(() => {
        this.setIsChangingClient(false);
      });
  };

  logOut = (): void => {
    this.setIsLoading(true);

    this.authService
      .logout()
      .then(this.deleteTokenAndReload)
      .catch((error: any) => {})
      .finally(() => this.setIsLoading(false));
  };

  checkAuth = (goToLogin?: () => void): void => {
    const tokens = rootStore.oAuth2Store.getToken();

    if (tokens) {
      this.completeAuth(tokens);
      return;
    }

    this.setIsAuth(false);
    this.setCompleteCheckAuth(true);
    goToLogin?.();
  };

  setAuthClient = (client: ClientForAuth) => {
    this.authClientService.saveClient(client);
  };

  getServerStatus = (options: { loader?: boolean; callback?: () => void }) => {
    if (this.isCheckingServerStatus) {
      return;
    }

    if (this.serverIsOk) {
      options.callback?.();
      return;
    }

    if (options?.loader) {
      this.setIsCheckingServerStatus(true);
    }

    this.authService
      .getServerStatus()
      .then(() => {
        this.setServerIsOk(true);
        options.callback?.();
      })
      .catch(err => {})
      .finally(() => {
        this.setIsCheckingServerStatus(false);
      });
  };

  resetPassword = (form: ResetPasswordForm, callback?: () => void) => {
    this.setIsLoading(true);

    this.userService
      .resetPassword(form)
      .then(() => {
        callback?.();

        if (this.forgotPasswordStep === 2) {
          runInAction(() => {
            this.date = Date.now();
          });
        }
      })
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        this.setErrorMessages(errors);
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  };

  // privates
  private init = () => {
    this.authService
      .getOSDeviceFingerprint()
      .then(platformData => {
        runInAction(() => {
          this.platformData = platformData;
          this.loginForm = LoginForm.create({ ...platformData });
          this.persistedLoginForm = LoginForm.create({ ...platformData });
        });
      })
      .catch(() => {})
      .finally();

    const savedLastCalledPathname = this.pathnameService.getPathname();

    if (savedLastCalledPathname) {
      this.setLastCalledPathname(savedLastCalledPathname);
    }

    const savedLastCalledAuthPathname = this.authPathnameService.getPathname();

    if (savedLastCalledAuthPathname) {
      this.setLastCalledAuthPathname(savedLastCalledAuthPathname);
    }
  };

  private noClientSelectedScenario = (auth: SuccessAuth | null) => {
    if (!auth || !auth.clients) {
      return;
    }

    this.setAuthClients(auth.clients);

    runInAction(() => {
      // выбираем первого по дефолту (чтобы селект был не пустой), забираем сохраненные данные из прошлой формы
      const client = this.authClientService.getClient() || auth?.clients?.[0];

      if (client) {
        this.authClientService.saveClient(client);
      }
      const newFormState = LoginForm.create({ ...this.persistedLoginForm, clientid: client?.clientid });
      this.loginForm = newFormState;
      this.setAuthStep(2);
    });
  };

  private authSucceededScenario = (authObject: SuccessAuth) => {
    const { accessToken, accessExpired, refreshToken, refreshExpired } = authObject;

    if (accessToken && accessExpired && refreshToken && refreshExpired) {
      const tokens = modelFactory.create<AuthTokenData>(AuthTokenData, {
        accessToken,
        accessExpired,
        refreshToken,
        refreshExpired,
        forbidden: false,
      });
      this.completeAuth(tokens);
    }
  };

  private mfaScenario = (auth: SuccessAuth | null) => {
    if (!auth || !auth.mfa) {
      return;
    }

    // забираем способы 2фа в локал стор
    if (auth.mfa.messengersTypes && auth.mfa.messengersTypes.length) {
      this.mfaMessengerTypesService?.saveTypes(auth.mfa.messengersTypes);
    }

    runInAction(() => {
      if (!auth.mfa) {
        return;
      }
      const newFormState = LoginForm.create({
        ...this.persistedLoginForm,
        messengertypeid: auth.mfa.messengertypeid,
      });
      this.loginForm = newFormState;
      this.date = Date.now();
      this.setAuthStep(3);
    });
  };

  private changeClientMFAScenario = (auth: SuccessAuth | null) => {
    if (!auth || !auth.mfa) {
      return;
    }

    // забираем способы 2фа в локал стор
    if (auth.mfa.messengersTypes && auth.mfa.messengersTypes.length) {
      this.mfaMessengerTypesService?.saveTypes(auth.mfa.messengersTypes);
    }

    runInAction(() => {
      if (!auth.mfa) {
        return;
      }

      const newFormState = ChangeClientForm.create({
        ...this.authInfo,
        ...this.changeClientForm,
        messengertypeid: auth.mfa.messengertypeid,
      });

      this.changeClientForm = newFormState;
      this.date = Date.now();
    });
  };

  getAuthInfo = async () => {
    if (this.isLoadingAuthInfo) {
      return;
    }

    this.setIsLoadingAuthInfo(true);

    try {
      const authInfo = await this.authService.getAuthInfo();
      this.setAuthInfo(authInfo);
    } catch (error) {
    } finally {
      this.setIsLoadingAuthInfo(false);
    }
  };

  private deleteTokenAndReload = () => {
    this.tokenService.deleteToken();
    rootStore.oAuth2Store.deleteToken();
    window.location.href = '/';
  };

  private completeAuth = (newTokens: AuthTokenData) => {
    this.tokenService.saveToken(newTokens);
    rootStore.oAuth2Store.saveToken(newTokens);

    this.setMfaData(null);
    this.setIsAuth(true);
    this.setCompleteCheckAuth(true);
    this.setAuthStep(1);
  };

  // reactions
  private onAuthChange = (auth: SuccessAuth | null) => {
    if (!auth) {
      return;
    }
    const { status, authStatus, mfa } = auth;

    this.setMfa(mfa);

    const clientIsNotSelected = status === null && authStatus === null;
    const mfaIsNotPassed = (status === 1 || status === 2) && authStatus === 3;
    const authSucceeded = (status === 1 || status === 2) && authStatus === 1;

    // авторизация не пройдена, не выбран клиент
    if (clientIsNotSelected) {
      this.noClientSelectedScenario(auth);
      return;
    }

    // авторизация не пройдена, 2фа
    if (mfaIsNotPassed) {
      if (auth.mfa && auth.mfa.phone) {
        this.setMfaData(auth.mfa);
      }

      this.mfaScenario(auth);
    }

    // авторизация пройдена
    if (authSucceeded) {
      this.authSucceededScenario(auth);
      this.redirectToMain?.();
      return;
    }
  };

  private onAuthInfoChange = (authInfo: AuthInfo | null) => {
    if (!authInfo) {
      return;
    }

    this.changeClientForm.setValues({ ...authInfo });
  };

  private onChangeClientAuthChange = (changeClientAuth: SuccessAuth | null) => {
    if (!changeClientAuth) {
      return;
    }

    const { status, authStatus } = changeClientAuth;

    const mfaIsNotPassed = status === 1 && authStatus === 3;
    const authSucceeded = status === 1 && authStatus === 1;

    // авторизация не пройдена, 2фа
    if (mfaIsNotPassed) {
      this.changeClientMFAScenario(changeClientAuth);
      this.openCodeConfirmationModal?.();
    }

    // авторизация пройдена
    if (authSucceeded) {
      this.authSucceededScenario(changeClientAuth);

      if (this.bufferedAuthClient) {
        this.setAuthClient(this.bufferedAuthClient);
      }

      this.showSuccessModal?.();
      return;
    }
  };

  getErrorData = (error: any) => {
    return this.errorsService.getErrors(error);
  };

  // Loadings
  setIsLoading = (state: boolean): void => {
    this.isLoading = state;
  };

  setIsLoadingAuthInfo = (state: boolean): void => {
    this.isLoadingAuthInfo = state;
  };

  setIsChangingClient = (state: boolean): void => {
    this.isChangingClient = state;
  };

  setIsCheckingServerStatus = (state: boolean): void => {
    this.isCheckingServerStatus = state;
  };

  // Setters
  setAuth = (auth: SuccessAuth | null) => {
    this.auth = auth;
  };

  setMfa = (mfa: MFA | null) => {
    this.mfa = mfa;
  };

  setMfaData = (mfa: MFA | null) => {
    this.mfaData = mfa;
  };

  setServerIsOk = (state: boolean) => {
    this.serverIsOk = state;
  };

  setLastCalledPathname = (pathname: string) => {
    if (pathname === this.lastCalledPathname) {
      return;
    }

    this.lastCalledPathname = pathname;
    this.pathnameService.savePathname(pathname);
  };

  setLastCalledAuthPathname = (pathname: string) => {
    if (pathname === this.lastCalledAuthPathname) {
      return;
    }

    this.lastCalledAuthPathname = pathname;
    this.authPathnameService.savePathname(pathname);
  };

  setAuthClients = (clients: ClientForAuth[] | null) => {
    this.authClients = clients;
  };

  setChangeClientAuth = (auth: SuccessAuth | null) => {
    this.changeClientAuth = auth;
  };

  setAuthInfo = (auth: AuthInfo | null) => {
    this.authInfo = auth;
  };

  setRedirectToMain = (redirectToMain?: () => void) => {
    this.redirectToMain = redirectToMain;
  };

  setOpenCodeConfirmationModal = (openCodeConfirmationModal?: () => void) => {
    this.openCodeConfirmationModal = openCodeConfirmationModal;
  };

  setShowSuccessModal = (showSuccessModal?: () => void) => {
    this.showSuccessModal = showSuccessModal;
  };

  setAuthStep = (step: number) => {
    this.authStep = step;
  };

  setRegisterStep = (step: number) => {
    this.registerStep = step;
  };

  setForgotPasswordStep = (step: number) => {
    this.forgotPasswordStep = step;
  };

  setCompleteCheckAuth = (value: boolean): void => {
    this.completeCheckAuth = value;
  };

  setIsAuth = (state: boolean): void => {
    this.isAuth = state;
  };

  setErrorMessages = (errors: IErrorData | null): void => {
    this.errorMessages = errors;
  };

  setIsMountedBackground = (value: boolean) => {
    this.isMountedBackground = value;
  };

  setForbiddenError = (error: IErrorData | null): void => {
    this.forbiddenError = error;
  };
}
