import { MenuItem, MenuItemProps, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IQSelectItemProps extends MenuItemProps {}

const QSelectItem: React.FC<PropsWithChildren<IQSelectItemProps>> = props => {
  const { children, ...rest } = props;

  return (
    <MenuItem {...rest}>
      <Typography>{children}</Typography>
    </MenuItem>
  );
};

export default QSelectItem;
