import { Box, IconButton } from '@mui/material';
import { FC } from 'react';

import { QIcon } from 'components/UI/QIcon/QIcon';
import { QIconIds } from 'components/UI/QIcon/QIconIds';
import { useCommonStyles } from 'styles/commonStyles';

interface INotificationsButtonProps {}

const NotificationsButton: FC<INotificationsButtonProps> = () => {
  const commonClasses = useCommonStyles();
  // Effects

  // Handlers

  // Renders
  return (
    <Box className={commonClasses.headerActionsWrapper}>
      <IconButton size="small">
        <QIcon id={QIconIds.bell} className={commonClasses.greenIcon} />
      </IconButton>
    </Box>
  );
};

export default NotificationsButton;
