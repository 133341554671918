export interface IFileDownloadData {
  url: string;
  fileName: string;
}

export class FilesHelper {
  static downloadFile(file: Blob, fileName: string) {
    const { url } = this.getFileURL(file);

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', fileName);
    anchor.click();

    URL.revokeObjectURL(url);
  }

  static getFileURL(file: Blob, ext?: string | null): IFileDownloadData {
    const url = URL.createObjectURL(file);
    return { url, fileName: `${url.split('/').slice(-1)[0]}${ext}` };
  }

  static getFileURLWithFileName(file: Blob, fileName: string): IFileDownloadData {
    const url = URL.createObjectURL(file);
    return { url, fileName };
  }

  static getFileSize(sizeInBytes: number) {
    const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));

    return parseFloat((sizeInBytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  static getFileNameAndExtension(fileName: string) {
    const parts = fileName.split('.');

    if (parts.length < 2) {
      return { name: fileName, extension: '' };
    }

    return {
      name: parts[0],
      extension: parts[1],
    };
  }
}
