import { ClientForAuth } from 'modules/clients/models/ClientForAuth';

import AuthClientLocalRepository from './AuthClientLocalRepository';

export default class AuthClientService {
  authClientLocal: AuthClientLocalRepository;

  constructor() {
    this.authClientLocal = new AuthClientLocalRepository();
  }

  saveClient = (client: ClientForAuth) => {
    this.authClientLocal.set(client);
  };

  deleteClient = () => {
    this.authClientLocal.remove();
  };

  getClient = () => {
    const client = this.authClientLocal.get();

    return client ? (client as unknown as ClientForAuth) : null;
  };
}
