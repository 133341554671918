import { createTheme } from '@mui/material/styles';

import { CommonColors } from './colors/common';

export const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1440,
    },
  },
  palette: {
    common: {
      black: CommonColors.black,
      white: CommonColors.white,
    },
  },
  spacing: 4,
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontWeight: 500,
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '56px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '36px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.15px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    button: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.46px',
    },
    overline: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
  },
});
