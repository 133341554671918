import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';

export const useLoginFormPersistance = () => {
  const { authStore } = useRootStore();
  const { watch } = useFormContext();

  useEffect(() => {
    const subscr = watch((values: any) => {
      authStore.persistedLoginForm.setValues(values);
    });

    return () => subscr.unsubscribe();
  }, []);
};
