import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

import { CheckType } from '../enums/ToolsEnums';

export enum ToolsHistoryFormFields {
  favorite = 'favorite',
  dcid = 'dcid',
  url = 'url',
  ipv6 = 'ipv6',
  udp = 'udp',
  type = 'type',
  result = 'result',
}

export class ToolsHistoryForm extends BaseForm {
  favorite: number = 0;
  dcid: number = 0;
  url: string = '';
  ipv6: number = 0;
  udp: number = 0;
  type: CheckType = 'ping' as CheckType;
  result: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.favorite = 0;
    this.dcid = 0;
    this.url = '';
    this.ipv6 = 0;
    this.udp = 0;
    this.type = 'ping' as CheckType;
    this.result = '';
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(ToolsHistoryFormFields),
    });
  };
}
