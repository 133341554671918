import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

import { DesktopBaseLayout } from './DesktopBaseLayout';
import { MobileBaseLayout } from './MobileBaseLayout';

interface IBaseLayoutProps {
  isLoadingRequiredData: boolean;
}

export const BaseLayout: React.FC<PropsWithChildren<IBaseLayoutProps>> = observer(
  ({ children, isLoadingRequiredData }) => {
    // Renders
    return (
      <>
        <BrowserView>
          <DesktopBaseLayout isLoadingRequiredData={isLoadingRequiredData}>{children}</DesktopBaseLayout>
        </BrowserView>
        <MobileView>
          <MobileBaseLayout isLoadingRequiredData={isLoadingRequiredData}>{children}</MobileBaseLayout>
        </MobileView>
      </>
    );
  }
);
