import { routes } from 'routes/routes';

export interface ISidebarLink {
  title: string;
  link: string;
  icon: string;
  id: number;
}

export const sidebarLinksList: ISidebarLink[] = [
  {
    title: routes.MainScreen.title,
    link: routes.MainScreen.path,
    icon: 'home',
    id: 1,
  },
  {
    title: routes.DocumentsScreen.title,
    link: routes.DocumentsScreen.path,
    icon: 'files-landscape',
    id: 2,
  },
  {
    title: routes.ServicesScreen.title,
    link: routes.ServicesScreen.path,
    icon: 'cart',
    id: 10,
  },
  {
    title: routes.PassesScreen.title,
    link: routes.PassesScreen.path,
    icon: 'file-check',
    id: 3,
  },
  {
    title: routes.UsersScreen.title,
    link: routes.UsersScreen.path,
    icon: 'users',
    id: 4,
  },
  {
    title: routes.ClientRequisitesScreen.title,
    link: routes.ClientRequisitesScreen.path,
    icon: 'file-alt',
    id: 5,
  },
  {
    title: routes.RegistrantsScreen.title,
    link: routes.RegistrantsScreen.path,
    icon: 'user-square',
    id: 6,
  },
  {
    title: routes.HardwareStockScreen.title,
    link: routes.HardwareStockScreen.path,
    icon: 'cart',
    id: 7,
  },
  {
    title: routes.HardwareOrderScreen.title,
    link: routes.HardwareOrderScreen.path,
    icon: 'box',
    id: 8,
  },
  {
    title: routes.ToolsScreen.title,
    link: routes.ToolsScreen.path,
    icon: 'desktop',
    id: 9,
  },
];
