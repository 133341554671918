import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { MouseEvent, useRef } from 'react';

export const useRipple = () => {
  const rippleRef = useRef<TouchRippleActions | null>(null);

  const onRippleStart = (e: MouseEvent) => {
    rippleRef.current?.start(e);
  };

  const onRippleStop = (e: MouseEvent) => {
    rippleRef.current?.stop(e);
  };

  return {
    ref: rippleRef,
    onRippleStart,
    onRippleStop,
  };
};
