import { ModelFactory } from 'base/ModelFactory';

import { Hardware } from './models/Hardware';
import { HardwareAddInfo } from './models/HardwareAddInfo';

export class HardwareStockFactory extends ModelFactory {
  createHardwareList = (data: any[]) => {
    return data.map(this.createHardware);
  };

  createHardware = (data: any) => {
    const hardware = this.create<Hardware>(Hardware, data);

    if (data.addinfo) {
      hardware.addinfo = this.createList<HardwareAddInfo>(HardwareAddInfo, data.addinfo);
    }

    return hardware;
  };
}
