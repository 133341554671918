import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';
import FieldsFactory from 'base/modules/fields/FieldsFactory';

import { AddressesDto } from './dto/AddressesDto';
import { AddressTypes } from './enums/AddressesEnums';
import { AddressRefences } from './models/AddressReferences';

export default class AddressesFactory extends ModelFactory {
  fieldsFactory: FieldsFactory;

  constructor() {
    super();
    this.fieldsFactory = new FieldsFactory();
  }

  createAdderssesDto = (data: any) => {
    return Dto.populate<AddressesDto>(AddressesDto, data);
  };

  createAddressReferences = (addressType: AddressTypes, data: any) => {
    const addressReferences = this.create<AddressRefences>(AddressRefences, data);

    if (data.fields) {
      addressReferences.fields = this.createGroupedAddressesFields(addressType, data.fields);
    }

    return addressReferences;
  };

  createClientRequisiteField = (addressType: AddressTypes, data: any) => {
    const preparedField = this.fieldsFactory.createField(data);
    const field = this.fieldsFactory.createField({
      ...preparedField,
      name: `${addressType}__${preparedField.name}`,
    });

    if (data.possibleValues) {
      if (Array.isArray(data.possibleValues) && data.possibleValues.every((v: any) => typeof v !== 'string')) {
        field.possibleValues = this.fieldsFactory.createPossibleValuesList(data.possibleValues);
      }

      if (!Array.isArray(data.possibleValues)) {
        field.possibleValues = this.fieldsFactory.createPossibleValue(data.possibleValues);
      }
    }

    return field;
  };

  createClientRequisiteFieldsList = (addressType: AddressTypes, data: any[]) => {
    return data.map(data => this.createClientRequisiteField(addressType, data));
  };

  createGroupedAddressesFields = (addressType: AddressTypes, data: any[]) => {
    return data.map((data: any[]) => this.createClientRequisiteFieldsList(addressType, data));
  };
}
