import { ExpandMore } from '@mui/icons-material';
import { Box, FormControl, FormHelperText, FormLabel, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IQSelectProps } from 'interfaces/UIComponentsInterfaces';
import React, { forwardRef } from 'react';

import QHelperText from '../QHelperText';
import QSelectItem from './QSelectItem';

const QSelect: React.FC<IQSelectProps> = forwardRef(
  ({ errors, emptyValue, label, placeholder, fullWidth = true, children, ...rest }, ref) => {
    const classes = useStyles();

    // Renders
    return (
      <FormControl fullWidth={fullWidth} error={!!errors}>
        {label && <FormLabel>{label}</FormLabel>}
        {placeholder && (
          <Box component="span" className={clsx(classes.placeholder, { [classes.hidden]: rest.value })}>
            {placeholder}
          </Box>
        )}
        <Select
          autoComplete="off"
          fullWidth={fullWidth}
          ref={ref}
          displayEmpty
          IconComponent={ExpandMore}
          inputProps={{
            classes: { icon: clsx({ [classes.icon]: !rest.disabled, [classes.disabledIcon]: rest.disabled }) },
          }}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}
          {...rest}
        >
          {emptyValue && <QSelectItem value="">{emptyValue}</QSelectItem>}
          {children}
        </Select>
        {!!errors?.length && (
          <FormHelperText>
            <QHelperText errors={errors} />
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

const useStyles = makeStyles(theme => ({
  placeholder: {
    position: 'absolute',
    top: theme.spacing(9.5),
    left: theme.spacing(4),
    right: theme.spacing(8),
    color: theme.palette.customColors.surfaceTertiary,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  disabledIcon: {
    fill: theme.palette.customColors.surfaceTertiary,
  },
}));

export default QSelect;
