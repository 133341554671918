import { jsonProperty, Serializable } from 'ts-serializable';

export class InfoSubbill extends Serializable {
  @jsonProperty(String) amblvltotal: string = '';
  @jsonProperty(String) discmode: string = '';
  @jsonProperty(String) dtfrom: string = '';
  @jsonProperty(String) dtto: string = '';
  @jsonProperty(Number) discount: number = 0;
  @jsonProperty(Number) subagrid: number = 0;
}
