import { jsonProperty, Serializable } from 'ts-serializable';

export class CheckHistory extends Serializable {
  @jsonProperty(String) url: string = '';
  @jsonProperty(String) type: string = '';
  @jsonProperty(String) dcname: string = '';
  @jsonProperty(String) created_at: string = '';
  @jsonProperty(String) updated_at: string = '';
  @jsonProperty(Boolean) favorite: boolean = false;
  @jsonProperty(Boolean) ipv6: boolean = false;
  @jsonProperty(Boolean) udp: boolean = false;
  @jsonProperty(Number) id: number = 0;
  @jsonProperty(Number) dcid: number = 0;
  @jsonProperty(Number) clientid: number = 0;
  @jsonProperty(Number) userid: number = 0;
}
