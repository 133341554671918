import OthersHelper from 'helpers/OthersHelper';

export class AuthHelper {
  static getRandomPassword = () => {
    const lowRegisterCharset = 'abcdefghijklmnopqrstuvwxyz';
    const highRegisterCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numberCharset = '0123456789';

    const charset = lowRegisterCharset + highRegisterCharset + numberCharset;
    const charsetsArray = [lowRegisterCharset, highRegisterCharset, numberCharset];

    let result = '';

    for (let i = 0; i < 3; i++) {
      result += OthersHelper.getSomeRandomChars(charsetsArray[i], 2);
    }
    result = result + OthersHelper.getSomeRandomChars(charset, 4);

    return OthersHelper.shuffleArray(result.split('')).join('');
  };
}
