import LanguageLocalRepository from './LanguageLocalRepository';

export default class LanguageLocalService {
  languageLocal: LanguageLocalRepository;

  constructor() {
    this.languageLocal = new LanguageLocalRepository();
  }

  saveLanguage = (language: string | null) => {
    this.languageLocal.set(language);
  };

  deleteLanguage = () => {
    this.languageLocal.remove();
  };

  getLanguage = () => {
    const language = this.languageLocal.get();

    return language;
  };
}
