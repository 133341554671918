import { jsonProperty, Serializable } from 'ts-serializable';

import { FieldTypes } from 'base/modules/fields/enums/FieldsEnums';

import { PossibleValue } from './PossibbleValue';

export class Field extends Serializable {
  @jsonProperty(String, null) hint: string | null = null;
  @jsonProperty(String) label: string = '';
  @jsonProperty(String) name: string = '';
  @jsonProperty(String) type: FieldTypes = FieldTypes.string;
  @jsonProperty(Boolean) needVerify: boolean = false;
  @jsonProperty(Boolean) required: boolean = false;
  @jsonProperty(String, null) value: string = '';
  @jsonProperty(String, null) minValue: string | null = null;
  @jsonProperty(String, null) maxValue: string | null = null;
  @jsonProperty(Array) mask: string[] = [];
  @jsonProperty(Array, Object, null) possibleValues: (string | PossibleValue)[] | PossibleValue = [];
  @jsonProperty(Boolean, null) refreshform: boolean | null = null;
}
