import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

import { IPMIAccessTypes } from '../enums/UnitEmuns';
import { UnitSaveIpModel } from '../models/UnitSaveIpModel';

export enum IpmiAccessFormFields {
  accesstype = 'accesstype',
  ips = 'ips',
  hours = 'hours',
}

export class IpmiAccessForm extends BaseForm {
  accesstype: IPMIAccessTypes = IPMIAccessTypes.U;
  ips: UnitSaveIpModel[] = [];
  hours: number = 0;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.accesstype = IPMIAccessTypes.U;
    this.ips = [];
    this.hours = 0;
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(IpmiAccessFormFields),
    });
  };
}
