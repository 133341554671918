import ApiHelper from 'base/helpers/ApiHelper';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';

import ServicesApiRepository from './ServicesApiRepository';
import { ServicesFactory } from './ServicesFactory';
import { ServicesTableForm } from './forms/ServicesTableForm';
import { AgreementClass } from './models/AgreementClass';

export default class ServicesService {
  static readonly LIMIT = 5;

  servicesApi: ServicesApiRepository;
  servicesFactory: ServicesFactory;
  paginationService: PaginationService;

  constructor() {
    this.servicesApi = new ServicesApiRepository();
    this.servicesFactory = new ServicesFactory();
    this.paginationService = new PaginationService();
  }

  getRootServices = async ({ page, limit, query, sortValue, filters }: ServicesTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);

    const queryString = ApiHelper.getQueryString({ page, limit, query, ...sort, ...filters });

    const { data } = await this.servicesApi.getRootServices(queryString);

    const items = this.servicesFactory.createServices(data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);
    const totals = data.data.amblvltotalsFullRaw as Record<string, string>;

    return { items, paginator, totals };
  };

  getChildrenServices = async (parentsaid: number, tableForm?: ServicesTableForm) => {
    let queryString;

    if (!tableForm) {
      queryString = ApiHelper.getQueryString({ 'parentsaid[]': parentsaid });
    } else {
      const { sortValue } = tableForm;
      const sort = SortHelper.getFormattedSortValue(sortValue);
      queryString = ApiHelper.getQueryString({ 'parentsaid[]': parentsaid, ...sort });
    }

    const { data } = await this.servicesApi.getChildrenServices(queryString);

    const items = this.servicesFactory.createServices(data.data.items);
    const totals = data.data.amblvltotalsFullRaw as Record<string, string>;

    return { items, totals };
  };

  getAgreementClasses = async () => {
    const { data } = await this.servicesApi.getAgreementClasses();

    return this.servicesFactory.createList<AgreementClass>(AgreementClass, data.data);
  };
}
