import { alpha, Components, createTheme, Theme } from '@mui/material/styles';

import { CommonColors } from 'styles/colors/common';
import { DarkThemeColors } from 'styles/colors/dark';
import { theme } from 'styles/muiTheme';

import {
  buttonGroupOverrides,
  buttonOverrides,
  cssBaselineOverrides,
  formLabelOverrides,
  inputCommonOverrides,
  tableOverrides,
  tabsOverrides,
} from './common-overrides';

export const darkTheme = createTheme({
  ...theme,
  palette: {
    mode: 'dark',
    primary: {
      main: DarkThemeColors.primaryMain,
      light: DarkThemeColors.primaryLight,
      dark: DarkThemeColors.primaryDark,
    },
    secondary: { main: DarkThemeColors.secondaryMain, dark: DarkThemeColors.secondaryDark },
    error: { main: DarkThemeColors.errorMain, light: DarkThemeColors.errorLight, dark: DarkThemeColors.errorDark },
    background: { default: DarkThemeColors.background, paper: DarkThemeColors.surface },
    action: {
      active: DarkThemeColors.active,
      hover: DarkThemeColors.hover,
      selected: DarkThemeColors.selected,
      disabled: DarkThemeColors.disabled,
      disabledBackground: DarkThemeColors.disabledBackground,
      focus: DarkThemeColors.focus,
    },
    customColors: {
      overlay: DarkThemeColors.overlay,
      modalOverlay: DarkThemeColors.modalOverlay,
      border: DarkThemeColors.border,
      shadow: DarkThemeColors.shadowColor,
      statusRed: DarkThemeColors.statusRed,
      statusGreen: DarkThemeColors.statusGreen,
      statusYellow: DarkThemeColors.statusYellow,
      statusGrey: DarkThemeColors.statusGrey,
      partnersProgram: DarkThemeColors.partnersProgram,
      surfaceTertiary: DarkThemeColors.surfaceTertiary,
    },
  },
  components: {
    ...cssBaselineOverrides,
    MuiCssBaseline: {
      ...cssBaselineOverrides.MuiCssBaseline,
      styleOverrides: {
        ...cssBaselineOverrides.MuiCssBaseline.styleOverrides,
        '.PhoneInputInput': {
          color: CommonColors.white,
        },
      },
    },
    ...formLabelOverrides,
    MuiOutlinedInput: {
      styleOverrides: {
        ...inputCommonOverrides,
        root: {
          ...inputCommonOverrides.root,
          backgroundColor: DarkThemeColors.overlay,
          minHeight: 40,
          '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: DarkThemeColors.statusRed,
              backgroundColor: alpha(DarkThemeColors.statusRed, 0.16),
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: DarkThemeColors.statusRed,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        ...buttonOverrides,
        root: {
          ...buttonOverrides.root,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: DarkThemeColors.primaryDark,
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained-contrast' },
          style: {
            backgroundColor: DarkThemeColors.secondaryMain,
            color: DarkThemeColors.primaryMain,
            '&:hover': {
              backgroundColor: DarkThemeColors.secondaryDark,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: DarkThemeColors.primaryMain,
            ...theme.typography.subtitle2,
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        ...buttonGroupOverrides,
        grouped: {
          ...buttonGroupOverrides.grouped,
          color: theme.palette.common.white,
        },
      },
    },
    ...(tableOverrides(DarkThemeColors.surfaceTertiary) as Components<Omit<Theme, 'components'>>),
    ...(tabsOverrides(DarkThemeColors.surfaceTertiary) as Components<Omit<Theme, 'components'>>),
  },
});
