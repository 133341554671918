import { Serializable, jsonProperty } from 'ts-serializable';

export class Subbill extends Serializable {
  @jsonProperty(Number, null) subbillid: number | null = null;
  @jsonProperty(Number, null) billid: number | null = null;
  @jsonProperty(String, null) dtfrom: string | null = null;
  @jsonProperty(String, null) dtto: string | null = null;
  @jsonProperty(String, null) subbilltext: string | null = null;
  @jsonProperty(Number, null) amblvltotal: number | null = null;
  @jsonProperty(String, null) valuteid: string | null = null;
  @jsonProperty(Number, null) subagrid: number | null = null;
  @jsonProperty(String, null) rawsubbilltext: string | null = null;
  @jsonProperty(String, null) bvaluteid: string | null = null;
  @jsonProperty(Number, null) bstatus: number | null = null;
  @jsonProperty(String, null) priceperitem: string | null = null;
  @jsonProperty(String, null) priceperitem2: string | null = null;
  @jsonProperty(Number, null) period: number | null = null;
  @jsonProperty(Number, null) aclassid: number | null = null;
  @jsonProperty(String, null) dtblock: string | null = null;
  @jsonProperty(String, null) freeperiodusedxtimes: string | null = null;
  @jsonProperty(String, null) fullname: string | null = null;
  @jsonProperty(String, null) fullname2: string | null = null;
  @jsonProperty(String, null) payeduntil: string | null = null;
  @jsonProperty(String, null) discount: string | null = null;
}
