import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCommonStyles = makeStyles(theme => ({
  // icon colors
  greenIcon: {
    fill: theme.palette.primary.main,
  },
  grayIcon: {
    fill: theme.palette.action.active,
  },
  whiteIcon: {
    fill: theme.palette.common.white,
  },
  errorIconColor: {
    fill: theme.palette.customColors.statusRed,
  },
  warningIconColor: {
    fill: theme.palette.customColors.statusYellow,
  },
  okIconColor: {
    fill: theme.palette.customColors.statusGreen,
  },

  // text colors
  greenText: {
    color: theme.palette.primary.main,
  },
  grayText: {
    color: theme.palette.action.active,
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  errorTextColor: {
    color: theme.palette.customColors.statusRed,
  },
  warningTextColor: {
    color: theme.palette.customColors.statusYellow,
  },
  okTextColor: {
    color: theme.palette.customColors.statusGreen,
  },
  onSurfaceTertiary: {
    color: theme.palette.customColors.surfaceTertiary,
  },

  // buttons
  contrastButton1: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
    transition: theme.transitions.create('all', { duration: 150, easing: 'ease-in-out' }),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },

  contrastButton2: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.customColors.statusRed,
    ...theme.typography.subtitle2,
    transition: theme.transitions.create('all', { duration: 150, easing: 'ease-in-out' }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.customColors.statusRed, 0.1),
    },
  },

  contrastButton3: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    ...theme.typography.button,
    transition: theme.transitions.create('all', { duration: 150, easing: 'ease-in-out' }),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },

  // menus
  menuItemsWithIcons: {
    display: 'flex',
    gap: theme.spacing(3),
  },

  // auth
  loginButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },

  stepBack: {
    position: 'absolute',
    background: theme.palette.background.paper,
    boxShadow: `0px 0px 8px ${theme.palette.customColors.shadow}`,
    borderRadius: theme.spacing(8),
    top: theme.spacing(11),
    left: theme.spacing(4),
  },

  expired: {
    position: 'absolute',
    padding: theme.spacing(3),
    top: theme.spacing(-20),
    left: 0,
    width: '100%',
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      position: 'static',
    },
  },

  // header
  headerActionsWrapper: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(2),
  },

  avatar: {
    borderRadius: theme.spacing(2),
  },

  // sidebar
  sidebar: {
    position: 'relative',
    transition: 'all 0.3s ease',
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${theme.palette.customColors.border}`,
  },
  sidebarLogoBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    minHeight: theme.spacing(16),
    gap: theme.spacing(4),
    '& > a': {
      height: theme.spacing(5.5),
      marginRight: theme.spacing(4.5),
    },
  },
  sidebarMenu: {
    padding: theme.spacing(4),
  },
  sidebarMenuItem: {
    display: 'flex',
    padding: theme.spacing(3),
    alignItems: 'center',
    gap: theme.spacing(3),
    borderRadius: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },

  // router
  routerLink: {
    textDecoration: 'none',
    color: 'inherit',
  },

  routerLinkFullWidth: {
    display: 'block',
    width: '100%',
  },

  // link
  link: {
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
  },

  linkButton: {
    textDecoration: 'none',
    padding: theme.spacing(1.5, 2.5),
    borderRadius: theme.spacing(2),
  },

  // screen
  screenMainWrap: {
    padding: theme.spacing(4),
    position: 'relative',
  },

  // main screen
  rowTile: {
    width: '33%',
    maxWidth: theme.spacing(90),
  },

  rowTileStyle: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },

  rowTileGrayBGColor: {
    backgroundColor: theme.palette.customColors.border,
  },

  rowTileCaption: {
    textTransform: 'uppercase',
    color: theme.palette.customColors.surfaceTertiary,
    letterSpacing: 2.15,
  },

  // tables
  whiteTableHeader: {
    padding: theme.spacing(4.5, 4),
    backgroundColor: theme.palette.background.paper,
  },

  appliedFilters: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: '50%',
    boxShadow: `0px 0px 0px 2px ${theme.palette.background.paper}`,
  },

  // expandables
  expand: {
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    transform: 'rotate(0deg)',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },

  // layout
  requisiteColumn: {
    flexBasis: '50%',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },

  requisitesWrapper: {
    maxWidth: theme.spacing(275),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
  },

  // modals
  defaultModalBackground: {
    backgroundColor: theme.palette.background.default,
  },

  // mixin
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
