import { FieldValues } from 'react-hook-form';

import Dto from 'base/Dto';
import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';
import ClientsFactory from 'modules/clients/ClientsFactory';

import RegistrantsApiRepository from './RegistrantsApiRepository';
import { RegistrantsFactory } from './RegistrantsFactory';
import DeleteRegistrantDto from './dto/DeleteRegistrantDto';
import { IRegistrantCardQuery } from './interfaces/RegistrantsInterfaces';
import { Registrant } from './models/Registrant';

export default class RegistrantsService {
  private registrantsApi: RegistrantsApiRepository;

  private registrantsFactory: RegistrantsFactory;
  private clientsFactory: ClientsFactory;

  constructor() {
    this.registrantsApi = new RegistrantsApiRepository();

    this.registrantsFactory = new RegistrantsFactory();
    this.clientsFactory = new ClientsFactory();
  }

  getRegistrants = async () => {
    const { data } = await this.registrantsApi.getRegistrants();

    return this.registrantsFactory.createRegistrants(data.data);
  };

  getRegistrant = async (registrantid: number) => {
    const { data } = await this.registrantsApi.getRegistrant(registrantid);

    return this.registrantsFactory.createRegistrant(data.data);
  };

  getRegistrantCard = async (queryParams: IRegistrantCardQuery) => {
    const queryString = ApiHelper.getQueryString(queryParams);
    const { data } = await this.registrantsApi.getRegistrantCard(queryString);

    const card = this.clientsFactory.createRequisites(data.data);
    const requiredData = this.registrantsFactory.createRequiredRequisites(card);

    return { card, requiredData };
  };

  validateRegistrant = async (form: FieldValues) => {
    const { data } = this.clientsFactory.createValidateRequisitesDto(form);
    const response = await this.registrantsApi.validateRegistrant(data);

    return this.clientsFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  updateRegistrant = async (form: FieldValues) => {
    const { data } = this.clientsFactory.createUpdateRequisitesDto(form);
    const response = await this.registrantsApi.updateRegistrant(data);

    return this.clientsFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  createRegistrant = async (form: FieldValues) => {
    const preDto = this.prepareCreateRegistrantForm(form);
    const { data } = this.clientsFactory.createUpdateRequisitesDto(preDto);
    const response = await this.registrantsApi.createRegistrant(data);

    return this.clientsFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  deleteRegistrant = async (registrantid: number) => {
    const dto = Dto.populate<DeleteRegistrantDto>(DeleteRegistrantDto, { registrantid });
    const response = await this.registrantsApi.deleteRegistrant(dto);

    return this.clientsFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  localDeleteRegistrant = (
    registrantid: number,
    registrants: Registrant[],
    setRegistrants: (data: Registrant[]) => void
  ) => {
    const filteredData = registrants.filter(item => item.registrantid !== registrantid);

    setRegistrants(filteredData);
  };

  setRequiredValidateField = (form: FieldValues, fields: Record<string, boolean> | null, value: boolean) => {
    Object.keys(form).forEach(key => {
      if (fields?.[key] !== undefined) {
        fields[key] = value;
      }
    });
  };

  private prepareCreateRegistrantForm = (form: FieldValues) => {
    const preDto = Object.entries(form).filter(([, value]) => value !== '');

    return Object.fromEntries(preDto);
  };
}
