import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import { QIcon } from 'components/UI/QIcon/QIcon';
import RippleBox from 'components/UI/RippleBox';
import { MFAMessengerType } from 'modules/mfa/models/MFAMessengerType';
import { useCommonStyles } from 'styles/commonStyles';

interface IChangeMFAMethodProps {
  methods?: MFAMessengerType[] | null;
  onMessengerTypeClick?: (messengertypeid: number) => () => void;
}

const ChangeMFAMethod: FC<IChangeMFAMethodProps> = ({ methods, onMessengerTypeClick }) => {
  const {
    languageToggleStore: { _lk },
  } = useRootStore();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Renders
  return (
    <>
      <Box mt={6} className={classes.methods}>
        {methods?.map(method => {
          const iconId = method.label?.toLowerCase();
          const status = method.mfadefault ? _lk('Настроен и выбран как способ по умолчанию') : _lk('Способ настроен');

          return (
            <RippleBox
              component="button"
              className={classes.method}
              onClick={onMessengerTypeClick?.(method.messengertypeid)}
            >
              {iconId && <QIcon id={iconId} className={classes.methodIcon} />}
              <Box>
                <Typography variant="subtitle1">{method.label}</Typography>
                <Typography component="p" variant="caption" className={classes.status}>
                  {status}
                </Typography>
              </Box>
            </RippleBox>
          );
        })}
      </Box>
      <Box mt={7.5}>
        {/* TODO: адрес */}
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a href="#" target="_blank" rel="noopener noreferrer" className={commonClasses.link}>
          {_lk('Что делать, если ни один способ не доступен?')}
        </a>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  methods: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  method: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    backgroundColor: 'transparent',
    padding: theme.spacing(2.5, 3, 2.5, 4.5),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  methodIcon: {
    marginRight: theme.spacing(4),
    fill: theme.palette.action.active,
  },
  status: {
    color: theme.palette.action.active,
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
  },
}));

export default ChangeMFAMethod;
