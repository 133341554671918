import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { LoginForm } from 'modules/auth/forms/LoginForm';
import { loginFormScheme } from 'modules/auth/schemes/AuthScheme';

import { AuthCard } from './components/AuthCard';
import AuthScreen from './components/AuthScreen';
import ClientChoiceStep from './components/AuthSteps/ClientChoiceStep';
import LoginStep from './components/AuthSteps/LoginStep';
import MFAMethodChoiceStep from './components/AuthSteps/MFAMethodChoiceStep';
import MFAStep from './components/AuthSteps/MFAStep';
import Forbidden from './components/Forbidden';

interface ILoginScreenProps {}

const LoginScreen: React.FC<ILoginScreenProps> = observer(() => {
  const {
    authStore,
    languageToggleStore: { _lk, translations },
  } = useRootStore();
  const navigate = useNavigate();

  const form = useForm<LoginForm>({
    mode: 'onChange',
    resolver: yupResolver(loginFormScheme(authStore.authStep)) as any,
    defaultValues: LoginForm.createDefaultValues(),
  });

  const title = useMemo(() => {
    switch (authStore.authStep) {
      case 2:
        return _lk('Выберите клиента');
      case 3:
        return _lk('Включена двухфакторная аутентификация');
      case 4:
        return translations.mfaChangeMethod;
      default:
        return _lk('Вход');
    }
  }, [authStore.authStep, translations]);

  // Effects
  useEffect(() => {
    if (authStore.loginForm) {
      form.reset({ ...authStore.loginForm });
    }
  }, [authStore.loginForm]);

  // Handlers
  const handleChooseMFAMethodClick = () => {
    authStore.setAuthStep(4);
  };

  const handleGetNewCodeClick = () => {
    form.reset({ ...authStore.persistedLoginForm, mfacode: null });

    authStore.login(form.getValues() as LoginForm);
  };

  const handleMessengerTypeClick = (messengertypeid: number) => () => {
    form.reset({ ...authStore.persistedLoginForm, mfacode: null, messengertypeid });

    authStore.login(form.getValues() as LoginForm, () => {
      authStore.setAuthStep(3);
    });
  };

  const onSubmit = (values: FieldValues) => {
    authStore.login(values as LoginForm, () => {
      if (authStore.lastCalledPathname) {
        navigate(authStore.lastCalledPathname);
        return;
      }

      navigate(routes.MainScreen.path);
    });
  };

  // Renders
  const renderLoginSteps = () => {
    switch (authStore.authStep) {
      case 2:
        return <ClientChoiceStep clients={authStore.authClients} isLoading={authStore.isLoading} />;
      case 3:
        return (
          <MFAStep
            isLoading={authStore.isLoading}
            onChooseMFAMethodClick={handleChooseMFAMethodClick}
            onGetNewCodeClick={handleGetNewCodeClick}
            deadline={authStore.deadline}
          />
        );
      case 4:
        return (
          authStore.mfaTypes && (
            <MFAMethodChoiceStep methods={authStore.mfaTypes} onMessengerTypeClick={handleMessengerTypeClick} />
          )
        );
      default:
        return <LoginStep isLoading={authStore.isLoading} errors={authStore.errorMessages} />;
    }
  };

  return authStore.forbiddenError !== null ? (
    <AuthScreen>
      <Forbidden />
    </AuthScreen>
  ) : (
    <AuthScreen>
      <AuthCard title={title}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>{renderLoginSteps()}</form>
        </FormProvider>
      </AuthCard>
    </AuthScreen>
  );
});

export default LoginScreen;
