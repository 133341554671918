export const _lk = (str: string) => {
  return str;
};

export const _stringFormat = (str: string, values: Record<string, string | null | undefined>) => {
  let newString = str;
  Object.entries(values).forEach(([key, value]) => {
    if (value) {
      newString = newString.replace(new RegExp('\\{' + key + '\\}', 'gm'), value);
    }
  });
  return newString;
};

export class TranslationsHelper {
  // common
  static readonly close = _lk('Закрыть');
  static readonly cancel = _lk('Отмена');
  static readonly save = _lk('Сохранить');
  static readonly edit = _lk('Редактировать');
  static readonly load = _lk('Загрузить');
  static readonly more = _lk('Ещё');
  static readonly add = _lk('Добавить');
  static readonly delete = _lk('Удалить');
  static readonly ok = _lk('Хорошо');
  static readonly choose = _lk('Выбрать');
  static readonly change = _lk('Изменить');
  static readonly users = _lk('Пользователи');
  static readonly status = _lk('Статус');
  static readonly date = _lk('Дата');
  static readonly entrance = _lk('Вход');
  static readonly regAction = _lk('Зарегистрироваться');
  static readonly forgotPassword = _lk('Забыли пароль?');

  // bytes
  static readonly bytes = _lk('байт');
  static readonly Kb = _lk('Кб');
  static readonly Mb = _lk('Мб');
  static readonly Gb = _lk('Гб');
  static readonly Tb = _lk('Тб');

  // tables
  static readonly naming = _lk('Название');

  // form fields validation error messages
  static readonly requiredField = _lk('Обязательное поле');
  static readonly requiredCodeLength = _lk('Код должен состоять из 6 цифр');
  // password
  static readonly notMatchPasswords = _lk('Пароли не совпадают');
  static readonly lengthPassword = _lk('Минимум 8 символов');
  static readonly validPassword1 = _lk('Пароль должен содержить минимум 2 большие латинские буквы');
  static readonly validPassword2 = _lk('Пароль должен содержить минимум 2 маленькие латинские буквы');
  static readonly validPassword3 = _lk('Пароль должен содержить минимум 2 цифры');

  // mfa
  static readonly mfaTitle = _lk('Двойная аутентификация');
  static readonly mfaChangeMethod = _lk('Выбрать другой способ');

  // documents
  static readonly documentApproved = _lk('Утвержден');
  static readonly documentInProgress = _lk('На утверждении');
  static readonly documentAskedToDelete = _lk('Запрошено удаление');
  static readonly documentOverdue = _lk('Просрочено');
  static readonly documentRejected = _lk('Отказано');

  // requisites
  static readonly requisites = _lk('Реквизиты');

  // registrants
  static readonly registrants = _lk('Регистранты');
}

// export const d = {};
