import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { forgotPasswordFormScheme } from 'modules/auth/schemes/AuthScheme';
import { ResetPasswordForm, ResetPasswordFormFields } from 'modules/users/forms/ResetPasswordForm';

import { AuthCard } from './components/AuthCard';
import AuthScreen from './components/AuthScreen';
import { CodeStep } from './components/CommonSteps/CodeStep';
import Forbidden from './components/Forbidden';
import { ContactStep } from './components/ForgotPasswordSteps/ContactStep';
import { ResetPasswordStep } from './components/ForgotPasswordSteps/ResetPasswordStep';

interface IForgotPasswordScreenProps {}

const ForgotPasswordScreen: FC<IForgotPasswordScreenProps> = observer(() => {
  const {
    authStore,
    languageToggleStore: { _lk, translations },
  } = useRootStore();
  const navigate = useNavigate();

  const form = useForm<ResetPasswordForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(forgotPasswordFormScheme) as any,
    defaultValues: authStore.resetPasswordForm,
  });
  const { watch, handleSubmit, clearErrors } = form;

  const watchCode = watch(ResetPasswordFormFields.code) as string;

  const title = useMemo(() => {
    switch (authStore.forgotPasswordStep) {
      case 2:
        return _lk('Теперь введите код');
      case 3:
        return _lk('Введите новый пароль');
      default:
        return _lk('Восстановление пароля');
    }
  }, [authStore.forgotPasswordStep, translations]);

  // Effects
  useEffect(() => {
    return () => {
      form.reset(ResetPasswordForm.create());

      authStore.setErrorMessages(null);
      authStore.setForgotPasswordStep(1);
    };
  }, []);

  useEffect(() => {
    if (watchCode?.length === 6) {
      onSubmit({ ...form.getValues(), code: watchCode });
    } else {
      clearErrors(ResetPasswordFormFields.code);
      authStore.setErrorMessages(null);
    }
  }, [watchCode]);

  useEffect(() => {
    authStore.setErrorMessages(null);
    clearErrors();
  }, [authStore.forgotPasswordStep]);

  // Handlers
  const onSubmit = (values: FieldValues) => {
    authStore.resetPassword(values as ResetPasswordForm, () => {
      if (authStore.forgotPasswordStep === 3) {
        navigate(authRoutes.LoginScreen.path);
      } else {
        authStore.setForgotPasswordStep(authStore.forgotPasswordStep + 1);
      }
    });
  };

  const handleReturnToLogin = () => {
    navigate(authRoutes.LoginScreen.path);
  };

  const handleGetNewCodeClick = () => {
    form.reset({ ...form.getValues(), code: null });

    authStore.resetPassword(form.getValues() as ResetPasswordForm);
  };

  // Renders
  const renderForgotPasswordSteps = () => {
    switch (authStore.forgotPasswordStep) {
      case 2:
        return (
          <CodeStep
            onGetNewCodeClick={handleGetNewCodeClick}
            deadline={authStore.resetPasswordDeadline}
            isLoading={authStore.isLoading}
            errors={authStore.errorMessages}
          />
        );
      case 3:
        return <ResetPasswordStep isLoading={authStore.isLoading} errors={authStore.errorMessages} />;
      default:
        return <ContactStep isLoading={authStore.isLoading} errors={authStore.errorMessages} />;
    }
  };

  return authStore.forbiddenError !== null ? (
    <AuthScreen>
      <Forbidden />
    </AuthScreen>
  ) : (
    <AuthScreen>
      <AuthCard
        hideSelectLanguage={authStore.forgotPasswordStep !== 1}
        handleFirstStepBackButton={handleReturnToLogin}
        title={title}
      >
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>{renderForgotPasswordSteps()}</form>
        </FormProvider>
      </AuthCard>
    </AuthScreen>
  );
});

export default ForgotPasswordScreen;
