export enum QIconIds {
  eye = 'eye',
  eyeSlash = 'eye-slash',
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  hamburger = 'hamburger',
  multiply = 'multiply',
  search = 'search',
  circle = 'circle',
  bell = 'bell',
  doubleChevron = 'double-chevron',
  questionCircle = 'question-circle',
  outlinedQuestionCircle = 'outlined-question-circle',
  phone = 'phone',
  sms = 'sms',
  home = 'home',
  bag = 'bag',
  link = 'link',
  exclamationOctagon = 'exclamation-octagon',
  exclamationTriangle = 'exclamation-triangle',
  okCircle = 'ok-circle',
  angleDown = 'angle-down',
  angleRight = 'angle-right',
  angleLeft = 'angle-left',
  ellipsis = 'ellipsis',
  trash = 'trash',
  download = 'download',
  envelope = 'envelope',
  star = 'star',
  power = 'power',
  fileCheck = 'file-check',
  calendar = 'calendar',
  edit = 'edit',
  file = 'file',
  pen = 'pen',
  fileAlt = 'file-alt',
  filesLandscape = 'files-landscape',
  userSquare = 'user-square',
  box = 'box',
  users = 'users',
  cart = 'cart',
  filter = 'filter',
  desktop = 'desktop',
  camera = 'camera',
  delete = 'delete',
  sync = 'sync',
  phoneAlt = 'phone-alt',
  dice = 'dice',
  exclamationCircle = 'exclamation-circle',
  copy = 'copy',
  plus = 'plus',
  monitorHeartRate = 'monitor-heart-rate',
}
