import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IQModalProps } from 'interfaces/UIComponentsInterfaces';
import { FC } from 'react';

import { QIcon } from 'components/UI/QIcon/QIcon';
import { useCommonStyles } from 'styles/commonStyles';

import { QIconIds } from '../QIcon/QIconIds';
import QScrollbar from '../QScrollbar';

const QModal: FC<IQModalProps> = ({
  headerTitle,
  headerTitleFontVariant,
  centerContent,
  children,
  close,
  PaperProps,
  ...rest
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Handlers
  const handleClose = (e: any, r: any) => {
    e.stopPropagation();
    close?.();
  };

  // Renders
  return (
    <Dialog
      open
      onClose={handleClose}
      PaperProps={{ ...PaperProps, className: clsx(classes.modal, PaperProps?.className) }}
      {...rest}
    >
      <QScrollbar maxHeight={'100vh'}>
        <Box
          className={clsx(classes.header, {
            [classes.headerJustifyBetween]: headerTitle,
            [classes.headerJustifyEnd]: !headerTitle,
            [classes.headerJustifyStart]: rest.fullScreen,
          })}
        >
          {headerTitle && <Typography variant={headerTitleFontVariant || 'h6'}>{headerTitle}</Typography>}
          {close ? (
            <IconButton
              size="medium"
              onClick={e => handleClose(e, null)}
              className={clsx(classes.closeButton, { [classes.transparentCloseButton]: rest.fullScreen })}
            >
              <QIcon
                id={QIconIds.multiply}
                className={rest.fullScreen ? commonClasses.greenIcon : commonClasses.grayIcon}
                width={rest.fullScreen ? 24 : 16}
                height={rest.fullScreen ? 24 : 16}
              />
            </IconButton>
          ) : null}
        </Box>
        <Box className={clsx({ [classes.centerContent]: centerContent })}>{children}</Box>
      </QScrollbar>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  modal: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
    maxWidth: theme.spacing(128),
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerJustifyBetween: {
    justifyContent: 'space-between',
  },
  headerJustifyEnd: {
    justifyContent: 'flex-end',
  },
  headerJustifyStart: {
    justifyContent: 'flex-start',
  },
  centerContent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  closeButton: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.customColors.overlay,
  },
  transparentCloseButton: {
    backgroundColor: 'transparent',
  },
}));

export default QModal;
