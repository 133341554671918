import { Box, FormLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IQPhoneInputProps } from 'interfaces/UIComponentsInterfaces';
import { CountryCode } from 'libphonenumber-js/types';
import React, { FC, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { useRootStore } from 'base/hooks/useRootStore';
import OthersHelper from 'helpers/OthersHelper';

import { Loader } from '../Loader';
import QHelperText from '../QHelperText';

const COUNTRY_CODE_RUSSIAN = 643;

const QPhoneInput: FC<IQPhoneInputProps> = ({
  loading,
  classes,
  containerProps,
  labelProps,
  countryCode,
  onBlur,
  label,
  errors,
  required,
  requiredIcon,
  disableCountrySelect,
  ...props
}) => {
  const { languageToggleStore } = useRootStore();
  const locales = languageToggleStore.localesForPhoneInput as CountryCode[];

  const styles = useStyles(props);

  const [focused, setFocused] = useState(false);
  const ref = useRef<null | HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    if (disableCountrySelect && ref.current) {
      OthersHelper.getFirstElementFromLevel(ref.current, 3)?.setAttribute('disabled', 'true'); // <select class="PhoneInputCountrySelect" disabled="true" />
      OthersHelper.getFirstElementFromLevel(ref.current, 2)?.children[2]?.setAttribute('style', 'display: none'); // select arrow
    }
  }, [ref]);

  // Handlers
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    onBlur?.(e);
  };

  // Renders
  const renderRequiredIcon = () => {
    if (!required) {
      return null;
    }

    return requiredIcon ? requiredIcon : '*';
  };

  return (
    <Box ref={ref} {...containerProps}>
      {label && (
        <FormLabel color={focused ? 'primary' : 'secondary'} {...labelProps}>
          <Box display="flex" alignItems="center">
            {label} {renderRequiredIcon()}
          </Box>
        </FormLabel>
      )}
      {loading ? (
        <Loader size={24} isFlexItem minWidth="100%" />
      ) : (
        <PhoneInput
          {...props}
          labels={languageToggleStore.translatedLabelsForPhoneInput}
          international
          addInternationalOption={false}
          defaultCountry={languageToggleStore.locale.toUpperCase() as CountryCode}
          className={clsx(styles.wrapper, classes, { [styles.error]: !!errors })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          // countries={countryCode === COUNTRY_CODE_RUSSIAN ? locales.filter(locale => locale === 'RU') : locales}
          countries={locales}
        />
      )}
      {errors?.length && (
        <Typography className={styles.errorText}>
          <QHelperText errors={errors} />
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.customColors.overlay,
    padding: theme.spacing(2, 3),
    maxHeight: theme.spacing(10),
    borderRadius: theme.spacing(2),
    '& .PhoneInputInput': {
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      ...theme.typography.body1,
    },
    '& .PhoneInputCountryIcon--border': {
      boxShadow: 'none',
    },
    '& .PhoneInputCountrySelect': {
      backgroundColor: theme.palette.customColors.overlay,
      ...theme.typography.body1,
    },
  },
  error: {
    border: `1px solid ${theme.palette.customColors.statusRed}`,
  },
  errorText: {
    color: theme.palette.customColors.statusRed,
    fontWeight: 400,
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(4),
    letterSpacing: theme.spacing(0.1),
    margin: theme.spacing(0.75, 3.5, 0),
  },
}));

export default QPhoneInput;
