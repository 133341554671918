import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class DocumentsApiRepository extends AbstractApiRepository {
  getDocumentsList = (queryParams: string) => {
    return this.apiClient.get({ url: `/approval-document/list${queryParams}` });
  };

  getDocumentToShow = (queryParams: string) => {
    return this.apiClient.get({ url: `/approval-document/show${queryParams}` });
  };

  getDocumentValidationRules = () => {
    return this.apiClient.get({ url: '/approval-document/validation-rules' });
  };

  uploadDocument = (data: FormData) => {
    return this.apiClient.post({ url: '/approval-document/upload', data });
  };

  downloadAllDocuments = (queryParams: string) => {
    return this.apiClient.get({
      url: `/approval-document/download-all${queryParams}`,
      config: { responseType: 'blob' },
    });
  };

  downloadDocument = (queryParams: string) => {
    return this.apiClient.get({ url: `/approval-document/download${queryParams}`, config: { responseType: 'blob' } });
  };

  deleteAllDocuments = (queryParams: string) => {
    return this.apiClient.get({ url: `/approval-document/delete-all${queryParams}` });
  };

  deleteDocument = (queryParams: string) => {
    return this.apiClient.get({ url: `/approval-document/delete${queryParams}` });
  };
}
