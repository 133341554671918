import { IAuthTokenData } from 'modules/auth/interfaces/AuthInterfaces';

import TokenLocalRepository from './repositories/TokenLocalRepository';

export default class TokenLocalService {
  tokenLocal: TokenLocalRepository;

  constructor() {
    this.tokenLocal = new TokenLocalRepository();
  }

  saveToken = (token: IAuthTokenData) => {
    this.tokenLocal.set(token);
  };

  deleteToken = () => {
    this.tokenLocal.remove();
  };

  getToken = (): IAuthTokenData | null => {
    const token = this.tokenLocal.get();

    return token ? (token as unknown as IAuthTokenData) : null;
  };
}
