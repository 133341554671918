export default class Utilities {
  static curry(func: Function): Function {
    return function curried(this: any, ...args: any[]) {
      if (args.length >= func.length) {
        return func.apply(this, args);
      }

      return (...args2: any[]) => {
        return curried.apply(this, args.concat(args2));
      };
    };
  }

  static uncurry(func: Function) {
    return function (...args: any[]) {
      return args.reduce((func: Function, arg: any) => func(arg), func);
    };
  }

  static isObjectEmpty<T extends object>(object: T) {
    return JSON.stringify(object) === '{}';
  }

  static range(start: number, end: number) {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }

  static primitiveArraysEqual(a: string[] | number[], b: string[] | number[]) {
    if (!a || !b) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    if (JSON.stringify(a) !== JSON.stringify(b)) {
      return false;
    }

    return true;
  }

  static getTextArrayFromEnters = (text: string) => {
    return text.split('\n');
  };
}
