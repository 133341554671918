import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { LocalizationDto } from './dto/LocalizationDto';

export default class LanguageToggleApiRepository extends AbstractApiRepository {
  getLanguageList = () => {
    return this.apiClient.get({ url: '/translators/index' });
  };

  getLocalization = (data: LocalizationDto) => {
    return this.apiClient.post({ url: '/translators/localization', data });
  };
}
