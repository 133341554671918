import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC, PropsWithChildren, useEffect } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';

import BackgroundImage from 'assets/images/content/auth-screens-bg.jpg';

interface IAuthScreenProps {}

const AuthScreen: FC<PropsWithChildren<IAuthScreenProps>> = observer(({ children }) => {
  const { authStore } = useRootStore();
  const classes = useStyles();

  // Effects
  useEffect(() => {
    authStore.setIsMountedBackground(true);
  }, []);

  // Renders
  return (
    <div className={classes.containerWrap}>
      <div className={clsx(classes.containerBG, { [classes.showBG]: authStore.isMountedBackground })} />
      {!authStore.isCheckingServerStatus && <Box className={classes.cardWrap}>{children}</Box>}
    </div>
  );
});

export default AuthScreen;

const useStyles = makeStyles(theme => ({
  containerWrap: {
    position: 'relative',
    height: '100dvh',
    width: '100dvw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  containerBG: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'inherit',
    height: 'inherit',
    zIndex: -1,
    background: `center / cover no-repeat url(${BackgroundImage})`,
    filter: `blur(${theme.spacing(2)})`,
    transition: theme.transitions.create('filter', { duration: 600, easing: 'ease-in-out', delay: 200 }),
  },
  showBG: {
    filter: 'blur(0)',
  },
  cardWrap: {
    maxWidth: theme.spacing(96),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4),
      borderRadius: 0,
      height: 'inherit',
      overflow: 'auto',
    },
    padding: theme.spacing(4, 6),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
  },
}));
