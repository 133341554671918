import { jsonProperty, Serializable } from 'ts-serializable';

import { HardwareAddInfo } from './HardwareAddInfo';

export class Hardware extends Serializable {
  @jsonProperty(Number) hwiid: number = 0;
  @jsonProperty(String) devcname: string = '';
  @jsonProperty(String) hwnameshort: string = '';
  @jsonProperty(String) hwnamefull: string = '';
  @jsonProperty(String) serialnumber1: string = '';
  @jsonProperty(String, null) physicalobjectname: string | null = null;
  @jsonProperty(Number, null) physicalhwilobjid: number | null = null;
  @jsonProperty(Number, null) devcid: number | null = null;
  @jsonProperty(Number) hwvenid: number = 0;
  @jsonProperty(Number) storetype: number = 0;
  @jsonProperty(Array) addinfo: HardwareAddInfo[] = [];
}
