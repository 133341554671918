import { IQTextFieldWithControllerProps } from 'interfaces/UIComponentsInterfaces';
import { Controller } from 'react-hook-form';

import QTextField from './QTextField';

const QTextFieldWithController: React.FC<IQTextFieldWithControllerProps> = props => {
  const { errors, name, hookFormProps, controllerProps, ...rest } = props;

  return (
    <Controller
      name={name}
      control={hookFormProps.control}
      render={({ field, formState }) => {
        const getErrors = (): string | string[] => {
          if (errors === '' || typeof errors === 'string' || Array.isArray(errors)) {
            return errors;
          }

          return formState.errors[name]?.message;
        };

        return (
          <QTextField errors={getErrors()} {...rest} {...field} onBlur={rest.onBlur ? rest.onBlur : field.onBlur} />
        );
      }}
      {...controllerProps}
    />
  );
};

export default QTextFieldWithController;
