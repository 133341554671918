import { IQPhoneInputWithControllerProps } from 'interfaces/UIComponentsInterfaces';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import QPhoneInput from './QPhoneInput';

const QPhoneInputWithController: FC<IQPhoneInputWithControllerProps> = ({ name, label, hookFormProps, ...rest }) => {
  // Renders
  return (
    <Controller
      name={name}
      control={hookFormProps.control}
      render={({ field, formState }) => {
        return (
          <QPhoneInput
            value={field.value}
            onChange={field.onChange}
            errors={formState.errors[name]}
            label={label}
            {...rest}
          />
        );
      }}
    />
  );
};

export default QPhoneInputWithController;
