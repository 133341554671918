import { Box, CircularProgress, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

interface ILoaderProps {
  size?: number;
  minHeight?: string | number;
  minWidth?: string | number;
  isAbsolute?: boolean;
  isFlexItem?: boolean;
  color?: string;
}

export const Loader: React.FC<ILoaderProps> = ({
  size = 40,
  minHeight = 0,
  minWidth,
  isAbsolute = false,
  color,
  isFlexItem = false,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const rendeLoader = () => {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Box display="flex" color={color || palette.primary.main}>
          <CircularProgress size={size} color="inherit" />
        </Box>
      </Grid>
    );
  };

  if (isAbsolute) {
    return (
      <Box className={classes.isAbsolute} minHeight={minHeight}>
        <div className={classes.overline}></div>

        {rendeLoader()}
      </Box>
    );
  }

  return (
    <Box className={clsx(classes.root, { [classes.isFlexItem]: isFlexItem })} minHeight={minHeight} minWidth={minWidth}>
      {rendeLoader()}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexBasis: '100%',
  },
  isFlexItem: {
    flexBasis: 'unset',
    flex: 'unset',
    flexGrow: 1,
  },
  isAbsolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  overline: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.customColors.surfaceTertiary,
    zIndex: -1,
  },
}));
