import { jsonProperty, Serializable } from 'ts-serializable';

export default class LoginDto extends Serializable {
  @jsonProperty(String) login: string = '';
  @jsonProperty(String) pass: string = '';
  @jsonProperty(String) udid: string = '';
  @jsonProperty(String) osVersion: string = '';
  @jsonProperty(String) deviceModel: string = '';
  @jsonProperty(String, null) mfacode: string | null = null;
  @jsonProperty(Number, null) clientid: number | null = null;
  @jsonProperty(Number, null) messengertypeid: number | null = null;
}
