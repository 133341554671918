import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { ChangeClientDto } from './dto/ChangeClientDto';
import LoginDto from './dto/LoginDto';
import NewAccessTokenDto from './dto/NewAccessTokenDto';

export default class AuthApiRepository extends AbstractApiRepository {
  login = (data: LoginDto) => {
    return this.apiClient.post({ url: '/auth/login', data });
  };

  changeClient = (data: ChangeClientDto) => {
    return this.apiClient.post({ url: '/auth/change-client', data });
  };

  getAuthInfo = () => {
    return this.apiClient.get({ url: '/auth/info' });
  };

  getNewAccessToken = (data: NewAccessTokenDto) => {
    return this.apiClient.post({ url: `/auth/new-access-token`, data });
  };

  logout = () => {
    return this.apiClient.get({ url: '/auth/logout' });
  };

  getServerStatus = () => {
    return this.apiClient.get({ url: '/server/status' });
  };
}
