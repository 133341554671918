export enum LightThemeColors {
  background = '#F5F5F5',
  surface = '#fff',
  surfaceTertiary = 'rgba(0, 0, 0, 0.32)',
  border = 'rgba(0, 0, 0, 0.1)',
  overlay = 'rgba(33, 33, 33, 0.06)',
  overlay2 = 'rgba(0, 0, 0, 0.16)',
  modalOverlay = 'rgba(0, 0, 0, 0.6)',
  partnersProgram = 'radial-gradient(73.08% 771.87% at 73.08% 50%, #1C4D3E 0%, #06281E 100%)',

  primaryMain = '#63B759',
  primaryDark = '#35902E',
  primaryLight = '#D0E9CD',

  secondaryMain = 'rgba(63, 143, 54, 0.08)',
  secondaryDark = 'rgba(78, 168, 67, 0.16)',

  active = 'rgba(0, 0, 0, 0.54)',
  hover = 'rgba(0, 0, 0, 0.04)',
  focus = 'rgba(0, 0, 0, 0.12)',
  disabled = 'rgba(0, 0, 0, 0.26)',
  disabledBackground = 'rgba(0, 0, 0, 0.12)',
  selected = 'rgba(0, 0, 0, 0.06)',

  errorMain = '#F44336',
  errorDark = '#E31B0C',
  errorLight = '#F88078',

  statusRed = '#FC4141',
  statusGreen = '#2E906D',
  statusYellow = '#F7A507',
  statusGrey = '#989898',

  shadowColor = 'rgba(0, 0, 0, 0.08)',
}
