import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class ServiceApiRepository extends AbstractApiRepository {
  getService = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/info${query}` });
  };

  closeService = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/close${query}` });
  };
}
