import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';

import { LocalizationDto } from './dto/LocalizationDto';

export class LanguageToggleFactory extends ModelFactory {
  getLocalizationDto = (localeCode: string, versionFrom: string) => {
    return Dto.populate<LocalizationDto>(LocalizationDto, { localeCode, versionFrom, deviceType: 2 });
  };
}
