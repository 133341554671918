import { makeAutoObservable, reaction, runInAction } from 'mobx';

import Notification from 'base/ui/Notification';

import { MFAService } from './MFAService';
import { MFAForm } from './forms/MFAForm';
import { MFAAccessibleMessenger } from './models/MFAAccessibleMessenger';
import { MFACodeSendResponse } from './models/MFACodeSendResponse';

export class MFAStore {
  isLoadingStatus: boolean = false;
  isLoadingAccessibleMessengers: boolean = false;
  isTurningOnOff: boolean = false;
  isSettingDefault: boolean = false;
  isSendingTestMessage: boolean = false;

  date: number | null = null;

  status: boolean | null = null;
  accessibleMessengers: MFAAccessibleMessenger[] | null = null;
  currentAccessibleMessenger: MFAAccessibleMessenger | null = null;

  mfaForm: MFAForm = MFAForm.create();
  codeSendResponse: MFACodeSendResponse | null = null;

  private MFAService: MFAService;

  constructor() {
    makeAutoObservable(this);
    this.MFAService = new MFAService();

    reaction(() => this.status, this.loadAccessibleMessengers);
  }

  get enabledAccessibleMessengers() {
    return this.accessibleMessengers?.filter(messenger => messenger.isEnabled);
  }

  get deadline() {
    return Number(this.codeSendResponse?.delay).valueOf() * 1000 + Number(this.date).valueOf();
  }

  getListOfAccessibleMessengers = (callback?: () => void) => {
    if (this.isLoadingAccessibleMessengers) {
      return;
    }
    this.setIsLoadingAccessibleMessengers(true);

    this.MFAService.getListOfAccessibleMessengers()
      .then(accessibleMessengers => {
        this.setAccessibleMessengers(accessibleMessengers);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsLoadingAccessibleMessengers(false);
      });
  };

  getMFAStatus = () => {
    if (this.isLoadingStatus) {
      return;
    }
    this.setIsLoadingStatus(true);

    this.MFAService.getMFAStatus()
      .then(status => {
        this.setStatus(status);

        if (status) {
          this.getListOfAccessibleMessengers();
        }
      })
      .catch(() => {})
      .finally(() => {
        this.setIsLoadingStatus(false);
      });
  };

  sendTestMessage = async (messengertypeid: number, callback?: () => void) => {
    this.setIsSendingTestMessage(true);

    this.MFAService.sendTestMessage(messengertypeid)
      .then(({ message }) => {
        Notification.showSuccess(message);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsSendingTestMessage(false);
      });
  };

  turnMFAOn = (data: MFAForm, callback?: () => void) => {
    if (this.isTurningOnOff) {
      return;
    }
    this.setCodeSendResponse(null);
    this.setIsTurningOnOff(true);

    this.MFAService.turnMFAOn(data)
      .then(codeSendResponse => {
        this.setCodeSendResponse(codeSendResponse);

        runInAction(() => {
          this.date = Date.now();
        });

        if (codeSendResponse.final) {
          this.getListOfAccessibleMessengers();
          this.getMFAStatus();
        }
      })
      .catch(() => {})
      .finally(() => {
        callback?.();
        this.setIsTurningOnOff(false);
      });
  };

  turnMFAOff = (messengertypeid: number) => {
    this.setIsTurningOnOff(true);

    this.MFAService.turnMFAOff(messengertypeid)
      .then(() => {
        this.getListOfAccessibleMessengers();
        this.getMFAStatus();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsTurningOnOff(false);
      });
  };

  turnLastMFAOff = (callback?: () => void) => {
    this.setIsTurningOnOff(true);

    this.MFAService.turnLastMFAOff()
      .then(() => {
        this.getListOfAccessibleMessengers();
        this.getMFAStatus();
      })
      .catch(() => {})
      .finally(() => {
        this.setIsTurningOnOff(false);
        callback?.();
      });
  };

  setDefaultMessenger = (messengertypeid: number, callback?: () => void) => {
    this.setIsSettingDefault(true);

    this.MFAService.setDefaultMessenger(messengertypeid)
      .then(() => {
        this.getListOfAccessibleMessengers(callback);
      })
      .catch(() => {})
      .finally(() => {
        this.setIsSettingDefault(false);
      });
  };

  private loadAccessibleMessengers = (status: boolean | null) => {
    if (status) {
      this.getListOfAccessibleMessengers();
    }
  };

  // Loadings
  setIsLoadingStatus = (isLoading: boolean) => {
    this.isLoadingStatus = isLoading;
  };

  setIsSendingTestMessage = (state: boolean) => {
    this.isSendingTestMessage = state;
  };

  setIsLoadingAccessibleMessengers = (state: boolean) => {
    this.isLoadingAccessibleMessengers = state;
  };

  setIsTurningOnOff = (state: boolean) => {
    this.isTurningOnOff = state;
  };

  setIsSettingDefault = (state: boolean) => {
    this.isSettingDefault = state;
  };

  // Setters
  setStatus = (status: boolean) => {
    this.status = status;
  };

  setAccessibleMessengers = (accessibleMessengers: MFAAccessibleMessenger[] | null) => {
    this.accessibleMessengers = accessibleMessengers;
  };

  setCurrentAccessibleMessenger = (accessibleMessengers: MFAAccessibleMessenger | null) => {
    this.currentAccessibleMessenger = accessibleMessengers;
  };

  setCodeSendResponse = (codeSendResponse: MFACodeSendResponse | null) => {
    this.codeSendResponse = codeSendResponse;
  };
}
