import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';
import { Language } from 'modules/language-toggle/models/Language';

import UpdateUserLanguageDto from './dto/UpdateUserLanguageDto';
import { IUpdateUserLanguage } from './interfaces/UsersInterfaces';
import { CurrentUser } from './models/CurrentUser';

export default class ProfileFactory extends ModelFactory {
  createUser = (data: any) => {
    const user = this.create<CurrentUser>(CurrentUser, data);

    if (data?.language) {
      user.language = this.create<Language>(Language, data.language);
    }

    return user;
  };

  prepareUpdateUserLangugeDto = (data: IUpdateUserLanguage) => {
    return Dto.populate<UpdateUserLanguageDto>(UpdateUserLanguageDto, data);
  };
}
