export enum DarkThemeColors {
  background = '#1B2426',
  surface = '#33393F',
  surfaceTertiary = 'rgba(255, 255, 255, 0.5)',
  border = 'rgba(255, 255, 255, 0.12)',
  overlay = 'rgba(255, 255, 255, 0.06)',
  overlay2 = 'rgba(255, 255, 255, 0.16)',
  modalOverlay = 'rgba(255, 255, 255, 0.6)',
  partnersProgram = 'radial-gradient(73.08% 771.87% at 73.08% 50%, #39BA92 0%, #007551 100%)',

  primaryMain = '#86DB7C',
  primaryDark = '#61CF59',
  primaryLight = '#EFFFED',

  secondaryMain = 'rgba(148, 222, 139, 0.08)',
  secondaryDark = 'rgba(148, 222, 139, 0.16)',

  active = 'rgba(255, 255, 255, 0.54)',
  hover = 'rgba(255, 255, 255, 0.04)',
  focus = 'rgba(255, 255, 255, 0.12)',
  disabled = 'rgba(255, 255, 255, 0.26)',
  disabledBackground = 'rgba(255, 255, 255, 0.12)',
  selected = 'rgba(255, 255, 255, 0.06)',

  errorMain = '#FF5F53',
  errorDark = '#FF4234',
  errorLight = '#FFBBB6',

  statusRed = '#FF4F4F',
  statusGreen = '#52DFAC',
  statusYellow = '#FFBE42',
  statusGrey = '#BEBEBE',

  shadowColor = 'rgba(255, 255, 255, 0.08)',
}
