import { jsonProperty, Serializable } from 'ts-serializable';

export class PowerHistoryModel extends Serializable {
  @jsonProperty(Number, null) untid: number | null = null;
  @jsonProperty(Number, null) status: number | null = null;
  @jsonProperty(Number, null) objectid: number | null = null;
  @jsonProperty(Number, null) userid: number | null = null;
  @jsonProperty(String, null) fio: string | null = null;
  @jsonProperty(String, null) taskdescr: string | null = null;
  @jsonProperty(String, null) statusdescr: string | null = null;
  @jsonProperty(String, null) historystatusdescr: string | null = null;
  @jsonProperty(String, null) conntype: string | null = null;
  @jsonProperty(String, null) dtcreate: string | null = null;
  @jsonProperty(String, null) starttime: string | null = null;
  @jsonProperty(String, null) endtime: string | null = null;
}
