import { jsonProperty, Serializable } from 'ts-serializable';

export class UserDto extends Serializable {
  @jsonProperty(String) password: string = '';
  @jsonProperty(Number, null) id: number | null = null;
  @jsonProperty(String) confirm: string = '';
  @jsonProperty(String) codeword: string = '';
  @jsonProperty(String) email1: string = '';
  @jsonProperty(String) email2: string = '';
  @jsonProperty(String) phone2: string = '';
  @jsonProperty(Number) phone2add: number = 0;
  @jsonProperty(String) phone1: string = '';
  @jsonProperty(Number) phone1add: number = 0;
  @jsonProperty(String) phone3: string = '';
  @jsonProperty(Number) phone3add: number = 0;
  @jsonProperty(String) phone4: string = '';
  @jsonProperty(Number) phone4add: number = 0;
  @jsonProperty(String) phone5: string = '';
  @jsonProperty(Number) phone5add: number = 0;
  @jsonProperty(String) lastname: string = '';
  @jsonProperty(String) firstname: string = '';
  @jsonProperty(String) midname: string = '';
  @jsonProperty(String) paspbirth: string = '';
  @jsonProperty(String) dolgnost: string = '';
  @jsonProperty(Number) paspseria: number = 0;
  @jsonProperty(Number) paspnumber: number = 0;
  @jsonProperty(String) paspkemvid: string = '';
  @jsonProperty(String) paspkodpodrazd: string = '';
  @jsonProperty(String) paspdtvid: string = '';
}
