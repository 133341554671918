import { AddressAddInfoTypes, AddressTypes } from '../enums/AddressesEnums';

export class AddressesHelper {
  static readonly addressesAddInfoFieldNames = {
    [AddressTypes.uaddress]: AddressAddInfoTypes.uaddinfo,
    [AddressTypes.faddress]: AddressAddInfoTypes.faddinfo,
    [AddressTypes.paddress]: AddressAddInfoTypes.paddinfo,
  };

  static isAddressField(name: string) {
    return name === AddressTypes.faddress || name === AddressTypes.uaddress || name === AddressTypes.paddress;
  }

  static isAddressAddInfoField(name: string) {
    return (
      name === AddressAddInfoTypes.faddinfo ||
      name === AddressAddInfoTypes.uaddinfo ||
      name === AddressAddInfoTypes.paddinfo
    );
  }
}
