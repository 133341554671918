import { FieldValues } from 'react-hook-form/dist/types';

import Dto from 'base/Dto';
import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';
import FieldsFactory from 'base/modules/fields/FieldsFactory';
import FileValidationFactory from 'base/modules/file-validation/FileValidationFactory';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';

import UsersApiRepository from './UsersApiRepository';
import UsersFactory from './UsersFactory';
import { ResetPasswordDto } from './dto/ResetPasswordDto';
import { ResetPasswordForm } from './forms/ResetPasswordForm';
import { UserForm } from './forms/UserForm';
import { UsersTableForm } from './forms/UsersTableForm';
import { CreatedUser } from './models/CreatedUser';
import { PhoneCheckResponse } from './models/PhoneCheckResponse';
import { UserAvailableContacts } from './models/UserAvailableContacts';

export default class UsersService {
  private usersApi: UsersApiRepository;
  private usersFactory: UsersFactory;
  private fieldsFactory: FieldsFactory;
  private fileValidationFactory: FileValidationFactory;
  private paginationService: PaginationService;

  constructor() {
    this.usersApi = new UsersApiRepository();
    this.usersFactory = new UsersFactory();
    this.fieldsFactory = new FieldsFactory();
    this.fileValidationFactory = new FileValidationFactory();
    this.paginationService = new PaginationService();
  }

  getUsers = async ({ page, limit, sortValue }: UsersTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);
    const query = ApiHelper.getQueryString({ page, limit, ...sort });

    const { data } = await this.usersApi.getUsers(query);

    const items = this.usersFactory.createUsersList(data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };

  getUser = async (userid: number) => {
    const query = ApiHelper.getQueryString({ userid });

    const { data } = await this.usersApi.getUser(query);

    return this.usersFactory.createUser(data.data);
  };

  getUserFields = async (userid: number | null) => {
    const query = ApiHelper.getQueryString({ userid });

    const { data } = await this.usersApi.getUserFields(query);

    return this.fieldsFactory.createFieldsList(data.data[0].fields);
  };

  getValidationRules = async () => {
    const { data } = await this.usersApi.getValidationRules();

    return this.fileValidationFactory.createFileValidationRules(data.data);
  };

  createUser = async (form: UserForm) => {
    const dto = this.usersFactory.getUserDto(form);

    const { data } = await this.usersApi.createUser(dto);

    return this.usersFactory.create<CreatedUser>(CreatedUser, data.data);
  };

  checkUserPhone = async (form: any) => {
    const dto = this.usersFactory.getCheckUserPhoneDto(form);

    const { data } = await this.usersApi.checkUserPhone(dto);

    return this.usersFactory.create<PhoneCheckResponse>(PhoneCheckResponse, data.data);
  };

  updateUser = async (form: any) => {
    const dto = this.usersFactory.getUserUpdateDto(form);

    const formData = new FormData();

    Object.entries(dto.data).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    const { data } = await this.usersApi.updateUser(formData);

    return this.usersFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  deleteUser = async (userid: number) => {
    const dto = this.usersFactory.getUserUpdateDto({ userid });
    const { data } = await this.usersApi.deleteUser(dto.data);

    return this.usersFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  deleteUserAvatar = async (userid: number) => {
    const dto = this.usersFactory.getUserUpdateDto({ userid });
    const { data } = await this.usersApi.deleteUserAvatar(dto.data);

    return this.usersFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  validateUserData = async (form: FieldValues) => {
    const { data } = this.usersFactory.createValidateRequisitesDto(form);
    const response = await this.usersApi.validateUserData(data);

    return this.usersFactory.create<ResponseSuccess>(ResponseSuccess, response.data);
  };

  getUserContactsAvailable = async (userId: number, forNotifications?: boolean) => {
    const query = ApiHelper.getQueryString({ userId, notificationType: forNotifications ? '1' : '0' });

    const { data } = await this.usersApi.getContactsAvailable(query);

    return this.usersFactory.createList<UserAvailableContacts>(UserAvailableContacts, data?.data);
  };

  getEmptyField = (data: any) => {
    return this.fieldsFactory.createField(data);
  };

  resetPassword = async (form: ResetPasswordForm) => {
    const dto = Dto.populate(ResetPasswordDto, {
      ...form,
      password: form.password || null,
      code: form.code || null,
      confirm: form.confirm || null,
    });
    const { data } = await this.usersApi.resetPassword(dto);

    return this.usersFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };
}
