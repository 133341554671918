import ApiHelper from 'base/helpers/ApiHelper';

import { AddressesApiRepository } from './AddressesApiRepository';
import AddressesFactory from './AddressesFactory';
import { IAddressReferenceQuery } from './interfaces/AddressesInterfaces';
import { Address } from './models/Address';

export class AddressesService {
  private addressesApi: AddressesApiRepository;
  private addressesFactory: AddressesFactory;

  constructor() {
    this.addressesApi = new AddressesApiRepository();
    this.addressesFactory = new AddressesFactory();
  }

  getAddresses = async (query: any) => {
    const dto = this.addressesFactory.createAdderssesDto(query);
    const { data } = await this.addressesApi.getAddresses(dto);

    return data.data as Address[];
  };

  getAddressReferences = async (queryParams: IAddressReferenceQuery) => {
    const queryString = ApiHelper.getQueryString(queryParams);

    const { data } = await this.addressesApi.getAddressReferences(queryString);

    return this.addressesFactory.createAddressReferences(queryParams.addressType, data.data);
  };
}
