import AbstractApiRepository from 'base/api/AbstractApiRepository';

import UpdateUserLanguageDto from './dto/UpdateUserLanguageDto';

export default class ProfileApiRepository extends AbstractApiRepository {
  getUserInfo = () => {
    return this.apiClient.get({ url: '/user/info' });
  };

  updateUserLanguage = (data: UpdateUserLanguageDto) => {
    return this.apiClient.post({ url: '/user/update', data });
  };
}
