import { jsonProperty, Serializable } from 'ts-serializable';

import { DocumentFile } from './file/DocumentFile';

export class DocumentToShow extends Serializable {
  @jsonProperty(Number) csdtid: number = 0;
  @jsonProperty(String, null) documentname: string | null = null;
  @jsonProperty(Boolean, null) verified: boolean | null = null;
  @jsonProperty(Boolean, null) needverify: boolean | null = null;
  @jsonProperty(Boolean, null) deletionasked: boolean | null = null;
  @jsonProperty(Boolean, null) denied: boolean | null = null;
  @jsonProperty(String, null) dtvalidity: string | null = null;
  @jsonProperty(Number, null) countfiles: number | null = null;
  @jsonProperty(String, null) description: string | null = null;
  @jsonProperty(Array, null) files: DocumentFile[] | null = null;
}
