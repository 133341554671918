import { jsonProperty, Serializable } from 'ts-serializable';

import { Currency } from './Currency';

export class PaymentMethod extends Serializable {
  @jsonProperty(String) paytype: string = '';
  @jsonProperty(String) paydesc: string = '';
  @jsonProperty(Array) availableCurrencies: Currency[] = [];
  @jsonProperty(String, null) description: string | null = null;
  @jsonProperty(String) iconBase64: string = '';
}
