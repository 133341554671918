import { ModelFactory } from 'base/ModelFactory';

import { AccessIp } from './models/AccessIp';
import { UnitIpmiModel } from './models/UnitIpmiModel';

export class UnitFactory extends ModelFactory {
  createUnitIpmiModel = (data: any) => {
    const unitIpmiModel = this.create<UnitIpmiModel>(UnitIpmiModel, data);

    if (data.accessIps) {
      unitIpmiModel.accessIps = this.createList<AccessIp>(AccessIp, data.accessIps);
    }

    return unitIpmiModel;
  };

  createUnitIpmiModelList = (data: any[]) => {
    return data.map(this.createUnitIpmiModel);
  };
}
