import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { Loader } from 'components/UI/Loader';
import PasswordTextField from 'components/UI/TextFields/PasswordTextField';
import { ResetPasswordFormFields } from 'modules/users/forms/ResetPasswordForm';
import { useCommonStyles } from 'styles/commonStyles';

interface IResetPasswordStepProps {
  isLoading: boolean;
  errors: IErrorData | null;
}

export const ResetPasswordStep: React.FC<IResetPasswordStepProps> = ({ isLoading, errors }) => {
  const {
    languageToggleStore: { translations },
  } = useRootStore();

  const commonClasses = useCommonStyles();

  const { control, formState, setError, setValue, trigger } = useFormContext();
  const { isDirty, isValid } = formState;

  const passwordErrorMessage = formState.errors[ResetPasswordFormFields.password]?.message;
  const confirmErrorMessage = formState.errors[ResetPasswordFormFields.confirm]?.message;

  // Effects
  useEffect(() => {
    if (errors?.[ResetPasswordFormFields.password]) {
      setError(ResetPasswordFormFields.password, { message: errors[ResetPasswordFormFields.password] });
    }

    if (errors?.[ResetPasswordFormFields.confirm]) {
      setError(ResetPasswordFormFields.confirm, { message: errors[ResetPasswordFormFields.confirm] });
    }
  }, [errors]);

  // Renders
  return (
    <>
      <Box mb={6} pt={4}>
        <PasswordTextField
          name={ResetPasswordFormFields.password}
          hookFormProps={{ control, setValue, trigger }}
          errors={passwordErrorMessage}
          endIconClassName={commonClasses.greenIcon}
        />
      </Box>
      <Box mb={10}>
        <PasswordTextField
          name={ResetPasswordFormFields.confirm}
          hookFormProps={{ control }}
          errors={confirmErrorMessage}
          endIconClassName={commonClasses.greenIcon}
        />
      </Box>
      <Button
        variant="contained-contrast"
        color="primary"
        fullWidth
        type="submit"
        disabled={isLoading || !isDirty || !isValid}
      >
        {isLoading ? <Loader color="#fff" size={24} /> : translations.change}
      </Button>
    </>
  );
};
