import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ISidebarProps } from 'interfaces/UIComponentsInterfaces';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { QIcon } from 'components/UI/QIcon/QIcon';
import { QIconIds } from 'components/UI/QIcon/QIconIds';
import QScrollbar from 'components/UI/QScrollbar';
import { ThemeTypes } from 'modules/theme-toggle/types/ThemeToggleTypes';
import { useCommonStyles } from 'styles/commonStyles';

import SidebarMenuList from './components/SidebarMenuList';

import LogoWhite from 'assets/images/vector/mobile-logo-white.svg';
import Logo from 'assets/images/vector/mobile-logo.svg';

const MobileSidebar: FC<ISidebarProps> = observer(({ isOpen, onToggle }) => {
  const {
    themeToggleStore: { theme },
  } = useRootStore();

  const commonClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <QScrollbar
      width="100dvw"
      minHeight="100dvh"
      maxHeight="100dvh"
      component="aside"
      className={clsx(commonClasses.sidebar, classes.sidebar, { [classes.hidden]: !isOpen })}
    >
      <Box className={commonClasses.sidebarLogoBlock}>
        <IconButton onClick={onToggle}>
          <QIcon id={QIconIds.multiply} className={commonClasses.greenIcon} />
        </IconButton>
        <Link to={routes.MainScreen.path}>
          <img src={theme === ThemeTypes.DARK ? LogoWhite : Logo} alt="mobile-logo" />
        </Link>
      </Box>
      <SidebarMenuList isMobile isOpen={isOpen} closeSidebarMenu={onToggle} />
    </QScrollbar>
  );
});

const useStyles = makeStyles(theme => ({
  sidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  hidden: {
    left: '-100dvw',
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}));

export default MobileSidebar;
