import { jsonProperty, Serializable } from 'ts-serializable';

import { MFAMessengerType } from './MFAMessengerType';

export class MFA extends Serializable {
  @jsonProperty(Number, null) status: number | null = null;
  @jsonProperty(Number, null) messengertypeid: number | null = null;
  @jsonProperty(Number, null) delay: number | null = null;
  @jsonProperty(String, null) fullname: string | null = null;
  @jsonProperty(String, null) phone: string | null = null;
  @jsonProperty(Array, null) messengersTypes: MFAMessengerType[] | null = null;
}
