import AbstractApiRepository from 'base/api/AbstractApiRepository';
import { IAuthTokenData } from 'modules/auth/interfaces/AuthInterfaces';

export default class TokenApiRepository extends AbstractApiRepository {
  setAccessToken = (token: IAuthTokenData) => {
    this.apiClient.setAccessToken(token);
  };

  clearAccessToken = () => {
    this.apiClient.clearAccessToken();
  };
}
