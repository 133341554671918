import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { AddCarDto } from './dto/AddCarDto';
import { AddVisitorDto } from './dto/AddVisitorDto';
import { PassDeleteDto } from './dto/PassDeleteDto';
import { PassSaveDto } from './dto/PassSaveDto';

export default class PassesApiRepository extends AbstractApiRepository {
  getPassesList = (query: string) => {
    return this.apiClient.get({ url: `/passes/list${query}` });
  };

  getDataCenterList = () => {
    return this.apiClient.get({ url: '/passes/dc-list' });
  };

  getVisitorsList = () => {
    return this.apiClient.get({ url: '/passes/visitors' });
  };

  getCarsList = () => {
    return this.apiClient.get({ url: '/passes/cars' });
  };

  savePass = (data: PassSaveDto) => {
    return this.apiClient.post({ url: '/passes/save', data });
  };

  deletePass = (data: PassDeleteDto) => {
    return this.apiClient.post({ url: '/passes/delete', data });
  };

  addCar = (data: AddCarDto) => {
    return this.apiClient.post({ url: '/passes/add-car', data });
  };

  addVisitor = (data: AddVisitorDto) => {
    return this.apiClient.post({ url: '/passes/add-visitor', data });
  };
}
