import { ModelFactory } from 'base/ModelFactory';
import { ServiceFactory } from 'modules/service/ServiceFactory';

export class ServicesFactory extends ModelFactory {
  serviceFactory: ServiceFactory;
  constructor() {
    super();
    this.serviceFactory = new ServiceFactory();
  }

  createServices = (data: any[]) => {
    if (!data?.length) {
      return [];
    }

    return data.map(this.serviceFactory.createService);
  };
}
