import AbstractApiRepository from 'base/api/AbstractApiRepository';
import { DataPatch } from 'base/models/CommonModels';

import { CheckUserPhoneDto } from './dto/CheckUserPhoneDto';
import { ResetPasswordDto } from './dto/ResetPasswordDto';
import { UserDto } from './dto/UserDto';

export default class UsersApiRepository extends AbstractApiRepository {
  getUsers = (query: string) => {
    return this.apiClient.get({ url: `user/index${query}` });
  };

  getUser = (query: string) => {
    return this.apiClient.get({ url: `user/item${query}` });
  };

  getUserFields = (query: string) => {
    return this.apiClient.get({ url: `user/show${query}` });
  };

  getValidationRules = () => {
    return this.apiClient.get({ url: '/user/avatar-validation-rules' });
  };

  checkUserPhone = (data: CheckUserPhoneDto) => {
    return this.apiClient.post({ url: '/user/phone-check', data });
  };

  createUser = (data: UserDto) => {
    return this.apiClient.post({ url: `user/create`, data });
  };

  deleteUser = (data: DataPatch) => {
    return this.apiClient.post({ url: `user/delete`, data });
  };

  deleteUserAvatar = (data: DataPatch) => {
    return this.apiClient.post({ url: `user/delete-avatar`, data });
  };

  updateUser = (data: FormData) => {
    return this.apiClient.post({ url: `user/update`, data });
  };

  validateUserData = (data: Record<string, string | null>) => {
    return this.apiClient.post({ url: '/user/validate', data });
  };

  resetPassword = (data: ResetPasswordDto) => {
    return this.apiClient.post({ url: '/user/reset-password', data });
  };

  getContactsAvailable = (query: string) => {
    return this.apiClient.get({ url: `/user/contacts-available${query}` });
  };
}
