import AbstractApiRepository from 'base/api/AbstractApiRepository';
import { DataPatch } from 'base/models/CommonModels';

export class ClientsApiRepository extends AbstractApiRepository {
  getClientsList = () => {
    return this.apiClient.get({ url: '/auth/clients' });
  };

  getClientSummary = () => {
    return this.apiClient.get({ url: '/client/summary' });
  };

  getClientDocuments = () => {
    return this.apiClient.get({ url: '/client/documents' });
  };

  getClientDocument = (queryParams: string) => {
    return this.apiClient.get({ url: `/client/document-download${queryParams}`, config: { responseType: 'blob' } });
  };

  getClientRequisitesList = () => {
    return this.apiClient.get({ url: '/client/requisites-list' });
  };

  updateClientRequisites = (data: DataPatch) => {
    return this.apiClient.post({ url: '/client/requisites-update', data });
  };

  validateClientRequisites = (data: Record<string, string | null>) => {
    return this.apiClient.post({ url: '/client/requisites-validate', data });
  };
}
