import { Box } from '@mui/material';
import { ICommonLayoutProps } from 'interfaces/UIComponentsInterfaces';
import { PropsWithChildren, useEffect, useState } from 'react';

import { useAllMQ } from 'base/hooks/useAllMQ';
import DesktopHeader from 'components/Header/DesktopHeader';
import DesktopSidebar from 'components/Sidebar/DesktopSidebar';
import { Loader } from 'components/UI/Loader';

export const DesktopBaseLayout: React.FC<PropsWithChildren<ICommonLayoutProps>> = ({
  isLoadingRequiredData,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const { isXL } = useAllMQ();

  // Effects
  useEffect(() => {
    setIsOpen(!isXL);
  }, [isXL]);

  // Handlers
  const handleToggleOpen = () => {
    setIsOpen(o => !o);
  };

  return (
    <Box minWidth="100%" minHeight="inherit" display="flex">
      <DesktopSidebar isOpen={isOpen} onToggle={handleToggleOpen} />
      <Box width={!isOpen ? '102%' : '100%'} pl={!isOpen ? 25 : 0} ml={!isOpen ? -25 : 0}>
        <DesktopHeader />
        {isLoadingRequiredData ? <Loader minHeight="calc(100vh - 64px)" size={64} /> : children}
      </Box>
    </Box>
  );
};
