import { appConfig } from 'appConfig';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { authRoutes } from 'routes/routes';

import { rootStore } from 'base/RootStore';
import ApiHelper from 'base/helpers/ApiHelper';
import RouteHelper from 'base/routes/helpers/RouteHelper';
import Notification from 'base/ui/Notification';
import { IAuthTokenData } from 'modules/auth/interfaces/AuthInterfaces';
import { Language } from 'modules/language-toggle/models/Language';

import IApiClient from '../IApiClient';
import { IAxiosConfig, IAxiosResponse } from './IAxiosInterfaces';

export default class AxiosClient implements IApiClient {
  readonly SUCCESS_STATUSES = [200, 201];
  readonly SERVER_ERROR = 500;
  readonly UNAUTHORIZED = 401;
  readonly FORBIDDEN = 403;
  readonly NO_SHOW_ERROR_URLS = ['/validate'];
  // время разницы для проверки свежести токенов
  readonly TOKEN_EXPIRATION_TIME_DIFF = 5 * 60 * 1000; // 5 минут

  api: AxiosInstance;

  tokenData: IAuthTokenData | null;

  abortController: AbortController;

  constructor(config?: AxiosRequestConfig) {
    this.tokenData = null;

    this.api = axios.create(config);
    this.api.defaults.baseURL = ApiHelper.getApiUrl();

    this.abortController = new AbortController();
    this.api.defaults.signal = this.abortController.signal;

    this.setInterceptorRequest();
    this.setInterceptorResponse();
  }

  setAccessToken = (token: IAuthTokenData) => {
    this.api.defaults.headers['X-Api-Token'] = token.accessToken;
    this.tokenData = token;
  };

  clearAccessToken = () => {
    this.api.defaults.headers['X-Api-Token'] = null;
    this.tokenData = null;
  };

  setLanguage = (language: Language) => {
    this.api.defaults.headers['Accept-Language'] = language.langlocale;
  };

  cancelRequests = () => {
    this.abortController.abort();

    this.abortController = new AbortController();
    this.api.defaults.signal = this.abortController.signal;
  };

  get = <T extends {}>(config: IAxiosConfig) => {
    return this.api.get<IAxiosResponse<T>>(config.url, config.config);
  };

  post = <T extends {}>(config: IAxiosConfig) => {
    return this.api.post<IAxiosResponse<T>>(config.url, config.data, config.config);
  };

  put = <T extends {}>(config: IAxiosConfig) => {
    return this.api.put<IAxiosResponse<T>>(config.url, config.data, config.config);
  };

  delete = <T extends {}>(config: IAxiosConfig) => {
    return this.api.delete<IAxiosResponse<T>>(config.url, config.config);
  };

  protected getApiErrors = (error: any) => {
    Notification.showError(error.errorMessage || 'Unknown error');
  };

  private setInterceptorRequest = () => {
    this.api.interceptors.request.use(
      async config => {
        const newConfig: AxiosRequestConfig = {
          ...config,
          signal: this.abortController.signal,
          headers: {
            'Content-Type': config.headers?.['Content-Type'] || 'application/json',
            ...config.headers,
          },
        };

        if (this.tokenData) {
          const now = Date.now();
          const { refreshExpired, accessExpired, refreshToken, accessToken } = this.tokenData;

          // протух рефреш токен - разлогиниваем
          if (now > refreshExpired * 1000 - this.TOKEN_EXPIRATION_TIME_DIFF) {
            await this.logOut(newConfig);
            return newConfig;
          }

          // протух токен доступа - получаем новый
          if (now > accessExpired * 1000 - this.TOKEN_EXPIRATION_TIME_DIFF) {
            const newTokens = await rootStore.oAuth2Store.getNewAccessToken({ refreshToken, accessToken });

            this.setAccessToken({ ...this.tokenData, ...newTokens });
            newConfig.headers = { ...newConfig.headers, ['X-Api-Token']: newTokens.accessToken };
            return newConfig;
          }
        }

        return newConfig;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  };

  private logOut = async (config: AxiosRequestConfig) => {
    await rootStore.oAuth2Store.logOut(config);

    this.sessionExpired();
  };

  private sessionExpired = () => {
    this.clearAccessToken();
    rootStore.oAuth2Store.deleteToken();

    const path = RouteHelper.makePath(authRoutes.SessionExpiredScreen.path, [
      { p: RouteHelper.SESSION_PARAM, v: RouteHelper.EXPIRED },
    ]);
    window.location.href = path;
  };

  private forbidden = async (error: any) => {
    rootStore.authStore.setServerIsOk(false);
    if (!rootStore.authStore.forbiddenError) {
      const errorData = rootStore.authStore.getErrorData(error);
      rootStore.authStore.setForbiddenError(errorData);
    }

    if (this.tokenData) {
      const now = Date.now();
      const { refreshExpired, accessExpired, refreshToken, accessToken } = this.tokenData;

      // протух рефреш токен - разлогиниваем
      if (now > refreshExpired * 1000 - this.TOKEN_EXPIRATION_TIME_DIFF) {
        await this.logOut({ headers: { 'X-Api-Token': accessToken } });
        return;
      }

      // протух токен доступа - получаем новый
      if (now > accessExpired * 1000 - this.TOKEN_EXPIRATION_TIME_DIFF) {
        const newTokens = await rootStore.oAuth2Store.getNewAccessToken({ refreshToken, accessToken });
        this.setAccessToken({ ...this.tokenData, ...newTokens });
        return;
      }
    }
  };

  private setInterceptorResponse = () => {
    this.api.interceptors.response.use(
      response => {
        if (!this.SUCCESS_STATUSES.includes(response.status)) {
          Notification.showError(response.data?.errorMessage || 'Unknown error');

          return Promise.reject(response);
        }

        if (response?.data?.message) {
          Notification.showSuccess(response?.data?.message);
        }

        return response;
      },
      error => {
        // global showing error messages
        if (!!this.NO_SHOW_ERROR_URLS.find(url => error.config?.url?.includes(url))) {
          return Promise.reject(error.response?.data);
        }

        if (error.response?.status === this.UNAUTHORIZED) {
          this.cancelRequests();
          this.sessionExpired();
          return Promise.reject(error.response?.data);
        }

        if (error.response?.status === this.FORBIDDEN) {
          this.cancelRequests();
          this.forbidden(error.response?.data);
          return Promise.reject(error.response?.data);
        }

        if (error.response?.status === this.SERVER_ERROR) {
          Notification.showError('Server error');
        }

        const errorsArray: string[] = Array.isArray(error.response?.data.errorData)
          ? error.response?.data.errorData
          : Object.values(error.response?.data.errorData);

        if (errorsArray.length && error.response?.data.errorCode !== 1008) {
          errorsArray.forEach(err => {
            Notification.showError(err || 'Unknown error');
          });
          return Promise.reject(error.response?.data);
        }

        Notification.showError(error.response?.data.errorMessage || 'Unknown error');

        return Promise.reject(error.response?.data);
      }
    );
  };
}
