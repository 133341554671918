import { jsonProperty, Serializable } from 'ts-serializable';

import { CheckType } from '../enums/ToolsEnums';

export class ToolsHistoryDto extends Serializable {
  @jsonProperty(Number) favorite: number = 0;
  @jsonProperty(Number) dcid: number = 0;
  @jsonProperty(Number) ipv6: number = 0;
  @jsonProperty(Number) udp: number = 0;
  @jsonProperty(String) type: CheckType = '' as CheckType;
  @jsonProperty(String) dcname: string = '';
  @jsonProperty(String) url: string = '';
  @jsonProperty(String) result: string = '';
}
