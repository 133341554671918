import Utilities from 'helpers/Utilities';

import { TableForm } from '../forms/TableForm/TableForm';

export class TableHelper {
  static tableFormEqualityCheck(
    tableForm: TableForm,
    prevTableForm: Record<string, any>,
    setShowTableLoader: (state: boolean) => void,
    setPrevTableForm: (state: Record<string, any>) => void
  ) {
    const filterEntries = Object.entries(tableForm.filters);
    const filtersAreNotEqual = filterEntries.some(([key, value]) => {
      return !Utilities.primitiveArraysEqual(value, prevTableForm.filters?.[key]);
    });

    const tableChanged =
      tableForm.query !== prevTableForm.query ||
      tableForm.page !== prevTableForm.page ||
      tableForm.limit !== prevTableForm.limit ||
      tableForm.sortValue.orderBy !== prevTableForm.sortValue?.orderBy ||
      tableForm.sortValue.order !== prevTableForm.sortValue?.order ||
      filtersAreNotEqual;

    if (tableChanged) {
      setShowTableLoader(true);
    }
    setPrevTableForm({ ...tableForm });
  }
}
