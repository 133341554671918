import { makeAutoObservable } from 'mobx';

import { ServiceModel } from 'modules/service/models/ServiceModel';

import ServicePaymentService from './ServicePaymentService';
import { ServicesPaymentForm, ServicesPaymentFormFields } from './forms/ServicesPaymentForm';
import { IPrecalculationPoolPeriods } from './interfaces/ServicePaymentInterfaces';
import { Renewal } from './models/Renewal';
import { PaymentMethod } from './models/payment/PaymentMethod';
import { Precalculation } from './models/precalculation/Precalculation';
import { PrecalculationPool } from './models/precalculation/PrecalculationPool';

export class ServicePaymentStore {
  renewalLoading: boolean = false;
  precalculationPoolsLoading: boolean = false;
  precalculatedServicesLoading: boolean = false;
  paymentMethodsLoading: boolean = false;

  servicesPaymentForm = ServicesPaymentForm.createObservable();

  precalculation: Precalculation | null = null;
  precalculatedServices: ServiceModel[] | null = null;

  precalculationPoolPeriods: IPrecalculationPoolPeriods = { periodFrom: 1, periodTo: 3 };
  precalculationPools: PrecalculationPool[] | null = null;

  paymentMethods: PaymentMethod[] | null = null;
  personalAccounts: PaymentMethod[] | null = null;

  renewal: Renewal | null = null;

  private servicePaymentService: ServicePaymentService;

  constructor() {
    makeAutoObservable(this);
    this.servicePaymentService = new ServicePaymentService();
  }

  get subagridsToPrecalculate() {
    return this.precalculation?.params?.subbills
      ? Object.keys(this.precalculation?.params?.subbills).map(k => Number(k).valueOf())
      : [];
  }

  get totalServicesPaymentAmount() {
    if (!this.precalculation) {
      return '0';
    }

    const totals = this.precalculationPools?.find(pool => pool.defaultPeriod === this.servicesPaymentForm.defaultPeriod)
      ?.totals?.amblvltotal;

    return totals;
  }

  precalculate = (subagrids: number[], paytype?: string, callback?: () => void) => {
    this.servicePaymentService
      .precalculate({ 'subagrids[]': subagrids, paytype })
      .then(precalculation => {
        this.setPrecalculation(precalculation);

        const defaultPeriod = precalculation.params?.defaultPeriod;

        if (defaultPeriod && defaultPeriod > this.precalculationPoolPeriods.periodTo) {
          this.setPrecalculationPoolPeriods({
            periodFrom: this.precalculationPoolPeriods.periodFrom,
            periodTo: defaultPeriod,
          });
        }

        this.servicesPaymentForm.setValue(ServicesPaymentFormFields.defaultPeriod, defaultPeriod);
        this.servicesPaymentForm.setValue(ServicesPaymentFormFields.paytype, precalculation.params?.paytype);

        callback?.();
      })
      .catch(() => {})
      .finally(() => {});
  };

  getPrecalculationPool = (periods: { periodFrom: number; periodTo: number }, callback?: () => void) => {
    if (this.precalculationPoolsLoading) {
      return;
    }
    this.setPrecalculationPoolsLoading(true);

    this.servicePaymentService
      .precalculationPool({
        'subagrids[]': this.subagridsToPrecalculate,
        paytype: this.precalculation?.params?.paytype,
        ...periods,
      })
      .then(pools => {
        this.setPrecalculationPools(pools);
      })
      .catch(() => {})
      .finally(() => {
        this.setPrecalculationPoolsLoading(false);
        callback?.();
      });
  };

  getPaymentMethods = () => {
    if (this.paymentMethodsLoading) {
      return;
    }

    this.setPaymentMethodsLoading(true);
    this.servicePaymentService
      .getPayMethods({ 'subagrids[]': this.subagridsToPrecalculate })
      .then(({ personalAccounts, paymentMethods }) => {
        this.setPaymentMethods(paymentMethods);
        this.setPersonalAccounts(personalAccounts);
      })
      .catch(() => {})
      .finally(() => {
        this.setPaymentMethodsLoading(false);
      });
  };

  getPrecalculatedServices = () => {
    if (this.precalculatedServicesLoading) {
      return;
    }

    this.setPrecalculatedServicesLoading(true);

    this.servicePaymentService
      .getPrecalculatedServices(this.subagridsToPrecalculate)
      .then(this.setPrecalculatedServices)
      .catch(() => {})
      .finally(() => {
        this.setPrecalculatedServicesLoading(false);
      });
  };

  getRenewal = (callback?: () => void) => {
    if (this.renewalLoading) {
      return;
    }

    this.setRenewalLoading(true);

    this.servicePaymentService
      .getRenewals({
        'subagrids[]': this.subagridsToPrecalculate,
        defaultPeriod: this.servicesPaymentForm.defaultPeriod,
        paytype: this.servicesPaymentForm.paytype,
      })
      .then(renewal => {
        this.setRenewal(renewal);
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setRenewalLoading(false);
      });
  };

  // loaders
  setRenewalLoading = (loading: boolean) => {
    this.renewalLoading = loading;
  };

  setPrecalculationPoolsLoading = (loading: boolean) => {
    this.precalculationPoolsLoading = loading;
  };

  setPrecalculatedServicesLoading = (loading: boolean) => {
    this.precalculatedServicesLoading = loading;
  };

  setPaymentMethodsLoading = (loading: boolean) => {
    this.paymentMethodsLoading = loading;
  };

  // setters
  setPrecalculation = (precalculation: Precalculation | null) => {
    this.precalculation = precalculation;
  };

  setPaymentMethods = (paymentMethods: PaymentMethod[] | null) => {
    this.paymentMethods = paymentMethods;
  };

  setPersonalAccounts = (paymentAccounts: PaymentMethod[] | null) => {
    this.personalAccounts = paymentAccounts;
  };

  setPrecalculationPoolPeriods = (periods: IPrecalculationPoolPeriods) => {
    this.precalculationPoolPeriods = periods;
  };

  setPrecalculationPools = (precalculationPools: PrecalculationPool[] | null) => {
    if (!precalculationPools) {
      this.precalculationPools = null;
      return;
    }

    this.precalculationPools = this.precalculationPools
      ? [...this.precalculationPools, ...precalculationPools]
      : precalculationPools;
  };

  setPrecalculatedServices = (precalculatedServices: ServiceModel[]) => {
    this.precalculatedServices = precalculatedServices;
  };

  setRenewal = (renewals: Renewal) => {
    this.renewal = renewals;
  };
}
