import { jsonProperty, Serializable } from 'ts-serializable';

export class IPModel extends Serializable {
  @jsonProperty(Number, null) mainip: number | null = null;
  @jsonProperty(Number, null) ip1: number | null = null;
  @jsonProperty(Number, null) ip2: number | null = null;
  @jsonProperty(Number, null) ip3: number | null = null;
  @jsonProperty(Number, null) ip4: number | null = null;
  @jsonProperty(Number, null) mask1: number | null = null;
  @jsonProperty(Number, null) mask2: number | null = null;
  @jsonProperty(Number, null) mask3: number | null = null;
  @jsonProperty(Number, null) mask4: number | null = null;
  @jsonProperty(Number, null) gateway1: number | null = null;
  @jsonProperty(Number, null) gateway2: number | null = null;
  @jsonProperty(Number, null) gateway3: number | null = null;
  @jsonProperty(Number, null) gateway4: number | null = null;
  @jsonProperty(String, null) ip: string | null = null;
  @jsonProperty(String, null) mask: string | null = null;
  @jsonProperty(String, null) gateway: string | null = null;
}
