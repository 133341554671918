import { jsonProperty, Serializable } from 'ts-serializable';

export class Document extends Serializable {
  @jsonProperty(Number) csdtid: number = 0;
  @jsonProperty(Number, null) csdid: number | null = null;
  @jsonProperty(String, null) documentname: string | null = null;
  @jsonProperty(String, null) dtvalidity: string | null = null;
  @jsonProperty(Boolean, null) verified: boolean | null = null;
  @jsonProperty(Boolean, null) needverify: boolean | null = null;
  @jsonProperty(Boolean, null) deletionasked: boolean | null = null;
  @jsonProperty(Boolean, null) denied: boolean | null = null;
}
