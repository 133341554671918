import cz from 'react-phone-number-input/locale/cz.json';
import en from 'react-phone-number-input/locale/en.json';
import ru from 'react-phone-number-input/locale/ru.json';

import { modelFactory } from 'base/ModelFactory';

import LanguageToggleApiRepository from './LanguageToggleApiRepository';
import { LanguageToggleFactory } from './LanguageToggleFactory';
import { Language } from './models/Language';
import { Localization } from './models/Localization';
import LanguageLocalService from './modules/languages/LanguageLocalService';

const DEFAULT_LANGUAGE = 'en-US';

export default class LanguageToggleService {
  readonly countries: { [key: string]: any } = {
    ru,
    en,
    cz,
  };

  languageApi: LanguageToggleApiRepository;
  languageFactory: LanguageToggleFactory;
  languageLocalService: LanguageLocalService;

  constructor() {
    this.languageLocalService = new LanguageLocalService();
    this.languageFactory = new LanguageToggleFactory();
    this.languageApi = new LanguageToggleApiRepository();
  }

  saveLanguage = (language: Language) => {
    this.languageApi.apiClient.setLanguage(language);
    this.languageLocalService.saveLanguage(language.langtag);
  };

  getLanguage = (acceptableLanguages: Language[]) => {
    const savedLanguage = this.languageLocalService.getLanguage();
    const english = acceptableLanguages.find(({ langtag }) => langtag === DEFAULT_LANGUAGE);

    if (savedLanguage) {
      const langObject = acceptableLanguages.find(({ langtag }) => langtag === savedLanguage);
      return langObject || english || null;
    }

    const navigatorLanguage = navigator.language;

    const langObject = acceptableLanguages.find(({ langtag }) => langtag === navigatorLanguage);

    return langObject || english || null;
  };

  getLocalization = async (langlocale: string, date: string) => {
    const dto = this.languageFactory.getLocalizationDto(langlocale, date);

    const { data } = await this.languageApi.getLocalization(dto);

    return this.languageFactory.create<Localization>(Localization, { ...data.data, writeTime: Date.now() });
  };

  getLanguageList = async () => {
    const { data } = await this.languageApi.getLanguageList();

    return modelFactory.createList<Language>(Language, data.data);
  };
}
