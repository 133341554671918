import { action, makeObservable } from 'mobx';

import { TableForm } from 'modules/tables/forms/TableForm/TableForm';

export enum UnitTrafficTableFormFields {
  objectid = 'objectid',
  connid = 'connid',
  dt_from = 'dt_from',
  dt_to = 'dt_to',
  rra_id = 'rra_id',
}

export class UnitTrafficTableForm extends TableForm {
  objectid: number | null = null;
  connid: number | null = null;
  dt_from: Date | null = null;
  dt_to: Date | null = null;
  rra_id: number | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  resetForm = () => {
    this.objectid = null;
    this.connid = null;
    this.dt_from = null;
    this.dt_to = null;
    this.rra_id = null;
  };

  setAction<T>(key: keyof T, action: () => any): void {
    if (typeof action === 'function') {
      (this as any)[key] = action;
    }
  }

  static createObservable = () => {
    const context = new this();
    context.createTableFormObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(UnitTrafficTableFormFields),
      resetForm: action,
    });
  };
}
