import { Box, BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface IQScrollbarProps extends BoxProps {
  maxHeight: string | number;
}

const QScrollbar: FC<PropsWithChildren<IQScrollbarProps>> = ({ children, maxHeight, sx, className, ...rest }) => {
  const classes = useStyles();

  // Renders
  return (
    <Box
      className={clsx(classes.scrollbar, className)}
      sx={{ maxHeight, overflowY: 'auto', overflowX: 'hidden', ...sx }}
      {...rest}
    >
      {children}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: theme.spacing(1),
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
  },
}));

export default QScrollbar;
