import ApiHelper from 'base/helpers/ApiHelper';
import ServicesApiRepository from 'modules/services/ServicesApiRepository';
import { ServicesFactory } from 'modules/services/ServicesFactory';

import ServicePaymentApiRepository from './ServicePaymentApiRepository';
import { ServicePaymentFactory } from './ServicePaymentFactory';
import { IPayMethodsParams, IPreCalculateParams } from './interfaces/ServicePaymentInterfaces';
import { Renewal } from './models/Renewal';

export default class ServicePaymentService {
  servicesApi: ServicesApiRepository;
  servicePaymentApi: ServicePaymentApiRepository;
  servicePaymentFactory: ServicePaymentFactory;
  servicesFactory: ServicesFactory;

  constructor() {
    this.servicePaymentApi = new ServicePaymentApiRepository();
    this.servicesApi = new ServicesApiRepository();
    this.servicePaymentFactory = new ServicePaymentFactory();
    this.servicesFactory = new ServicesFactory();
  }

  precalculate = async (params: IPreCalculateParams) => {
    const queryString = ApiHelper.getQueryString(params);

    const { data } = await this.servicePaymentApi.precalculate(queryString);

    return this.servicePaymentFactory.createPrecalculation(data.data);
  };

  getPrecalculatedServices = async (subagrids: number[]) => {
    const queryString = ApiHelper.getQueryString({ 'subagrids[]': subagrids });

    const { data } = await this.servicesApi.getChildrenServices(queryString);

    return this.servicesFactory.createServices(data.data.items);
  };

  precalculationPool = async (params: IPreCalculateParams) => {
    const queryString = ApiHelper.getQueryString(params);

    const { data } = await this.servicePaymentApi.precalculationPool(queryString);

    return this.servicePaymentFactory.createPrecalculationPools(data.data);
  };

  getPayMethods = async (params: IPayMethodsParams) => {
    const query = ApiHelper.getQueryString(params);

    const { data } = await this.servicePaymentApi.getPayMethods(query);

    return this.servicePaymentFactory.createPaymentMethods(data.data);
  };

  getRenewals = async (params: IPreCalculateParams) => {
    const query = ApiHelper.getQueryString(params);

    const { data } = await this.servicePaymentApi.getRenewals(query);

    return this.servicePaymentFactory.create<Renewal>(Renewal, data.data);
  };
}
