import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { ToolsHistoryDto } from './dto/ToolsHistoryDto';

export default class ToolsApiRepository extends AbstractApiRepository {
  getPingEndPoints = () => {
    return this.apiClient.get({ url: 'dc/ping-endpoints' });
  };

  getTracerouteEndPoints = () => {
    return this.apiClient.get({ url: 'dc/traceroute-endpoints' });
  };

  getChecksHistory = (query: string) => {
    return this.apiClient.get({ url: `tools/history${query}` });
  };

  createChecksHistory = (data: ToolsHistoryDto) => {
    return this.apiClient.post({ url: 'tools/history', data });
  };

  deleteChecksHistory = (query: string) => {
    return this.apiClient.delete({ url: `tools/history${query}` });
  };

  clearChecksHistory = (query: string) => {
    return this.apiClient.delete({ url: `tools/history-clear${query}` });
  };

  updateChecksHistory = (id: number, data: ToolsHistoryDto) => {
    return this.apiClient.put({ url: `tools/history/${id}`, data });
  };

  ping = (query: string) => {
    return this.apiClient.get({ url: `tools/ping${query}` });
  };

  traceroute = (query: string) => {
    return this.apiClient.get({ url: `tools/traceroute${query}` });
  };
}
