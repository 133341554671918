import { jsonProperty, Serializable } from 'ts-serializable';

export class HardwareOrder extends Serializable {
  @jsonProperty(Number) hsoid: number = 0;
  @jsonProperty(Number) status: number = 0;
  @jsonProperty(Number) numitems: number = 0;
  @jsonProperty(String) orderdate: string = '';
  @jsonProperty(String) statusText: string = '';
  @jsonProperty(String) devcname: string = '';
  @jsonProperty(String) hwnameshort: string = '';
  @jsonProperty(String) ticket: string = '';
}
