import { Box, IconButton } from '@mui/material';
import { FC } from 'react';

import { QIcon } from 'components/UI/QIcon/QIcon';
import { QIconIds } from 'components/UI/QIcon/QIconIds';
import { useCommonStyles } from 'styles/commonStyles';

interface IMobileSearchProps {}

const MobileSearch: FC<IMobileSearchProps> = () => {
  const commonClasses = useCommonStyles();

  // Renders
  return (
    <Box className={commonClasses.headerActionsWrapper}>
      <IconButton size="small">
        <QIcon id={QIconIds.search} className={commonClasses.greenIcon} />
      </IconButton>
    </Box>
  );
};

export default MobileSearch;
