import { makeAutoObservable, runInAction } from 'mobx';

import BillsService from 'modules/bills/BillsService';
import { BillPayStatuses } from 'modules/bills/enums/BillsEnums';
import { Bill } from 'modules/bills/models/Bill';
import ServicesService from 'modules/services/ServicesService';
import { IServiceChildrenInfo } from 'modules/services/interfaces/ServicesInterfaces';
import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';

import ServiceService from './ServiceService';
import { ServiceStatuses } from './enums/ServiceStatuses';
import { ServiceBillsTableForm } from './forms/ServiceBillsTableForm';
import { ServiceModel } from './models/ServiceModel';

export class ServiceStore {
  serviceLoading: boolean = false;
  serviceChildrenLoading: boolean = false;
  childrenLoading: { [k: number]: boolean } = {};

  serviceBillsLoading: boolean = false;
  servicePayedBillsLoading: boolean = false;

  service: ServiceModel | null = null;
  serviceChildren: ServiceModel[] | null = null;
  serviceChildrenInfo: IServiceChildrenInfo = {};
  serviceChildrenTableForm = ServiceBillsTableForm.createObservable();

  serviceBills: Bill[] | null = null;
  servicePayedBills: Bill[] | null = null;

  serviceBillsTableForm = ServiceBillsTableForm.createObservable();
  servicePayedBillsTableForm = ServiceBillsTableForm.createObservable();

  private serviceService: ServiceService;
  private servicesService: ServicesService;
  private billsService: BillsService;

  constructor() {
    this.serviceService = new ServiceService();
    this.servicesService = new ServicesService();
    this.billsService = new BillsService();

    makeAutoObservable(this);

    this.serviceChildrenTableForm.setAction(TableFormDynamicActions.getList, this.getServiceChildren);
    this.serviceBillsTableForm.setAction(TableFormDynamicActions.getList, this.getServiceBills);
    this.servicePayedBillsTableForm.setAction(TableFormDynamicActions.getList, this.getPayedServiceBills);
  }

  get serviceBillsIds() {
    return this.serviceBills?.map(({ billid }) => billid) || [];
  }

  getService = (subagrid: number) => {
    if (this.serviceLoading) {
      return;
    }

    this.setServiceLoading(true);

    this.serviceService
      .getService(subagrid)
      .then(this.setService)
      .catch(() => {})
      .finally(() => {
        this.setServiceLoading(false);
      });
  };

  getServiceChildren = () => {
    if (this.serviceChildrenLoading || !this.service) {
      return;
    }

    this.setServiceChildrenLoading(true);

    this.servicesService
      .getChildrenServices(this.service.subagrid, this.serviceChildrenTableForm)
      .then(({ items }) => {
        this.setServiceChildren(items);
      })
      .catch(() => {})
      .finally(() => {
        this.setServiceChildrenLoading(false);
      });
  };

  getCurrentServiceChildren = (service: ServiceModel, callback: () => void) => {
    if (this.childrenLoading[service.subagrid]) {
      return;
    }

    this.setChildrenLoading({ [service.subagrid]: true });

    this.servicesService
      .getChildrenServices(service.subagrid)
      .then(({ items, totals }) => {
        service.children = items;
        this.setServiceChildrenInfo({ [service.subagrid]: { count: items.length, totals } });
        callback();
      })
      .catch(() => {})
      .finally(() => {
        this.setChildrenLoading({ [service.subagrid]: false });
      });
  };

  closeService = (service: ServiceModel, callback?: () => void) => {
    this.serviceService
      .closeService(service.subagrid)
      .then(() => {
        if (service) {
          service.status = ServiceStatuses.C;
        }
        callback?.();
      })
      .catch(() => {})
      .finally(() => {});
  };

  getServiceBills = () => {
    if (this.serviceBillsLoading || !this.service) {
      return;
    }

    this.setServiceBillsLoading(true);

    this.billsService
      .getServiceBillsList(
        { subagrId: this.service.subagrid, payStatus: BillPayStatuses.NOT_PAYED },
        this.serviceBillsTableForm
      )
      .then(({ items, paginator }) => {
        this.setServiceBills(items);
        runInAction(() => {
          this.serviceBillsTableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setServiceBillsLoading(false);
      });
  };

  getPayedServiceBills = () => {
    if (this.servicePayedBillsLoading || !this.service) {
      return;
    }

    this.setServicePayedBillsLoading(true);

    this.billsService
      .getServiceBillsList(
        { subagrId: this.service.subagrid, payStatus: BillPayStatuses.PAYED },
        this.servicePayedBillsTableForm
      )
      .then(({ items, paginator }) => {
        this.setServicePayedBills(items);
        runInAction(() => {
          this.servicePayedBillsTableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setServicePayedBillsLoading(false);
      });
  };

  // loaders
  setServiceLoading = (loading: boolean) => {
    this.serviceLoading = loading;
  };

  setServiceChildrenLoading = (loading: boolean) => {
    this.serviceChildrenLoading = loading;
  };

  setChildrenLoading = (loading: { [k: number]: boolean }) => {
    this.childrenLoading = { ...this.childrenLoading, ...loading };
  };

  setServiceBillsLoading = (loading: boolean) => {
    this.serviceBillsLoading = loading;
  };

  setServicePayedBillsLoading = (loading: boolean) => {
    this.servicePayedBillsLoading = loading;
  };

  // setters
  setService = (service: ServiceModel | null) => {
    this.service = service;
  };

  setServiceChildren = (serviceChildren: ServiceModel[] | null) => {
    this.serviceChildren = serviceChildren;
  };

  setServiceChildrenInfo = (childrenInfo: IServiceChildrenInfo) => {
    this.serviceChildrenInfo = { ...this.serviceChildrenInfo, ...childrenInfo };
  };

  setServiceBills = (bills: Bill[] | null) => {
    this.serviceBills = bills;
  };

  setServicePayedBills = (bills: Bill[] | null) => {
    this.servicePayedBills = bills;
  };
}
