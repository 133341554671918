// import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { ICommonModalProps } from 'interfaces/UIComponentsInterfaces';
import { FC, useEffect } from 'react';
import { Control, FieldValues, useFormContext } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';
import QModal from 'components/UI/Modal/QModal';
import QTextFieldWithController from 'components/UI/TextFields/QTextFieldWithController';
import { useTimer } from 'hooks/useTimer';
import { ChangeClientForm, ChangeClientFormFields } from 'modules/auth/forms/ChangeClientForm';
import { ClientForAuth } from 'modules/clients/models/ClientForAuth';
import { MFA } from 'modules/mfa/models/MFA';
import { ChangeClientModalTypes } from 'modules/modal/types/ChangeClientTypes';
import { useCommonStyles } from 'styles/commonStyles';
import { useCodeConfirmationModalStyles } from 'styles/modules/useCodeConfirmationModalStyles';

interface ICodeConfirmationModalProps extends ICommonModalProps {
  isLoading: boolean;
  showVariants: boolean;
  deadline: number;
  mfa?: MFA | null;
  client?: ClientForAuth;
  changeClient: (changeClientForm: ChangeClientForm, client: ClientForAuth) => void;
}

const CodeConfirmationModal: FC<ICodeConfirmationModalProps> = ({
  close,
  showVariants,
  mfa,
  deadline,
  client,
  isLoading,
  changeClient,
}) => {
  const {
    authStore,
    modalsStore,
    languageToggleStore: { _lk, _stringFormat, translations },
  } = useRootStore();

  const commonClasses = useCommonStyles();
  const classes = useCodeConfirmationModalStyles();

  const form = useFormContext<ChangeClientForm>();

  const phone = mfa?.phone || '';
  const messengerName =
    mfa?.messengersTypes?.find(type => {
      return type.messengertypeid === mfa?.messengertypeid;
    })?.label || '';

  const { minutes, seconds, timeIsUp } = useTimer(deadline);

  const control = form.control as unknown as Control<FieldValues, any>;

  const disabled = !form.formState.isValid;

  // Effects
  useEffect(() => {
    form.reset({ ...authStore.changeClientForm });
  }, []);

  // Handlers
  const onSubmit = (data: FieldValues) => {
    if (!client) {
      return;
    }
    changeClient(data as ChangeClientForm, client);
  };

  const handleSendNewCodeClick = () => {
    authStore.changeClientForm.setValue(ChangeClientFormFields.mfacode, null);
    form.reset({ ...authStore.changeClientForm });

    if (!client) {
      return;
    }
    changeClient(form.getValues() as unknown as ChangeClientForm, client);
  };

  const handleChooseMFAMethodClick = () => {
    modalsStore.setChangeClientModalType(ChangeClientModalTypes.CHANGE_MFA_METHOD);
  };

  // Renders
  return (
    <QModal close={close} headerTitle={translations.mfaTitle} centerContent>
      <Box mt={5}>
        <Typography variant="h5" className={classes.title} textAlign="center">
          {_lk('Теперь подтвердите номер')}
        </Typography>
        <Typography variant="caption" component="p" className={classes.subtitle}>
          {_stringFormat(
            _lk(
              'Мы отправили код в {messengerName} на номер {phone}, введите его или выберите другой способ подтверждения'
            ),
            {
              messengerName,
              phone,
            }
          )}
        </Typography>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <QTextFieldWithController
            name={ChangeClientFormFields.mfacode}
            hookFormProps={{ control }}
            containerProps={{ my: 8 }}
            InputProps={{ sx: { maxWidth: 96, mx: 'auto' } }}
            FormHelperTextProps={{ sx: { textAlign: 'center' } }}
            inputProps={{ style: { textAlign: 'center' } }}
            errors={form.formState.errors[ChangeClientFormFields.mfacode]?.message}
          />
          {!timeIsUp && (
            <Typography variant="caption" component="p" textAlign="center">
              {_stringFormat(_lk('Если код не придёт, получить новый можно через {minutes}:{seconds}'), {
                minutes,
                seconds,
              })}
            </Typography>
          )}
          {timeIsUp && (
            <Button variant="contained-contrast" className={classes.button} onClick={handleSendNewCodeClick}>
              {_lk('Отправить код ещё раз')}
            </Button>
          )}
          <Button variant="contained" type="submit" className={classes.button} disabled={disabled}>
            {isLoading ? <Loader color="#fff" size={24} /> : translations.send}
          </Button>
          {showVariants && (
            <Button variant="contained-contrast" onClick={handleChooseMFAMethodClick} className={classes.button}>
              {translations.mfaChangeMethod}
            </Button>
          )}
          <Box mt={5} mb={4} textAlign="center">
            {/* TODO: адрес */}
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a href="#" target="_blank" rel="noopener noreferrer" className={commonClasses.link}>
              {_lk('Мне не пришло сообщение. Что делать?')}
            </a>
          </Box>
        </form>
      </Box>
    </QModal>
  );
};

export default CodeConfirmationModal;
