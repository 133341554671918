import { appConfig } from 'appConfig';

import { ResponseSuccess } from 'base/models/CommonModels';
import Notification from 'base/ui/Notification';

export default class ApiHelper {
  static getApiErrors(error: any) {
    if (error?.message) {
      if (!Array.isArray(error?.errors) && error?.errors) {
        const errorsArray = Object.values(error?.errors);
        const errors = Array.prototype.concat.apply([], errorsArray);

        Notification.showError(errors.join('\n') || 'Unknown error');
      } else {
        Notification.showError(error?.message || 'Unknown error');
      }
    }
  }

  static isResponseSuccess(object: unknown): object is ResponseSuccess {
    return typeof object === 'object' && object !== null && object.hasOwnProperty('success');
  }

  static getApiUrl() {
    if (process.env.NODE_ENV === 'production' && !appConfig.apiUrl.prod) {
      console.error('env.REACT_APP_PROD_API_URL - api url is not found!');
    }

    return (process.env.NODE_ENV === 'production' ? appConfig.apiUrl.prod : appConfig.apiUrl.dev) as string;
  }

  static getQueryString(
    object?: object,
    options?: { convertBooleanToNumber?: boolean; addBrackets?: boolean; straight?: boolean }
  ) {
    if (!object) {
      return '';
    }
    return `?${Object.entries(object)
      .map(([key, value]) => {
        if (options?.straight) {
          return `${key}=${value}`;
        }

        if (!value) {
          return '';
        }

        if (Array.isArray(value)) {
          return value.map(v => `${key}${options?.addBrackets ? '[]' : ''}=${v}`).join('&');
        }

        return `${key}=${options?.convertBooleanToNumber && !isNaN(value) ? Number(value).valueOf() : value}`;
      })
      .filter((value: string) => value)
      .join('&')}`;
  }
}
