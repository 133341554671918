import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';
import { Nullable } from 'base/types/BaseTypes';

export enum ChangeClientFormFields {
  mdid = 'mdid',
  clientid = 'clientid',
  userid = 'userid',
  udid = 'udid',
  mfacode = 'mfacode',
  messengertypeid = 'messengertypeid',
}

export class ChangeClientForm extends BaseForm {
  mdid: number = 0;
  clientid: number = 0;
  userid: number = 0;
  messengertypeid: number = 0;
  udid: string = '';
  mfacode: Nullable<string> = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(ChangeClientFormFields),
    });
  };
}
