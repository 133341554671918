import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { StrictMode, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useRootStore } from 'base/hooks/useRootStore';
import { AllRoutes } from 'base/routes/components/AllRoutes';
import { ThemeTypes } from 'modules/theme-toggle/types/ThemeToggleTypes';
import { darkTheme } from 'styles/themes/dark';
import { lightTheme } from 'styles/themes/light';

export const App: React.FC = observer(() => {
  const { themeToggleStore } = useRootStore();

  // Effects
  useEffect(() => {
    themeToggleStore.getSavedTheme();
  }, []);

  // Renders
  return (
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeToggleStore.theme === ThemeTypes.LIGHT ? lightTheme : darkTheme}>
          <CssBaseline />
          <Router>
            <AllRoutes />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </StrictMode>
  );
});
