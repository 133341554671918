import { jsonProperty, Serializable } from 'ts-serializable';

import { Phone } from './Phone';

export class User extends Serializable {
  @jsonProperty(Number) id: number = 0;
  @jsonProperty(String, null) avatar: string | null = null;
  @jsonProperty(String) lastname: string = '';
  @jsonProperty(String) firstname: string = '';
  @jsonProperty(String) midname: string = '';
  @jsonProperty(String) fio: string = '';
  @jsonProperty(String) email1: string = '';
  @jsonProperty(String) email2: string = '';
  @jsonProperty(String) phone1: string = '';
  @jsonProperty(String) phone1add: string = '';
  @jsonProperty(String) phone2: string = '';
  @jsonProperty(String) phone2add: string = '';
  @jsonProperty(Boolean) phone2mob: boolean = false;
  @jsonProperty(Boolean) phone2checked: boolean = false;
  @jsonProperty(String) phone3: string = '';
  @jsonProperty(String) phone3add: string = '';
  @jsonProperty(String) phone4: string = '';
  @jsonProperty(String) phone4add: string = '';
  @jsonProperty(String) phone5: string = '';
  @jsonProperty(String) phone5add: string = '';
  @jsonProperty(String) dolgnost: string = '';
  @jsonProperty(String) enabled: string = '';
  @jsonProperty(String) category: string = '';
  @jsonProperty(Array) phones: Phone[] = [];
}
