import ApiHelper from 'base/helpers/ApiHelper';
import { ResponseSuccess } from 'base/models/CommonModels';
import PaginationService from 'base/modules/pagination/PaginationService';

import ToolsApiRepository from './ToolsApiRepository';
import { ToolsFactory } from './ToolsFactory';
import { PingForm } from './forms/PingForm';
import { ToolsHistoryForm } from './forms/ToolsHistoryForm';
import { ToolsTableForm } from './forms/ToolsTableForm';
import { TracerouteForm } from './forms/TracerouteForm';
import { CheckHistory } from './models/CheckHistory';
import { PingEndpoint } from './models/PingEndpoints';
import { TracerouteEndpoint } from './models/TracerouteEndpoint';

export default class ToolsService {
  static readonly HISTORY_LIMIT = 4;

  private toolsApi: ToolsApiRepository;
  private toolsFactory: ToolsFactory;
  private paginationService: PaginationService;

  constructor() {
    this.toolsApi = new ToolsApiRepository();
    this.toolsFactory = new ToolsFactory();
    this.paginationService = new PaginationService();
  }

  getPingEndPoints = async () => {
    const { data } = await this.toolsApi.getPingEndPoints();

    return this.toolsFactory.createList<PingEndpoint>(PingEndpoint, data.data);
  };

  getTracerouteEndPoints = async () => {
    const { data } = await this.toolsApi.getTracerouteEndPoints();

    return this.toolsFactory.createList<TracerouteEndpoint>(TracerouteEndpoint, data.data);
  };

  getChecksHistory = async ({ page, limit }: ToolsTableForm, form: ToolsHistoryForm) => {
    const query = ApiHelper.getQueryString({ page, limit, favorite: form.favorite }, { straight: true });

    const { data } = await this.toolsApi.getChecksHistory(query);

    const items = this.toolsFactory.createList<CheckHistory>(CheckHistory, data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };

  createCheckHistory = async (form: ToolsHistoryForm) => {
    const dto = this.toolsFactory.getToolsHistoryDto(form);

    const { data } = await this.toolsApi.createChecksHistory(dto);

    return this.toolsFactory.create<CheckHistory>(CheckHistory, data.data);
  };

  deleteCheckHistory = async (ids: number[]) => {
    const query = ApiHelper.getQueryString({ ids }, { addBrackets: true });

    const { data } = await this.toolsApi.deleteChecksHistory(query);

    return this.toolsFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  clearCheckHistory = async (favorite: 0 | 1 | null) => {
    const query = ApiHelper.getQueryString({ favorite }, { straight: true });

    const { data } = await this.toolsApi.clearChecksHistory(query);

    return this.toolsFactory.create<ResponseSuccess>(ResponseSuccess, data.data);
  };

  updateCheckHistory = async (id: number, form: ToolsHistoryForm) => {
    const dto = this.toolsFactory.getToolsHistoryDto(form);

    const { data } = await this.toolsApi.updateChecksHistory(id, dto);

    return this.toolsFactory.create<CheckHistory>(CheckHistory, data.data);
  };

  ping = async (form: PingForm) => {
    const query = ApiHelper.getQueryString({ ...form }, { convertBooleanToNumber: true });

    const { data } = await this.toolsApi.ping(query);

    if (Array.isArray(data.data)) {
      return this.toolsFactory.createPing({
        url: form.urlForPing,
      });
    }

    return this.toolsFactory.createPing(data.data);
  };

  traceroute = async (form: TracerouteForm) => {
    const query = ApiHelper.getQueryString({ ...form }, { convertBooleanToNumber: true });

    const { data } = await this.toolsApi.traceroute(query);

    if (Array.isArray(data.data)) {
      return this.toolsFactory.createTraceroute({
        url: form.urlForTraceroute,
      });
    }

    return this.toolsFactory.createTraceroute(data.data);
  };

  getHistoryItem = (data: any) => {
    return this.toolsFactory.create<CheckHistory>(CheckHistory, data);
  };

  cancelRequests = () => {
    this.toolsApi.cancelRequests();
  };
}
