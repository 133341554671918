import * as yup from 'yup';

import { YupHelper } from 'base/helpers/YupHelper';
import { TranslationsHelper } from 'helpers/TranslationsHelper';

export const loginFormScheme = (step: number) => {
  let mfacode;

  if (step !== 3 && step !== 4) {
    mfacode = yup.string().transform(YupHelper.transformToNull).nullable();
  } else {
    mfacode = yup.string().required(TranslationsHelper.requiredField);
  }

  const object = {
    login: yup.string().required(TranslationsHelper.requiredField),
    pass: yup.string().required(TranslationsHelper.requiredField),
    udid: yup.string().required(TranslationsHelper.requiredField),
    osVersion: yup.string().required(TranslationsHelper.requiredField),
    deviceModel: yup.string().required(TranslationsHelper.requiredField),
    clientid: yup.number().integer().transform(YupHelper.transformToNull).nullable(),
    messengertypeid: yup.number().integer().transform(YupHelper.transformToNull).nullable(),
    mfacode,
  };

  return yup.object(object);
};

export const changeClientFormScheme = yup.object({
  mdid: yup.number().integer(),
  clientid: yup.number().integer(),
  userid: yup.number().integer(),
  udid: yup.string(),
  messengertypeid: yup.number().integer(),
  mfacode: yup.string().required(TranslationsHelper.requiredField),
});

export const forgotPasswordFormScheme = yup.object({
  contact: yup.string(),
  password: yup
    .string()
    .min(8, TranslationsHelper.lengthPassword)
    .matches(/.*[A-Z].*[A-Z].*/, TranslationsHelper.validPassword1)
    .matches(/.*[a-z].*[a-z].*/, TranslationsHelper.validPassword2)
    .matches(/.*[0-9].*[0-9].*/, TranslationsHelper.validPassword3)
    .transform(YupHelper.transformToNull)
    .nullable(),
  confirm: yup
    .string()
    .oneOf([yup.ref('password')], TranslationsHelper.notMatchPasswords)
    .transform(YupHelper.transformToNull)
    .nullable(),
  code: yup.string().length(6, TranslationsHelper.requiredCodeLength).transform(YupHelper.transformToNull).nullable(),
});
