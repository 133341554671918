import { makeAutoObservable } from 'mobx';

import { ChangeClientModalTypes } from './types/ChangeClientTypes';
import { ClientRequisitesModalTypes } from './types/ClientRequisitesTypes';
import { DocumentsScreenModalTypes } from './types/DocumentsTypes';
import { HardwareStockScreenModalTypes } from './types/HardwareStockTypes';
import { PassesScreenModalTypes } from './types/PassesTypes';
import { ProfileScreenModalTypes } from './types/ProfileTypes';
import { RegistrantsScreenModalTypes } from './types/RegistrantsScreenModalTypes';
import { ServicesScreenModalTypes } from './types/ServicesTypes';
import { ToolsScreenModalTypes } from './types/ToolsTypes';
import { UsersScreenModalTypes } from './types/UsersTypes';

interface ModalsStoreSetterOptions {
  replace?: boolean;
  close?: boolean;
}

type MultipleAbleModalTypes =
  | DocumentsScreenModalTypes
  | PassesScreenModalTypes
  | UsersScreenModalTypes
  | ServicesScreenModalTypes;

export class ModalsStore {
  profileScreenModalType: ProfileScreenModalTypes | null = null;

  changeClientModalType: ChangeClientModalTypes | null = null;

  clientRequisitesModalType: ClientRequisitesModalTypes | null = null;

  hardwareStockScreenModalType: HardwareStockScreenModalTypes | null = null;

  registrantsScreenModalType: RegistrantsScreenModalTypes | null = null;

  toolsScreenModalType: ToolsScreenModalTypes | null = null;

  documentsScreenModalType: Set<DocumentsScreenModalTypes> = new Set();

  passesScreenModalType: Set<PassesScreenModalTypes> = new Set();

  usersScreenModalType: Set<UsersScreenModalTypes> = new Set();

  servicesScreenModalType: Set<ServicesScreenModalTypes> = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  get passesScreenModalTypesList() {
    return [...this.passesScreenModalType];
  }

  get documentsScreenModalTypesList() {
    return [...this.documentsScreenModalType];
  }

  get usersScreenModalTypesList() {
    return [...this.usersScreenModalType];
  }

  get servicesScreenModalTypesList() {
    return [...this.servicesScreenModalType];
  }

  setProfileScreenModalType = (modalType: ProfileScreenModalTypes | null) => {
    this.profileScreenModalType = modalType;
  };

  setChangeClientModalType = (modalType: ChangeClientModalTypes | null) => {
    this.changeClientModalType = modalType;
  };

  setClientRequisitesModalType = (modalType: ClientRequisitesModalTypes | null) => {
    this.clientRequisitesModalType = modalType;
  };

  setHardwareStockScreenModalType = (modalType: HardwareStockScreenModalTypes | null) => {
    this.hardwareStockScreenModalType = modalType;
  };

  setRegistrantsScreenModalType = (modalType: RegistrantsScreenModalTypes | null) => {
    this.registrantsScreenModalType = modalType;
  };

  setToolsScreenModalType = (modalType: ToolsScreenModalTypes | null) => {
    this.toolsScreenModalType = modalType;
  };

  setDocumentsScreenModalType = (modalType: DocumentsScreenModalTypes | null, options?: ModalsStoreSetterOptions) => {
    this.setModalType('documentsScreenModalType')(modalType, options);
  };

  setPassesScreenModalType = (modalType: PassesScreenModalTypes | null, options?: ModalsStoreSetterOptions) => {
    this.setModalType('passesScreenModalType')(modalType, options);
  };

  setUsersScreenModalType = (modalType: UsersScreenModalTypes | null, options?: ModalsStoreSetterOptions) => {
    this.setModalType('usersScreenModalType')(modalType, options);
  };

  setServicesScreenModalType = (modalType: ServicesScreenModalTypes | null, options?: ModalsStoreSetterOptions) => {
    this.setModalType('servicesScreenModalType')(modalType, options);
  };

  private setModalType =
    (type: keyof this) => (modalType: MultipleAbleModalTypes | null, options?: ModalsStoreSetterOptions) => {
      const set = this[type] as Set<MultipleAbleModalTypes>;

      if (!modalType) {
        set.clear();
        return;
      }

      if (options?.replace) {
        set.clear();
        set.add(modalType);
        return;
      }

      if (options?.close) {
        set.delete(modalType);
        return;
      }

      set.add(modalType);
    };
}
