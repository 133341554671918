import { lazy } from 'react';

import { withSuspense } from 'base/HOCs/withSuspense';
import RouteHelper from 'base/routes/helpers/RouteHelper';
import { RoutesType } from 'base/routes/types/RouteTypes';
import { TranslationsHelper, _lk } from 'helpers/TranslationsHelper';
import ForgotPasswordScreen from 'screens/auth/ForgotPasswordScreen';
import LoginScreen from 'screens/auth/LoginScreen';
import RegisterScreen from 'screens/auth/RegisterScreen';
import ForbiddenScreen from 'screens/forbidden/ForbiddenScreen';
import NotFoundScreen from 'screens/not-found/NotFoundScreen';

const SessionExpiredScreen = LoginScreen;

// developer help screens
const IconsScreen = withSuspense(lazy(() => import('screens/icons/IconsScreen')));

const MainScreen = withSuspense(lazy(() => import('screens/main/MainScreen')));
const ProfileScreen = withSuspense(lazy(() => import('screens/profile/ProfileScreen')));
const MonitoringScreen = withSuspense(lazy(() => import('screens/monitoring/MonitoringScreen')));
const ToolsScreen = withSuspense(lazy(() => import('screens/tools/ToolsScreen')));
const BillsScreen = withSuspense(lazy(() => import('screens/bills/BillsScreen')));
const UsersScreen = withSuspense(lazy(() => import('screens/users/UsersScreen')));
const UserScreen = withSuspense(lazy(() => import('screens/users/UserScreen')));
const PassesScreen = withSuspense(lazy(() => import('screens/passes/PassesScreen')));
const DocumentsScreen = withSuspense(lazy(() => import('screens/documents/DocumentsScreen')));
const ServicesScreen = withSuspense(lazy(() => import('screens/services/ServicesScreen')));
const ServiceScreen = withSuspense(lazy(() => import('screens/services/ServiceScreen')));
const ServiceObjectScreen = withSuspense(lazy(() => import('screens/services/ServiceObjectScreen')));
const HardwareStockScreen = withSuspense(lazy(() => import('screens/hardware-stock/HardwareStockScreen')));
const HardwareOrderScreen = withSuspense(lazy(() => import('screens/hardware-order/HardwareOrderScreen')));

const ClientRequisitesScreen = withSuspense(lazy(() => import('screens/client-requisites/ClientRequisitesScreen')));
const ClientRequisitesEditScreen = withSuspense(
  lazy(() => import('screens/client-requisites/ClientRequisitesEditScreen'))
);

const RegistrantsScreen = withSuspense(lazy(() => import('screens/registrants/RegistrantsScreen')));
const RegistrantScreen = withSuspense(lazy(() => import('screens/registrants/RegistrantScreen')));
const CreateRegistrantScreen = withSuspense(lazy(() => import('screens/registrants/CreateRegistrantScreen')));
const EditRegistrantRequisitesScreen = withSuspense(
  lazy(() => import('screens/registrants/EditRegistrantRequisitesScreen'))
);

const devScreens = { IconsScreen };

const screens = {
  MainScreen,

  ProfileScreen,

  MonitoringScreen,
  ToolsScreen,
  BillsScreen,
  UsersScreen,
  UserScreen,
  PassesScreen,
  DocumentsScreen,
  HardwareOrderScreen,
  HardwareStockScreen,
  ForbiddenScreen,

  RegistrantsScreen,
  RegistrantScreen,
  EditRegistrantRequisitesScreen,
  CreateRegistrantScreen,

  ServicesScreen,
  ServiceScreen,
  ServiceObjectScreen,

  ClientRequisitesScreen,
  ClientRequisitesEditScreen,

  NotFoundScreen,
};

const authScreens = {
  LoginScreen,
  SessionExpiredScreen,
  ForgotPasswordScreen,
  RegisterScreen,
  NotFoundScreen,
};

const err404Title = _lk('404 Страница не найдена');

type RoutesKeys = keyof typeof screens;
export const routes: RoutesType<RoutesKeys> = {
  MainScreen: {
    path: '/',
    title: _lk('Главная'),
    element: <MainScreen />,
  },
  ProfileScreen: {
    path: '/profile',
    title: _lk('Профиль'),
    element: <ProfileScreen />,
  },
  MonitoringScreen: {
    path: '/monitoring',
    title: _lk('Мониторинг'),
    element: <MonitoringScreen />,
  },
  ToolsScreen: {
    path: '/tools',
    title: _lk('Инструменты'),
    element: <ToolsScreen />,
  },
  BillsScreen: {
    path: '/bills',
    title: _lk('Счета'),
    element: <BillsScreen />,
  },
  UsersScreen: {
    path: '/users',
    title: TranslationsHelper.users,
    element: <UsersScreen />,
  },
  UserScreen: {
    path: '/users/:userid',
    title: '',
    element: <UserScreen />,
  },
  DocumentsScreen: {
    path: '/documents',
    title: _lk('Документы'),
    element: <DocumentsScreen />,
  },
  HardwareOrderScreen: {
    path: '/hardware-order',
    title: _lk('Заказ оборудования'),
    element: <HardwareOrderScreen />,
  },
  HardwareStockScreen: {
    path: '/hardware-stock',
    title: _lk('Склад'),
    element: <HardwareStockScreen />,
  },
  PassesScreen: {
    path: '/passes',
    title: _lk('Пропуски в ЦОД'),
    element: <PassesScreen />,
  },
  ClientRequisitesScreen: {
    path: '/client-requisites',
    title: _lk('Реквизиты'),
    element: <ClientRequisitesScreen />,
  },
  ClientRequisitesEditScreen: {
    path: '/client-requisites/edit',
    title: _lk('Редактирование реквизитов'),
    element: <ClientRequisitesEditScreen />,
  },
  RegistrantsScreen: {
    path: '/registrants',
    title: _lk('Регистранты'),
    element: <RegistrantsScreen />,
  },
  RegistrantScreen: {
    path: '/registrants/view/:registrantid',
    title: _lk('Регистрант'),
    element: <RegistrantScreen />,
  },
  CreateRegistrantScreen: {
    path: '/registrants/create',
    title: _lk('Регистранты'),
    element: <CreateRegistrantScreen />,
  },
  EditRegistrantRequisitesScreen: {
    path: '/registrants/view/:registrantid/edit-requisites',
    title: _lk('Регистрант'),
    element: <EditRegistrantRequisitesScreen />,
  },
  ServicesScreen: {
    path: '/services',
    title: _lk('Услуги'),
    element: <ServicesScreen />,
  },
  ServiceScreen: {
    path: '/services/:subagrid',
    title: '',
    element: <ServiceScreen />,
  },
  ServiceObjectScreen: {
    path: '/services/:subagrid/object/:objectid',
    title: '',
    element: <ServiceObjectScreen />,
  },
  ForbiddenScreen: {
    path: '/forbidden',
    title: _lk('Доступ запрещен'),
    element: <ForbiddenScreen />,
  },
  NotFoundScreen: {
    path: '*',
    title: err404Title,
    element: <NotFoundScreen />,
  },
};

// Auth Routes
type AuthRouteKeys = keyof typeof authScreens;
export const authRoutes: RoutesType<AuthRouteKeys> = {
  LoginScreen: {
    path: '/login',
    title: TranslationsHelper.entrance,
    element: <LoginScreen />,
  },

  SessionExpiredScreen: {
    path: `/login/:${RouteHelper.SESSION_PARAM}`,
    title: TranslationsHelper.entrance,
    element: <LoginScreen />,
  },

  ForgotPasswordScreen: {
    path: '/forgot-password',
    title: _lk('Восстановление пароля'),
    element: <ForgotPasswordScreen />,
  },

  RegisterScreen: {
    path: '/register',
    title: _lk('Регистрация'),
    element: <RegisterScreen />,
  },

  NotFoundScreen: {
    path: '*',
    title: err404Title,
    element: <NotFoundScreen />,
  },
};

type DevRouteKeys = keyof typeof devScreens;
export const devRoutes: RoutesType<DevRouteKeys> = {
  IconsScreen: {
    path: '/icons',
    title: 'Иконки',
    element: <IconsScreen />,
  },
};
