import { AxiosRequestConfig } from 'axios';

import { modelFactory } from 'base/ModelFactory';
import { ResponseSuccess } from 'base/models/CommonModels';
import { NewAccessTokenSuccess } from 'modules/auth/models/NewAccessTokenSuccess';

import NewAccessTokenDto from '../../dto/NewAccessTokenDto';
import { OAuth2ApiRepository } from './OAuth2ApiRepository';

export class OAuth2Service {
  oAuth2ApiRepository: OAuth2ApiRepository;

  constructor() {
    this.oAuth2ApiRepository = new OAuth2ApiRepository();
  }

  getNewAccessToken = async (data: { refreshToken: string; accessToken: string }) => {
    const { refreshToken, accessToken } = data;
    const dto = modelFactory.create<NewAccessTokenDto>(NewAccessTokenDto, {
      refreshToken,
      currentAccessToken: accessToken,
    });
    const newTokens = await this.oAuth2ApiRepository.getNewAccessToken(dto);

    return modelFactory.create<NewAccessTokenSuccess>(NewAccessTokenSuccess, newTokens);
  };

  logOut = async (config: AxiosRequestConfig) => {
    const data = await this.oAuth2ApiRepository.logOut(config);
    return modelFactory.create<ResponseSuccess>(ResponseSuccess, data);
  };
}
