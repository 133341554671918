import { AxiosRequestConfig } from 'axios';

import ApiHelper from 'base/helpers/ApiHelper';

import NewAccessTokenDto from '../../dto/NewAccessTokenDto';

export class OAuth2ApiRepository {
  readonly apiUrl = ApiHelper.getApiUrl();

  getNewAccessToken = async ({ refreshToken, currentAccessToken }: NewAccessTokenDto) => {
    const newTokens = await fetch(`${this.apiUrl}/auth/new-access-token`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken, currentAccessToken }),
    });
    const data = await newTokens.json();

    return data.data as { accessToken: string; accessExpired: number };
  };

  logOut = async (config: AxiosRequestConfig) => {
    const res = await fetch(`${this.apiUrl}/auth/logout`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Token': config.headers?.['X-Api-Token'] as string,
      },
    });

    const data = await res.json();

    return data.data as { success: boolean };
  };
}
