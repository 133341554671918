import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { ICommonModalProps } from 'interfaces/UIComponentsInterfaces';
import { FC } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';
import ChangeMFAMethod from 'components/MFA/ChangeMFAMethod';
import QModal from 'components/UI/Modal/QModal';
import { TranslationsHelper } from 'helpers/TranslationsHelper';
import { MFAMessengerType } from 'modules/mfa/models/MFAMessengerType';
import { ChangeClientModalTypes } from 'modules/modal/types/ChangeClientTypes';
import { useCommonStyles } from 'styles/commonStyles';

interface IChangeMFAMethodModalProps extends ICommonModalProps {
  methods?: MFAMessengerType[] | null;
  onMessengerTypeClick?: (messengertypeid: number) => () => void;
}

const ChangeMFAMethodModal: FC<IChangeMFAMethodModalProps> = ({ close, onMessengerTypeClick, methods }) => {
  const commonClasses = useCommonStyles();
  const { modalsStore } = useRootStore();

  // Handlers
  const handleStepBackClick = () => {
    modalsStore.setChangeClientModalType(ChangeClientModalTypes.CODE_CONFIRMATION);
  };

  // Renders
  return (
    <QModal close={close} headerTitle={TranslationsHelper.mfaChangeMethod} centerContent>
      <Box className={commonClasses.stepBack}>
        <IconButton onClick={handleStepBackClick}>
          <ChevronLeft color="primary" />
        </IconButton>
      </Box>
      <ChangeMFAMethod methods={methods} onMessengerTypeClick={onMessengerTypeClick} />
    </QModal>
  );
};

export default ChangeMFAMethodModal;
