import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import MobileSearch from 'components/Header/Search/MobileSearch';
import MobileSidebar from 'components/Sidebar/MobileSidebar';
import { QIcon } from 'components/UI/QIcon/QIcon';
import { QIconIds } from 'components/UI/QIcon/QIconIds';
import { ThemeTypes } from 'modules/theme-toggle/types/ThemeToggleTypes';
import { useCommonStyles } from 'styles/commonStyles';

import NotificationsButton from './Notifications/NotificationsButton';
import SupportMenu from './SupportMenu/SupportMenu';

import MobileLogoWhite from 'assets/images/vector/mobile-logo-white.svg';
import MobileLogo from 'assets/images/vector/mobile-logo.svg';

interface IMobileHeaderProps {}

const MobileHeader: FC<IMobileHeaderProps> = observer(() => {
  const {
    themeToggleStore: { theme },
  } = useRootStore();
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  // Handlers
  const handleToggleOpen = () => {
    setIsOpen(o => !o);
  };

  // Renders
  return (
    <>
      <Box component="header" width="100%" className={classes.header}>
        <Box className={classes.headerLogoBlock}>
          <IconButton onClick={handleToggleOpen}>
            <QIcon id={QIconIds.hamburger} className={commonClasses.greenIcon} />
          </IconButton>
          <Link to={routes.MainScreen.path}>
            <img src={theme === ThemeTypes.DARK ? MobileLogoWhite : MobileLogo} alt="mobile-logo" />
          </Link>
        </Box>
        <Box className={classes.headerActionsBlock}>
          <MobileSearch />
          <NotificationsButton />
          <SupportMenu />
        </Box>
      </Box>
      <MobileSidebar isOpen={isOpen} onToggle={handleToggleOpen} />
    </>
  );
});

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    gap: theme.spacing(4),
    position: 'fixed',
    top: 0,
    zIndex: 99,
    alignItems: 'center',
    padding: theme.spacing(3),
    minHeight: theme.spacing(16),
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(1)} ${theme.palette.customColors.shadow}`,
    backgroundColor: theme.palette.background.default,
  },
  headerLogoBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    '& > a': {
      height: theme.spacing(5.5),
    },
  },
  headerActionsBlock: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
}));

export default MobileHeader;
