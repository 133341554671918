import { AuthTokenData } from 'modules/auth/models/AuthTokenData';

import TokenApiRepository from './repositories/TokenApiRepository';

export default class TokenService {
  tokenApi: TokenApiRepository;

  constructor() {
    this.tokenApi = new TokenApiRepository();
  }

  saveToken = (token: AuthTokenData) => {
    this.tokenApi.setAccessToken(token);
  };

  deleteToken = () => {
    this.tokenApi.clearAccessToken();
  };
}
