import { modelFactory } from 'base/ModelFactory';

import ErrorModel from './ErrorModel';
import { IErrorData } from './interfaces/ErrorInterfaces';

export default class ErrorsService {
  getErrors = (err: any) => {
    const error = modelFactory.create<ErrorModel>(ErrorModel, err);
    const errorMessages = error.errorData;
    const errorCodes = [1008, 1042, 0, 1083];

    if (!Array.isArray(errorMessages) && errorMessages) {
      return errorMessages;
    } else {
      if (errorCodes.includes(error.errorCode ?? 0)) {
        return { error: error.errorMessage } as IErrorData;
      }

      return null;
    }
  };
}
