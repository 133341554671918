import AbstractApiRepository from 'base/api/AbstractApiRepository';
import { DataPatch } from 'base/models/CommonModels';

import DeleteRegistrantDto from './dto/DeleteRegistrantDto';

export default class RegistrantsApiRepository extends AbstractApiRepository {
  getRegistrants = () => {
    return this.apiClient.get({ url: '/registrant/index' });
  };

  getRegistrant = (registrantid: number) => {
    return this.apiClient.get({ url: `/registrant/item/?registrantid=${registrantid}` });
  };

  getRegistrantCard = (queryParams: string) => {
    return this.apiClient.get({ url: `/registrant/show/${queryParams}` });
  };

  validateRegistrant = (data: Record<string, string | null>) => {
    return this.apiClient.post({ url: '/registrant/validate', data });
  };

  updateRegistrant = (data: DataPatch) => {
    return this.apiClient.post({ url: '/registrant/update', data });
  };

  createRegistrant = (data: DataPatch) => {
    return this.apiClient.post({ url: '/registrant/create', data });
  };

  deleteRegistrant = (data: DeleteRegistrantDto) => {
    return this.apiClient.post({ url: '/registrant/delete', data });
  };
}
