interface CountableItemOptions {
  itemsCount?: number | null;
  items: {
    oneItem: string;
    twoItems: string;
    fiveItems: string;
  };
}

export default class OthersHelper {
  static getDigitWithZeroPrefix(num: number) {
    return num >= 10 ? `${num}` : `0${num}`;
  }

  static getCountableItem({ itemsCount, items }: CountableItemOptions) {
    const { oneItem, twoItems, fiveItems } = items;

    if (!itemsCount) {
      return fiveItems;
    }

    const value = Math.abs(itemsCount) % 100;
    const num = value % 10;

    if (value > 10 && value < 20) {
      return fiveItems;
    }

    if (num > 1 && num < 5) {
      return twoItems;
    }

    if (num === 1) {
      return oneItem;
    }

    return fiveItems;
  }

  static getRandomChar = (str: string) => {
    const arr = str.split('');
    const randomIndex = Math.floor(Math.random() * (arr.length - 1));

    return arr[randomIndex];
  };

  static getSomeRandomChars = (str: string, count: number) => {
    let result = '';

    for (let i = 0; i < count; i++) {
      result += OthersHelper.getRandomChar(str);
    }

    return result;
  };

  static shuffleArray = (array: any[]) => {
    let result = [...array];

    for (let i = result.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [result[i], result[j]] = [result[j], result[i]];
    }

    return result;
  };

  static getFirstElementFromLevel = (element: Element, level: number): Element | undefined => {
    if (level === 0) {
      return element;
    }

    return this.getFirstElementFromLevel(element.children[0], level - 1);
  };
}
