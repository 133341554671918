import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum PingFormFields {
  dcid = 'dcid',
  urlForPing = 'urlForPing',
  ipv6 = 'ipv6',
  numberOfPackages = 'numberOfPackages',
}

export class PingForm extends BaseForm {
  dcid: number = 0;
  urlForPing: string = '';
  ipv6: number = 0;
  numberOfPackages: number = 1;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.dcid = 0;
    this.urlForPing = '';
    this.ipv6 = 0;
    this.numberOfPackages = 1;
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(PingFormFields),
    });
  };
}
