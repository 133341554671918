import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useRootStore } from 'base/hooks/useRootStore';

import { AuthCard } from './components/AuthCard';

interface IRegisterScreenProps {}

const RegisterScreen: FC<IRegisterScreenProps> = observer(() => {
  const {
    authStore,
    languageToggleStore: { _lk },
  } = useRootStore();

  // Handlers

  // Renders
  return <AuthCard title={_lk('Регистрация')}></AuthCard>;
});

export default RegisterScreen;
