import { jsonProperty, Serializable } from 'ts-serializable';

import { Hop } from './Hop';

export class Traceroute extends Serializable {
  @jsonProperty(String) raw: string = '';
  @jsonProperty(String) url: string = '';
  @jsonProperty(String) ip: string = '';
  @jsonProperty(String) hops_max: string = '';
  @jsonProperty(String) byte_packets: string = '';
  @jsonProperty(Array) hops: Hop[] = [];
}
