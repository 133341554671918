import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum ServicesPaymentFormFields {
  defaultPeriod = 'defaultPeriod',
  paytype = 'paytype',
}

export class ServicesPaymentForm extends BaseForm {
  defaultPeriod: number = 0;
  paytype: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.defaultPeriod = 0;
    this.paytype = '';
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(ServicesPaymentFormFields),
    });
  };
}
