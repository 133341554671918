import { jsonProperty, Serializable } from 'ts-serializable';

import { AttentionText } from './AttentionText';
import { PrecalculationInfo } from './PrecalculationInfo';
import { PrecalculationParams } from './PrecalculationParams';

export class Precalculation extends Serializable {
  @jsonProperty(String) infoText: string = '';
  @jsonProperty(Array) info: PrecalculationInfo[] = [];
  @jsonProperty(Object, null) attentionText: AttentionText | null = null;
  @jsonProperty(Object, null) params: PrecalculationParams | null = null;
}
