import SortFactory from './SortFactory';
import SortModel from './models/SortModel';
import SortValue from './models/SortValue';
import { SortOrdersEnum } from './types/SortTypes';

export default class SortService {
  sortFactory: SortFactory;

  constructor() {
    this.sortFactory = new SortFactory();
  }

  getSortModel = (sort: any): SortModel => {
    return this.sortFactory.create<SortModel>(SortModel, sort);
  };

  getSortValue = (currentOrderBy: string, sortValue: SortValue): SortValue => {
    const model = this.sortFactory.create<SortValue>(SortValue, sortValue);
    const isAsc = sortValue?.orderBy === currentOrderBy && sortValue.order?.toLowerCase() === SortOrdersEnum.asc;
    const isDesc = currentOrderBy === sortValue?.orderBy && sortValue.order?.toLowerCase() === SortOrdersEnum.desc;
    const order = isAsc ? SortOrdersEnum.desc : SortOrdersEnum.asc;

    if (isDesc) {
      model.orderBy = null;
      return model;
    }

    model.orderBy = currentOrderBy;
    model.order = order;

    return model;
  };

  getSort = (sortValue: SortValue | null): SortModel => {
    const model = sortValue;

    if (model?.orderBy && model?.order === SortOrdersEnum.desc) {
      return this.getSortModel({ orderBy: '-' + model.orderBy });
    }

    return this.getSortModel({ orderBy: model?.orderBy });
  };
}
