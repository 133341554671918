import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum VisitorFormFields {
  fio = 'fio',
}

export class VisitorForm extends BaseForm {
  fio: string | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(VisitorFormFields),
    });
  };
}
