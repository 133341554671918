import { Box } from '@mui/material';
import 'animate.css/animate.min.css';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { Loader } from 'components/UI/Loader';

import RouteHelper from '../helpers/RouteHelper';
import AuthRoutes from './AuthRoutes';
import UnAuthRoutes from './UnAuthRoutes';

export const AllRoutes: React.FC = observer(() => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const location = useLocation();

  const isExpired = location.pathname.includes(RouteHelper.EXPIRED);
  const isForbidden = location.pathname.includes(RouteHelper.FORBIDDEN);
  const isFromAuthRoutesPath =
    !!Object.values(authRoutes).find(route => route.path === location.pathname) && !authStore.isAuth;

  const goToLogin = () => {
    if (isExpired) {
      navigate(
        RouteHelper.makePath(authRoutes.SessionExpiredScreen.path, [
          { p: RouteHelper.SESSION_PARAM, v: RouteHelper.EXPIRED },
        ])
      );
      return;
    }

    if (isForbidden) {
      navigate(
        RouteHelper.makePath(authRoutes.SessionExpiredScreen.path, [
          { p: RouteHelper.SESSION_PARAM, v: RouteHelper.FORBIDDEN },
        ])
      );
      return;
    }

    if (isFromAuthRoutesPath) {
      navigate(location.pathname);
      return;
    }

    navigate(authRoutes.LoginScreen.path);
  };

  // Effects
  useEffect(() => {
    authStore.checkAuth(goToLogin);
  }, [authStore.isAuth]);

  // Renders
  if (!authStore.completeCheckAuth) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100dvh', width: '100%' }}>
        <Loader size={64} />
      </Box>
    );
  }

  return (
    <>
      {authStore.isAuth ? <AuthRoutes /> : <UnAuthRoutes />}
      <ReactNotifications />
    </>
  );
});
