import AbstractApiRepository from 'base/api/AbstractApiRepository';

export default class ServicePaymentApiRepository extends AbstractApiRepository {
  precalculate = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/pre-calculation${query}` });
  };

  precalculationPool = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/pre-calculation-pool${query}` });
  };

  getPayMethods = (query: string) => {
    return this.apiClient.get({ url: `/pay-methods/index${query}` });
  };

  getRenewals = (query: string) => {
    return this.apiClient.get({ url: `/subagrs/renewal${query}` });
  };
}
