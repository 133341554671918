import SortValue from '../models/SortValue';

export class SortHelper {
  static getFormattedSortValue(sortValue: SortValue) {
    if (!sortValue.orderBy) {
      return {};
    }

    return { [`sort[${sortValue.orderBy}]`]: sortValue.order?.toUpperCase() };
  }
}
