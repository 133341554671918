import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { FC, ReactElement, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useRootStore } from 'base/hooks/useRootStore';
import { ChangeClientForm, ChangeClientFormFields } from 'modules/auth/forms/ChangeClientForm';
import { changeClientFormScheme } from 'modules/auth/schemes/AuthScheme';
import { ChangeClientModalTypes } from 'modules/modal/types/ChangeClientTypes';

import ChangeClientSuccessModal from './ChangeClientSuccessModal';
import ChangeMFAMethodModal from './ChangeMFAMethodModal';
import CodeConfirmationModal from './CodeConfirmationModal';

interface IChangeClientModalsProps {}

const ChangeClientModals: FC<IChangeClientModalsProps> = observer(() => {
  const { authStore, clientsStore, modalsStore } = useRootStore();

  const isMultipleMFAMethods = useMemo(
    () => !!(authStore.mfaTypes && authStore.mfaTypes.length > 1),
    [authStore.mfaTypes]
  );

  const form = useForm<ChangeClientForm>({
    mode: 'onChange',
    resolver: yupResolver(changeClientFormScheme) as any,
    defaultValues: authStore.changeClientForm,
  });

  const client = clientsStore.clients?.find(
    client => client.clientid === form.getValues()[ChangeClientFormFields.clientid]
  );

  // Handlers
  const closeModal = () => {
    modalsStore.setChangeClientModalType(null);
  };

  const handleMessengerTypeClick = (messengertypeid: number) => () => {
    form.reset({ ...authStore.changeClientForm, mfacode: null, messengertypeid });
    const values = form.getValues() as ChangeClientForm;

    if (!client) {
      return;
    }

    authStore.changeClient(values, client, () => {
      modalsStore.setChangeClientModalType(ChangeClientModalTypes.CODE_CONFIRMATION);
    });
  };

  const modals: { [k in keyof typeof ChangeClientModalTypes]: ReactElement } = {
    [ChangeClientModalTypes.CODE_CONFIRMATION]: (
      <FormProvider {...form}>
        <CodeConfirmationModal
          isLoading={authStore.isChangingClient}
          close={closeModal}
          mfa={authStore.changeClientAuth?.mfa}
          showVariants={isMultipleMFAMethods}
          client={client}
          deadline={authStore.changeClientDeadline}
          changeClient={authStore.changeClient}
        />
      </FormProvider>
    ),
    [ChangeClientModalTypes.CHANGE_MFA_METHOD]: (
      <ChangeMFAMethodModal
        close={closeModal}
        methods={authStore.mfaTypes}
        onMessengerTypeClick={handleMessengerTypeClick}
      />
    ),
    [ChangeClientModalTypes.CLIENT_CHANGE_SUCCESS]: <ChangeClientSuccessModal close={closeModal} />,
  };

  // Renders
  return modalsStore.changeClientModalType && modals[modalsStore.changeClientModalType];
});

export default ChangeClientModals;
