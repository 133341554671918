import { FieldValues } from 'react-hook-form';

import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';
import { rootStore } from 'base/RootStore';
import { UpdateDataDto, ValidateDataDto } from 'base/dto/CommonDto';
import FieldsFactory from 'base/modules/fields/FieldsFactory';
import { FieldTypes } from 'base/modules/fields/enums/FieldsEnums';
import { Field } from 'base/modules/fields/models/Field';
import { AddressTypes } from 'modules/addresses/enums/AddressesEnums';
import { AddressesHelper } from 'modules/addresses/helpers/AddressesHelper';

import { ClientSummary } from './models/ClientSummary';
import { Country } from './models/Country';
import { PayType } from './models/PayType';
import { ClientRequisites } from './models/requisites/ClientRequisites';
import { ClientRequisiteType } from './models/requisites/ClientRequisitesType';

export default class ClientsFactory extends ModelFactory {
  fieldsFactory: FieldsFactory;

  constructor() {
    super();
    this.fieldsFactory = new FieldsFactory();
  }

  createRequisites = (data: any) => {
    const dataEntries = Object.entries(data).map(([key, value]) => [key, this.createClientRequisiteType(value)]);

    return Object.fromEntries(dataEntries) as ClientRequisites;
  };

  createClientSummary = (data: any) => {
    const summary = this.create<ClientSummary>(ClientSummary, data);

    if (data.country) {
      summary.country = this.create<Country>(Country, data.country);
    }

    if (data.defultPaytype) {
      summary.defultPaytype = this.create<PayType>(PayType, data.defultPaytype);
    }

    return summary;
  };

  createUpdateRequisitesDto = (form: FieldValues) => {
    const keys = Object.keys(form);
    const formWithManualAddressFields = keys.some(key => key.includes('__'));

    if (!formWithManualAddressFields) {
      return Dto.populate<UpdateDataDto>(UpdateDataDto, {
        data: { ...form },
      });
    }

    const addressFieldNames = keys
      .filter(key => AddressesHelper.isAddressField(key))
      .filter(addressFieldName => keys.some(key => key.startsWith(`${addressFieldName}__`)));
    const entries = Object.entries(form);
    const addressFieldValues = addressFieldNames.reduce((prev, curr) => {
      const values = entries.reduce((previous, current) => {
        const [fieldName, fieldValue] = current;

        if (!fieldName.startsWith(`${curr}__`)) {
          return { ...previous };
        }
        delete form[fieldName];
        return { ...previous, [fieldName.split('__')[1]]: fieldValue };
      }, {});
      return { ...prev, [curr]: values };
    }, {});

    return Dto.populate<UpdateDataDto>(UpdateDataDto, {
      data: { ...form, ...addressFieldValues },
    });
  };

  createValidateRequisitesDto = (form: FieldValues) => {
    return Dto.populate<ValidateDataDto>(ValidateDataDto, {
      data: { ...form },
    });
  };

  private createClientRequisiteType = (data: any) => {
    const requisite = this.create<ClientRequisiteType>(ClientRequisiteType, data);
    const fields = this.fieldsFactory.createFieldsList(data.fields);

    const fieldsWithAddInfo: Field[] = [];
    fields.forEach(field => {
      if (AddressesHelper.isAddressField(field.name)) {
        const allValue = field.value.split('  ');

        const addressValue = allValue[0];
        const addressField = this.fieldsFactory.createField({ ...field, value: addressValue });
        fieldsWithAddInfo.push(addressField);

        const addInfoValue = allValue[1];
        const addInfoField = this.fieldsFactory.createField({
          hint: null,
          label: rootStore.languageToggleStore._lk('Дополнительная информация'),
          name: AddressesHelper.addressesAddInfoFieldNames[field.name as AddressTypes],
          type: FieldTypes.string,
          value: addInfoValue,
          minValue: null,
          maxValue: null,
          mask: [],
          possibleValues: [],
        });
        fieldsWithAddInfo.push(addInfoField);
      } else {
        fieldsWithAddInfo.push(field);
      }
    });

    requisite.fields = fieldsWithAddInfo;

    return requisite;
  };
}
