import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum UserFormFields {
  email1 = 'email1',
  phone2 = 'phone2',
  lastname = 'lastname',
  firstname = 'firstname',
  midname = 'midname',
  dolgnost = 'dolgnost',
  password = 'password',
  confirm = 'confirm',
}

export class UserForm extends BaseForm {
  id: number | null = null;
  email1: string = '';
  phone2: string = '';
  lastname: string = '';
  firstname: string = '';
  midname: string = '';
  dolgnost: string = '';
  password: string = '';
  confirm: string = '';
  avatar: string = '';

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.id = null;
    this.email1 = '';
    this.phone2 = '';
    this.lastname = '';
    this.firstname = '';
    this.midname = '';
    this.dolgnost = '';
    this.password = '';
    this.confirm = '';
    this.avatar = '';
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(UserFormFields),
    });
  };
}
