import { jsonProperty, Serializable } from 'ts-serializable';

import { Visitor } from './Visitor';

export class Pass extends Serializable {
  @jsonProperty(Number, null) dcpid: number | null = null;
  @jsonProperty(Number, null) dcid: number | null = null;
  @jsonProperty(Number, null) haveautomobile: number | null = null;
  @jsonProperty(Number, null) dcvcid: number | null = null;
  @jsonProperty(Number, null) status: number | null = null;
  @jsonProperty(Number, null) useridcreated: number | null = null;
  @jsonProperty(Number, String, null) useridprocessed: number | null = null;
  @jsonProperty(String, null) dtvisitdate: string | null = null;
  @jsonProperty(String, null) dtvisittime: string | null = null;
  @jsonProperty(String, null) dtcreated: string | null = null;
  @jsonProperty(String, null) dtprocessed: string | null = null;
  @jsonProperty(String, null) descr: string | null = null;
  @jsonProperty(String, null) carnumber: string | null = null;
  @jsonProperty(String, null) carname: string | null = null;
  @jsonProperty(String, null) carmodel: string | null = null;
  @jsonProperty(String, null) statusTitle: string | null = null;
  @jsonProperty(Array, null) visitors: Visitor[] | null = null;
}
