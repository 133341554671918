import { FC, PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';

import QScrollbar from 'components/UI/QScrollbar';

interface IScreenLayoutProps {
  className?: string;
  splashScreen?: boolean;
}

const ScreenLayout: FC<PropsWithChildren<IScreenLayoutProps>> = ({ children, className, splashScreen }) => {
  // Renders
  return isMobile ? (
    <>{children}</>
  ) : (
    <QScrollbar
      maxHeight={splashScreen ? 'unset' : 'calc(100vh - 64px)'}
      minHeight={splashScreen ? 'calc(100vh - 64px)' : 'unset'}
      className={className}
    >
      {children}
    </QScrollbar>
  );
};

export default ScreenLayout;
