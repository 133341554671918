import { jsonProperty, Serializable } from 'ts-serializable';

import { Packets } from './Packets';
import { Query } from './Query';
import { Speed } from './Speed';

export class Ping extends Serializable {
  @jsonProperty(String) url: string = '';
  @jsonProperty(Array) queries: Query[] = [];
  @jsonProperty(Object) packets: Packets | null = null;
  @jsonProperty(Object) speed: Speed | null = null;
}
