import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';

import RouteHelper from '../helpers/RouteHelper';
import { AppRoutes } from './AppRoutes';

interface IUnAuthRoutesProps {}

const UnAuthRoutes: FC<IUnAuthRoutesProps> = observer(() => {
  const { authStore, languageToggleStore } = useRootStore();

  const location = useLocation();
  const navigate = useNavigate();

  const isForbidden = location.pathname.includes(RouteHelper.FORBIDDEN);

  useEffect(() => {
    if (isForbidden) {
      return;
    }
    const lastCalledPathname = `${location.pathname}${location.search}`;
    authStore.setLastCalledAuthPathname(lastCalledPathname);
  }, [isForbidden, location.pathname, location.search]);

  useEffect(() => {
    authStore.getServerStatus({
      loader: true,
      callback: () => {
        languageToggleStore.getLanguagesList();
      },
    });

    if (!authStore.forbiddenError) {
      return;
    }

    navigate(
      RouteHelper.makePath(authRoutes.SessionExpiredScreen.path, [
        { p: RouteHelper.SESSION_PARAM, v: RouteHelper.FORBIDDEN },
      ])
    );
  }, [authStore.forbiddenError]);

  // Renders
  return <AppRoutes routes={authRoutes} />;
});

export default UnAuthRoutes;
