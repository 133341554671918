import { Box } from '@mui/material';
import { ComponentType, Suspense } from 'react';

import { Loader } from 'components/UI/Loader';

export function withSuspense<P>(Component: ComponentType & any) {
  return (props: P) => (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100dvh - 64px)',
            width: '100%',
          }}
        >
          <Loader size={64} />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );
}
