import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { authRoutes } from 'routes/routes';

import { useRootStore } from 'base/hooks/useRootStore';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { Loader } from 'components/UI/Loader';
import PasswordTextField from 'components/UI/TextFields/PasswordTextField';
import QTextFieldWithController from 'components/UI/TextFields/QTextFieldWithController';
import { LoginFormFields } from 'modules/auth/forms/LoginForm';
import { useLoginFormPersistance } from 'screens/auth/hooks/useLoginFormPersistance';
import { useCommonStyles } from 'styles/commonStyles';

interface ILoginStepProps {
  isLoading: boolean;
  errors: IErrorData | null;
}

const LoginStep: FC<ILoginStepProps> = ({ isLoading, errors }) => {
  const {
    languageToggleStore: { _lk, translations },
  } = useRootStore();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const { control, formState, setError } = useFormContext();

  const loginErrorMessage = formState.errors[LoginFormFields.login]?.message;
  const loginErrorState = !!loginErrorMessage || loginErrorMessage === '';
  const passErrorMessage = formState.errors[LoginFormFields.pass]?.message;
  const passErrorState = !!passErrorMessage || passErrorMessage === '';

  // Effects
  useEffect(() => {
    if (errors) {
      setError(LoginFormFields.login, { message: errors[LoginFormFields.login] });
      setError(LoginFormFields.pass, { message: errors[LoginFormFields.pass] });
    }
  }, [errors]);

  useLoginFormPersistance();

  // Renders
  return (
    <>
      <Box mt={10} mb={8}>
        <QTextFieldWithController
          autoComplete="login"
          name={LoginFormFields.login}
          placeholder={_lk('Номер телефона')}
          hookFormProps={{ control }}
          errors={loginErrorMessage}
          error={loginErrorState}
        />
      </Box>
      <Box mb={5.5}>
        <PasswordTextField
          autoComplete="pass"
          name={LoginFormFields.pass}
          placeholder={_lk('Пароль')}
          hookFormProps={{ control }}
          errors={passErrorMessage}
          error={passErrorState}
          endIconClassName={commonClasses.greenIcon}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mb={7.5}>
        <Link to={authRoutes.ForgotPasswordScreen.path} className={classes.forgotLink}>
          {translations.forgotPassword}
        </Link>
      </Box>
      <Box className={commonClasses.loginButtons}>
        <Button variant="contained" fullWidth type="submit" disabled={!formState.isValid}>
          {isLoading ? <Loader color="#fff" size={24} /> : translations.enter}
        </Button>
        <Link to={authRoutes.RegisterScreen.path} className="router-link">
          <Button variant="contained-contrast" fullWidth type="button">
            {translations.regAction}
          </Button>
        </Link>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  forgotLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
  },
  forgotButton: {
    padding: 0,
    ...theme.typography.subtitle2,
  },
}));

export default LoginStep;
