import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum VerificationFormFields {
  userid = 'userid',
  phone = 'phone',
  code = 'code',
}

export class VerificationForm extends BaseForm {
  userid: number | null = null;
  phone: string = '';
  code: string | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.userid = null;
    this.phone = '';
    this.code = null;
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(VerificationFormFields),
    });
  };
}
