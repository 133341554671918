import { FC } from 'react';

import ChangeMFAMethod from 'components/MFA/ChangeMFAMethod';
import { MFAMessengerType } from 'modules/mfa/models/MFAMessengerType';

interface IMFAMethodChoiceStepProps {
  methods?: MFAMessengerType[];
  onMessengerTypeClick: (messengertypeid: number) => () => void;
}

const MFAMethodChoiceStep: FC<IMFAMethodChoiceStepProps> = ({ methods, onMessengerTypeClick }) => {
  return <ChangeMFAMethod methods={methods} onMessengerTypeClick={onMessengerTypeClick} />;
};

export default MFAMethodChoiceStep;
