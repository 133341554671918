import { ModelFactory } from 'base/ModelFactory';
import { ClientRequisites } from 'modules/clients/models/requisites/ClientRequisites';

import { DomainModel } from './models/DomainModel';
import { Registrant } from './models/Registrant';

export class RegistrantsFactory extends ModelFactory {
  createRegistrants = (data: any[]) => {
    return data.map(this.createRegistrant);
  };

  createRegistrant = (data: any) => {
    const model = this.create<Registrant>(Registrant, data);

    if (data.domains) {
      model.domains = data.domains.map((domain: any, index: number) =>
        this.createDomainModel(domain, `${data.registrantid} - ${index}`)
      );
    }

    return model;
  };

  createDomainModel = (data: any, domainid: string) => {
    return this.create<DomainModel>(DomainModel, {
      ...data,
      domainid: domainid,
    });
  };

  createRequiredRequisites = (items: ClientRequisites) => {
    const result: Record<string, boolean> = {};

    Object.values(items).forEach(value => {
      value?.fields?.forEach(field => {
        if (field.required) {
          result[field.name] = false;
        }
      });
    });

    return result;
  };
}
