import SortValue from 'base/modules/sort/models/SortValue';
import { SortOrders } from 'base/modules/sort/types/SortTypes';

import TablesFactory from './TablesFactory';

export default class TablesService {
  private tablesFactory: TablesFactory;

  constructor() {
    this.tablesFactory = new TablesFactory();
  }

  getTablePaginationData = (page: number, currentLimit: number): { page: number; limit: number } => {
    const limit = currentLimit;
    return { page, limit };
  };

  getCurrentSortValue = (currentSort: string, sortDirection: SortOrders): SortValue => {
    return this.tablesFactory.create<SortValue>(SortValue, { orderBy: currentSort, order: sortDirection });
  };
}
