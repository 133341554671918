import { alpha, Components, createTheme, Theme } from '@mui/material/styles';

import { LightThemeColors } from 'styles/colors/light';
import { theme } from 'styles/muiTheme';

import {
  buttonGroupOverrides,
  buttonOverrides,
  cssBaselineOverrides,
  formLabelOverrides,
  inputCommonOverrides,
  tableOverrides,
  tabsOverrides,
} from './common-overrides';

export const lightTheme = createTheme({
  ...theme,
  palette: {
    mode: 'light',
    primary: {
      main: LightThemeColors.primaryMain,
      light: LightThemeColors.primaryLight,
      dark: LightThemeColors.primaryDark,
    },
    secondary: { main: LightThemeColors.secondaryMain, dark: LightThemeColors.secondaryDark },
    error: { main: LightThemeColors.errorMain, light: LightThemeColors.errorLight, dark: LightThemeColors.errorDark },
    background: { default: LightThemeColors.background, paper: LightThemeColors.surface },
    action: {
      active: LightThemeColors.active,
      hover: LightThemeColors.hover,
      selected: LightThemeColors.selected,
      disabled: LightThemeColors.disabled,
      disabledBackground: LightThemeColors.disabledBackground,
      focus: LightThemeColors.focus,
    },
    customColors: {
      overlay: LightThemeColors.overlay,
      modalOverlay: LightThemeColors.modalOverlay,
      border: LightThemeColors.border,
      shadow: LightThemeColors.shadowColor,
      statusRed: LightThemeColors.statusRed,
      statusGreen: LightThemeColors.statusGreen,
      statusYellow: LightThemeColors.statusYellow,
      statusGrey: LightThemeColors.statusGrey,
      partnersProgram: LightThemeColors.partnersProgram,
      surfaceTertiary: LightThemeColors.surfaceTertiary,
    },
  },
  components: {
    ...cssBaselineOverrides,
    ...formLabelOverrides,
    MuiOutlinedInput: {
      styleOverrides: {
        ...inputCommonOverrides,
        root: {
          ...inputCommonOverrides.root,
          backgroundColor: LightThemeColors.overlay,
          minHeight: 40,
          '&.Mui-error': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: LightThemeColors.statusRed,
              backgroundColor: alpha(LightThemeColors.statusRed, 0.16),
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: LightThemeColors.statusRed,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        ...buttonOverrides,
        root: {
          ...buttonOverrides.root,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: LightThemeColors.primaryDark,
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained-contrast' },
          style: {
            backgroundColor: LightThemeColors.secondaryMain,
            color: LightThemeColors.primaryMain,
            '&:hover': {
              backgroundColor: LightThemeColors.secondaryDark,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: LightThemeColors.primaryMain,
            ...theme.typography.subtitle2,
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        ...buttonGroupOverrides,
      },
    },
    ...(tableOverrides(LightThemeColors.surfaceTertiary) as Components<Omit<Theme, 'components'>>),
    ...(tabsOverrides(LightThemeColors.surfaceTertiary) as Components<Omit<Theme, 'components'>>),
  },
});
