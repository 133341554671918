import dayjs, { Dayjs } from 'dayjs';

import OthersHelper from 'helpers/OthersHelper';

interface SplitOptions {
  originalDelimiter: string;
  requiredDelimiter: string;
  parts: number;
}

export class DateHelper {
  static readonly hours24 = 1000 * 60 * 60 * 24;
  static readonly date1991 = '1991-01-01 00:00:00';

  static getDayJSFormattedDate(
    dateString: string | null,
    options: SplitOptions = { originalDelimiter: '.', requiredDelimiter: '-', parts: 3 }
  ) {
    const { originalDelimiter, requiredDelimiter } = options;

    if (!dateString) {
      return null;
    }

    return dateString.split(originalDelimiter).reverse().join(requiredDelimiter);
  }

  static getDayJSFormattedTime(
    timeString: string,
    options: SplitOptions = { originalDelimiter: ':', requiredDelimiter: ':', parts: 2 }
  ) {
    const { originalDelimiter, requiredDelimiter } = options;
    return timeString.split(originalDelimiter).slice(0, options.parts).join(requiredDelimiter);
  }

  static getDayJSObjectFromDateAndTimeStrings(date?: string | null, time?: string | null) {
    if (!date || !time) {
      return null;
    }

    return dayjs(
      `${this.getDayJSFormattedDate(date)}T${this.getDayJSFormattedTime(time, {
        originalDelimiter: ':',
        requiredDelimiter: ':',
        parts: 3,
      })}.000Z`
    );
  }

  static getFormattedDate(dayjsObject: Dayjs | null) {
    if (!dayjsObject || !dayjsObject?.isValid()) {
      return null;
    }

    const date = [dayjsObject.date(), OthersHelper.getDigitWithZeroPrefix(dayjsObject.month() + 1), dayjsObject.year()];

    return date.join('.');
  }

  static getFormattedTime(dayjsObject: Dayjs | null) {
    if (!dayjsObject || !dayjsObject?.isValid()) {
      return null;
    }

    const time = [
      OthersHelper.getDigitWithZeroPrefix(dayjsObject.hour()),
      OthersHelper.getDigitWithZeroPrefix(dayjsObject.minute()),
    ];

    return time.join(':');
  }

  static getDayJsObjectFromTimestamp(timestamp: number) {
    return dayjs(timestamp);
  }

  static getDate(date?: string) {
    const formatDate = (str: string) =>
      str
        .split('T')
        .map((value: string) => (value.includes('.') ? value.split('.')[0] : value))
        .join(' ');

    if (date) {
      return formatDate(dayjs(date).toISOString());
    }

    return formatDate(dayjs(Date.now()).toISOString());
  }

  static getPresentDateDiff(dateStr: number | string | null) {
    const date = typeof dateStr === 'string' ? dayjs(this.getDayJSFormattedDate(dateStr)) : dayjs(dateStr);
    const present = dayjs();

    return date.diff(present);
  }
}
