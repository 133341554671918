import { IQTableFilter } from 'interfaces/TableInterfaces';
import { makeAutoObservable, runInAction } from 'mobx';

import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';
import { TableHelper } from 'modules/tables/helpers/TableHelper';

import HardwareStockService from './HardwareStockService';
import { HardwareStockFilters } from './enums/HardwareStockEnums';
import { HardwareStockTableForm } from './forms/HardwareStockTableForm';
import { DeviceCategory } from './models/DeviceCategory';
import { DeviceLocation } from './models/DeviceLocation';
import { DeviceVendor } from './models/DeviceVendor';
import { Hardware } from './models/Hardware';

export class HardwareStockStore {
  loadingHardwareList: boolean = false;
  showTableLoader: boolean = false;

  loadingDeviceCategories: boolean = false;
  loadingDeviceVendors: boolean = false;
  loadingDeviceLocations: boolean = false;

  tableForm: HardwareStockTableForm = HardwareStockTableForm.createObservable();
  prevTableForm: Record<string, any> = {};

  hardwareList: Hardware[] | null = null;

  deviceCategories: DeviceCategory[] | null = null;
  deviceVendors: DeviceVendor[] | null = null;
  deviceLocations: DeviceLocation[] | null = null;

  private hardwareStockService: HardwareStockService;

  constructor() {
    makeAutoObservable(this);
    this.hardwareStockService = new HardwareStockService();

    this.tableForm.setAction(TableFormDynamicActions.getList, this.getHardwareList);
  }

  get hardwareListIds() {
    if (!this.hardwareList) {
      return [];
    }

    return this.hardwareList.map(({ hwiid }) => hwiid);
  }

  get hardwareFilterOptions(): { [k: string]: IQTableFilter[] | undefined } {
    return {
      [HardwareStockFilters.CATEGORY]: this.deviceCategories?.map(({ devcid, devcname }) => ({
        id: devcid,
        title: devcname,
      })),
      [HardwareStockFilters.VENDOR]: this.deviceVendors?.map(({ hwvenid, hwvenname }) => ({
        id: hwvenid,
        title: hwvenname,
      })),
      [HardwareStockFilters.LOCATION]: this.deviceLocations?.map(({ storetype, name }) => ({
        id: storetype,
        title: name,
      })),
    };
  }

  get appliedFiltersCount() {
    return Object.values(this.tableForm.filters).reduce((prev, curr) => prev + curr.length, 0);
  }

  getHardwareList = () => {
    if (this.loadingHardwareList) {
      return;
    }

    TableHelper.tableFormEqualityCheck(
      this.tableForm,
      this.prevTableForm,
      this.setShowTableLoader,
      this.setPrevTableForm
    );

    this.setLoadingHardwareList(true);

    this.hardwareStockService
      .getHardwareList(this.tableForm)
      .then(({ items, paginator }) => {
        this.setHardwareList(items);
        runInAction(() => {
          this.tableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoadingHardwareList(false);
        this.setShowTableLoader(false);
      });
  };

  getCategories = () => {
    if (this.loadingDeviceCategories) {
      return;
    }

    if (this.deviceCategories !== null) {
      return;
    }

    this.setLoadingDeviceCategories(true);

    this.hardwareStockService
      .getCategories()
      .then(this.setDeviceCategories)
      .catch(() => {})
      .finally(() => {
        this.setLoadingDeviceCategories(false);
      });
  };

  getVendors = () => {
    if (this.loadingDeviceVendors) {
      return;
    }

    if (this.deviceVendors !== null) {
      return;
    }

    this.setLoadingDeviceVendors(true);

    this.hardwareStockService
      .getVendors()
      .then(this.setDeviceVendors)
      .catch(() => {})
      .finally(() => {
        this.setLoadingDeviceVendors(false);
      });
  };

  getLocations = () => {
    if (this.loadingDeviceLocations) {
      return;
    }

    if (this.deviceLocations !== null) {
      return;
    }

    this.setLoadingDeviceLocations(true);

    this.hardwareStockService
      .getLocations()
      .then(this.setDeviceLocations)
      .catch(() => {})
      .finally(() => {
        this.setLoadingDeviceLocations(false);
      });
  };

  // loadings
  setLoadingHardwareList = (state: boolean) => {
    this.loadingHardwareList = state;
  };

  setLoadingDeviceCategories = (state: boolean) => {
    this.loadingDeviceCategories = state;
  };

  setLoadingDeviceVendors = (state: boolean) => {
    this.loadingDeviceVendors = state;
  };

  setLoadingDeviceLocations = (state: boolean) => {
    this.loadingDeviceLocations = state;
  };

  // setters
  setHardwareList = (hardwares: Hardware[] | null) => {
    this.hardwareList = hardwares;
  };

  setDeviceCategories = (categories: DeviceCategory[] | null) => {
    this.deviceCategories = categories;
  };

  setDeviceVendors = (vendors: DeviceVendor[] | null) => {
    this.deviceVendors = vendors;
  };

  setDeviceLocations = (locations: DeviceLocation[] | null) => {
    this.deviceLocations = locations;
  };

  setShowTableLoader = (state: boolean) => {
    this.showTableLoader = state;
  };

  setPrevTableForm = (state: Record<string, any>) => {
    this.prevTableForm = state;
  };
}
