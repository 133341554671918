import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { DateHelper } from 'helpers/DateHelper';
import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';
import { TableHelper } from 'modules/tables/helpers/TableHelper';

import PassesService from './PassesService';
import { CarForm } from './forms/CarForm';
import { PassForm, PassFormFields } from './forms/PassForm';
import { PassesTableForm } from './forms/PassesTableForm';
import { VisitorForm } from './forms/VisitorForm';
import { Car } from './models/Car';
import { DataCenter } from './models/DataCenter';
import { Pass } from './models/Pass';
import { Visitor } from './models/Visitor';

export class PassesStore {
  // loadings
  passesListLoading: boolean = false;
  showTableLoader: boolean = false;

  dataCentersListLoading: boolean = false;
  visitorsListLoading: boolean = false;
  carsListLoading: boolean = false;
  passIsProcessing: boolean = false;
  addingVisitor: boolean = false;
  addingCar: boolean = false;

  // forms
  tableForm: PassesTableForm = PassesTableForm.createObservable();
  prevTableForm: Record<string, any> = {};

  passForm: PassForm = PassForm.createObservable();
  visitorForm: VisitorForm = VisitorForm.createObservable();
  carForm: CarForm = CarForm.createObservable();

  // lists
  passes: Pass[] | null = null;
  currentPass: Pass | null = null;

  allVisitors: Visitor[] | null = null;
  dataCenters: DataCenter[] | null = null;
  cars: Car[] | null = null;

  private passesService: PassesService;

  constructor() {
    makeAutoObservable(this);
    this.passesService = new PassesService();

    this.tableForm.setAction(TableFormDynamicActions.getList, this.getPassesList);

    reaction(() => this.currentPass, this.onCurrentPassChange);
  }

  // lists
  getPassesList = () => {
    if (this.passesListLoading) {
      return;
    }

    TableHelper.tableFormEqualityCheck(
      this.tableForm,
      this.prevTableForm,
      this.setShowTableLoader,
      this.setPrevTableForm
    );

    this.setPassesListLoading(true);

    this.passesService
      .getPassesList(this.tableForm)
      .then(({ items, paginator }) => {
        runInAction(() => {
          this.passes = items;
          this.tableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setPassesListLoading(false);
        this.setShowTableLoader(false);
      });
  };

  getDataCentersList = () => {
    if (this.dataCentersListLoading) {
      return;
    }
    this.setDataCentersListLoading(true);

    this.passesService
      .getDataCenterList()
      .then(this.setDataCenters)
      .catch(() => {})
      .finally(() => {
        this.setDataCentersListLoading(false);
      });
  };

  getVisitorsList = () => {
    if (this.visitorsListLoading) {
      return;
    }
    this.setVisitorsListLoading(true);

    this.passesService
      .getVisitorsList()
      .then(this.setAllVisitors)
      .catch(() => {})
      .finally(() => {
        this.setVisitorsListLoading(false);
      });
  };

  getCarsList = () => {
    if (this.carsListLoading) {
      return;
    }
    this.setCarsListLoading(true);

    this.passesService
      .getCarsList()
      .then(this.setCars)
      .catch(() => {})
      .finally(() => {
        this.setCarsListLoading(false);
      });
  };

  // pass actions
  cancelPass = (pass: Pass) => {
    if (this.passIsProcessing) {
      return;
    }

    this.setPassIsProcessing(true);

    this.passesService
      .cancelPass(pass)
      .then(() => {
        this.getPassesList();
      })
      .catch(() => {})
      .finally(() => {
        this.setPassIsProcessing(false);
      });
  };

  orderPass = (pass: PassForm | Pass, callback?: () => void) => {
    if (this.passIsProcessing) {
      return;
    }

    this.setPassIsProcessing(true);

    this.passesService
      .orderPass(pass)
      .then(newPass => {
        this.setCurrentPass(newPass);
        this.getPassesList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPassIsProcessing(false);
      });
  };

  savePass = (pass: PassForm, callback?: () => void) => {
    if (this.passIsProcessing) {
      return;
    }
    this.setPassIsProcessing(true);

    this.passesService
      .savePass(pass)
      .then(() => {
        this.getPassesList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPassIsProcessing(false);
      });
  };

  deletePass = (pass: Pass, callback?: () => void) => {
    if (this.passIsProcessing) {
      return;
    }
    this.setPassIsProcessing(true);

    this.passesService
      .deletePass(pass)
      .then(() => {
        this.getPassesList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setPassIsProcessing(false);
      });
  };

  addCar = (car: CarForm, callback?: () => void) => {
    if (this.addingCar) {
      return;
    }
    this.setAddingCar(true);

    this.passesService
      .addCar(car)
      .then(() => {
        this.getCarsList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setAddingCar(false);
      });
  };

  addVisitor = (visitor: VisitorForm, callback?: () => void) => {
    if (this.addingVisitor) {
      return;
    }
    this.setAddingVisitor(true);

    this.passesService
      .addVisitor(visitor)
      .then(() => {
        this.getVisitorsList();
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setAddingVisitor(false);
      });
  };

  // reactions
  private onCurrentPassChange = (pass: Pass | null) => {
    if (!pass) {
      this.passForm.reset();
      return;
    }

    const date = DateHelper.getDayJSObjectFromDateAndTimeStrings(pass.dtvisitdate, pass.dtvisittime);

    this.passForm.setValue(PassFormFields.status, pass.status);
    this.passForm.setValue(PassFormFields.dcid, pass.dcid ? +pass.dcid : null);
    this.passForm.setValue(PassFormFields.dcpid, pass.dcpid);
    this.passForm.setValue(PassFormFields.dtvisitdate, date);
    this.passForm.setValue(PassFormFields.dtvisittime, date);
    this.passForm.setValue(PassFormFields.visitor, pass.visitors);

    if (pass.haveautomobile) {
      this.passForm.setValue(PassFormFields.car, {
        dcvcid: pass.dcvcid,
        carmodel: pass.carmodel,
        carnumber: pass.carnumber,
      });
    }
  };

  // Loadings
  private setPassesListLoading = (state: boolean) => {
    this.passesListLoading = state;
  };

  private setPassIsProcessing = (state: boolean) => {
    this.passIsProcessing = state;
  };

  private setDataCentersListLoading = (state: boolean) => {
    this.dataCentersListLoading = state;
  };

  private setVisitorsListLoading = (state: boolean) => {
    this.visitorsListLoading = state;
  };

  private setCarsListLoading = (state: boolean) => {
    this.carsListLoading = state;
  };

  private setAddingVisitor = (state: boolean) => {
    this.addingVisitor = state;
  };

  private setAddingCar = (state: boolean) => {
    this.addingCar = state;
  };

  // Setters
  setCurrentPass = (pass: Pass | null) => {
    this.currentPass = pass;
  };

  setShowTableLoader = (state: boolean) => {
    this.showTableLoader = state;
  };

  setPrevTableForm = (form: Record<string, any>) => {
    this.prevTableForm = form;
  };

  private setCars = (cars: Car[] | null) => {
    this.cars = cars;
  };

  private setAllVisitors = (visitors: Visitor[] | null) => {
    this.allVisitors = visitors;
  };

  private setDataCenters = (dataCenters: DataCenter[] | null) => {
    this.dataCenters = dataCenters;
  };
}
