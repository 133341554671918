import AbstractApiRepository from 'base/api/AbstractApiRepository';

import { MFATurnOnDto } from './dto/MFATurnOnDto';

export class MFAApiRepository extends AbstractApiRepository {
  getListOfAccessibleMessengers = () => {
    return this.apiClient.get({ url: '/client-messenger-chats/index?notificationType=0' });
  };

  getMFAStatus = () => {
    return this.apiClient.get({ url: '/client/mfa-status' });
  };

  turnMFAOn = (data: MFATurnOnDto) => {
    return this.apiClient.post({ url: '/client-messenger-chats/turn-on', data });
  };

  turnLastMFAOff = () => {
    return this.apiClient.get({ url: '/client/mfa-turn-off' });
  };

  turnMFAOff = (queryParams: string) => {
    return this.apiClient.get({ url: `/client-messenger-chats/turn-off${queryParams}` });
  };

  setDefaultMessenger = (queryParams: string) => {
    return this.apiClient.get({ url: `/client-messenger-chats/set-default-chat${queryParams}` });
  };

  sendTestMessage = (queryParams: string) => {
    return this.apiClient.get({ url: `/client-messenger-chats/send-test-message${queryParams}` });
  };
}
