import { Box, FormLabel, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IQTextFieldProps } from 'interfaces/UIComponentsInterfaces';
import { FC, forwardRef, useState } from 'react';

import { Loader } from '../Loader';
import QHelperText from '../QHelperText';
import { QIcon } from '../QIcon/QIcon';

const QTextField: FC<IQTextFieldProps> = forwardRef((props, ref) => {
  const {
    loading,
    label,
    fieldInfo,
    required,
    requiredIcon,
    labelProps,
    startIconId,
    startIconClassName,
    endIconId,
    endIconClassName,
    InputProps,
    errors,
    name,
    containerProps,
    ...rest
  } = props;
  const classes = useStyles();

  const [focused, setFocused] = useState(false);

  // Handlers
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  // Renders
  const renderIcon = (id: string, iconClassName?: string) => () => {
    return (
      <IconButton color="secondary" className={classes.startIcon}>
        {<QIcon id={id} width={18} height={18} className={iconClassName} />}
      </IconButton>
    );
  };

  const renderRequiredIcon = () => {
    if (!required) {
      return null;
    }

    return requiredIcon ? requiredIcon : '*';
  };

  return (
    <Box textAlign="left" {...containerProps}>
      {label && (
        <FormLabel color={focused ? 'primary' : 'secondary'} {...labelProps}>
          <Box display="flex" alignItems="center">
            {label} {renderRequiredIcon()}
          </Box>
        </FormLabel>
      )}
      <Box position="relative">
        {loading ? (
          <Loader size={24} isFlexItem minWidth="100%" />
        ) : (
          <TextField
            fullWidth
            autoComplete="off"
            ref={ref}
            name={name}
            color="secondary"
            variant="outlined"
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={!!errors}
            helperText={errors?.length ? <QHelperText errors={errors} /> : undefined}
            InputProps={{
              startAdornment: startIconId && renderIcon(startIconId, startIconClassName)(),
              endAdornment: endIconId && renderIcon(endIconId, endIconClassName)(),
              ...InputProps,
            }}
            {...rest}
          />
        )}
      </Box>
    </Box>
  );
});

const useStyles = makeStyles(theme => ({
  startIcon: {},
  endIcon: {},
}));

export default QTextField;
