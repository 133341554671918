import { jsonProperty, Serializable } from 'ts-serializable';

export class ChangeClientDto extends Serializable {
  @jsonProperty(Number) mdid: number = 0;
  @jsonProperty(Number) clientid: number = 0;
  @jsonProperty(Number) userid: number = 0;
  @jsonProperty(Number) messengertypeid: number = 0;
  @jsonProperty(String) udid: string = '';
  @jsonProperty(String) mfacode: string = '';
}
