import { makeObservable } from 'mobx';

import { BaseForm } from 'base/BaseForm';

export enum DocumentFormFields {
  dtvalidity = 'dtvalidity',
  csdtid = 'csdtid',
  document = 'document',
}

export class DocumentForm extends BaseForm {
  dtvalidity: string = '';
  csdtid: number = 0;
  document: File[] | null = null;

  constructor(props?: any) {
    super(props);
    this.load(props);
  }

  reset() {
    this.document = null;
    this.csdtid = 0;
    this.dtvalidity = '';
  }

  static createObservable = () => {
    const context = new this();
    context.createObservable();

    return makeObservable(context, {
      ...context.makeObservableValues(DocumentFormFields),
    });
  };
}
