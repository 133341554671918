import { makeStyles } from '@mui/styles';

export const useCodeConfirmationModalStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    color: theme.palette.customColors.surfaceTertiary,
    textAlign: 'center',
  },
  hidden: {
    display: 'none',
  },
  button: {
    display: 'block',
    margin: theme.spacing(2, 'auto', 0),
  },
}));
