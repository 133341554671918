import ApiHelper from 'base/helpers/ApiHelper';
import PaginationService from 'base/modules/pagination/PaginationService';
import { SortHelper } from 'base/modules/sort/helpers/SortHelper';

import BillsApiRepository from './BillsApiRepository';
import BillsFactory from './BillsFactory';
import { BillsTableForm } from './forms/BillsTableForm';
import { ISeviceBillsFilters } from './interfaces/BillsInterfaces';

export default class BillsService {
  private billsApi: BillsApiRepository;
  private billsFactory: BillsFactory;
  private paginationService: PaginationService;

  constructor() {
    this.billsApi = new BillsApiRepository();
    this.billsFactory = new BillsFactory();
    this.paginationService = new PaginationService();
  }

  getBillsList = async ({ page, limit, query, sortValue, filters }: BillsTableForm) => {
    const sort = SortHelper.getFormattedSortValue(sortValue);

    const queryString = ApiHelper.getQueryString({ page, limit, query, ...sort, ...filters });

    const { data } = await this.billsApi.getBills(queryString);

    const items = this.billsFactory.createBillsList(data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };

  getServiceBillsList = async (filters: ISeviceBillsFilters, tableForm?: BillsTableForm) => {
    let queryString;

    if (!tableForm) {
      queryString = ApiHelper.getQueryString(filters, { straight: true });
    } else {
      const { sortValue, page, limit } = tableForm;
      const sort = SortHelper.getFormattedSortValue(sortValue);
      queryString = ApiHelper.getQueryString({ ...filters, ...sort, page, limit }, { straight: true });
    }

    const { data } = await this.billsApi.getBills(queryString);

    const items = this.billsFactory.createBillsList(data.data.items);
    const paginator = this.paginationService.getPagination(data.data.paginator);

    return { items, paginator };
  };
}
