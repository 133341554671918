import { makeAutoObservable, runInAction } from 'mobx';

import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';
import { TableHelper } from 'modules/tables/helpers/TableHelper';

import HardwareOrderService from './HardwareOrderService';
import { HardwaresTableForm } from './forms/HardwaresTableForm';
import { HardwareOrder } from './models/HardwareOrder';

export class HardwareOrderStore {
  loadingHardwareList: boolean = false;
  showTableLoader: boolean = false;

  tableForm: HardwaresTableForm = HardwaresTableForm.createObservable();
  prevTableForm: Record<string, any> = {};

  hardwareOrderList: HardwareOrder[] | null = null;

  private hardwareOrderService: HardwareOrderService;

  constructor() {
    makeAutoObservable(this);
    this.hardwareOrderService = new HardwareOrderService();

    this.tableForm.setAction(TableFormDynamicActions.getList, this.getHardwareOrderList);
  }

  getHardwareOrderList = () => {
    if (this.loadingHardwareList) {
      return;
    }

    TableHelper.tableFormEqualityCheck(
      this.tableForm,
      this.prevTableForm,
      this.setShowTableLoader,
      this.setPrevTableForm
    );

    this.setLoadingHardwareList(true);

    this.hardwareOrderService
      .getHardwareOrderList(this.tableForm)
      .then(({ items, paginator }) => {
        this.setHardwareOrderList(items);
        runInAction(() => {
          this.tableForm.setValues(paginator);
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setLoadingHardwareList(false);
        this.setShowTableLoader(false);
      });
  };

  // loadings
  setLoadingHardwareList = (state: boolean) => {
    this.loadingHardwareList = state;
  };

  // setters
  setHardwareOrderList = (hardwares: HardwareOrder[] | null) => {
    this.hardwareOrderList = hardwares;
  };

  setShowTableLoader = (state: boolean) => {
    this.showTableLoader = state;
  };

  setPrevTableForm = (state: Record<string, any>) => {
    this.prevTableForm = state;
  };
}
