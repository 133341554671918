import { ModelFactory } from 'base/ModelFactory';
import { DateHelper } from 'helpers/DateHelper';

import { DocumentForm } from './forms/DocumentForm';
import { DocumentToShow } from './models/DocumentToShow';
import { DocumentFile } from './models/file/DocumentFile';

export default class DocumentsFactory extends ModelFactory {
  createDocumentToShow = (data: any) => {
    const documentToShow = this.create<DocumentToShow>(DocumentToShow, data);

    if (data.files) {
      documentToShow.files = this.createList<DocumentFile>(DocumentFile, data.files);
    }

    return documentToShow;
  };

  prepareUploadDocumentFormData = (form: DocumentForm) => {
    const data = {
      ...form,
      dtvalidity:
        form.dtvalidity === ''
          ? null
          : DateHelper.getFormattedDate(DateHelper.getDayJsObjectFromTimestamp(+form.dtvalidity)),
    };

    const formData = new FormData();

    formData.append('csdtid', `${data.csdtid}`);

    if (data.dtvalidity) {
      formData.append('dtvalidity', data.dtvalidity);
    }

    if (data.document) {
      const length = data.document.length;

      for (let i = 0; i < length; i++) {
        formData.append(`document`, data.document[i]);
      }
    }

    return formData;
  };

  createDownloadableFile = (data: any) => {
    let contentDisposition = data.headers['content-disposition'];

    if (!contentDisposition) {
      contentDisposition = data.headers['Content-Disposition'];
    }

    const fileName = contentDisposition?.split(' ')[1].split('=')[1].replace(/\"/g, '');

    return { blob: data.data as Blob, fileName };
  };
}
