import { makeAutoObservable, runInAction } from 'mobx';
import { FieldValues } from 'react-hook-form';

import { ResponseSuccess } from 'base/models/CommonModels';
import ErrorsService from 'base/modules/errors/ErrorsService';
import { IErrorData } from 'base/modules/errors/interfaces/ErrorInterfaces';
import { FieldsHelper } from 'base/modules/fields/helpers/FieldsHelper';
import { Field } from 'base/modules/fields/models/Field';
import { FileValidationRules } from 'base/modules/file-validation/models/FileValidationRules';
import { TableFormDynamicActions } from 'modules/tables/forms/TableForm/TableForm';
import { TableHelper } from 'modules/tables/helpers/TableHelper';

import UsersService from './UsersService';
import { UserForm, UserFormFields } from './forms/UserForm';
import { UsersTableForm } from './forms/UsersTableForm';
import { VerificationForm } from './forms/VerificationForm';
import { ICheckUserPhone } from './interfaces/UsersInterfaces';
import { CreatedUser } from './models/CreatedUser';
import { Phone } from './models/Phone';
import { PhoneCheckResponse } from './models/PhoneCheckResponse';
import { User } from './models/User';
import { UserAvailableContacts } from './models/UserAvailableContacts';

export class UsersStore {
  loadingUsers: boolean = false;
  showTableLoader: boolean = false;

  loadingUser: boolean = false;
  loadingUserFields: boolean = false;
  loadingValidationRules: boolean = false;
  isCheckingUserPhone: boolean = false;
  creatingUser: boolean = false;
  updatingUser: boolean = false;
  userAvailableContactsLoading: boolean = false;

  errorMessages: IErrorData | null = null;

  avatarValidationRules: FileValidationRules | null = null;
  userFields: Field[] | null = null;
  phoneCheckResponse: PhoneCheckResponse | null = null;

  contactToChange: Field | null = null;
  phoneToChange: Phone | null = null;

  tableForm: UsersTableForm = UsersTableForm.createObservable();
  prevTableForm: Record<string, any> = {};

  userForm: UserForm = UserForm.createObservable();
  verificationForm: VerificationForm = VerificationForm.createObservable();

  users: User[] | null = null;

  createdUser: CreatedUser | null = null;
  currentUser: User | null = null;

  selectedUserAvailableContact: string = '';
  userAvailableContacts: UserAvailableContacts[] | null = null;

  private usersService: UsersService;
  private errorsService: ErrorsService;

  constructor() {
    makeAutoObservable(this);
    this.usersService = new UsersService();
    this.errorsService = new ErrorsService();

    this.tableForm.setAction(TableFormDynamicActions.getList, this.getUsers);
  }

  get aggregatedUserFieldsValues() {
    return FieldsHelper.aggregateFieldValues(this.userFields);
  }

  get fieldsWithoutContacts() {
    return this.userFields?.filter(
      field => field.name !== UserFormFields.phone2 && field.name !== UserFormFields.email1
    );
  }

  get phoneNeedVerification() {
    return this.userFields?.find(field => field.name === UserFormFields.phone2)?.needVerify;
  }

  getUsers = () => {
    if (this.loadingUsers) {
      return;
    }

    TableHelper.tableFormEqualityCheck(
      this.tableForm,
      this.prevTableForm,
      this.setShowTableLoader,
      this.setPrevTableForm
    );

    this.setLoadingUsers(true);

    this.usersService
      .getUsers(this.tableForm)
      .then(({ items, paginator }) => {
        this.setUsers(items);
        runInAction(() => {
          this.tableForm.setValues(paginator);
        });
      })
      .catch()
      .finally(() => {
        this.setLoadingUsers(false);
        this.setShowTableLoader(false);
      });
  };

  getUser = (userid: number) => {
    if (this.loadingUser) {
      return;
    }
    this.setLoadingUser(true);

    this.usersService
      .getUser(userid)
      .then(this.setCurrentUser)
      .catch(() => {})
      .finally(() => {
        this.setLoadingUser(false);
      });
  };

  checkUserPhone = (data: ICheckUserPhone, callback?: (res?: PhoneCheckResponse) => void) => {
    if (this.isCheckingUserPhone) {
      return;
    }
    this.setIsCheckingUserPhone(true);

    this.usersService
      .checkUserPhone(data)
      .then(res => {
        this.setPhoneCheckResponse(res);
        callback?.(res);
      })
      .catch(this.handleErrors)
      .finally(() => {
        this.setIsCheckingUserPhone(false);
      });
  };

  createUser = (form: UserForm, callback?: (id?: number) => void) => {
    if (this.creatingUser) {
      return;
    }
    this.setCreatingUser(true);

    this.usersService
      .createUser(form)
      .then(user => {
        this.setCreatedUser(user);
        callback?.(user.userid);
      })
      .catch(this.handleErrors)
      .finally(() => {
        this.setCreatingUser(false);
      });
  };

  updateUser = (form: UserForm, callback?: () => void) => {
    if (this.updatingUser) {
      return;
    }
    this.setUpdatingUser(true);

    this.usersService
      .updateUser(form)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUpdatingUser(false);
      });
  };

  deleteUser = (userid: number, callback?: () => void) => {
    if (this.updatingUser) {
      return;
    }
    this.setUpdatingUser(true);

    this.usersService
      .deleteUser(userid)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUpdatingUser(false);
      });
  };

  deleteUserAvatar = (userid: number, callback?: () => void) => {
    if (this.updatingUser) {
      return;
    }
    this.setUpdatingUser(true);

    this.usersService
      .deleteUserAvatar(userid)
      .then(() => {
        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUpdatingUser(false);
      });
  };

  validateUserData = (form: FieldValues, callback: (res: ResponseSuccess | (IErrorData | null)) => void) => {
    this.usersService
      .validateUserData(form)
      .then(res => {
        callback(res);
      })
      .catch(error => {
        const errors = this.errorsService.getErrors(error);
        callback(errors);
      })
      .finally(() => {});
  };

  getUserFields = (userid: number | null) => {
    if (this.loadingUserFields) {
      return;
    }
    this.setLoadingUserFields(true);

    this.usersService
      .getUserFields(userid)
      .then(this.setUserFields)
      .catch(() => {})
      .finally(() => {
        this.setLoadingUserFields(false);
      });
  };

  getValidationRules = () => {
    if (this.loadingValidationRules || this.avatarValidationRules) {
      return;
    }
    this.setLoadingValidationRules(true);

    this.usersService
      .getValidationRules()
      .then(this.setAvatarValidationRules)
      .catch(() => {})
      .finally(() => {
        this.setLoadingValidationRules(false);
      });
  };

  getUserContactsAvailable = (userId: number, forNotifications?: boolean, callback?: () => void) => {
    this.setUserAvailableContactsLoading(true);

    this.usersService
      .getUserContactsAvailable(userId, forNotifications)
      .then(data => {
        this.setUserAvailableContacts(data);

        callback?.();
      })
      .catch(() => {})
      .finally(() => {
        this.setUserAvailableContactsLoading(false);
      });
  };

  getField = (data: any) => {
    return this.usersService.getEmptyField(data);
  };

  private handleErrors = (error: any) => {
    const errors = this.errorsService.getErrors(error);
    this.setErrorMessages(errors);
  };

  // loadings
  setLoadingUsers = (state: boolean) => {
    this.loadingUsers = state;
  };

  setIsCheckingUserPhone = (state: boolean) => {
    this.isCheckingUserPhone = state;
  };

  setLoadingUser = (state: boolean) => {
    this.loadingUser = state;
  };

  setLoadingUserFields = (state: boolean) => {
    this.loadingUserFields = state;
  };

  setLoadingValidationRules = (state: boolean) => {
    this.loadingValidationRules = state;
  };

  setCreatingUser = (state: boolean) => {
    this.creatingUser = state;
  };

  setUpdatingUser = (state: boolean) => {
    this.updatingUser = state;
  };

  setUserAvailableContactsLoading = (state: boolean) => {
    this.userAvailableContactsLoading = state;
  };

  setSelectedUserAvailableContact = (contact: string) => {
    this.selectedUserAvailableContact = contact;
  };

  // setters
  setUsers = (users: User[] | null) => {
    this.users = users;
  };

  setPhoneCheckResponse = (response: PhoneCheckResponse | null) => {
    this.phoneCheckResponse = response;
  };

  setCurrentUser = (user: User | null) => {
    this.currentUser = user;
  };

  setAvatarValidationRules = (rules: FileValidationRules | null) => {
    this.avatarValidationRules = rules;
  };

  setUserFields = (fields: Field[] | null) => {
    this.userFields = fields;
  };

  setCreatedUser = (users: CreatedUser | null) => {
    this.createdUser = users;
  };

  setErrorMessages = (errors: IErrorData | null): void => {
    this.errorMessages = errors;
  };

  setContactToChange = (field: Field | null) => {
    this.contactToChange = field;
  };

  setPhoneToChange = (phone: Phone | null) => {
    this.phoneToChange = phone;
  };

  setPrevTableForm = (data: Record<string, any>) => {
    this.prevTableForm = data;
  };

  setShowTableLoader = (state: boolean) => {
    this.showTableLoader = state;
  };

  setUserAvailableContacts = (contacts: UserAvailableContacts[] | null) => {
    this.userAvailableContacts = contacts;
  };
}
