import { Serializable, jsonProperty } from 'ts-serializable';

import { IErrorData } from './interfaces/ErrorInterfaces';

export default class ErrorModel extends Serializable {
  @jsonProperty(Number, null) errorCode: number | null = null;
  @jsonProperty(String, null) errorCodeMessage: string | null = null;
  @jsonProperty(String, null) status: string | null = null;
  @jsonProperty(String, null) errorMessage: string | null = null;
  @jsonProperty(Object, null) errorData: IErrorData | IErrorData[] | null = null;
}
