import Dto from 'base/Dto';
import { ModelFactory } from 'base/ModelFactory';

import { ToolsHistoryDto } from './dto/ToolsHistoryDto';
import { ToolsHistoryForm } from './forms/ToolsHistoryForm';
import { Packets } from './models/Ping/Packets';
import { Ping } from './models/Ping/Ping';
import { Query } from './models/Ping/Query';
import { Speed } from './models/Ping/Speed';
import { Hop } from './models/Traceroute/Hop';
import { Package } from './models/Traceroute/Package';
import { Traceroute } from './models/Traceroute/Traceroute';

export class ToolsFactory extends ModelFactory {
  // traceroute
  private createHop = (data: any) => {
    const hop = this.create<Hop>(Hop, data);

    if (data.packages) {
      hop.packages = this.createList<Package>(Package, data.packages);
    }

    return hop;
  };

  private createHopsList = (data: any[]) => {
    return data.map(this.createHop);
  };

  createTraceroute = (data: any) => {
    const traceroute = this.create<Traceroute>(Traceroute, data);

    if (data.hops) {
      traceroute.hops = this.createHopsList(data.hops);
    }

    return traceroute;
  };

  // ping
  createPing = (data: any) => {
    const ping = this.create<Ping>(Ping, data);

    if (data.queries) {
      ping.queries = this.createList<Query>(Query, data.queries);
    }

    if (data.packets) {
      ping.packets = this.create<Packets>(Packets, data.packets);
    }

    if (data.speed) {
      ping.speed = this.create<Speed>(Speed, data.speed);
    }

    return ping;
  };

  getToolsHistoryDto = (data: ToolsHistoryForm) => {
    const preDto = { ...data };

    if (data.ipv6) {
      preDto.ipv6 = 1;
    } else {
      preDto.ipv6 = 0;
    }

    if (data.udp) {
      preDto.udp = 1;
    } else {
      preDto.udp = 0;
    }

    return Dto.populate<ToolsHistoryDto>(ToolsHistoryDto, preDto);
  };
}
